import { Toast as SToast, ToastHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const Toast = ({
	type = 'error',
	body,
	onClose = () => {},
	onMouseEnter,
	onMouseLeave,
}) => {
	return (
		<SToast
			className='toast content-enter-animation'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<ToastHeader className={type === 'error' && 'bg-danger text-light'}>
				{body}

				<FontAwesomeIcon
					icon={['far', 'xmark']}
					className='toast__close-btn'
					onClick={onClose}
				/>
			</ToastHeader>
		</SToast>
	)
}

export default Toast
