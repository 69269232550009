import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { setProjectFilters } from 'src/redux/actions/projectFilters'

import PropTypes from 'prop-types'
import { Select } from 'src/components/Select'
import { SCENARIOS, YEARS, DEFENDS, EPCS } from 'src/constants'
import ButtonGroup from '../../components/ButtonGroup'
import {
	getDefaultDefend,
	getDefaultEpcTargetYear,
	isProductionEnv,
} from '../../utils'
import { getAppConfig } from '../../config'
import Explainer from '../../components/Explainer'
import './styles.scss'

function _ProjectSummaryFilters({
	defaultScenario,
	defaultTargetEpc,
	setTargetEpc,
	defaultYear,
	defaultEpcTargetYear,
	userFeatureFlags,
	defaultDefend,
	onSelect,
	setProjectFilters,
}) {
	const [epcTargetYear, setEpcTargetYear] = useState(
		defaultEpcTargetYear || getDefaultEpcTargetYear()
	)

	const appConfig = getAppConfig()
	const isProductionMode = isProductionEnv()
	const enableMidRcps = userFeatureFlags['enable-mid-rcps'] || false
	const enableEpcs = userFeatureFlags['enable-epcs'] || false
	const enableSsps = userFeatureFlags['enable-ssps'] || false

	useEffect(() => {
		if (epcTargetYear !== defaultEpcTargetYear) {
			setEpcTargetYear(defaultEpcTargetYear)
		}
	}, [defaultEpcTargetYear])

	const defends = useMemo(
		() =>
			DEFENDS.map(({ value, displayValue }) => ({
				value,
				displayValue,
				type: 'secondary',
				isDisabled: !appConfig.featureFlags.isDefendsButtonEnabled,
			})),
		[]
	)

	const selectedDefend = useMemo(() => {
		const searchValue = appConfig.featureFlags.isDefendsButtonEnabled
			? defaultDefend
			: getDefaultDefend().value
		return DEFENDS.find(({ value }) => value === searchValue)
	}, [defaultDefend])

	const updatedScenarios = SCENARIOS.map((scenario) => {
		// Enable specific scenarios based on the feature flag and production environment
		if (
			isProductionMode &&
			enableMidRcps &&
			(scenario.value.includes('rcp45') || scenario.value.includes('rcp60'))
		) {
			return { ...scenario, disabled: false }
		}
		if (enableSsps && scenario.value.includes('ssp')) {
			return { ...scenario, disabled: false }
		}
		return scenario
	})
	const scenarios = updatedScenarios

	const years = YEARS
	const epcs = EPCS

	const handleSelect = (key, value) => {
		if (key === 'scenario') {
			const defended = defaultDefend === 'undefended' ? false : true
			setProjectFilters(value, defended)
		}
		onSelect({ [key]: value })
	}

	const handleFilterSelect = (key, option) => {
		const defended = option.value === 'undefended' ? false : true
		setProjectFilters(defaultScenario, defended)
		onSelect({ [key]: option.value })
	}

	return (
		<div
			id={`project-summary-settings${enableEpcs ? '' : '_no_epc'}`}
			className='project-summary-settings-wrapper'
		>
			<div className='project-summary-settings-wrapper__title'>Settings</div>
			<div className='row'>
				<div className='scenario-column'>
					<Select
						locatorId='select-scenario'
						wrapperId={`explainer-${
							enableEpcs ? 'scenario' : 'scenario_no_epc'
						}`}
						options={scenarios}
						selectedValue={defaultScenario}
						onChange={(scenario) => handleSelect('scenario', scenario)}
					/>
					<Explainer
						explainerKey={enableEpcs ? 'scenario' : 'scenario_no_epc'}
						placement='bottom'
					/>
				</div>
				<div className='year-column'>
					<Select
						locatorId='select-year'
						wrapperId='explainer-year'
						options={years}
						selectedValue={defaultYear}
						onChange={(year) => handleSelect('year', year)}
					/>
					<Explainer explainerKey='year' placement='bottom' />
				</div>
			</div>
			{enableEpcs ? (
				<div className='row'>
					<span className='epc-label-column'>Target EPC Rating:</span>
					<div className='epc-column'>
						<Select
							locatorId='select-target-epc-rating'
							wrapperId='explainer-target_epc'
							options={epcs}
							selectedValue={defaultTargetEpc}
							onChange={(targetEpc) => {
								handleSelect('targetEpc', targetEpc)
								setTargetEpc(targetEpc)
							}}
						/>
						<Explainer explainerKey='target_epc' placement='bottom' />
					</div>
					<div className='button-row'>
						<ButtonGroup
							locatorId='select-defences'
							wrapperId='explainer-defended_undefended'
							options={defends}
							defaultValue={selectedDefend}
							onChange={(defend) => handleFilterSelect('defended', defend)}
						/>
						<Explainer explainerKey='defended_undefended' placement='bottom' />
					</div>
				</div>
			) : (
				<div className='row'>
					<div className='button-row'>
						<ButtonGroup
							wrapperId='explainer-defended_undefended'
							options={defends}
							defaultValue={selectedDefend}
							onChange={(defend) => handleFilterSelect('defended', defend)}
						/>
						<Explainer explainerKey='defended_undefended' placement='bottom' />
					</div>
				</div>
			)}
		</div>
	)
}

_ProjectSummaryFilters.propTypes = {
	onSelect: PropTypes.func,
	defaultScenario: PropTypes.string,
	defaultTargetEpc: PropTypes.string,
	setTargetEpc: PropTypes.func.isRequired,
	defaultYear: PropTypes.string,
	defaultEpcTargetYear: PropTypes.string,
	defaultDefend: PropTypes.string,
	userFeatureFlags: PropTypes.object.isRequired,
}

export const ProjectSummaryFilters = connect((state) => ({}), {
	setProjectFilters,
})(_ProjectSummaryFilters)
