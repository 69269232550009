export const getReportButtonText = (
	isAssetsPdfLoading,
	pdfExportPercentage,
	assetsPdfExportData,
	urlType
) => {
	if (isAssetsPdfLoading) {
		return `Generating Report... ${pdfExportPercentage}%`
	}

	if (assetsPdfExportData && assetsPdfExportData[urlType]) {
		return 'Download Report'
	}

	return 'PDF Report'
}
