import { matchPath } from 'react-router-dom'
import { find } from 'lodash'
import {
	CLIMATE_X_USER_EXPIRE_TIME,
	CONFIRM_PASSWORD_ROUTE,
	CONFIRM_USER_ROUTE,
	ENABLE_MFA_ROUTE,
	LOCAL_STORAGE_KEY,
	LOGIN_ROUTE,
	SOMETHING_WENT_WRONG,
	VERIFY_MFA_ROUTE,
} from 'src/constants'
import {
	SET_COGNITO_SESSION,
	SET_USER_SETTINGS,
	SET_USER_DATA,
	SET_USER_DATA_LOADING,
	SIGN_OUT,
	UPDATE_USER_LENGTH_UNIT,
	UPDATE_USER_CURRENCY,
} from '../constants'
import { history } from '../store'
import { startUiLoading, stopUiLoading } from './ui'
import {
	createHomeLink,
	getErrorMessage,
	getIsUserAuthenticate,
	getUserFromLocalStorage,
	removeUserFromLocalStorage,
} from 'src/utils'
import { stopCsvExportPoll, clearPdfExportData, resetAssets } from './assets'
import { stopAnnouncementsPoll } from './announcements'
import { addToast } from './toasts'
import api from 'src/service/api'
import { clearAdaptData } from './adaptations'

export function setUserDataLoading(isLoading) {
	return { type: SET_USER_DATA_LOADING, isLoading }
}

export const SET_FORM_MESSAGES = 'setFormMessage'

export const autoSignInUser = () => async (dispatch, getState) => {
	const state = getState()
	const user = state.user.userData || getUserFromLocalStorage()

	if (!user) {
		dispatch(setUserDataLoading(false))
		// With these checks we allow user to open those routes in case user is not logged in.
		const isConfirmUserRoute = find([CONFIRM_USER_ROUTE], (route) =>
			matchPath(history.location.pathname, { path: route })
		)
		const isConfirmPasswordRoute = find([CONFIRM_PASSWORD_ROUTE], (route) =>
			matchPath(history.location.pathname, { path: route })
		)

		if (isConfirmPasswordRoute || isConfirmUserRoute) {
			return
		}

		if (history.location.pathname !== LOGIN_ROUTE) {
			history.replace(LOGIN_ROUTE)
		}
	} else {
		dispatch(setUserDataLoading(true))
		try {
			const loggedInUserAccessRights = localStorage.getItem(
				LOCAL_STORAGE_KEY.USER
			)
			const loggedInUserAccessRightsObject =
				loggedInUserAccessRights && JSON.parse(loggedInUserAccessRights)
			await dispatch(
				identifyAndSetUserData(loggedInUserAccessRightsObject, history)
			)
		} catch (error) {
			dispatch(
				setFormMessage({
					login: {
						type: 'error',
						message: error?.message || SOMETHING_WENT_WRONG,
					},
				})
			)
			dispatch(signOutUser())
		}
	}
}

export const signInUser = (email, password, history) => async (dispatch) => {
	dispatch(startUiLoading())
	dispatch(setFormMessage({ login: null }))
	localStorage.removeItem(CLIMATE_X_USER_EXPIRE_TIME)
	localStorage.removeItem(LOCAL_STORAGE_KEY.IS_LOGGED_IN_ON_HELP_PAGE)
	try {
		const loggedInUser = await api(
			'/login',
			{
				method: 'POST',
				json: {
					email: email,
					password: password,
				},
			},
			true
		)

		dispatch(setUserData({ accessRights: loggedInUser }))
		await dispatch(identifyAndSetUserData(loggedInUser, history))
		dispatch(stopUiLoading())
	} catch (error) {
		dispatch(
			setFormMessage({
				login: {
					type: 'error',
					message: getErrorMessage(error),
				},
			})
		)
		dispatch(stopUiLoading())
	}
}

const identifyAndSetUserData =
	(loggedInUserAccessRights, history) => async (dispatch) => {
		if (!loggedInUserAccessRights) {
			dispatch(signOut())
			return
		}

		try {
			const user = await api(
				'/me',
				{
					method: 'GET',
					headerOptions: {
						access_token: loggedInUserAccessRights?.access_token,
					},
				},

				true
			)

			if (user.mfa_enabled && !user.mfa_verified) {
				const registerMfa = await api(
					'/register-mfa',
					{
						method: 'POST',
						headerOptions: {
							access_token: loggedInUserAccessRights?.access_token,
						},
					},
					true
				)

				dispatch(
					setUserData({
						...user,
						...registerMfa,
						accessRights: loggedInUserAccessRights,
					})
				)
				history.push(ENABLE_MFA_ROUTE)
			} else if (user.mfa_enabled && user.mfa_verified) {
				dispatch(
					setUserData({ ...user, accessRights: loggedInUserAccessRights })
				)
				if (!getIsUserAuthenticate()) {
					history.push(VERIFY_MFA_ROUTE)
				} else {
					dispatch(fetchUserSettings())
				}
			} else if (!user.mfa_enabled) {
				localStorage.setItem(
					LOCAL_STORAGE_KEY.USER,
					JSON.stringify(loggedInUserAccessRights)
				)
				await dispatch(fetchUserSettings())
				dispatch(setUserData(user))
				history.replace(createHomeLink())
			}
		} catch (error) {
			throw error
		}
	}

export const validateMfa = (token) => async (dispatch, getState) => {
	try {
		dispatch(startUiLoading())
		const user = getState().user.userData

		await api(
			'/validate-mfa',
			{
				method: 'POST',
				json: {
					mfa_token: token,
				},
				headerOptions: {
					access_token: user.accessRights?.access_token,
				},
			},
			true
		)

		localStorage.setItem(
			LOCAL_STORAGE_KEY.USER,
			JSON.stringify(user.accessRights)
		)
		dispatch(fetchUserSettings())
		dispatch(stopUiLoading())
		history.replace(createHomeLink())
	} catch (error) {
		dispatch(stopUiLoading())
		dispatch(
			setFormMessage({
				login: {
					type: 'error',
					message: getErrorMessage(error),
				},
			})
		)
	}
}

export const verifySoftwareToken = (token) => async (dispatch, getState) => {
	try {
		dispatch(startUiLoading())
		const user = getState().user.userData

		await api(
			'/verify-mfa',
			{
				method: 'POST',
				json: {
					mfa_token: token,
				},
				headerOptions: {
					access_token: user.accessRights?.access_token,
				},
			},
			true
		)

		localStorage.setItem(
			LOCAL_STORAGE_KEY.USER,
			JSON.stringify(user.accessRights)
		)
		dispatch(stopUiLoading())
		dispatch(setUserData({ ...user, mfa_verified: true }))
		history.replace(createHomeLink())
	} catch (error) {
		dispatch(stopUiLoading())
		dispatch(
			setFormMessage({
				login: {
					type: 'error',
					message: getErrorMessage(error),
				},
			})
		)
	}
}

export const confirmUser = (code, password, history) => async (dispatch) => {
	try {
		dispatch(startUiLoading())
		await api(
			'/verify-email',
			{
				method: 'POST',
				json: {
					code: code,
					password: password,
				},
			},
			true
		)
		history.push(LOGIN_ROUTE)
		dispatch(stopUiLoading())
	} catch (error) {
		dispatch(
			setFormMessage({
				confirmUser: {
					type: 'error',
					message: getErrorMessage(error),
				},
			})
		)
		dispatch(stopUiLoading())
	}
}

export const resetPassword = (email) => async (dispatch) => {
	try {
		dispatch(startUiLoading())
		await api(
			'/forgot-password',
			{
				method: 'POST',
				json: {
					email: email,
				},
			},
			true
		)

		dispatch(stopUiLoading())
		dispatch(
			setFormMessage({
				changePassword: {
					type: 'success',
					message: 'Password reset link has successfully sent to your email.',
				},
			})
		)
	} catch (error) {
		dispatch(
			setFormMessage({
				changePassword: {
					type: 'error',
					message: getErrorMessage(error),
				},
			})
		)
		dispatch(stopUiLoading())
	}
}

export const confirmPassword =
	(code, newPassword, history) => async (dispatch) => {
		try {
			dispatch(startUiLoading())
			await api(
				'/confirm-forgot-password',
				{
					method: 'POST',
					json: {
						newPassword: newPassword,
						code: code,
					},
				},
				true
			)

			history.push(LOGIN_ROUTE)
			dispatch(stopUiLoading())
		} catch (error) {
			dispatch(
				setFormMessage({
					confirmPassword: {
						type: 'error',
						message: getErrorMessage(error),
					},
				})
			)
			dispatch(stopUiLoading())
		}
	}

export function signOut() {
	return async (dispatch) => {
		const user = getUserFromLocalStorage()

		if (user) {
			try {
				removeUserFromLocalStorage()
				dispatch({ type: SIGN_OUT })
				await api('/logout', { method: 'POST' }, true)
			} catch (error) {
				dispatch(
					addToast({
						body: getErrorMessage(error),
						type: 'error',
					})
				)
			}
		}

		history.replace(LOGIN_ROUTE)
	}
}

export function signOutUser() {
	return (dispatch) => {
		dispatch(stopCsvExportPoll())
		dispatch(clearPdfExportData())
		dispatch(stopAnnouncementsPoll())
		dispatch(signOut())
	}
}

export const setCognitoSession = (token) => ({
	type: SET_COGNITO_SESSION,
	token,
})

export const setUserData = (userData) => {
	return {
		type: SET_USER_DATA,
		userData,
	}
}

export const setFormMessage = (payload) => {
	return {
		type: SET_FORM_MESSAGES,
		payload,
	}
}

export const changeUserPassword = (oldPassword, newPassword) => {
	return async (dispatch) => {
		try {
			await api(
				'/reset-password',
				{
					method: 'POST',
					json: {
						oldPassword,
						newPassword,
					},
				},
				true
			)
			dispatch(
				addToast({
					body: 'Password has been changed successfully',
					type: 'info',
				})
			)
		} catch (error) {
			dispatch(
				addToast({
					body:
						error.code === 401
							? 'Error: Current password is not valid'
							: getErrorMessage(error),
					type: 'error',
				})
			)
		}
	}
}

export const setUserSettings = (payload) => {
	return { type: SET_USER_SETTINGS, payload }
}

export const fetchUserSettings = () => {
	return async (dispatch) => {
		try {
			const settings = await api.get('/users/settings')

			dispatch(setUserSettings(settings))
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not fetch user currency'),
					type: 'error',
				})
			)
		}
	}
}

export const updateUserCurrency = (payload) => {
	return { type: UPDATE_USER_CURRENCY, payload }
}

export const updateCurrency = (currencyId) => {
	return async (dispatch, getState) => {
		const currencies = getState().currencies

		try {
			await api.patch('/users/settings', {
				preferred_currency_id: currencyId,
			})
			const currency = currencies.find((c) => c.id === currencyId)
			if (currency) {
				dispatch(updateUserCurrency(currency))
			}
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not update currency'),
					type: 'error',
				})
			)
		}
	}
}

export const updateUserLengthUnit = (payload) => {
	return { type: UPDATE_USER_LENGTH_UNIT, payload }
}

export const updateLengthUnit = (lengthUnit) => {
	return async (dispatch, getState) => {
		const userSettings = getState().user.userSettings

		try {
			await api.patch('/users/settings', {
				preferred_length_unit: lengthUnit,
				preferred_currency_id: userSettings.preferred_currency_id,
			})

			dispatch(updateUserLengthUnit(lengthUnit))
			dispatch(clearAdaptData())
			dispatch(resetAssets())
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not update currency'),
					type: 'error',
				})
			)
		}
	}
}
