import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { closeSideBar } from '../../redux/actions/sidebar'
import { getActiveSideBar } from '../../redux/selectors/sidebar'
import Announcements from './Announcements'
import { Menu } from './Menu'
import { ReactComponent as ClimateXLogo } from '../../assets/icons/header-icon.svg'
import './styles.scss'

const _SideBar = ({ view, closeSideBar }) => {
	return (
		<div className={classnames('side-bar', view && 'side-bar--active')}>
			<div
				className='side-bar__backdrop'
				onClick={(e) => {
					if (!e.target.classList.contains('side-bar__container')) {
						closeSideBar()
					}
				}}
			/>

			<div className='side-bar__container'>
				<ClimateXLogo className='side-bar__logo' onClick={closeSideBar} />
				<div className='w-75 m-auto side-bar__content'>
					{view === 'notifications' && <Announcements />}
					{view === 'menu' && <Menu />}
				</div>
			</div>
		</div>
	)
}

_SideBar.propTypes = {
	view: PropTypes.oneOf(['notifications', 'menu']),
	closeSidebar: PropTypes.func,
}

export const SideBar = connect(
	(state) => ({
		view: getActiveSideBar(state),
	}),
	{ closeSideBar }
)(_SideBar)
