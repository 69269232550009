import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

export const Header = ({ projectName, onClose }) => {
	return (
		<div className='project-compare-header'>
			<h3 className='project-compare-header__title'>{projectName}</h3>
			<FontAwesomeIcon
				icon={['fas', 'times']}
				className='project-compare-header__close-icon'
				onClick={onClose}
			/>
		</div>
	)
}

Header.propTypes = {
	projectName: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
}
