import React, { useEffect } from 'react'
import FormMessages from '../component'
import { setFormMessage } from 'src/redux/actions/user'
import { connect } from 'react-redux'

const FormMessagesContainer = ({ formObject, formMessage }) => {
	useEffect(() => {
		setFormMessage({
			[formObject.type]: { type: 'Error' },
		})
	}, [formObject])
	return <FormMessages formObject={formObject} formMessage={formMessage} />
}

const mapStateToProps = ({ user, ui }, ownProps) => {
	return {
		formMessage: user.formMessage[ownProps.formObject.type],
	}
}

export default connect(mapStateToProps, { setFormMessage })(
	FormMessagesContainer
)
