import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faChevronLeft as fasChevronLeft,
	faEllipsis as fasEllipsis,
	faTimes as fasTimes,
} from '@fortawesome/pro-solid-svg-icons'

import {
	faArrowDown as farArrowDown,
	faArrowRight as farArrowRight,
	faArrowUp as farArrowUp,
	faBullhorn as farBullhorn,
	faChartBar as farChartBar,
	faChartColumn as farChartColumn,
	faChevronDown as farChevronDown,
	faChevronRight as farChevronRight,
	faCircleCheck as farCircleCheck,
	faCircleDashed as farCircleDashed,
	faCircleQuestion as farCircleQuestion,
	faCircleTrash as farCircleTrash,
	faCircleUser as farCircleUser,
	faCity as farCity,
	faCodeCompare as farCodeCompare,
	faDownload as farDownload,
	faExternalLinkAlt as farExternalLinkAlt,
	faFileChartColumn as farFileChartColumn,
	faFileExport as farFileExport,
	faGlobe as farGlobe,
	faGripLinesVertical as farGripLinesVertical,
	faInfoCircle as farInfoCircle,
	faList as farList,
	faLock as farLock,
	faMagnifyingGlass as farMagnifyingGlass,
	faPenCircle as farPenCircle,
	faPlus as farPlus,
	faRightToBracket as farRightToBracket,
	faTableLayout as farTableLayout,
	faTrashCan as farTrashCan,
	faXmark as farXmark,
} from '@fortawesome/pro-regular-svg-icons'
library.add(
	// Regular icons
	farArrowDown,
	farArrowRight,
	farArrowUp,
	farBullhorn,
	farChartBar,
	farChartColumn,
	farChevronDown,
	farChevronRight,
	farCircleCheck,
	farCircleDashed,
	farCircleQuestion,
	farCircleTrash,
	farCircleUser,
	farCity,
	farCodeCompare,
	farDownload,
	farExternalLinkAlt,
	farFileChartColumn,
	farFileExport,
	farGlobe,
	farGripLinesVertical,
	farInfoCircle,
	farList,
	farLock,
	farMagnifyingGlass,
	farPenCircle,
	farPlus,
	farRightToBracket,
	farTableLayout,
	farTrashCan,
	farXmark,
	// Solid icons
	fasChevronLeft,
	fasEllipsis,
	fasTimes
)
