import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { getProjectSummaryLoading } from '../../../redux/selectors/projects'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../../../components/Button'
import { getReportButtonText } from '../../../utils'
import './styles.scss'

const _ActionButtons = ({
	activeTab,
	onHeatMapClick,
	onProjectSummaryClick,
	isAdaptationsRoute,
	isExploreRoute,
	assetId,
	isAssetSummaryLoading,
	onExport,
	onCompareClick,
	onDownloadDataDictClick,
	isAssetsListLoading,
	isAssetsPdfLoading,
	projectSummaryLoading,
	assetsPdfExportData,
	assetsCsvExportdata,
}) => {
	const pdfExportPercentage = assetsPdfExportData?.percent
	const isAssets = activeTab === 'assets'
	const isAdapt = activeTab === 'adaptations'

	return (
		<div className='project-sidebar-actions'>
			{!isExploreRoute && (
				<Button
					className='project-sidebar-actions__button'
					onClick={onHeatMapClick}
				>
					<FontAwesomeIcon icon={['far', 'file-export']} />
					Heatmap
				</Button>
			)}
			{isExploreRoute && assetId && (
				<Button
					className='project-sidebar-actions__button'
					disabled={isAssetSummaryLoading}
					onClick={onProjectSummaryClick}
				>
					<FontAwesomeIcon icon={['far', 'chart-column']} />
					Project Summary
				</Button>
			)}
			{isAdapt && isAdaptationsRoute ? (
				<div></div>
			) : isAssets ? (
				<Button
					locatorId='button-export-csv'
					className={classnames(
						'project-sidebar-actions__button',
						'project-sidebar-actions__button--report',
						(assetsCsvExportdata.loading || isAssetsListLoading) &&
							'project-sidebar-actions__button--loading'
					)}
					onClick={onExport}
					disabled={assetsCsvExportdata.loading}
				>
					<span
						className={classnames(
							'project-sidebar-actions__progress-indicator',
							!assetsCsvExportdata.loading &&
								'project-sidebar-actions__progress-indicator--loading-finished'
						)}
						style={{
							width: assetsCsvExportdata.progressInPercentage
								? `${assetsCsvExportdata.progressInPercentage}%`
								: 0,
						}}
					/>
					<FontAwesomeIcon icon={['far', 'file-chart-column']} />
					{assetsCsvExportdata.loading
						? `Generating your data... ${assetsCsvExportdata.progressInPercentage}%`
						: 'Export CSV'}
				</Button>
			) : (
				<Button
					locatorId='button-generate-pdf-report'
					id='generate-report-button'
					className={classnames(
						'project-sidebar-actions__button',
						'project-sidebar-actions__button--report',
						isAssetsPdfLoading && 'project-sidebar-actions__button--loading'
					)}
					onClick={onExport}
					disabled={isAssetsPdfLoading}
				>
					<span
						className={classnames(
							'project-sidebar-actions__progress-indicator',
							!isAssetsPdfLoading &&
								'project-sidebar-actions__progress-indicator--loading-finished'
						)}
						style={{
							width: pdfExportPercentage ? `${pdfExportPercentage}%` : 0,
						}}
					/>
					<FontAwesomeIcon icon={['far', 'file-chart-column']} />
					{getReportButtonText(
						isAssetsPdfLoading,
						pdfExportPercentage,
						assetsPdfExportData,
						'url'
					)}
				</Button>
			)}
			{(!isAdaptationsRoute || (isAdaptationsRoute && isAssets)) && (
				<Button
					className={classnames(
						'project-sidebar-actions__button',
						projectSummaryLoading && 'project-sidebar-actions__button--loading'
					)}
					onClick={
						!isAdaptationsRoute && isAssets
							? onDownloadDataDictClick
							: !isAdaptationsRoute && !isAssets
								? onCompareClick
								: onDownloadDataDictClick
					}
				>
					<FontAwesomeIcon
						icon={[
							'far',
							!isAdaptationsRoute && !isAssets ? 'code-compare' : 'download',
						]}
					/>
					{!isAdaptationsRoute && !isAssets ? 'Compare' : 'Data Dictionary'}
				</Button>
			)}
		</div>
	)
}

_ActionButtons.propTypes = {
	isAssetsPdfLoading: PropTypes.bool.isRequired,
	assetsPdfExportData: PropTypes.object.isRequired,
	activeTab: PropTypes.oneOf(['assets', 'summary', 'adaptations']).isRequired,
	onHeatMapClick: PropTypes.func.isRequired,
	onProjectSummaryClick: PropTypes.func.isRequired,
	onExport: PropTypes.func.isRequired,
	isExploreRoute: PropTypes.bool.isRequired,
	assetId: PropTypes.string.isRequired,
	isAssetSummaryLoading: PropTypes.bool.isRequired,
	onCompareClick: PropTypes.func.isRequired,
	onDownloadDataDictClick: PropTypes.func.isRequired,
	projectSummaryLoading: PropTypes.bool,
	assetsCsvExportdata: PropTypes.shape({
		loading: PropTypes.bool.isRequired,
		progressInPercentage: PropTypes.number,
	}).isRequired,
}

export const ActionButtons = connect((state) => {
	return {
		projectSummaryLoading: getProjectSummaryLoading(state),
	}
}, null)(_ActionButtons)
