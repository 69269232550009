import React, { createElement, forwardRef } from 'react'
import ReactMarkdown from 'markdown-to-jsx'
import classNames from 'classnames'
import { MarkdownLink } from './Link'
import { MarkdownParagraph } from './Paragraph'
import { MarkdownList } from './List'
import { MarkdownHeadline } from './Headline'
import { MarkdownPre } from './MarkdownPre'
import { MarkdownCode } from './MarkdownCode'
import { MarkdownStrong } from './MarkdownStrong'
import { MarkdownItalic } from './MarkdownItalic'
import './styles.scss'

export const Markdown = forwardRef(
	({ wrapperClassName, isInlineForced = false, markdown }, ref) => {
		if (!markdown) {
			return null
		}

		const HideMarkdownElement = (imgAttributes) => {
			if (imgAttributes.className === 'emoji') {
				return createElement('img', imgAttributes)
			}
			return null
		}

		return (
			<div
				className={classNames(
					'markdown',
					wrapperClassName,
					isInlineForced && 'markdown--inline'
				)}
				ref={ref}
			>
				<ReactMarkdown
					options={{
						forceInline: isInlineForced,
						overrides: {
							a: MarkdownLink,
							blockquote: MarkdownParagraph,
							code: MarkdownCode,
							em: MarkdownItalic,
							h1: MarkdownHeadline,
							h2: MarkdownHeadline,
							h3: MarkdownHeadline,
							h4: MarkdownHeadline,
							h5: MarkdownHeadline,
							h6: MarkdownHeadline,
							img: HideMarkdownElement,
							p: MarkdownParagraph,
							pre: MarkdownPre,
							strong: MarkdownStrong,
							ul: MarkdownList,
						},
					}}
				>
					{markdown}
				</ReactMarkdown>
			</div>
		)
	}
)
