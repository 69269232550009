import React from 'react'
import PropTypes from 'prop-types'
import { ProjectsBody } from './ProjectsBody'
import './styles.scss'

function Projects({ location }) {
	return <ProjectsBody location={location} />
}

Projects.propTypes = {
	location: PropTypes.object,
	fetchProjects: PropTypes.func,
}

export default Projects
