import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import './styles.scss'

export const MarkdownItalic = ({ children }) => {
	if (isEmpty(children)) {
		return null
	}

	return <em className='markdown-italic'>{children[0]}</em>
}

MarkdownItalic.propTypes = {
	children: PropTypes.object.isRequired,
}
