import { SET_CURRENCIES } from '../actions/currencies'

const DEFAULT_STATE = []

export default function currencies(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SET_CURRENCIES:
			return action.payload
		default:
			return state
	}
}
