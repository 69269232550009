import { DEFENDS, HAZARD_TYPES, SCENARIOS, YEARS } from '../constants'

export const isHazardTypeValid = (hazardType) => {
	return Object.values(HAZARD_TYPES).some(({ value }) => value === hazardType)
}

export const isScenarioValid = (scenario) => {
	return SCENARIOS.some(({ value }) => value === scenario)
}

export const isYearValid = (year) => {
	return YEARS.some(({ value }) => value === year)
}

export const isDefendValid = (defend) => {
	return DEFENDS.some(({ value }) => value === defend)
}
