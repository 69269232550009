import dayjs from 'dayjs'
import { isNaN, isUndefined } from 'lodash'

export const getUpdatedAtFormat = (date) => {
	const currentTime = dayjs()
	const projectUpdatedAt = dayjs(date)
	const differenceInDays = currentTime.diff(projectUpdatedAt, 'days') >= 1

	if (differenceInDays) {
		return dayjs(date).format('DD-MM-YYYY').toString()
	}

	const differenceInHours = currentTime.diff(projectUpdatedAt, 'hours')

	if (differenceInHours >= 1) {
		return `${differenceInHours} hours ago`
	}

	const differenceInMinutes = currentTime.diff(projectUpdatedAt, 'minutes')

	if (isNaN(differenceInMinutes) || isUndefined(differenceInMinutes)) {
		return `0 minutes ago`
	}

	return `${differenceInMinutes} minutes ago`
}
