import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from 'src/components/Button'
import { ReactComponent as CircularLoading } from '../../assets/icons/circular-loading.svg'
import './styles.scss'

export const ConfirmationModalContent = ({
	wrapperClassName,
	question,
	text,
	confirmLabel,
	cancelLabel,
	onConfirm,
	onCancel,
	isLoading,
}) => {
	return (
		<div className={classnames('confirmation-modal-content', wrapperClassName)}>
			<h2 className='confirmation-modal-content__question'>{question}</h2>
			<p className='confirmation-modal-content__text'>{text}</p>
			{isLoading ? (
				<div className='confirmation-modal-content__loading-wrapper'>
					<CircularLoading className='confirmation-modal-content__loading' />
				</div>
			) : (
				<div className='confirmation-modal-content__actions'>
					<Button
						className='confirmation-modal-content__confirm'
						onClick={onConfirm}
						locatorId='button-yes-take-me-to-results'
					>
						{confirmLabel}
					</Button>
					<Button
						type='secondary'
						className='confirmation-modal-content__cancel'
						onClick={onCancel}
					>
						{cancelLabel}
					</Button>
				</div>
			)}
		</div>
	)
}

ConfirmationModalContent.defaultProps = {
	question: 'Are you sure?',
	confirmLabel: 'Yes',
	cancelLabel: 'No',
}

ConfirmationModalContent.propTypes = {
	wrapperClassName: PropTypes.string,
	question: PropTypes.string,
	text: PropTypes.string,
	confirmLabel: PropTypes.string,
	cancelLabel: PropTypes.string,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
	isLoading: PropTypes.bool,
}
