import React from 'react'
import './styles.scss'

const AuthLayout = ({ children }) => {
	return (
		<div id='page-login' className='login'>
			<div className='container'>
				<div className='row login__right-bar'>
					<div
						data-locator-id='img-login-art'
						className='col-lg-6 col-md-3 col-sx-0 login__art p-0'
					></div>
					<div className='col-lg-6 col-md-9 col-sx-12 p-0'>
						<main>
							<div className='container'>
								<div className='row'>
									<div className='col-1'></div>
									<div className='col-10'>{children}</div>
									<div className='col-1'></div>
								</div>
							</div>
						</main>

						<footer className='login__footer'>
							<div className='container'>
								<div className='row'>
									<div className='col-1'></div>
									<div className='col-10'>
										<ul>
											<li>
												<a
													href='https://www.climate-x.com'
													rel='noopener noreferrer'
												>
													<span className='d-none d-sm-none'>©</span>
													Climate-X.com
												</a>
											</li>
											<li>
												<a
													href='https://www.climate-x.com/legals/terms-and-conditions'
													rel='noopener noreferrer'
												>
													Terms of Use
												</a>
											</li>
											<li>
												<a
													href='https://www.climate-x.com/legals/privacy-policy'
													rel='noopener noreferrer'
												>
													Privacy Policy
												</a>
											</li>
										</ul>
									</div>
									<div className='col-1'></div>
								</div>
							</div>
						</footer>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AuthLayout
