import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AssetsProvider from '../../AssetsProvider'
import { addAssetsToProject } from 'src/redux/actions/assets'
import { closeModal } from 'src/redux/actions/modal'
import './styles.scss'

const _AddAssets = ({
	closeModal,
	addAssetsToProject,
	maximumAssetsToAdd,
	activeProjectId,
}) => {
	const handleAddAssets = (assets, source, batchId, allDataLength) => {
		addAssetsToProject(assets, source, batchId, allDataLength, activeProjectId)
		closeModal()
	}

	return (
		<div className='add-assets-modal'>
			<AssetsProvider
				addAssetsToExistingProject={handleAddAssets}
				maximumAssetsToAdd={maximumAssetsToAdd}
			/>
		</div>
	)
}

_AddAssets.propTypes = {
	addAssetsToProject: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	maximumAssetsToAdd: PropTypes.number.isRequired,
	activeProjectId: PropTypes.number.isRequired,
}

export const AddAssets = connect(null, { addAssetsToProject, closeModal })(
	_AddAssets
)
