import React from 'react'
import { connect } from 'react-redux'
import { AdaptationsRollUp } from 'src/components/AdaptationsRollUp'
import { getAdaptationResults } from 'src/redux/selectors/adaptations'
import { getAdaptations } from 'src/redux/selectors/adaptations'
import PropTypes from 'prop-types'
import { ThreeDotLoader } from 'src/components/Loader'
import './styles.scss'

function Adaptation({ adaptations, adaptation_results, current_asset }) {
	const asset_adaptation_results = adaptation_results

	const aggregated_results = asset_adaptation_results?.aggregated_results
		? asset_adaptation_results?.aggregated_results
		: {}
	const sorted_risk_scores = asset_adaptation_results?.aggregated_results
		.sorted_risk_scores
		? asset_adaptation_results?.aggregated_results.sorted_risk_scores
		: []
	const sortedRiskScoresPreAdapt = asset_adaptation_results?.aggregated_results
		.sorted_risk_scores_pre_adapt
		? asset_adaptation_results?.aggregated_results.sorted_risk_scores_pre_adapt
		: []
	const adaptation_wise_results =
		asset_adaptation_results?.adaptation_wise_results
			? asset_adaptation_results?.adaptation_wise_results
			: []

	if (adaptations.adaptRollupLoading) {
		return (
			<div style={{ position: 'relative', top: '40%' }}>
				<ThreeDotLoader locatorId='loader-adapt-rollup' />
			</div>
		)
	}

	return (
		<div className='container-fluid'>
			<AdaptationsRollUp
				current_asset={current_asset}
				aggregated_results={aggregated_results}
				sorted_risk_scores={sorted_risk_scores}
				sortedRiskScoresPreAdapt={sortedRiskScoresPreAdapt}
				adaptation_wise_results={adaptation_wise_results}
			/>
		</div>
	)
}

Adaptation.propTypes = {
	location: PropTypes.object,
	fetchProjects: PropTypes.func,
}

export default connect(
	(state) => ({
		adaptation_results: getAdaptationResults(
			state,
			state.adaptations.current_asset
		),
		adaptations: getAdaptations(state),
		current_asset: state.adaptations.current_asset,
	}),
	{}
)(Adaptation)
