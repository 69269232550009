import { last } from 'lodash'
import {
	ASSET_MARKERS_CLUSTERS_ID,
	ASSET_MARKERS_CLUSTER_COUNT_ID,
	ASSET_MARKERS_SINGLE_ASSET_ID,
	ASSET_MARKER_ZOOM_BREAKPOINT,
} from 'src/constants'

export const isAssetMarker = (layerId) =>
	[
		ASSET_MARKERS_CLUSTERS_ID,
		ASSET_MARKERS_CLUSTER_COUNT_ID,
		ASSET_MARKERS_SINGLE_ASSET_ID,
	].includes(layerId)

export const findFeatureById = (features, id) => {
	return last(features.filter((feature) => feature.layer.id === id))
}

export const easeToTarget = ({
	map,
	coordinates,
	onEaseEnd,
	duration = 2000,
}) => {
	const adjustedDuration = duration * 0.5

	let firstTimeout = null
	let secondTimeout = null

	const clearTimeouts = () => {
		map.stop()

		if (firstTimeout) {
			clearTimeout(firstTimeout)
		}

		if (secondTimeout) {
			clearTimeout(secondTimeout)
		}

		map.off('drag', clearTimeouts)
		map.off('wheel', clearTimeouts)
		map.off('click', clearTimeouts)
	}

	map.once('drag', clearTimeouts)
	map.once('wheel', clearTimeouts)
	map.once('click', clearTimeouts)

	const pitchToMarker = () => {
		map.easeTo({
			center: coordinates,
			pitch: 70,
			zoom: 18,
			duration: adjustedDuration,
		})

		secondTimeout = setTimeout(() => {
			clearTimeouts()
			onEaseEnd()
		}, adjustedDuration)
	}

	if (map.getZoom() < ASSET_MARKER_ZOOM_BREAKPOINT) {
		map.easeTo({
			center: coordinates,
			zoom: ASSET_MARKER_ZOOM_BREAKPOINT + 1,
			duration: adjustedDuration,
		})

		firstTimeout = setTimeout(() => {
			pitchToMarker()
		}, adjustedDuration)
	} else {
		firstTimeout = setTimeout(() => {
			pitchToMarker()
		}, adjustedDuration)
	}
}

export const createFullAddress = (addressNames = []) => {
	return addressNames.filter((name) => !!name).join(' ')
}

export const generateGeoJson = (assets) => {
	return {
		type: 'FeatureCollection',
		features: assets.map(({ id, longitude, latitude }) => {
			return {
				type: 'Feature',
				properties: {
					id,
					longitude,
					latitude,
				},
				geometry: {
					type: 'Point',
					coordinates: [longitude, latitude],
				},
			}
		}),
	}
}
