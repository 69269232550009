import React, { useState } from 'react'
import Input from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { validate } from '../../../../utils'
import { NavLink } from 'reactstrap'
import StatusMessage from '../../../../components/StatusMessage'
import qs from 'qs'

const ConfirmUserForm = ({ onSubmit, routerProps, formMessage, loading }) => {
	const { history } = routerProps
	const queryParams = qs.parse(history.location.search, {
		ignoreQueryPrefix: true,
	})
	const [controls, setControls] = useState({
		password: {
			value: '',
			touched: false,
			validationRules: {
				minLength: 3,
				isCognitoPassword: true,
			},
			valid: false,
		},
		passwordRepeat: {
			value: '',
			touched: false,
			validationRules: {
				minLength: 3,
				isCognitoPassword: true,
			},
			valid: false,
		},
	})

	const updateState = (field, value) => {
		setControls((prevState) => {
			const newState = {
				...prevState,
				[field]: {
					...prevState[field],
					value,
					touched: true,
					valid: validate(value, prevState[field].validationRules),
				},
			}

			newState.passwordRepeat.valid =
				newState.passwordRepeat.value === newState.password.value

			return newState
		})
	}

	const onConfirm = (e) => {
		e.preventDefault()

		if (
			queryParams.code &&
			controls.passwordRepeat.valid &&
			controls.password.valid
		) {
			onSubmit(queryParams.code, controls.password.value, history)
		}
	}

	const isFormValid = () => {
		return controls.passwordRepeat.valid && controls.password.valid
	}

	return (
		<>
			{formMessage && (
				<StatusMessage type={formMessage.type}>
					{formMessage.message}
				</StatusMessage>
			)}
			<form className='form-floating' action=''>
				<div className='form-floating mb-3'>
					<Input
						type='password'
						label='Password'
						error={controls.password.touched && !controls.password.valid}
						placeholder='Password'
						value={controls.password.value}
						onChange={(e) => updateState('password', e.target.value)}
					/>
				</div>
				<div className='form-floating mb-3'>
					<Input
						type='password'
						label='Repeat password'
						error={
							controls.passwordRepeat.touched && !controls.passwordRepeat.valid
						}
						placeholder='Repeat password'
						value={controls.passwordRepeat.value}
						onChange={(e) => updateState('passwordRepeat', e.target.value)}
					/>
				</div>
				<div className='col-auto col-sm-12'>
					<div className='d-flex align-items-center mt-4 mb-4'>
						<Button
							className='btn btn-primary mt-0'
							onClick={onConfirm}
							disabled={loading || !isFormValid()}
						>
							{loading ? 'Loading ...' : 'Confirm'}
						</Button>
						<NavLink
							onClick={() => history.push('/login')}
							className='form-link'
						>
							Login
						</NavLink>
					</div>
				</div>
			</form>
		</>
	)
}
export default ConfirmUserForm
