import {
	SET_BUSINESS_DISRUPTION,
	SET_BUSINESS_DISRUPTION_LOADING,
	SET_BUSINESS_DISRUPTION_ERROR_MESSAGE,
} from '../actions/businessDisruption'
import { getBusinessDisruptionCacheKey } from 'src/utils/getCacheKey'

const DEFAULT_STATE = {
	data: undefined,
	isLoading: undefined,
	error: null,
}

export default function businessDisruption(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SET_BUSINESS_DISRUPTION:
			const {
				payload: {
					projectId,
					assetId,
					scenario,
					targetYear,
					defended,
					payload,
				},
			} = action
			const key = getBusinessDisruptionCacheKey({
				projectId,
				assetId,
				scenario,
				targetYear,
				defended,
			})

			return {
				...state,
				isLoading: false,
				error: null,
				data: {
					...state.data,
					[key]: payload,
				},
			}
		case SET_BUSINESS_DISRUPTION_LOADING:
			return { ...state, isLoading: action.isLoading, error: null }
		case SET_BUSINESS_DISRUPTION_ERROR_MESSAGE:
			return {
				...state,
				isLoading: false,
				error: action.errorMessage,
			}
		default:
			return state
	}
}
