const steps = ['Vegetation', 'River flow', 'Urbanization', 'Weather patterns']

export const getProgressStep = (progress) => {
	const index = Math.floor(progress / 25) % steps.length
	return steps[index]
}

export const getInitialRandomProgress = () => {
	return 2 + Math.floor(Math.random() * 9)
}

export const getRandomProgress = () => {
	return Math.floor(Math.random() * 20)
}
