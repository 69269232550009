import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.scss'

export const Button = ({
	children,
	type,
	className,
	id,
	styles,
	disabled,
	onClick,
	locatorId,
}) => {
	return (
		<button
			id={id}
			className={classnames(
				`button button--${type}`,
				className,
				disabled && 'button--disabled'
			)}
			disabled={disabled}
			onClick={onClick}
			style={{ ...styles }}
			data-locator-id={locatorId}
		>
			{children}
		</button>
	)
}

Button.defaultProps = {
	type: 'primary',
	locatorId: 'not-set',
}

Button.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.element,
	]),
	id: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.oneOf(['primary', 'secondary']).isRequired,
	onClick: PropTypes.func.isRequired,
	styles: PropTypes.object,
	locatorId: PropTypes.string,
}
