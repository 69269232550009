import { SOMETHING_WENT_WRONG } from '../constants'

export const getErrorMessage = (
	error,
	defaultErrorMsg = SOMETHING_WENT_WRONG
) => {
	const errorMsg = error?.body?.message
	if (errorMsg) {
		return `Error: ${errorMsg}`
	}
	return defaultErrorMsg
}
