import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ProjectSummaryContent from '../../../ProjectSummaryContent'
import 'react-circular-progressbar/dist/styles.css'

function ProjectSummary({
	projectSummary,
	projectSummaryLoading,
	projectSummaryErrorMessage,
	selectedProjectId,
	defaultScenario,
	defaultYear,
	defaultEpcTargetYear,
	fetchProjectSummary,
	defended,
	userSettings,
	userFeatureFlags,
	defaultTargetEpc,
}) {
	const isMounted = useRef(false)

	useEffect(() => {
		if (
			selectedProjectId &&
			defaultScenario &&
			defaultYear &&
			defaultEpcTargetYear &&
			isMounted.current &&
			defended
		) {
			fetchProjectSummary(
				defaultScenario,
				defaultYear,
				defaultEpcTargetYear,
				defended,
				selectedProjectId
			)
		}

		if (!isMounted.current) {
			isMounted.current = true
		}
	}, [
		selectedProjectId,
		defaultScenario,
		defaultYear,
		defended,
		defaultEpcTargetYear,
		defaultTargetEpc,
	])

	return (
		<ProjectSummaryContent
			projectSummary={projectSummary}
			projectSummaryLoading={projectSummaryLoading}
			projectSummaryErrorMessage={projectSummaryErrorMessage}
			userSettings={userSettings}
			userFeatureFlags={userFeatureFlags}
			targetEpc={defaultTargetEpc}
		/>
	)
}

ProjectSummary.propTypes = {
	projectSummary: PropTypes.object,
	projectSummaryLoading: PropTypes.bool,
	selectedProjectId: PropTypes.string,
	defaultScenario: PropTypes.string,
	defaultYear: PropTypes.string,
	defaultEpcTargetYear: PropTypes.string,
	defended: PropTypes.string,
	fetchProjectSummary: PropTypes.func.isRequired,
	userSettings: PropTypes.object,
	userFeatureFlags: PropTypes.object,
	targetEpc: PropTypes.string,
}

export default ProjectSummary
