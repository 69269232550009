import { isEmpty } from 'lodash'

export const ADD_POPULAR_PROJECTS = `addPopularProjects`
export function addPopularProjects(projects) {
	return { type: ADD_POPULAR_PROJECTS, projects }
}

export const ADD_POPULAR_PROJECT = `addPopularProject`
export function addPopularProject(projectId) {
	return (dispatch, getState) => {
		if (projectId) {
			const popularProjects = getState().popularProjects.data
			const projects = getState().projects

			const foundInPopularProject =
				!isEmpty(popularProjects) &&
				popularProjects.find(
					(project) => project.id.toString() === projectId.toString()
				)
			const projectCopy =
				!isEmpty(projects.data) &&
				projects.data.find(
					(project) => project && project.id.toString() === projectId.toString()
				)

			if (!foundInPopularProject && projectCopy) {
				dispatch({ type: ADD_POPULAR_PROJECT, project: projectCopy })
			}
		}
	}
}

export const DELETE_POPULAR_PROJECT = `deletePopularProject`
export function deletePopularProject(projectIds) {
	return { type: DELETE_POPULAR_PROJECT, projectIds }
}

export const EDIT_POPULAR_PROJECT = `editPopularProject`
export function editPopularProject(projectId, payload) {
	return { type: EDIT_POPULAR_PROJECT, projectId, payload }
}
