import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNull } from 'lodash'
import { CheckBox } from '../../CheckBox'
import { Tooltip } from 'reactstrap'

export const AssetListItem = ({
	isActive,
	asset,
	onDelete,
	onReview,
	onSelect,
}) => {
	const [activeTooltip, setActiveTooltip] = useState({
		[`asset-edit-${asset.id}`]: false,
		[`asset-delete-${asset.id}`]: false,
		[`asset-explore-${asset.id}`]: false,
	})

	const handleTooltip = (key) => {
		setActiveTooltip({ ...activeTooltip, [key]: !activeTooltip[key] })
	}

	const {
		id,
		asset_id,
		street_name,
		postcode,
		country_name,
		longitude,
		latitude,
	} = asset

	const hasLonLat = !isNull(longitude) && !isNull(latitude)

	return (
		<div
			className={classnames(
				'assets-list__row',
				isActive && 'assets-list__row--active'
			)}
			onClick={() => onSelect(!isActive)}
		>
			<div className='assets-list__body-cell'>
				<CheckBox
					appearance='circle'
					defaultValue={isActive}
					applyChangeFromParent
					onChange={(value) => onSelect(value)}
				/>
			</div>
			<div className='assets-list__body-cell'>{asset_id}</div>
			<div className='assets-list__body-cell'>{street_name}</div>
			<div className='assets-list__body-cell'>{postcode}</div>
			<div className='assets-list__body-cell'>{country_name}</div>
			<div className='assets-list__body-cell'>
				<FontAwesomeIcon
					icon={['far', 'circle-trash']}
					id={`asset-delete-${id}`}
					className='assets-list__action-button'
					onClick={(e) => {
						e.stopPropagation()
						onDelete()
					}}
				/>
				<FontAwesomeIcon
					icon={['far', 'right-to-bracket']}
					id={`asset-explore-${id}`}
					data-locator-id={'button-single-asset-inspect'}
					className={classnames(
						'assets-list__action-button',
						!hasLonLat && 'assets-list__action-button--disabled'
					)}
					onClick={(e) => {
						e.stopPropagation()
						if (hasLonLat) {
							onReview()
						}
					}}
				/>
				{hasLonLat && (
					<Tooltip
						placement='top'
						isOpen={activeTooltip[`asset-explore-${id}`]}
						target={`asset-explore-${id}`}
						toggle={() => handleTooltip(`asset-explore-${id}`)}
						hideArrow={false}
					>
						Load summary
					</Tooltip>
				)}
				<Tooltip
					placement='top'
					isOpen={activeTooltip[`asset-delete-${id}`]}
					target={`asset-delete-${id}`}
					toggle={() => handleTooltip(`asset-delete-${id}`)}
					hideArrow={false}
				>
					Delete this asset
				</Tooltip>
			</div>
		</div>
	)
}

AssetListItem.propTypes = {
	isActive: PropTypes.bool,
	onDelete: PropTypes.func.isRequired,
	onReview: PropTypes.func.isRequired,
}
