import LoginForm from '../../containers/Auth/Forms/LoginForm'
import ConfirmUserForm from '../../containers/Auth/Forms/ConfirmUserForm'
import PasswordResetForm from '../../containers/Auth/Forms/PasswordResetForm'
import PasswordConfirmForm from '../../containers/Auth/Forms/PasswordConfirmForm'
import VerifyMfaForm from '../../containers/Auth/Forms/VerifyMfaForm'
import EnableMfaForm from '../../containers/Auth/Forms/EnableMfaForm'

import { UncontrolledTooltip } from 'reactstrap'

export const formsObj = {
	'/login': {
		type: 'login',
		title: 'Sign in',
		subtitle:
			"You will need an account to continue. If you have one already, what are you waiting for? Let's put your data to work!",
		component: LoginForm,
		function: 'signInUser',
	},
	'/confirm-user': {
		type: 'confirmUser',
		title: 'Confirm User',
		subtitle:
			'Set up your password. Password must contain at least one lowercase letter and one capital letter. Minimum length is 8 characters.',
		component: ConfirmUserForm,
		function: 'confirmUser',
	},
	'/reset-password': {
		type: 'changePassword',
		title: 'Reset Password',
		subtitle: 'Please provide your email/username.',
		component: PasswordResetForm,
		function: 'resetPassword',
	},
	'/confirm-password': {
		type: 'confirmPassword',
		title: 'Confirm Password',
		subtitle: 'Please provide new password',
		component: PasswordConfirmForm,
		function: 'confirmPassword',
		withTimer: true,
	},
	'/enable-mfa': {
		type: 'login',
		title: 'Enable two-factor authentication',
		subtitle: (
			<div>
				To ensure your account’s security, you must add a token that’s used to
				verify your identity when you log in <br />
				<br />
				Please download an{' '}
				<span style={{ color: 'red' }} id='AuthAppList'>
					authenticator app
				</span>
				<UncontrolledTooltip placement='bottom' target='AuthAppList'>
					accepted apps: Authy, Ms/Google Authenticator, 2FA
				</UncontrolledTooltip>{' '}
				onto your mobile device (or use an existing one) and add a new account.
				Then, scan this QR code and enter token.
				<br />
				<br />
			</div>
		),
		component: EnableMfaForm,
		function: 'verifySoftwareToken',
		withTimer: true,
	},
	'/verify-mfa': {
		type: 'login',
		title: 'Two-factor authentication',
		subtitle: (
			<div>
				Your account is protected by two-factor authentication. <br /> <br />
				Open your authenticator app and type in the{' '}
				<strong>6–digit token</strong> for this site.
			</div>
		),
		component: VerifyMfaForm,
		function: 'validateMfa',
		withTimer: true,
	},
}
