export const WALKTHROUGH_STEPS_HOME_PAGE = [
	{
		target: '#assets-provider-wrapper',
		content: (
			<>
				Enter a single address or press ‘Upload File’ to load an entire
				portfolio into Spectra using either an address, latitude/longitude or a
				country-specific identifier (e.g. UPRN).
			</>
		),
		disableBeacon: true,
	},
]

export const WALKTHROUGH_STEPS_EXPLORE_PAGE = [
	{
		target: '#walkthrough-map',
		content: (
			<>
				You’re now brought to the main dashboard where you can see a geographic
				distribution of your locations.
			</>
		),
		disableBeacon: true,
		placement: 'auto',
	},
	{
		target: '#project-summary-settings',
		content: (
			<>
				In Settings, you can select a desired Physical/Transition Risk scenario
				as well as a projection year until 2100. You can also specify an EPC
				target year which sets the limit for Transition Risk losses. Finally,
				the Settings allow you to toggle between flood defences being Off or On.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#project-summary-settings_no_epc',
		content: (
			<>
				In Settings, you can select a desired Physical Risk scenario as well as
				a projection year until 2100. Finally, the Settings allow you to toggle
				between flood defences being Off or On.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#scores-and-volumes',
		content: (
			<>
				This section provides an overall portfolio summary. You can click each
				wheel to display High, Medium and Low risk assets on the map.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#hazard-distribution-wrapper',
		content: (
			<>
				This dropdown tells you more about the rating distribution of each
				hazard. You can press each bar to view a respective hazard layer and
				locations on the map.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#project-losses-wrapper',
		content: (
			<>
				Here you can view Physical and Transition Risk losses expressed as both
				amounts and percentages.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#project-losses-wrapper_no_epc',
		content: (
			<>
				Here you can view Physical Risk losses expressed as amount and
				percentage.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#walkthrough-map',
		content: (
			<>
				If you zoom in and click on an individual asset, you can deep-dive into
				severity, likelihoods, ratings and losses associated with one particular
				asset.
			</>
		),
		disableBeacon: true,
		placement: 'auto',
	},
	{
		target: '#generate-report-button',
		content: (
			<>
				‘PDF Report’ button produces on-the-fly executive reports including
				summaries, benchmarking and more insights into high risk areas as well
				as methodology and validation sections.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#sidebar-tab-assets',
		content: (
			<>
				If you press the ‘Data’ tab, you can remove/add/edit your entries and,
				most importantly, the ‘Export CSV’ button will download all of the
				Spectra outputs for your portfolio in a csv format.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#nav-link-projects',
		content: <>Visit ‘Projects’ section for your past uploads.</>,
		disableBeacon: true,
	},
	{
		target: '#nav-link-announcements',
		content: <>Check for the latest product announcements</>,
		disableBeacon: true,
	},
	{
		target: '#nav-link-helps',
		content: (
			<>
				For queries, raising tickets and an extensive Knowledge Hub, visit 'Help
				Centre'.
			</>
		),
		disableBeacon: true,
	},
	{
		target: '#logged-in-header__user-dropdown',
		content: (
			<>In user settings, you can change currency and other user parameters.</>
		),
		disableBeacon: true,
	},
]

export const WALKTHROUGH_SPOTLIGHT_STYLES = {
	borderRadius: '16px',
}
