import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAdaptations } from 'src/redux/selectors/adaptations'
import { getAssets } from 'src/redux/selectors/assets'
import { addToast } from '../../../redux/actions/toasts'
import { closeModal, setModal } from 'src/redux/actions/modal'
import { getIsUserDemo } from '../../../redux/selectors/user'
import ProjectAdaptationsFilters from '../../ProjectAdaptationsFilters'
import ProjectAdaptationsPagination from '../../ProjectAdaptationsPagination'
import ProjectAdaptationsConfigurationDisplay from '../../ProjectAdaptationsConfigurationDisplay'
import {
	selectAdaptationConfigDisplay,
	fetchAdaptationsByAssetId,
} from 'src/redux/actions/adaptations'
import { usePrevious } from 'src/customHooks'
import { clearPdfExportData } from 'src/redux/actions/assets'
import './styles.scss'

function Adaptations({
	selectAdaptationConfigDisplay,
	adaptations,
	currentAsset,
	assets,
	fetchAdaptationsByAssetId,
	fetchAssets,
	selectedProjectId,
	clearPdfExportData,
}) {
	const prevSelectedProjectId = usePrevious(selectedProjectId)

	useEffect(() => {
		if (selectedProjectId !== prevSelectedProjectId) {
			fetchAssets()
		}
	}, [prevSelectedProjectId, selectedProjectId, fetchAssets])

	useEffect(() => {
		if (!!currentAsset?.id) {
			fetchAdaptationsByAssetId(currentAsset)
		}
	}, [currentAsset, fetchAdaptationsByAssetId])

	return (
		<div
			data-locator-id={'adaptations-panel'}
			className='sidebar-adaptations-wrapper'
		>
			<div className='row w-100 m-0'>
				<ProjectAdaptationsFilters
					onSelect={(asset) => {
						clearPdfExportData()
						fetchAdaptationsByAssetId(asset)
					}}
					currentAsset={adaptations.current_asset || assets.data?.[0]}
				/>

				<ProjectAdaptationsPagination
					adaptations={adaptations}
					adaptationConfiguration={adaptations}
					onSelect={selectAdaptationConfigDisplay}
				/>
				<ProjectAdaptationsConfigurationDisplay
					adaptationConfiguration={adaptations}
					assets={assets}
				/>
			</div>
		</div>
	)
}

Adaptations.propTypes = {
	fetchAdaptationsByAssetId: PropTypes.func.isRequired,
	fetchAssets: PropTypes.func.isRequired,
	selectAdaptationConfigDisplay: PropTypes.func.isRequired,
	adaptations: PropTypes.object,
	assets: PropTypes.array,
	currentAsset: PropTypes.number,
}

export default connect(
	(state) => ({
		adaptations: getAdaptations(state),
		assets: getAssets(state),
		isUserDemo: getIsUserDemo(state),
	}),
	{
		fetchAdaptationsByAssetId,
		selectAdaptationConfigDisplay,
		setModal,
		closeModal,
		addToast,
		clearPdfExportData,
	}
)(Adaptations)
