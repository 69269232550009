export const START_UI_LOADING = 'startUiLoading'
export const STOP_UI_LOADING = 'stopUiLoading'

export const startUiLoading = () => {
	return {
		type: START_UI_LOADING,
	}
}

export const stopUiLoading = () => {
	return {
		type: STOP_UI_LOADING,
	}
}
