import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAssetSummary } from '../../../../redux/selectors/assetSummary'
import { fetchAssetSummary } from '../../../../redux/actions/assetSummary'
import { AssetSummaryContent } from 'src/containers/AssetSummaryContent'
import {
	getUserSettings,
	getUserFeatureFlags,
} from '../../../../redux/selectors/user'

export const _AssetSummary = ({
	assetId,
	scenario,
	year,
	epcTargetYear,
	fetchAssetSummary,
	assetSummary,
	assetName,
	defended,
	userSettings,
	userFeatureFlags,
	targetEpc,
}) => {
	return (
		<AssetSummaryContent
			fetchAssetSummary={fetchAssetSummary}
			assetSummary={assetSummary}
			assetId={assetId}
			scenario={scenario}
			year={year}
			epcTargetYear={epcTargetYear}
			assetName={assetName}
			defended={defended}
			userSettings={userSettings}
			userFeatureFlags={userFeatureFlags}
			targetEpc={targetEpc}
		/>
	)
}

_AssetSummary.propTypes = {
	assetId: PropTypes.string.isRequired,
	assetName: PropTypes.string.isRequired,
	defended: PropTypes.string,
	year: PropTypes.string,
	epcTargetYear: PropTypes.string,
	scenario: PropTypes.string,
	targetEpc: PropTypes.string,
	fetchAssetSummary: PropTypes.func.isRequired,
}

export const AssetSummary = connect(
	(state) => ({
		assetSummary: getAssetSummary(state),
		userSettings: getUserSettings(state),
		userFeatureFlags: getUserFeatureFlags(state),
	}),
	{ fetchAssetSummary }
)(_AssetSummary)
