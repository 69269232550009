// TODO: Can we make this a little better?
import {
	isLocalEnv,
	isProductionEnv,
	isStagingEnv,
	isScratchEnv,
} from '../utils'
import {
	localEnvConfig,
	prodEnvConfig,
	stagingEnvConfig,
	scratchEnvConfig,
} from './envs'

const isProdMode = isProductionEnv()
const isLocalMode = isLocalEnv()
const isStagingMode = isStagingEnv()
const isScratchMode = isScratchEnv()

export const getAppConfig = () => {
	if (isProdMode) {
		return prodEnvConfig
	} else if (isStagingMode) {
		return stagingEnvConfig
	} else if (isLocalMode) {
		return localEnvConfig
	} else if (isScratchMode) {
		return scratchEnvConfig
	}
}
