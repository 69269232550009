export const createAssetFullName = ({
	asset_id,
	street_name,
	city,
	country_name,
}) => {
	let nameParts = [street_name, city, country_name]
	nameParts = nameParts.filter((part) => !!part)
	let assetName = nameParts.join(' ')

	if (!assetName) {
		assetName = `ID: ${asset_id}`
	}
	return assetName
}
