export const scratchEnvConfig = {
	environment: 'staging',
	authApiUrl: 'http://localhost:4000/auth',
	mainApiUrl: 'http://localhost:8000/main',
	layersApiUrl:
		// TODO: Add scratch IP to s3 policy
		// 'https://stage-climatex-heatmap-store.s3.eu-west-1.amazonaws.com',
		'https://dx7wswwtk5rjg.cloudfront.net/tile-server/tile',
	dynamicTilesConfigUrl:
		'https://stage-climatex-heatmap-store.s3.eu-west-1.amazonaws.com/hazard_layers_version_config.json',
	layerProperties: {
		coastal_flood: {
			hasDefend: true,
		},
		river_flood: {
			hasDefend: true,
			hasExtent: true,
		},
		surface_flood: {
			hasExtent: true,
		},
		storm_surge: {
			hasDefend: true,
			hasExtent: true,
		},
	},
	customHeaders: {
		'x-tenant-type': 'STANDARD',
	},
	isReduxLogEnabled: true,
	featureFlags: {
		isDefendsButtonEnabled: true,
	},
}
