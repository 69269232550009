import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import './styles.scss'

export const MarkdownPre = ({ key, children }) => {
	if (!get(children, 'props.children')) {
		return null
	}

	return (
		<pre className='markdown-pre' key={key}>
			{children.props.children}
		</pre>
	)
}

MarkdownPre.propTypes = {
	children: PropTypes.object.isRequired,
	key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
