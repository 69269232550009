import { get, pick } from 'lodash'
import qs from 'qs'
import api from '../../service/api'
import { getDefaultScenario, getErrorMessage } from '../../utils'
import { DEFAULT_EXTENT_VALUE, HAZARD_TYPES } from '../../constants'

export const SET_ASSET_SUMMARY_LOADING = `setAssetSummaryLoading`
export function setAssetSummaryLoading(isLoading) {
	return { type: SET_ASSET_SUMMARY_LOADING, isLoading }
}

export const SET_ASSET_SUMMARY_ERROR_MESSAGE = `setAssetSummaryErrorMessage`
export function setAssetSummaryErrorMessage(errorMessage) {
	return { type: SET_ASSET_SUMMARY_ERROR_MESSAGE, errorMessage }
}

export const SET_ASSET_SUMMARY = 'setAssetSummary'
export function setAssetSummary(data) {
	return { type: SET_ASSET_SUMMARY, ...data }
}

export function fetchAssetSummary(
	scenario,
	year,
	epcTargetYear,
	defended,
	asset_id
) {
	return async (dispatch) => {
		dispatch(setAssetSummaryLoading(true))
		try {
			const params = qs.stringify({
				asset_id: asset_id.toString(),
				scenario: getDefaultScenario(scenario).scenario,
				action: getDefaultScenario(scenario).action,
				year: parseInt(year),
				target_year: parseInt(epcTargetYear),
				defended: defended === 'defended',
				extent: DEFAULT_EXTENT_VALUE,
			})

			const data = await api.get(`/asset-summary?${params}`)

			dispatch(
				setAssetSummary({
					summary: pick(data || {}, [
						...Object.values(HAZARD_TYPES).map(({ value }) => value),
						'country_id',
					]),
					loss_data: get(data, 'loss_data'),
					retrofit_data: get(data, 'retrofit_data'),
					epc_data: get(data, 'epc_data'),
					physical_risk: get(data, 'physical_risk'),
				})
			)
			dispatch(setAssetSummaryLoading(false))
		} catch (error) {
			dispatch(
				setAssetSummaryErrorMessage(
					getErrorMessage(error, 'Error: Could not load asset summary')
				)
			)
		}
	}
}
