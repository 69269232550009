import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { size } from 'lodash'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const getPagesData = (total, limit) => {
	const result = []
	const pagesAmount = Math.ceil(total / limit)
	for (let i = 0; i < pagesAmount; i++) {
		result.push({
			key: i,
			label: i + 1,
			offset: i * limit,
		})
	}

	return result
}

export const PaginationNavigation = ({
	wrapperClassName,
	stepClassName,
	withArrowIcons,
	prevLabel,
	nextLabel,
	activeOffset,
	total,
	limit,
	onPageChange,
}) => {
	const pages = getPagesData(total, limit)
	const [activePage, setActivePage] = useState(
		pages.find(({ offset }) => offset === activeOffset)
	)

	useEffect(() => {
		if (activePage && activeOffset !== activePage.offset) {
			setActivePage(pages.find(({ offset }) => offset === activeOffset))
		}
	}, [activeOffset])

	if (!total) {
		return null
	}

	const handlePageChange = (page) => {
		setActivePage(page)
		onPageChange(page)
	}

	const isPrevButtonDisabled = activePage.offset === pages[0].offset
	const isNextButtonDisabled =
		activePage.offset === pages[pages.length - 1].offset
	const activePageIndex = pages.findIndex(
		({ offset }) => offset === activePage.offset
	)

	if (size(pages) < 2) {
		return null
	}

	return (
		<div className={classnames('pagination-navigation', wrapperClassName)}>
			<div
				className={classnames(
					'pagination-navigation__prev',
					isPrevButtonDisabled && 'pagination-navigation__prev--disabled'
				)}
				onClick={() => {
					handlePageChange(pages[activePageIndex - 1])
				}}
			>
				{withArrowIcons && (
					<FontAwesomeIcon icon={['fa-solid', 'chevron-left']} />
				)}
				{prevLabel}
			</div>
			<div className='pagination-navigation__pages'>
				{pages.map((page) => {
					const isPageVisible =
						page.key === 0 ||
						page.key === activePage.key ||
						page.key === pages[pages.length - 1].key ||
						(page.key !== pages[0].key &&
							pages[activePage.key - 1] &&
							page.key === pages[activePage.key - 1].key) ||
						(activePage.key < pages.length - 1 &&
							pages[activePage.key + 1] &&
							page.key === pages[activePage.key + 1].key)

					if (isPageVisible) {
						return (
							<span
								key={page.offset}
								className={classnames(
									'pagination-navigation__page',
									page.offset === activePage.offset &&
										'pagination-navigation__page--active',
									stepClassName
								)}
								onClick={() => handlePageChange(page)}
							>
								{page.label}
							</span>
						)
					}

					return null
				})}
			</div>
			<div
				className={classnames(
					'pagination-navigation__next',
					isNextButtonDisabled && 'pagination-navigation__prev--disabled'
				)}
				onClick={() => {
					handlePageChange(pages[activePageIndex + 1])
				}}
			>
				{nextLabel}
				{withArrowIcons && (
					<FontAwesomeIcon icon={['fa-solid', 'chevron-right']} />
				)}
			</div>
		</div>
	)
}

PaginationNavigation.defaultProps = {
	withArrowIcons: true,
	prevLabel: 'Prev',
	nextLabel: 'Next',
}

PaginationNavigation.propTypes = {
	withArrowIcons: PropTypes.bool,
	prevLabel: PropTypes.string,
	nextLabel: PropTypes.string,
	wrapperClassName: PropTypes.string,
	stepClassName: PropTypes.string,
	onPageChange: PropTypes.func.isRequired,
}
