import React from 'react'
import { connect } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import { getExplainers } from '../../redux/selectors/explainers'
import { isEmpty } from 'lodash'
import { setFormMessage } from '../../redux/actions/user'

const Explainer = ({
	explainers,
	elementId,
	explainerKey = '',
	placement = 'top',
}) => {
	if (isEmpty(explainers) || !explainers[explainerKey]) {
		return null
	}

	const tooltipId = elementId || `explainer-${explainerKey}`

	return (
		<UncontrolledTooltip
			delay={{ show: 1000, hide: 0 }}
			placement={placement}
			target={tooltipId}
			hideArrow={false}
		>
			{explainers[explainerKey]}
		</UncontrolledTooltip>
	)
}

export default connect(
	(state) => ({
		explainers: getExplainers(state),
	}),
	{ setFormMessage }
)(Explainer)
