import React from 'react'
import { connect } from 'react-redux'
import IndirectMeasures from './IndirectMeasures'
import DirectAdaptations from './DirectAdaptations'
import ExposureSensitivity from './ExposureSensitivity'
import {
	setAdaptiveCapacity,
	setDirectMeasure,
	setIndirectMeasure,
	setExposureSensitivity,
	saveExposureSensitivity,
	saveAdaptiveCapacity,
} from '../../redux/actions/adaptations'
import { getUserSettings } from '../../redux/selectors/user'
import { ThreeDotLoader } from 'src/components/Loader'
import './styles.scss'

function ProjectAdaptationsConfigurationDisplay({
	userSettings,
	assets,
	adaptationConfiguration,
	setAdaptiveCapacity,
	setIndirectMeasure,
	setDirectMeasure,
	setExposureSensitivity,
	saveExposureSensitivity,
	saveAdaptiveCapacity,
}) {
	const slice =
		adaptationConfiguration.asset_adaptations?.[
			`asset_${adaptationConfiguration.current_asset?.id}`
		]

	if (adaptationConfiguration.adaptSidebarLoading || assets.loading) {
		return (
			<div style={{ paddingTop: '50%' }}>
				<ThreeDotLoader
					locatorId='loader-adapt-sidebar'
					width='50'
					height='50'
				/>
			</div>
		)
	}
	if (slice) {
		return (
			<div className='project-adaptations-settings-wrapper'>
				{adaptationConfiguration.display === 'exposure_sensitivity' ? (
					<div className='exposure-sensitivity-wrapper'>
						<ExposureSensitivity
							adapt_data={slice.building_vulnerabilities}
							onSelect={setExposureSensitivity}
							onSave={saveExposureSensitivity}
							userSettings={userSettings}
						/>
					</div>
				) : adaptationConfiguration.display === 'indirect_measures' ? (
					<div className='indirect-measures-wrapper'>
						<IndirectMeasures
							adapt_data={slice.adaptation_options}
							setIndirectMeasure={setIndirectMeasure}
							setAdaptiveCapacity={setAdaptiveCapacity}
							saveAdaptiveCapacity={saveAdaptiveCapacity}
						/>
					</div>
				) : (
					<div className='direct-adaptations-wrapper'>
						<DirectAdaptations
							userSettings={userSettings}
							adapt_data={slice.adaptation_options}
							adapt_direct_measure_results={
								slice.adaptation_direct_measure_results
							}
							setChecked={setDirectMeasure}
						/>
					</div>
				)}
			</div>
		)
	} else {
		return <div>No asset data has been selected</div>
	}
}

ProjectAdaptationsConfigurationDisplay.propTypes = {}

export default connect(
	(state) => ({
		userSettings: getUserSettings(state),
	}),
	{
		setAdaptiveCapacity,
		setDirectMeasure,
		setIndirectMeasure,
		setExposureSensitivity,
		saveExposureSensitivity,
		saveAdaptiveCapacity,
	}
)(ProjectAdaptationsConfigurationDisplay)
