import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

export const SearchInput = ({
	wrapperClassName,
	inputClassName,
	defaultValue,
	onChange,
	...otherProps
}) => {
	const [value, setValue] = useState(defaultValue)
	const wrapperClassNames = classnames('search-input-wrapper', wrapperClassName)
	const inputClassNames = classnames(
		'search-input-wrapper__input',
		inputClassName
	)

	const handleInputChange = (e) => {
		setValue(e.target.value)
		onChange(e.target.value)
	}

	return (
		<div className={wrapperClassNames}>
			<input
				type='text'
				className={inputClassNames}
				value={value}
				onChange={handleInputChange}
				{...otherProps}
			/>
			<FontAwesomeIcon
				icon={['far', 'magnifying-glass']}
				className='search-input-wrapper__icon'
			/>
		</div>
	)
}

SearchInput.defaultProps = {
	defaultValue: '',
}

SearchInput.propTypes = {
	wrapperClassName: PropTypes.string,
	inputClassName: PropTypes.string,
	defaultValue: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
}
