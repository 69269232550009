import { get, isEmpty } from 'lodash'

export const getProjects = (state) => {
	return state.projects.data
}
export const getProjectsLoading = (state) => state.projects.loading
export const getFirstFiveProjects = (state) => {
	return {
		...state.projects,
		data: state.projects.data.slice(0, 5),
	}
}
export const getSelectedProject = (state, projectId) => {
	if (projectId) {
		const project = state.projects.data.find(
			(project) => project.id.toString() === projectId.toString()
		)
		const popularProject =
			!isEmpty(state.popularProjects.data) &&
			state.popularProjects.data.find(
				(project) => project.id.toString() === projectId.toString()
			)

		if (project || popularProject) {
			return project || popularProject
		} else {
			return state.projects.data[0]
		}
	}
	return state.projects.data[0]
}

export const getProjectsSearchValue = (state) => state.projects.searchValue

export const getProjectSummary = (state) => {
	return get(state, `projectsSummaries.data`)
}

export const getProjectSummaryLoading = (state) => {
	return get(state, 'projectsSummaries.loading')
}

export const getProjectSummaryError = (state) => {
	return get(state, 'projectsSummaries.error')
}

export const getProjectsPaginationOptions = (state) => state.projects.pagination
