import api from '../../service/api'
import { addToast } from './toasts'
import { getErrorMessage } from '../../utils'

export const SET_EXPLAINERS = 'setExplainers'

export const setExplainers = (payload) => {
	return {
		type: SET_EXPLAINERS,
		payload,
	}
}

export const fetchExplainers = () => async (dispatch) => {
	try {
		const explainersArray = await api.get('/explainers')
		const explainers = explainersArray.reduce((accumulator, { key, text }) => {
			return { ...accumulator, [key]: text }
		}, {})
		dispatch(setExplainers(explainers))
	} catch (error) {
		dispatch(
			addToast({
				body: getErrorMessage(error, 'Error: Explainers could not be fetched'),
				type: 'error',
			})
		)
	}
}
