import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaginationNavigation } from '../../../components/PaginationNavigation'
import './styles.scss'

export const PaginationAndBulkActions = ({
	pagination,
	fetchAssets,
	deleteSelectedAssets,
	isAssetsSelected,
}) => {
	return (
		<div className='pagination-and-bulk-actions'>
			<div>
				<PaginationNavigation
					wrapperClassName='pagination-and-bulk-actions__pagination-wrapper'
					stepClassName='pagination-and-bulk-actions__pagination-step'
					withArrowIcons={false}
					prevLabel='Previous'
					nextLabel='Next'
					activeOffset={pagination.offset}
					total={pagination.total}
					limit={pagination.limit}
					onPageChange={(activePage) =>
						fetchAssets({ ...pagination, offset: activePage.offset })
					}
				/>
			</div>

			<div className='pagination-and-bulk-actions__footer'>
				<FontAwesomeIcon
					className={classnames(
						'pagination-and-bulk-actions__delete',
						isAssetsSelected && 'pagination-and-bulk-actions__delete--disabled'
					)}
					icon={['far', 'trash-can']}
					onClick={deleteSelectedAssets}
				/>
			</div>
		</div>
	)
}

PaginationAndBulkActions.propTypes = {
	deleteSelectedAssets: PropTypes.func.isRequired,
	isAssetsSelected: PropTypes.bool.isRequired,
}
