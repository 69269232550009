import { throttle } from 'lodash'
import { useEffect } from 'react'

export const useElementResize = (ref, { onResize }) => {
	return useEffect(() => {
		const el = ref.current

		const _onResize = throttle(() => {
			onResize()
		}, 500)

		const resizeObserver = new ResizeObserver(_onResize)

		// start observing for resize
		resizeObserver.observe(el)

		return () => {
			_onResize.cancel()
			resizeObserver.unobserve(el)
		}
	}, [ref, onResize])
}
