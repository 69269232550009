import React, { useEffect, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { getAnnouncements } from 'src/redux/selectors/announcements'
import {
	runAnnouncementsPoll,
	setAnnouncementAsRead,
	stopAnnouncementsPoll,
} from 'src/redux/actions/announcements'
import { ReactComponent as CircularLoading } from 'src/assets/icons/circular-loading.svg'
import { ReactComponent as ClimateXLogoBig } from '../../../assets/icons/clx-header-logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Markdown } from 'src/components/Markdown'
import './styles.scss'

function Announcements({
	announcements,
	runAnnouncementsPoll,
	setAnnouncementAsRead,
	stopAnnouncementsPoll,
}) {
	const [activeAnnouncementId, setActiveAnnouncementId] = useState(null)
	useEffect(() => {
		stopAnnouncementsPoll()

		return () => {
			runAnnouncementsPoll()
		}
	}, [])

	const isLoading = isEmpty(announcements.data) && announcements.loading
	const errorMessage = announcements.error

	return (
		<div className='announcements'>
			<ClimateXLogoBig className='announcements__climatex-logo' />
			<h3 className='announcements__title'>Announcements</h3>
			<div className='announcements__container'>
				{isLoading && (
					<CircularLoading className='sidebar-assets-wrapper__loading' />
				)}

				{!isEmpty(announcements.data) && (
					<Fragment>
						{announcements.data.map(({ id, title, text, created_at, read }) => {
							if (activeAnnouncementId && activeAnnouncementId !== id) {
								return null
							}

							return (
								<div key={id} className='announcements__item'>
									{activeAnnouncementId && (
										<span
											className='announcements__back'
											onClick={() => setActiveAnnouncementId(null)}
										>
											<FontAwesomeIcon icon={['fa-solid', 'chevron-left']} />
											Back
										</span>
									)}

									<h3
										className={classnames(
											'announcements__item-title',
											!read && 'announcements__item-title--red',
											activeAnnouncementId &&
												'announcements__item-title--active'
										)}
									>
										{title}
									</h3>
									<p className='announcements__item-timestamp'>{created_at}</p>

									<Markdown
										markdown={text}
										isInlineForced
										wrapperClassName={classnames(
											'announcements__item-content',
											activeAnnouncementId &&
												'announcements__item-content--active'
										)}
									/>

									{!activeAnnouncementId && (
										<span
											className='announcements__item-details'
											onClick={() => {
												setActiveAnnouncementId(id)
												if (!read) {
													setAnnouncementAsRead(id)
												}
											}}
										>
											Details
											<FontAwesomeIcon icon={['fa-solid', 'chevron-right']} />
										</span>
									)}
								</div>
							)
						})}
					</Fragment>
				)}

				{isEmpty(announcements.data) &&
					!announcements.loading &&
					!errorMessage && <h5>No announcements yet.</h5>}

				{errorMessage && !isLoading && <h5>{errorMessage}</h5>}
			</div>
		</div>
	)
}

Announcements.propTypes = {
	getAnnouncements: PropTypes.array,
	stopAnnouncementsPoll: PropTypes.func.isRequired,
	setAnnouncementAsRead: PropTypes.func.isRequired,
}

export default connect(
	(state) => ({
		announcements: getAnnouncements(state),
	}),
	{ runAnnouncementsPoll, setAnnouncementAsRead, stopAnnouncementsPoll }
)(Announcements)
