import React from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { components } from 'react-select'
import api from 'src/service/api'
import qs from 'qs'
import { getDefaultScenario } from 'src/utils'
import { DEFAULT_EXTENT_VALUE } from 'src/constants'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

function ProjectAdaptationsFilters({ onSelect, currentAsset }) {
	const history = useHistory()

	const locationSearch = history.location.search
	const queryParams = qs.parse(locationSearch, {
		ignoreQueryPrefix: true,
	})

	const handleSelect = (value) => {
		onSelect(value)
	}

	const getOptionLabel = (option) => {
		return `ID: ${option.asset_id}, ${
			option.street_name ? option.street_name : option.country_name
		}`
	}

	const loadOptions = async (search, loadedOptions, { page }) => {
		const paginationOptions = qs.stringify({
			search_value: search,
			offset: (page - 1) * 20,
			limit: 20,
		})

		const filterOptionsQs = qs.stringify({
			scenario: getDefaultScenario(queryParams.scenario).scenario,
			year: queryParams.year,
			defended: queryParams.defended === 'defended',
			one_in_x: DEFAULT_EXTENT_VALUE,
			action: getDefaultScenario(queryParams.scenario).action,
		})

		const activeLink = `/assets?project_id=${queryParams.projectId}&${paginationOptions}&${filterOptionsQs}`

		const response = await api.get(activeLink)

		return {
			options: response.result,
			hasMore: response.result.length > 0,
			additional: {
				page: page + 1,
			},
		}
	}

	const CustomDropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<FontAwesomeIcon
					icon={['far', 'chevron-down']}
					className='project-adaptations-assets-wrapper__arrow-icon'
				/>
			</components.DropdownIndicator>
		)
	}

	return (
		<div className='project-adaptations-assets-wrapper'>
			<div className='project-adaptations-assets-wrapper__label'>Asset:</div>
			<div data-locator-id={'select-adapt-single-asset-id'}>
				<AsyncPaginate
					key={locationSearch}
					value={currentAsset}
					defaultValue={currentAsset}
					onChange={(option) => {
						handleSelect(option)
					}}
					loadOptionsOnMenuOpen={true}
					loadOptions={loadOptions}
					getOptionValue={(option) => option.id}
					getOptionLabel={getOptionLabel}
					components={{ DropdownIndicator: CustomDropdownIndicator }}
					styles={{
						option: (provided, state) => {
							return {
								...provided,
								backgroundColor: state.isSelected ? '#e5e5e5' : 'white',
								color: 'black',
								padding: 10,
								cursor: 'pointer',
								':hover': {
									backgroundColor: '#e5e5e5',
								},
							}
						},
						control: (provided) => {
							return {
								...provided,
								backgroundColor: '#0000',
								border: '1px solid #7e8686',
								borderRadius: '30px',
								color: '#7e8686',
								cursor: 'pointer',
								fontFamily: `Graphik Web, Segoe UI, Helvetica Neue, Helvetica, Arial, "sans-serif"`,
								fontSize: '1.25em',
								fontWeight: 400,
								lineHeight: 1,
								outline: 'none',
								padding: '5px 5px 5px 10px',
								transition: 'backgroundColor .2s ease',
								width: '100%',
								boxShadow: 'none',
								minWidth: '260px',
								':hover': {
									backgroundColor: 'none',
								},
							}
						},
						indicatorSeparator: (provided) => {
							return {
								...provided,
								display: 'none',
							}
						},
					}}
					additional={{
						page: 1,
					}}
				/>
			</div>
		</div>
	)
}

ProjectAdaptationsFilters.propTypes = {}

export default ProjectAdaptationsFilters
