import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { RISK_DASHBOARD_COLUMNS } from '../../../constants'
import { getRiskDashboardTableData } from '../../../utils'
import Explainer from '../../../components/Explainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

export const RiskDashboard = ({ summary }) => {
	const { columns, rows } = useMemo(() => {
		return getRiskDashboardTableData(summary)
	}, [summary])

	const getColumnConfig = (id) => {
		return columns.find((c) => c.id === id)
	}

	return (
		<div className='asset-risk-dashboard'>
			<table className='asset-risk-dashboard__table'>
				<tr className='asset-risk-dashboard__row'>
					{columns.map((column) => {
						return (
							<th key={column.id}>
								{column.title}{' '}
								{column.hasColumnExplainer && (
									<>
										<FontAwesomeIcon
											id={`explainer-${column.explainerKey}`}
											icon={['far', 'circle-question']}
										/>
										<Explainer explainerKey={column.explainerKey} />
									</>
								)}
							</th>
						)
					})}
				</tr>
				{rows.map((row, index) => {
					return (
						<tr key={`row-${index}`} className='asset-risk-dashboard__row'>
							{Object.values(RISK_DASHBOARD_COLUMNS).map((columnId) => {
								const cellData = row[columnId]
								const columnConfig = getColumnConfig(columnId)

								let elementId
								if (cellData.hasInPlaceExplainer) {
									elementId = `explainer-${cellData.explainerKey}`
								} else if (columnConfig.hasColumnExplainer) {
									elementId = `explainer-${columnId}-row-${index}`
								}

								return (
									<td
										key={columnId}
										id={elementId}
										className={cellData.className}
									>
										{cellData.content}
										{cellData.hasInPlaceExplainer && (
											<Explainer explainerKey={cellData.explainerKey} />
										)}
										{!cellData.hasInPlaceExplainer &&
											columnConfig.hasColumnExplainer && (
												<Explainer
													elementId={elementId}
													explainerKey={columnConfig.explainerKey}
												/>
											)}
									</td>
								)
							})}
						</tr>
					)
				})}
			</table>
		</div>
	)
}

RiskDashboard.propTypes = {
	summary: PropTypes.object,
}
