import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

export const MarkdownHeadline = ({ key, children }) => {
	return (
		<div className='markdown-headline' key={key}>
			{children}
		</div>
	)
}

MarkdownHeadline.propTypes = {
	children: PropTypes.array.isRequired,
	key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
