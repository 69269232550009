import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LEFT_COLUMN_CLASSES, RIGHT_COLUMN_CLASSES } from 'src/constants'
import ProjectProgressIndicator from '../../containers/ProjectProgressIndicator'
import './styles.scss'

export const PageWrapper = ({
	bodyContent,
	sidebarContent,
	bodyClassName,
	sideBarClassName,
	sidebarOpen,
	isSidebarHidden,
	isGutterDisabled,
	isContentDisabled,
}) => {
	const [isSideBarOpen, setIsSideBarOpen] = useState(sidebarOpen)
	return (
		<div className='container-fluid page-top-padding page-wrapper'>
			<div className='row'>
				<div
					className={classnames(
						LEFT_COLUMN_CLASSES,
						'page-wrapper__body',
						bodyClassName,
						(!isSideBarOpen || isSidebarHidden) &&
							'page-wrapper__body--maximized',
						isContentDisabled && 'page-wrapper__body--disabled'
					)}
				>
					<ProjectProgressIndicator />
					{bodyContent}
				</div>

				<div
					className={classnames(
						RIGHT_COLUMN_CLASSES,
						'p-0 page-wrapper__sidebar-wrapper',
						sideBarClassName,
						isGutterDisabled && 'page-wrapper__sidebar-wrapper--border',
						isSideBarOpen && !isSidebarHidden
							? 'page-wrapper__sidebar-wrapper--open'
							: 'page-wrapper__sidebar-wrapper--closed'
					)}
				>
					{!isSidebarHidden && !isGutterDisabled && (
						<div
							className='page-wrapper__gutter'
							data-locator-id='page-wrapper-gutter'
							onClick={() => setIsSideBarOpen(!isSideBarOpen)}
						>
							<FontAwesomeIcon
								icon={['far', 'grip-lines-vertical']}
								className='page-wrapper__gutter-icon'
							/>
						</div>
					)}

					{sidebarContent}
				</div>
			</div>
		</div>
	)
}

PageWrapper.defaultProps = {
	sidebarOpen: true,
}

PageWrapper.propTypes = {
	bodyContent: PropTypes.node,
	sidebarContent: PropTypes.node,
	bodyClassName: PropTypes.string,
	sideBarClassName: PropTypes.string,
	sidebarOpen: PropTypes.bool,
	isGutterDisabled: PropTypes.bool,
	isContentDisabled: PropTypes.bool,
}
