import ProgressIndicator from '../../components/ProgressIndicator'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const ProjectProgressIndicator = ({ progress }) =>
	progress && <ProgressIndicator progress={progress} />

const mapStateToProps = ({ progressBars }) => ({
	progress: progressBars.csvUploadIndicator,
})

ProjectProgressIndicator.propTypes = {
	progress: PropTypes.object,
}

export default connect(mapStateToProps)(ProjectProgressIndicator)
