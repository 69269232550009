import React from 'react'
import './styles.scss'
import DirectAdaptationsBuildingsTable from './DirectAdaptationsBuildingsTable'

function DirectAdaptations({
	userSettings,
	adapt_data,
	adapt_direct_measure_results,
	setChecked,
}) {
	return (
		<div>
			<div className='row'></div>
			<DirectAdaptationsBuildingsTable
				userSettings={userSettings}
				adapt_data={adapt_data}
				adapt_direct_measure_results={adapt_direct_measure_results}
				setChecked={setChecked}
				locatorId={'table-adapt-direct-adaptations'}
			/>
		</div>
	)
}

DirectAdaptations.propTypes = {}

export default DirectAdaptations
