import { compact } from 'lodash'
import * as reducers from './reducers'
import { createStore, compose, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { SIGN_OUT } from './constants'
import { getAppConfig } from '../config'

export const history = createBrowserHistory()
const appConfig = getAppConfig()

const logger = createLogger({
	duration: true,
	collapsed: true,
})

const rawMiddlewares = compact([thunk, appConfig.isReduxLogEnabled && logger])

const middlewares = [
	applyMiddleware(...rawMiddlewares),
	window.__REDUX_DEVTOOLS_EXTENSION__
		? window.__REDUX_DEVTOOLS_EXTENSION__()
		: (f) => f,
]

const rootReducer = combineReducers({
	...reducers,
})

export default createStore(
	(state, action) => {
		if (action.type === SIGN_OUT) {
			return rootReducer(undefined, action)
		}

		return rootReducer(state, action)
	},
	compose(...middlewares)
)
