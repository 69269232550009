import React, { useState } from 'react'
import { Map } from 'src/containers/Map'
import {
	WALKTHROUGH_STEPS_EXPLORE_PAGE,
	WALKTHROUGH,
	WALKTHROUGH_SPOTLIGHT_STYLES,
} from '../../constants'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'

function Explorer() {
	const walkthroughLocalStorage = localStorage.getItem(WALKTHROUGH)
	const isExplorePageWalkthroughDone =
		walkthroughLocalStorage &&
		JSON.parse(walkthroughLocalStorage)?.isExplorePageWalkthroughDone === 'true'
	const [walkthrough, setWalkthrough] = useState({
		run: !isExplorePageWalkthroughDone,
		stepIndex: 0,
		tourActive: true,
	})

	const handleCallback = (data) => {
		const { action, index, type, status } = data

		if (
			[STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
			action === ACTIONS.CLOSE
		) {
			localStorage.setItem(
				WALKTHROUGH,
				JSON.stringify({
					isHomePageWalkThroughDone: 'true',
					isExplorePageWalkthroughDone: 'true',
				})
			)

			setWalkthrough({ ...walkthrough, run: false, stepIndex: 0 })
		} else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)

			setWalkthrough({
				...walkthrough,
				stepIndex: nextStepIndex,
			})
		}
	}

	return (
		<>
			<Joyride
				callback={handleCallback}
				continuous
				run={walkthrough.run}
				stepIndex={walkthrough.stepIndex}
				steps={WALKTHROUGH_STEPS_EXPLORE_PAGE}
				disableOverlayClose
				spotlightPadding={0}
				floaterProps={{ disableAnimation: true }}
				styles={{
					spotlight: WALKTHROUGH_SPOTLIGHT_STYLES,
				}}
			/>
			<Map />
		</>
	)
}

export default Explorer
