import React from 'react'
import { ReactComponent as ClimateXLogoBig } from '../../../assets/icons/clx-header-logo.svg'
import './styles.scss'

export const Menu = () => {
	return (
		<div className='side-bar-menu'>
			<ClimateXLogoBig className='side-bar-menu__climatex-logo' />
			<ul className='side-bar-menu__nav-list'>
				<li>
					<a
						href='https://www.climate-x.com/product'
						title='Discover how Climate X can deliver the climate risk data you need'
						rel='noopener noreferrer'
					>
						Product
					</a>
				</li>
				<li>
					<a
						href='https://www.climate-x.com/industries'
						title='Industries covered by Climate X'
						rel='noopener noreferrer'
					>
						Industries
					</a>
				</li>
				<li>
					<a
						href='https://www.climate-x.com/people'
						title='Learn about the people behind Climate X'
						rel='noopener noreferrer'
					>
						People
					</a>
				</li>
				<li>
					<a
						href='https://www.climate-x.com/values'
						title='Learn about the values that drive Climate X'
						rel='noopener noreferrer'
					>
						Values
					</a>
				</li>
				<li>
					<a
						href='https://www.climate-x.com/careers'
						title='Career opportunities at Climate-X'
						rel='noopener noreferrer'
					>
						Careers
					</a>
				</li>
				<li>
					<a
						href='https://www.climate-x.com/press'
						title='Visit our Press Area for useful media downloads, videos and PDFs'
						rel='noopener noreferrer'
					>
						Press
					</a>
				</li>
				<li>
					<a
						href='https://www.climate-x.com/lets-talk'
						title='Connect with the team at Climate X'
						rel='noopener noreferrer'
					>
						Let's Talk
					</a>
				</li>
			</ul>

			<footer className='side-bar-menu__footer-nav'>
				<ul className='side-bar-menu__footer-nav-list'>
					<li>
						<a
							href='https://status.climate-x.com'
							title='View our Service Status'
							rel='noopener noreferrer'
						>
							Status
						</a>
					</li>
					<li>
						<a
							href='https://www.climate-x.com/legals/privacy-policy'
							title='View our Privacy Policy'
							rel='noopener noreferrer'
						>
							Privacy
						</a>
					</li>
					<li>
						<a href='https://www.climate-x.com' rel='noopener noreferrer'>
							© Climate X Ltd
						</a>
					</li>
				</ul>
			</footer>
		</div>
	)
}
