export const ADD_TOAST = 'addToast'

/**
 * @typedef {Object} Payload
 * @property {string|React.ReactElement} body
 * @property {('error'|'info')} type
 */

/**
 * @param {Payload} payload
 */
export const addToast = (payload) => {
	return {
		type: ADD_TOAST,
		payload,
	}
}

export const REMOVE_TOAST = 'removeToast'
export const removeToast = (payload) => {
	return { type: REMOVE_TOAST, payload }
}
