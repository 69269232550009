import React from 'react'
import PropTypes from 'prop-types'
import AssetsProvider from 'src/containers/AssetsProvider'
const HomeMain = ({ createProject, fetchUserSettings, isLoading }) => {
	return (
		<main className='app__product__canvas align-items-center col-xl-9 col-lg-12 col-md-12 col-xs-12'>
			<AssetsProvider
				createProject={createProject}
				fetchUserSettings={fetchUserSettings}
				isLoading={isLoading}
			/>
		</main>
	)
}

HomeMain.propTypes = {
	createProject: PropTypes.func.isRequired,
	fetchUserSettings: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
}

export default HomeMain
