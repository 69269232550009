import { lowerCase, round, groupBy, get } from 'lodash'

export const getSelectedRiskWeight = (summary, selectedRisk) => {
	const filteredByHazardType = (
		summary?.hazard_wise_score_distribution || []
	).filter((it) => it.hazard_type === selectedRisk)
	const groupedByScoreData = groupBy(filteredByHazardType, 'score')
	let total = 0
	let total_num_assets = 0

	for (const score in groupedByScoreData) {
		total += getNumAssetsForScore(groupedByScoreData[score]) * score
		total_num_assets += getNumAssetsForScore(groupedByScoreData[score])
	}

	return round(total / total_num_assets)
}

const getNumAssetsForScore = (scoreData) => {
	if (!scoreData.length) {
		return 0
	} else if (scoreData.length === 1) {
		return scoreData[0].num_assets
	}

	return scoreData.reduce((acc, current) => acc + current.num_assets, 0)
}

export const getPercentage = (maximumAmount, currentAmount) => {
	if (currentAmount === 0) {
		return 0
	}
	return (currentAmount / maximumAmount) * 100
}

export const getVolumePercentage = (analysedAssets, riskAmount) => {
	return Math.ceil((riskAmount * 100) / analysedAssets)
}

export const getVolumeDistribution = (
	summary,
	{ assetsScoreKey, riskType }
) => {
	const filteredByHazardType = (
		summary?.hazard_wise_score_distribution || []
	).filter((it) => it.hazard_type === riskType)

	let filteredResult = filteredByHazardType

	if (assetsScoreKey) {
		filteredResult = filteredByHazardType.filter(
			(it) => lowerCase(it.risck_category) === assetsScoreKey
		)
	}

	const groupedByScoreData = groupBy(filteredResult, 'score')

	let total_num_assets = 0
	let totalSumAssetsByScore = {}
	for (const score in groupedByScoreData) {
		total_num_assets += getNumAssetsForScore(groupedByScoreData[score])

		totalSumAssetsByScore[score] = {
			total_num_assets: getNumAssetsForScore(groupedByScoreData[score]),
		}
	}

	return [
		{
			key: 'improbable',
			score_key: 'A',
			label: 'A',
			percentage: getPercentage(
				total_num_assets,
				get(totalSumAssetsByScore, '[1].total_num_assets', 0)
			),
			amount: get(totalSumAssetsByScore, '[1].total_num_assets', 0),
		},
		{
			key: 'unlikely',
			score_key: 'B',
			label: 'B',
			percentage: getPercentage(
				total_num_assets,
				get(totalSumAssetsByScore, '[2].total_num_assets', 0)
			),
			amount: get(totalSumAssetsByScore, '[2].total_num_assets', 0),
		},
		{
			key: 'likely',
			score_key: 'C',
			label: 'C',
			percentage: getPercentage(
				total_num_assets,
				get(totalSumAssetsByScore, '[3].total_num_assets', 0)
			),
			amount: get(totalSumAssetsByScore, '[3].total_num_assets', 0),
		},
		{
			key: 'high',
			score_key: 'D',
			label: 'D',
			percentage: getPercentage(
				total_num_assets,
				get(totalSumAssetsByScore, '[4].total_num_assets', 0)
			),
			amount: get(totalSumAssetsByScore, '[4].total_num_assets', 0),
		},
		{
			key: 'very_high',
			score_key: 'E',
			label: 'E',
			percentage: getPercentage(
				total_num_assets,
				get(totalSumAssetsByScore, '[5].total_num_assets', 0)
			),
			amount: get(totalSumAssetsByScore, '[5].total_num_assets', 0),
		},
		{
			key: 'extreme',
			score_key: 'F',
			label: 'F',
			percentage: getPercentage(
				total_num_assets,
				get(totalSumAssetsByScore, '[6].total_num_assets', 0)
			),
			amount: get(totalSumAssetsByScore, '[6].total_num_assets', 0),
		},
	]
}
