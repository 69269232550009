import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SCORE_PALETTE } from 'src/constants'
import './styles.scss'

export const Score = ({ score, wrapperClassName, locatorId }) => {
	return (
		<div
			className={classNames('score-circle', wrapperClassName)}
			style={{ backgroundColor: SCORE_PALETTE[score] }}
			data-locator-id={locatorId}
		>
			{score}
		</div>
	)
}

Score.defaultProps = {
	score: 'A',
	locatorId: 'not-set',
}

Score.propTypes = {
	score: PropTypes.oneOf(['A', 'B', 'C', 'D', 'E', 'F']),
	wrapperClassName: PropTypes.string,
}
