import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.scss'

export const ThreeDotLoader = ({
	className,
	id,
	locatorId,
	color = '#35AE78',
	radius = '10',
	width = '100',
	height = '100',
}) => {
	return (
		<div
			id={id}
			className={classnames('loader', className)}
			data-locator-id={locatorId}
		>
			<ThreeDots
				color={color}
				visible={true}
				radius={radius}
				width={width}
				height={height}
			/>
		</div>
	)
}

ThreeDotLoader.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	locatorId: PropTypes.string,
	color: PropTypes.string,
	radius: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
