import {
	SET_ANNOUNCEMENTS_LOADING,
	SET_ANNOUNCEMENTS,
	SET_ANNOUNCEMENTS_ERROR_MESSAGE,
	SET_ANNOUNCEMENTS_POLLING_STATE,
	STOP_ANNOUNCEMENTS_POLL,
	SET_ANNOUNCEMENT_AS_READ,
} from '../actions/announcements'

const DEFAULT_STATE = {
	data: undefined,
	loading: false,
	error: undefined,
	isUnreadAnnouncementExists: false,
	isAnnouncementsPolling: false,
}

export default function announcements(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SET_ANNOUNCEMENTS_LOADING:
			return {
				...state,
				loading: action.isLoading,
			}
		case SET_ANNOUNCEMENTS:
			return {
				...state,
				data: action.announcements,
				isUnreadAnnouncementExists: action.isUnreadAnnouncementExists,
				loading: false,
				error: undefined,
			}
		case SET_ANNOUNCEMENTS_ERROR_MESSAGE:
			return {
				...state,
				data: undefined,
				loading: false,
				isUnreadAnnouncementExists: false,
				error: action.message,
			}
		case STOP_ANNOUNCEMENTS_POLL:
			return {
				...state,
				loading: false,
				isAnnouncementsPolling: false,
			}
		case SET_ANNOUNCEMENTS_POLLING_STATE:
			return {
				...state,
				isAnnouncementsPolling: action.isAnnouncementsPolling,
			}
		case SET_ANNOUNCEMENT_AS_READ:
			return {
				...state,
				data: state.data.map((announcement) => {
					if (announcement.id === action.announcementId) {
						return {
							...announcement,
							read: true,
						}
					}

					return announcement
				}),
				isUnreadAnnouncementExists: state.data.some((announcement) => {
					return announcement.id !== action.announcementId && !announcement.read
				}),
				loading: false,
			}
		default:
			return state
	}
}
