import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import App from './App'
import './assets/styles/reset.scss'
import './assets/styles/animations.scss'
import './assets/styles/mixins.scss'
import './assets/styles/global.scss'

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)
