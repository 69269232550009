import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash/lang'
import classnames from 'classnames'
import './styles.scss'

export const CheckBox = ({
	label,
	defaultValue,
	applyChangeFromParent,
	isDisabled,
	appearance,
	color,
	onChange,
}) => {
	const [isChecked, setIsChecked] = useState(!!defaultValue)
	const handleOnChange = (isChecked) => {
		if (isDisabled) return

		if (!applyChangeFromParent) {
			setIsChecked(isChecked)
		}

		onChange(isChecked)
	}

	useEffect(() => {
		if (applyChangeFromParent) {
			setIsChecked(defaultValue)
		}
	}, [defaultValue, applyChangeFromParent])

	const isDashedCircle = appearance === 'dashed-circle'

	return (
		<div
			className={classnames(
				'checkbox',
				`checkbox--${appearance}`,
				`checkbox--${color}`,
				isDisabled && 'checkbox--disabled',
				isDashedCircle && isChecked && 'checkbox--active'
			)}
			onClick={() => {
				handleOnChange(!isChecked)
			}}
		>
			{isDashedCircle ? (
				<span>
					<FontAwesomeIcon
						icon={['far', isChecked ? 'circle-check' : 'circle-dashed']}
					/>
				</span>
			) : (
				<input
					type='checkbox'
					className='form-check-input'
					checked={isChecked}
					readOnly={true}
				/>
			)}
			{!isEmpty(label) && (
				<label
					className={classnames(
						'checkbox__label',
						isDashedCircle && 'checkbox__label--dashed-circle'
					)}
				>
					{label}
				</label>
			)}
		</div>
	)
}

CheckBox.defaultProps = {
	appearance: 'square',
	color: 'red',
	isDisabled: false,
	isSingleSelect: false,
}

CheckBox.propTypes = {
	appearance: PropTypes.oneOf(['square', 'circle', 'dashed-circle']),
	applyChangeFromParent: PropTypes.bool,
	color: PropTypes.oneOf(['red', 'white']),
	defaultValue: PropTypes.bool,
	isDisabled: PropTypes.bool,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
}
