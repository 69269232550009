import React from 'react'
import StatusMessage from 'src/components/StatusMessage'
import PropTypes from 'prop-types'

const FormMessages = ({ formObject, formMessage }) => {
	return (
		<>
			<h1>{formObject.title}</h1>
			<div>{formObject.subtitle}</div>
			{formMessage && (
				<StatusMessage type={formMessage.type}>
					{formMessage.message}
				</StatusMessage>
			)}
		</>
	)
}

FormMessages.propTypes = {
	formObject: PropTypes.shape({
		title: PropTypes.string.isRequired,
		subtitle: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array,
			PropTypes.object,
		]),
	}),
	formMessage: PropTypes.shape({
		type: PropTypes.string.isRequired,
		message: PropTypes.string.isRequired,
	}),
}

export default FormMessages
