export const ADD_PROJECT_FILTERS = 'addProjectFilters'

export function addProjectFilters(filters) {
	return { type: ADD_PROJECT_FILTERS, filters }
}

export function setProjectFilters(scenario, defended) {
	return (dispatch) => {
		dispatch(addProjectFilters({ scenario, defended }))
	}
}
