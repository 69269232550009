import {
	ADD_PROJECT,
	SET_PROJECT_LOADING,
	ADD_PROJECTS_TO_BOTTOM,
	ON_PROJECT_DELETE,
	ON_PROJECT_EDIT,
	CLEAR_PROJECTS_SEARCH_AND_REFETCH,
	UPDATE_PROJECT_ITEM,
} from '../actions/projects'

export const PROJECTS_INIT_PAGINATION = {
	offset: 0,
	limit: 20,
}

const DEFAULT_STATE = {
	data: [],
	pagination: PROJECTS_INIT_PAGINATION,
	assetsProgress: undefined,
	searchValue: undefined,
	loading: undefined,
	paginationLoading: false,
	error: null,
}

export const DEFAULT_PROJECTS_PAGINATION_STATE = DEFAULT_STATE.pagination

export default function projects(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case ADD_PROJECT:
			return {
				...state,
				data: [action.project, ...state.data],
			}
		case ADD_PROJECTS_TO_BOTTOM:
			return {
				...state,
				data: action.projects,
				pagination: { ...state.pagination, ...action.pagination },
				searchValue: action.searchValue,
				loading: false,
			}
		case SET_PROJECT_LOADING:
			return { ...state, loading: action.isLoading }
		case CLEAR_PROJECTS_SEARCH_AND_REFETCH:
			return { ...state, searchValue: undefined }
		case ON_PROJECT_DELETE:
			return {
				...state,
				data: state.data.filter(
					(project) => !action.projectIds.includes(project.id)
				),
			}
		case ON_PROJECT_EDIT:
			return {
				...state,
				data: state.data.map((project) => {
					if (project.id !== action.projectId) {
						return project
					}

					return {
						...project,
						...action.payload,
					}
				}),
			}
		case UPDATE_PROJECT_ITEM:
			return {
				...state,
				data: state.data.map((project) => {
					if (project.id !== action.project.id) {
						return project
					}

					return {
						...project,
						...action.project,
					}
				}),
			}
		default:
			return state
	}
}
