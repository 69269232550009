import { CLIMATE_X_USER_EXPIRE_TIME } from '../constants'

export const getUserSessionExpireStatus = () => {
	let expireTimeExists = false
	let userExpireTime = localStorage.getItem(CLIMATE_X_USER_EXPIRE_TIME)
	if (userExpireTime) {
		userExpireTime = parseInt(userExpireTime, 10)
		expireTimeExists = true
	} else {
		userExpireTime = 0
	}

	const timeDiff = Date.now() - userExpireTime

	return {
		expireTimeExists,
		timeDiff,
		isExpired: timeDiff > 0,
	}
}
