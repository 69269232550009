import React from 'react'
import './styles.scss'
import { Select } from 'src/components/Select'
import Input from 'src/components/Input'
import { Button } from 'src/components/Button'
import { CustomSwitch } from 'src/components/CustomSwitch'

function transformOptions(possibleOptions) {
	if (!possibleOptions || possibleOptions.length === 0) {
		return [{ value: 'None', displayValue: 'None' }]
	}

	return possibleOptions.map((option) => ({
		value: option ?? 'None',
		displayValue: option ?? 'None',
	}))
}

function IndirectMeasures({
	adapt_data,
	setIndirectMeasure,
	setAdaptiveCapacity,
	saveAdaptiveCapacity,
}) {
	const enableEpcs = true

	return (
		<div>
			<div className='row'>
				<div className='col-md-6'>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Sustainable Urban Drainage System
						</div>
						<div className='indirect-measures-switch'>
							<CustomSwitch
								locatorId={
									'switch-indirect-measures-sustainable-urban-drainage-system'
								}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures.sustainable_urban_drainage_system
										.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure(
										'sustainable_urban_drainage_system',
										event.target.checked
									)
								}
								checked={
									adapt_data.indirect_measures.sustainable_urban_drainage_system
										.default
								}
							/>
						</div>
					</div>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Coastal Defences
						</div>
						<div className='indirect-measures-switch'>
							<CustomSwitch
								locatorId={'switch-indirect-measures-coastal-defences'}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures.coastal_defences.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure('coastal_defences', event.target.checked)
								}
								checked={adapt_data.indirect_measures.coastal_defences.default}
							/>
						</div>
					</div>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Cliff Strengthening and Stabalisation
						</div>
						<div className='indirect-measures-switch'>
							<CustomSwitch
								locatorId={
									'switch-indirect-measures-cliff-strengthening-and-stabilisation'
								}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures
										.cliff_strengthening_and_stabilisation.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure(
										'cliff_strengthening_and_stabilisation',
										event.target.checked
									)
								}
								checked={
									adapt_data.indirect_measures
										.cliff_strengthening_and_stabilisation.default
								}
							/>
						</div>
					</div>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Prescribed Burning and Veg Management
						</div>
						<div className='indirect-measures-switch'>
							<CustomSwitch
								locatorId={
									'switch-indirect-measures-prescribed-burning-and-vegetation-management'
								}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures
										.prescribed_burning_and_vegetation_management.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure(
										'prescribed_burning_and_vegetation_management',
										event.target.checked
									)
								}
								checked={
									adapt_data.indirect_measures
										.prescribed_burning_and_vegetation_management.default
								}
							/>
						</div>
					</div>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Green Infrastructure for Wind Control
						</div>
						<div className='indirect-measures-switch'>
							<CustomSwitch
								locatorId={
									'switch-indirect-measures-green-infrastructure-for-wind-control'
								}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures
										.green_infrastructure_for_wind_control.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure(
										'green_infrastructure_for_wind_control',
										event.target.checked
									)
								}
								checked={
									adapt_data.indirect_measures
										.green_infrastructure_for_wind_control.default
								}
							/>
						</div>
					</div>
				</div>
				<div className='col-md-6'>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							River Flood Control Infrastructure
						</div>
						<div className='indirect-measure-switch'>
							<CustomSwitch
								locatorId={
									'switch-indirect-measures-river-flood-control-infrastructure'
								}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures
										.river_flood_control_infrastructure.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure(
										'river_flood_control_infrastructure',
										event.target.checked
									)
								}
								checked={
									adapt_data.indirect_measures
										.river_flood_control_infrastructure.default
								}
							/>
						</div>
					</div>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Upgraded Ground Water Management
						</div>
						<div className='indirect-measure-switch'></div>
						<CustomSwitch
							locatorId={
								'switch-indirect-measures-upgraded-ground-water-management'
							}
							size='lg'
							variant='solid'
							color={
								adapt_data.indirect_measures.upgraded_ground_water_management
									.default
									? 'success'
									: ''
							}
							onChange={(event) =>
								setIndirectMeasure(
									'upgraded_ground_water_management',
									event.target.checked
								)
							}
							checked={
								adapt_data.indirect_measures.upgraded_ground_water_management
									.default
							}
						/>
					</div>

					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Urban Green Infrastructure
						</div>
						<div className='indirect-measure-switch'>
							<CustomSwitch
								locatorId={
									'switch-indirect-measures-urban-green-infrastructure'
								}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures.urban_green_infrastructure
										.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure(
										'urban_green_infrastructure',
										event.target.checked
									)
								}
								checked={
									adapt_data.indirect_measures.urban_green_infrastructure
										.default
								}
							/>
						</div>
					</div>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Water Conservation and Efficient Systems
						</div>
						<div className='indirect-measure-switch'>
							<CustomSwitch
								locatorId={
									'switch-indirect-measures-water-conservation-and-efficient-systems'
								}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures
										.water_conservation_and_efficient_systems.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure(
										'water_conservation_and_efficient_systems',
										event.target.checked
									)
								}
								checked={
									adapt_data.indirect_measures
										.water_conservation_and_efficient_systems.default
								}
							/>
						</div>
					</div>
					<div className='switch-row'>
						<div className='indirect-measures-switch-label'>
							Storm Surge Gates
						</div>
						<div className='indirect-measure-switch'>
							<CustomSwitch
								locatorId={'switch-indirect-measures-storm-surge-gates'}
								size='lg'
								variant='solid'
								color={
									adapt_data.indirect_measures.storm_surge_gates.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setIndirectMeasure('storm_surge_gates', event.target.checked)
								}
								checked={adapt_data.indirect_measures.storm_surge_gates.default}
							/>
						</div>
					</div>
				</div>
				<hr />
				<div style={{ fontWeight: 'bold' }}>Adaptive Capacity Options</div>
				<div className='col-md-4'>
					<div className='select-row'>
						<div className='adaptive-capacity-select__label'>
							Organizational
						</div>
						<div>
							<Select
								locatorId={'select-adaptive-capacity-options-organizational'}
								options={transformOptions(
									adapt_data.adaptive_capacity_options
										?.organizational_capability.possible_options
								)}
								selectedValue={
									adapt_data.adaptive_capacity_options
										?.organizational_capability.default || 'None'
								}
								onChange={(organizational_capability) =>
									setAdaptiveCapacity(
										'organizational_capability',
										organizational_capability
									)
								}
								className='adaptive-capacity__select'
							/>
						</div>
					</div>
				</div>
				<div className='col-md-4'>
					<div className='select-row'>
						<div className='adaptive-capacity-select__label'>Financial</div>
						<div>
							<Select
								locatorId={'select-adaptive-capacity-options-financial'}
								wrapperId={`explainer-${
									enableEpcs ? 'financialcapacity' : 'nofinancialcapacity'
								}`}
								options={transformOptions(
									adapt_data.adaptive_capacity_options?.financial_capacity
										.possible_options
								)}
								selectedValue={
									adapt_data.adaptive_capacity_options?.financial_capacity
										.default || 'None'
								}
								onChange={(financial_capacity) =>
									setAdaptiveCapacity('financial_capacity', financial_capacity)
								}
								className='adaptive-capacity__select'
							/>
						</div>
					</div>
				</div>
				<div className='col-md-4'>
					<div className='select-row'>
						<div className='adaptive-capacity-select__label'>Technical</div>
						<div>
							<Select
								locatorId={'select-adaptive-capacity-options-technical'}
								wrapperId={`explainer-${
									enableEpcs ? 'scenario' : 'scenario_no_epc'
								}`}
								options={transformOptions(
									adapt_data.adaptive_capacity_options?.technical_capacity
										.possible_options
								)}
								selectedValue={
									adapt_data.adaptive_capacity_options?.technical_capacity
										.default || 'None'
								}
								onChange={(technical_capacity) =>
									setAdaptiveCapacity('technical_capacity', technical_capacity)
								}
								className='adaptive-capacity__select'
							/>
						</div>
					</div>
				</div>
				<hr />
				<div
					className='row'
					style={{ textAlign: 'center', justifyContent: 'center' }}
				>
					<div className='col-md-6'>
						<div>Estimated Government Charge or Tax</div>
						<div style={{ paddingTop: '10px' }}>
							<Input
								locatorId={
									'select-adaptive-capacity-estimated-government-charge-or-tax'
								}
								value={
									adapt_data.adaptive_capacity_options
										?.estimated_government_charge_or_tax.default
								}
								onChange={(estimated_government_charge_or_tax) =>
									setAdaptiveCapacity(
										'estimated_government_charge_or_tax',
										estimated_government_charge_or_tax.target.value
									)
								}
								className='adaptive-capacity__input'
							/>
						</div>
					</div>
					<div className='col-md-6'>
						<div className='adaptive-capacity-switch__title'>
							Include Adaptive Capacity
						</div>
						<div
							className='adaptive-capacity__switch'
							style={{ paddingTop: '20px' }}
						>
							<CustomSwitch
								locatorId={'switch-adaptive-capacity-cap_included'}
								size='lg'
								variant='solid'
								color={
									adapt_data.adaptive_capacity_options.cap_included.default
										? 'success'
										: ''
								}
								onChange={(event) =>
									setAdaptiveCapacity('cap_included', event.target.checked)
								}
								checked={
									adapt_data.adaptive_capacity_options.cap_included.default
								}
							/>
						</div>
					</div>
				</div>
				<hr />
				<div className='row'>
					<div className='col-md-2'></div>
					<div
						className='col-md-8'
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Button
							onClick={saveAdaptiveCapacity}
							locatorId={'button-save-adaptive-capacity-options'}
						>
							Save Capacity Options
						</Button>
					</div>
					<div className='col-md-2'></div>
				</div>
			</div>
		</div>
	)
}

IndirectMeasures.propTypes = {}

export default IndirectMeasures
