import { BASE_YEAR } from 'src/constants/baseParameters'

export const getBusinessDisruptionCacheKey = ({
	projectId,
	assetId,
	scenario,
	targetYear,
	defended,
}) => {
	return `${projectId}-${assetId}-${scenario}-${BASE_YEAR}-${targetYear}-${defended}`
}
