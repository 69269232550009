export const getUser = (state) => state.user
export const getUserData = (state) => state.user.userData
export const getIsUserDemo = (state) => {
	return state.user.userData && state.user.userData?.role === 'DEMO'
}
export const getUserCountries = (state) => {
	return state.user.userData?.countries
}
export const getUserSettings = (state) => {
	return state.user.userSettings
}

export const getUserFeatureFlags = (state) => {
	return state.user.userData?.feature_flags
}
