import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'
import Summary from './Summary'
import ProjectSidebarTabs, { PROJECT_SIDEBAR_TABS } from '../ProjectSidebarTabs'
import { getCompareProject } from '../../redux/selectors/compareProject'
import {
	closeProjectCompare,
	setCompareProjectFilters,
} from '../../redux/actions/projectCompare'
import { getUserFeatureFlags } from '../../redux/selectors/user'
import { Header } from './Header'
import './styles.scss'

const _ProjectCompare = ({
	selectedProject,
	setCompareProjectFilters,
	closeProjectCompare,
	assetId,
	assetName,
	userFeatureFlags,
}) => {
	const [activeTab, setActiveTab] = useState('summary')

	const selectedScenario = selectedProject.filters.scenario
	const selectedYear = selectedProject.filters.year
	const selectedEpcTargetYear = selectedProject.filters.epcTargetYear
	const selectedDefended = selectedProject.filters.defended
	const selectedTargetEpc = selectedProject.filters.targetEpc

	const handleFilterChangeChange = (updatedParams) => {
		setCompareProjectFilters(updatedParams)
	}

	useEffect(() => {
		return () => {
			closeProjectCompare()
		}
	}, [closeProjectCompare])

	return (
		<div className='project-compare-sidebar content-enter-animation'>
			<Header
				projectName={get(selectedProject, 'project.name')}
				onClose={closeProjectCompare}
			/>
			<ProjectSidebarTabs
				onTabSelect={(key) => setActiveTab(key)}
				activeTab={activeTab}
				hideTabs={[
					PROJECT_SIDEBAR_TABS.ASSETS,
					PROJECT_SIDEBAR_TABS.ADAPTATIONS,
				]}
			/>
			<div className='project-sidebar__body'>
				<Summary
					onSelect={handleFilterChangeChange}
					defaultScenario={selectedScenario}
					defaultYear={selectedYear}
					defaultEpcTargetYear={selectedEpcTargetYear}
					defaultDefend={selectedDefended}
					defaultTargetEpc={selectedTargetEpc}
					selectedProject={selectedProject}
					assetId={assetId}
					assetName={assetName}
					userFeatureFlags={userFeatureFlags}
				/>
			</div>
		</div>
	)
}

_ProjectCompare.propTypes = {
	selectedProject: PropTypes.object,
	assetId: PropTypes.string,
	assetName: PropTypes.string,
	closeProjectCompare: PropTypes.func.isRequired,
	setCompareProjectFilters: PropTypes.func.isRequired,
}

export const ProjectCompare = connect(
	(state) => {
		return {
			selectedProject: getCompareProject(state),
			userFeatureFlags: getUserFeatureFlags(state),
		}
	},
	{ closeProjectCompare, setCompareProjectFilters }
)(_ProjectCompare)
