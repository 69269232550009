export const INDEX_ROUTE = '/'
export const HOME_ROUTE = '/home'
export const EXPLORER_ROUTE = '/explorer'
export const PROJECTS_ROUTE = '/projects'
export const ADAPTATIONS_ROUTE = '/adaptations'
export const SETTINGS_ROUTE = '/settings'
export const LOGIN_ROUTE = '/login'
export const CONFIRM_USER_ROUTE = '/confirm-user'
export const VERIFY_MFA_ROUTE = '/verify-mfa'
export const ENABLE_MFA_ROUTE = '/enable-mfa'
export const NOT_FOUND_ROUTE = '/not-found'
export const RESET_PASSWORD_ROUTE = '/reset-password'
export const CONFIRM_PASSWORD_ROUTE = '/confirm-password'
