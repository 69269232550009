export const HAZARD_TYPES = {
	river_flood: {
		value: 'river_flood',
		displayValue: 'River Flooding Score',
		mapDisplayValue: 'River flooding',
	},
	surface_flood: {
		value: 'surface_flood',
		displayValue: 'Surface Flooding Score',
		mapDisplayValue: 'Surface flooding',
	},
	coastal_flood: {
		value: 'coastal_flood',
		displayValue: 'Coastal Flooding Score',
		mapDisplayValue: 'Coastal flooding',
	},
	subsidence: {
		value: 'subsidence',
		displayValue: 'Subsidence Score',
		mapDisplayValue: 'Subsidence',
	},
	landslide: {
		value: 'landslide',
		displayValue: 'Landslide Score',
		mapDisplayValue: 'Landslide',
	},
	wildfire: {
		value: 'wildfires',
		displayValue: 'Wildfire Score',
		mapDisplayValue: 'Wildfire',
	},
	storm: {
		value: 'storms',
		displayValue: 'Storm Score',
		mapDisplayValue: 'Storm',
	},
	tropical_cyclones: {
		value: 'tropical_cyclones',
		displayValue: 'Tropical Cyclones Score',
		mapDisplayValue: 'Tropical Cyclones',
	},
	storm_surge: {
		value: 'storm_surge',
		displayValue: 'Storm Surge Score',
		mapDisplayValue: 'Storm Surge',
	},
	droughts: {
		value: 'droughts',
		displayValue: 'Drought Score',
		mapDisplayValue: 'Drought',
	},
	extreme_heat: {
		value: 'extreme_heat_days',
		displayValue: 'Extreme Heat Score',
		mapDisplayValue: 'Extreme heat',
	},
}

export const HAZARD_NAMES = [
	'river_flood',
	'coastal_flood',
	'surface_flood',
	'tropical_cyclones',
	'extreme_heat_days',
	'landslide',
	'subsidence',
	'storms',
	'wildfires',
	'droughts',
	'storm_surge',
]

export const SCENARIO_TYPES = [
	'rcp26',
	'rcp45',
	'rcp60',
	'rcp85',
	'ssp126',
	'ssp245',
	'ssp370',
	'ssp585',
]
