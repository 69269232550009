import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isUndefined, get, map, round } from 'lodash'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { SCORE_WEIGHTS, HAZARD_TYPES } from 'src/constants'
import {
	getSelectedRiskWeight,
	getVolumeDistribution,
	numberWithCommas,
} from 'src/utils'
import { Select } from 'src/components/Select'
import { Score } from 'src/components/Score'
import { VolumeDistribution } from 'src/components/VolumeDistribution'
import { Volumes } from './Volumes'
import { LoadingState } from './LoadingState'
import classnames from 'classnames'
import { EpcRating } from './EpcRating'
import { Losses } from '../../components/Losses'
import { usePrevious } from '../../customHooks'
import Explainer from '../../components/Explainer'
import './styles.scss'

function ProjectSummaryContent({
	projectSummary,
	projectSummaryLoading,
	projectSummaryErrorMessage,
	defaultRiskType,
	onRiskTypeSet,
	shouldUpdateDefaultRiskType,
	isMainSideBar,
	onAssetsScoreSelect,
	onVolumeDistributionSelect,
	isCompareEnabled,
	userSettings,
	userFeatureFlags,
	targetEpc,
}) {
	const [lossesDisplayFormat, setLossesDisplayFormat] = useState('cost')
	const enableEpcs = userFeatureFlags['enable-epcs'] || false

	const location = useLocation()
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
	const assetsVolumeKey = queryParams.assets_volume_key
	const assetsScoreKey = queryParams.assets_score_key

	const prevVolumeKey = usePrevious(assetsVolumeKey)

	const [riskType, setRiskType] = useState(defaultRiskType)
	const [activeVolumeDistribution, setActiveVolumeDistribution] =
		useState(assetsVolumeKey)

	useEffect(() => {
		if (shouldUpdateDefaultRiskType && defaultRiskType !== riskType) {
			setRiskType(defaultRiskType)
		}
	}, [defaultRiskType])

	useEffect(() => {
		if (prevVolumeKey && !queryParams.assets_volume_key) {
			setActiveVolumeDistribution(undefined)
		}
	}, [queryParams.assets_volume_key])

	if (isUndefined(projectSummaryLoading) || projectSummaryLoading) {
		return <LoadingState />
	}

	if (isEmpty(projectSummary) || projectSummaryErrorMessage) {
		return (
			<div className='project-summary_content__empty'>
				{projectSummaryErrorMessage ||
					'No summary data to show, please try selecting another project'}
			</div>
		)
	}

	const handleRiskTypeChange = (riskType) => {
		setRiskType(riskType)

		if (shouldUpdateDefaultRiskType) {
			onRiskTypeSet(riskType)
		}
	}

	const handleVolumeDistributionSelect = (item) => {
		// Do not filter in case user clicks inside compare sidebar or compare is enabled
		if (isMainSideBar && !isCompareEnabled) {
			if (item.score_key === activeVolumeDistribution) {
				setActiveVolumeDistribution(undefined)
				onVolumeDistributionSelect(undefined)
			} else {
				setActiveVolumeDistribution(item.score_key)
				onVolumeDistributionSelect(item.score_key)
			}
		}
	}

	return (
		<div
			className='project-summary_content'
			data-locator-id='container-project-summary'
		>
			<div id='scores-and-volumes'>
				<div className='row w-100 m-0 project-summary_content__main-title-row'>
					<div className='col-12 py-2'>
						<h3 className='project-summary_content__main-title'>
							Full portfolio
						</h3>
					</div>
				</div>
				<div className='row w-100 m-0 project-summary_content__portfolio-analised'>
					<div
						className={`col-12 col-md-${
							enableEpcs ? '4' : '6'
						} py-2 project-summary_content__portfolio`}
					>
						<h4 className='project-summary_content__section-title'>
							Physical risk score
						</h4>
						<div
							id='explainer-project_summary_physical_risk_score'
							className='project-summary_content__section-score'
							data-locator-id='element-physical-risk-score'
						>
							<Score
								score={SCORE_WEIGHTS[round(projectSummary.physical_risk)]}
								locatorId='element-project-summary-circle'
							/>
						</div>
						<Explainer explainerKey='project_summary_physical_risk_score' />
					</div>
					{enableEpcs ? (
						<div
							className={`col-12 col-md-${
								enableEpcs ? '4' : '6'
							} py-2 project-summary_content__portfolio`}
						>
							<h4 className='project-summary_content__section-title'>
								EPC rating
							</h4>
							<div className='row w-100 m-0'>
								<div
									id='explainer-project_summary_epc_rating_actual'
									className='col-6'
								>
									<EpcRating
										label='Actual'
										value={round(projectSummary?.actual_epc)}
										locatorId='element-epc-rating-actual-circle'
									/>
								</div>
								<Explainer explainerKey='project_summary_epc_rating_actual' />
								<div
									id='explainer-project_summary_epc_rating_potential'
									className='col-6'
								>
									<EpcRating
										label='Potential'
										value={round(projectSummary?.potential_epc)}
										locatorId='element-epc-rating-potential-circle'
									/>
								</div>
								<Explainer explainerKey='project_summary_epc_rating_potential' />
							</div>
						</div>
					) : null}

					<div
						id='explainer-analyzed_loaded'
						className={`col-12 col-md-${enableEpcs ? '4' : '6'} py-2`}
					>
						<h4 className='project-summary_content__section-title'>
							Analysed / Loaded
						</h4>
						<div
							className='project-summary_content__analyse-loaded'
							data-locator-id='element-analyzed-loaded'
						>
							{numberWithCommas(get(projectSummary, 'num_assets_analyzed', 0))}{' '}
							/ {numberWithCommas(get(projectSummary, 'num_assets', 0))}
						</div>
					</div>
					<Explainer explainerKey='analyzed_loaded' />
				</div>
				<div
					id='explainer-volumes'
					className='row w-100 m-0 project-summary_content__risc-score'
				>
					<div className='col-12 py-2'>
						<h4 className='project-summary_content__section-title'>Volumes</h4>
					</div>
					<Volumes
						projectSummary={projectSummary}
						onAssetsScoreSelect={onAssetsScoreSelect}
						isMainSideBar={isMainSideBar}
						isCompareEnabled={isCompareEnabled}
					/>
				</div>
			</div>
			<Explainer explainerKey='volumes' />
			<div id='hazard-distribution-wrapper'>
				<div className='row w-100 m-0'>
					<div
						id='explainer-selected_hazard'
						className='col-12 py-2'
						data-locator-id='explainer-selected-hazard'
					>
						<Select
							locatorId='select-hazard-summary-sidebar'
							options={Object.values(HAZARD_TYPES)}
							selectedValue={riskType}
							onChange={(riskType) => {
								handleRiskTypeChange(riskType)
							}}
							className='project-summary_content__select'
						/>
						<Explainer explainerKey='selected_hazard' />

						<div className='project-summary_content__section-score'>
							<Score
								locatorId='element-hazard-score-circle'
								score={
									SCORE_WEIGHTS[getSelectedRiskWeight(projectSummary, riskType)]
								}
							/>
						</div>
					</div>
				</div>
				<div className='row w-100 mx-0 project-summary_content__volume-distribution-row'>
					<div className='col-12 py-2'>
						<h4 className='project-summary_content__section-title'>
							Volume distribution
						</h4>
						<VolumeDistribution
							distributionData={getVolumeDistribution(projectSummary, {
								assetsScoreKey: assetsScoreKey,
								riskType: riskType,
							})}
							onSelect={handleVolumeDistributionSelect}
							activeScoreKey={activeVolumeDistribution}
						/>
					</div>
				</div>
			</div>

			<div
				id={`project-losses-wrapper${enableEpcs ? '' : '_no_epc'}`}
				className='row w-100 m-0'
			>
				<div
					id={`explainer-${enableEpcs ? 'losses' : 'losses_no_epc'}`}
					className='col-12 py-2'
				>
					<div className='project-summary_content__section-title-wrapper'>
						<h4 className='project-summary_content__section-title'>
							Estimated Losses p.a
						</h4>
						<div
							className={classnames(
								'project-summary_content__section-title-icon',
								lossesDisplayFormat !== 'cost' &&
									'project-summary_content__section-title-icon--inactive'
							)}
							onClick={(e) => {
								e.stopPropagation()
								setLossesDisplayFormat('cost')
							}}
							data-locator-id='element-losses-in-currency-btn'
						>
							{userSettings?.preferred_currency_symbol || ''}
						</div>
						<div
							className={classnames(
								'project-summary_content__section-title-icon',
								lossesDisplayFormat !== 'percentage' &&
									'project-summary_content__section-title-icon--inactive'
							)}
							onClick={(e) => {
								e.stopPropagation()
								setLossesDisplayFormat('percentage')
							}}
							data-locator-id='element-losses-in-percentage-btn'
						>
							%
						</div>
					</div>
					<Explainer explainerKey={enableEpcs ? 'losses' : 'losses_no_epc'} />
				</div>

				<div className='row w-100 m-0 pb-3 justify-content-center'>
					<div className='col-6'>
						<Losses
							locatorId='text-physical-loss-amount'
							displayFormat={lossesDisplayFormat}
							riskCost={get(projectSummary, 'physical_loss')}
							riskPercentage={
								get(projectSummary, 'physical_loss_percentage') * 100
							}
							riskLabel='physical risk'
						/>
					</div>
					{enableEpcs ? (
						<div className='col-6'>
							<Losses
								locatorId='text-transition-loss-amount'
								displayFormat={lossesDisplayFormat}
								riskCost={get(
									projectSummary,
									`retrofit_cost_to_${targetEpc.toLowerCase()}`,
									0
								)}
								riskPercentage={
									get(
										projectSummary,
										`retrofit_cost_to_${targetEpc.toLowerCase()}_percentage`,
										0
									) * 100
								}
								riskLabel={
									<span>
										transition risk<sup>beta</sup>
									</span>
								}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}

ProjectSummaryContent.defaultProps = {
	defaultRiskType: HAZARD_TYPES.river_flood.value,
}

ProjectSummaryContent.propTypes = {
	projectSummary: PropTypes.object,
	onAssetsScoreSelect: PropTypes.func,
	isMainSideBar: PropTypes.bool,
	projectSummaryLoading: PropTypes.bool,
	projectSummaryErrorMessage: PropTypes.string,
	defaultRiskType: PropTypes.oneOf(map(Object.values(HAZARD_TYPES), 'value')),
	onRiskTypeSet: PropTypes.func,
}

export default ProjectSummaryContent
