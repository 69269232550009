import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	getProjectSummary,
	getProjectSummaryError,
} from '../../../redux/selectors/projects'
import {
	getUserSettings,
	getUserFeatureFlags,
} from '../../../redux/selectors/user'
import { fetchProjectSummary } from '../../../redux/actions/projectsSummaries'
import ProjectSummary from './ProjectSummary'
import { AssetSummary } from './AssetSummary'
import { ProjectSummaryFilters } from '../../ProjectSummaryFilters'
import { getDefaultTargetEpc } from '../../../utils'
import 'react-circular-progressbar/dist/styles.css'
import './styles.scss'

function Summary({
	onSelect,
	defaultDefend,
	defaultScenario,
	defaultYear,
	defaultTargetEpc,
	defaultEpcTargetYear,
	selectedProjectId,
	projectSummary,
	fetchProjectSummary,
	projectSummaryLoading,
	projectSummaryErrorMessage,
	assetId,
	assetName,
	defaultRiskType,
	onRiskTypeSet,
	onAssetsScoreSelect,
	onVolumeDistributionSelect,
	isCompareEnabled,
	userSettings,
	userFeatureFlags,
}) {
	const [targetEpc, setTargetEpc] = useState(
		defaultTargetEpc || getDefaultTargetEpc().value
	)

	return (
		<div className='project-summary'>
			<div className='row w-100 m-0'>
				<ProjectSummaryFilters
					defaultDefend={defaultDefend}
					defaultScenario={defaultScenario}
					setTargetEpc={setTargetEpc}
					defaultTargetEpc={targetEpc}
					defaultYear={defaultYear}
					userFeatureFlags={userFeatureFlags}
					defaultEpcTargetYear={defaultEpcTargetYear}
					onSelect={onSelect}
				/>
			</div>

			{assetId ? (
				<AssetSummary
					assetId={assetId}
					scenario={defaultScenario}
					year={defaultYear}
					epcTargetYear={defaultEpcTargetYear}
					defended={defaultDefend}
					assetName={assetName}
					targetEpc={targetEpc}
				/>
			) : (
				<ProjectSummary
					projectSummary={projectSummary}
					projectSummaryLoading={projectSummaryLoading}
					projectSummaryErrorMessage={projectSummaryErrorMessage}
					selectedProjectId={selectedProjectId}
					defaultScenario={defaultScenario}
					defaultYear={defaultYear}
					defaultEpcTargetYear={defaultEpcTargetYear}
					defended={defaultDefend}
					fetchProjectSummary={fetchProjectSummary}
					defaultRiskType={defaultRiskType}
					onRiskTypeSet={onRiskTypeSet}
					onAssetsScoreSelect={onAssetsScoreSelect}
					onVolumeDistributionSelect={onVolumeDistributionSelect}
					isCompareEnabled={isCompareEnabled}
					userSettings={userSettings}
					userFeatureFlags={userFeatureFlags}
					defaultTargetEpc={targetEpc}
				/>
			)}
		</div>
	)
}

Summary.propTypes = {
	projectSummaryLoading: PropTypes.bool,
	isCompareEnabled: PropTypes.bool.isRequired,
	assetId: PropTypes.string,
	assetName: PropTypes.string,
	fetchProjectSummary: PropTypes.func.isRequired,
	projectSummaryErrorMessage: PropTypes.string,
	defaultRiskType: PropTypes.string,
	defaultDefend: PropTypes.string,
	onRiskTypeSet: PropTypes.func,
	selectedProjectId: PropTypes.string,
	history: PropTypes.func.isRequired,
	defaultYear: PropTypes.string,
	defaultTargetEpc: PropTypes.string,
	defaultEpcTargetYear: PropTypes.string,
}

export default connect(
	(state) => {
		return {
			projectSummary: getProjectSummary(state),
			projectSummaryErrorMessage: getProjectSummaryError(state),
			userSettings: getUserSettings(state),
			userFeatureFlags: getUserFeatureFlags(state),
		}
	},
	{ fetchProjectSummary }
)(Summary)
