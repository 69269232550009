import React, { Fragment } from 'react'
import { range } from 'lodash'
import { Skeleton } from '../../../components/Skeleton'

export const LoadingState = () => {
	return (
		<Fragment>
			<div className='row w-100 m-0 project-summary_content__main-title-row'>
				<div className='col-12 py-2'>
					<Skeleton width={120} height={16} className='my-1' />
				</div>
			</div>
			<div className='row w-100 m-0 project-summary_content__portfolio-analised'>
				<div className='col-12 col-md-6 py-2 project-summary_content__portfolio'>
					<Skeleton width={90} height={16} className='mb-3' />
					<div className='project-summary_content__section-score'>
						<Skeleton width={42} height={42} type='circle' className='mb-2' />
					</div>
				</div>
				<div className='col-12 col-md-6 py-2 project-summary_content__portfolio'>
					<Skeleton width={90} height={16} className='mb-3' />
					<div className='row justify-content-center'>
						<div className='col-6 d-flex project-summary_content__section-score'>
							<Skeleton width={42} height={42} type='circle' className='mb-2' />
						</div>
						<div className='col-6 d-flex project-summary_content__section-score'>
							<Skeleton width={42} height={42} type='circle' className='mb-2' />
						</div>
					</div>
				</div>
			</div>

			<div className='row w-100 m-0 project-summary__risc-score'>
				<div className='col-12 py-2'>
					<Skeleton width={110} height={16} className='mb-3' />
					<div className='d-flex mb-3 justify-content-between'>
						<Skeleton width={90} height={10} />
						<Skeleton width={90} height={10} className='mx-2' />
						<Skeleton width={90} height={10} className='mx-2' />
						<Skeleton width={90} height={10} className='mx-2' />
						<Skeleton width={90} height={10} />
					</div>
				</div>
				<div className='col-12 py-2'>
					<Skeleton width={90} height={16} className='mb-3' />
					{range(7).map((it) => (
						<div className='d-flex mb-3 justify-content-between' key={it}>
							<Skeleton width={90} height={10} />
							<Skeleton width={90} height={10} className='mx-2' />
							<Skeleton width={90} height={10} className='mx-2' />
							<Skeleton width={90} height={10} className='mx-2' />
							<Skeleton width={90} height={10} />
						</div>
					))}
					<Skeleton width={90} height={16} className='mb-3' />
					{range(3).map((it) => (
						<div className='d-flex mb-3 justify-content-between' key={it}>
							<Skeleton width={90} height={10} />
							<Skeleton width={90} height={10} className='mx-2' />
							<Skeleton width={90} height={10} className='mx-2' />
							<Skeleton width={90} height={10} className='mx-2' />
							<Skeleton width={90} height={10} />
						</div>
					))}
				</div>
			</div>

			<div className='row w-100 m-0'>
				<div className='col-12 py-2'>
					<Skeleton width={110} height={16} className='mb-4' />

					<div className='row d-flex'>
						<div className='col-6 d-flex project-summary_content__loss justify-content-center'>
							<Skeleton width={80} height={16} className='mb-2' />
						</div>
						<div className='col-6 d-flex project-summary_content__loss justify-content-center'>
							<Skeleton width={80} height={16} className='mb-2' />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
