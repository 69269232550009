import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { LOGIN_ROUTE } from 'src/constants'
import UserTimerContextProvider from 'src/contexts/UserTimerContext'
import { getIsUserAuthenticate } from '../../utils'

const ProtectedRoute = ({
	component: Component,
	autoSignOutUser,
	...restOfProps
}) => {
	const isAuthenticated = getIsUserAuthenticate()

	return (
		<Route
			{...restOfProps}
			render={(props) => {
				return isAuthenticated ? (
					<UserTimerContextProvider onTimerFinish={autoSignOutUser}>
						<Component {...props} />
					</UserTimerContextProvider>
				) : (
					<Redirect to={LOGIN_ROUTE} />
				)
			}}
		/>
	)
}

export default ProtectedRoute
