import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'src/components/Button'
import './styles.scss'

export const InformationModalContent = ({ message, onClose }) => {
	return (
		<div className='information-modal-content'>
			<h2 className='information-modal-content__text'>{message}</h2>
			<div className='information-modal-content__actions'>
				<Button onClick={onClose}>Close</Button>
			</div>
		</div>
	)
}

InformationModalContent.propTypes = {
	onClose: PropTypes.func.isRequired,
}
