import { get } from 'lodash'
import {
	CREATE_DUPLICATE_PROJECT,
	CLOSE_PROJECT_COMPARE,
	SET_COMPARE_PROJECT_LOADING,
	REPLACE_COMPARE_PROJECT_SUMMARY,
	SET_COMPARE_PROJECT_FILTERS,
	SET_PROJECT_COMPARE_SUMMARY_ERROR_MESSAGE,
	SET_COMPARE_BUSINESS_DISRUPTION,
	SET_COMPARE_BUSINESS_DISRUPTION_LOADING,
	SET_COMPARE_BUSINESS_DISRUPTION_ERROR_MESSAGE,
} from '../actions/projectCompare'
import { getBusinessDisruptionCacheKey } from 'src/utils/getCacheKey'

const DEFAULT_STATE = {
	project: {},
	summary: {},
	businessDisruption: {
		data: undefined,
		isLoading: undefined,
		error: null,
	},
	filters: {
		year: undefined,
		scenario: undefined,
	},
	loading: false,
	error: undefined,
}

export default function projectCompare(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case CREATE_DUPLICATE_PROJECT:
			return {
				...state,
				project: action.selectedProject,
				summary: action.selectedProjectSummary,
				businessDisruption: action.businessDisruption,
				filters: {
					year: action.selectedYear,
					epcTargetYear: action.selectedEpcTargetYear,
					targetEpc: action.selectedTargetEpc,
					scenario: action.selectedScenario,
					defended: action.defended,
				},
			}
		case REPLACE_COMPARE_PROJECT_SUMMARY:
			return {
				...state,
				summary: action.summary,
				loading: false,
			}
		case SET_COMPARE_PROJECT_LOADING:
			return {
				...state,
				loading: action.isLoading,
			}
		case SET_COMPARE_PROJECT_FILTERS:
			return {
				...state,
				filters: {
					...state.filters,
					year: get(action, 'filters.year') || get(state, 'filters.year'),
					epcTargetYear:
						get(action, 'filters.epcTargetYear') ||
						get(state, 'filters.epcTargetYear'),
					scenario:
						get(action, 'filters.scenario') || get(state, 'filters.scenario'),
					targetEpc:
						get(action, 'filters.epc') || get(state, 'filters.targetEpc'),
					defended:
						get(action, 'filters.defended') || get(state, 'filters.defended'),
				},
			}
		case SET_PROJECT_COMPARE_SUMMARY_ERROR_MESSAGE:
			return {
				...DEFAULT_STATE,
				error: action.errorMessage,
			}
		case SET_COMPARE_BUSINESS_DISRUPTION:
			const {
				payload: {
					projectId,
					assetId,
					scenario,
					targetYear,
					defended,
					payload,
				},
			} = action
			const key = getBusinessDisruptionCacheKey({
				projectId,
				assetId,
				scenario,
				targetYear,
				defended,
			})

			return {
				...state,
				businessDisruption: {
					isLoading: false,
					error: null,
					data: {
						...state.businessDisruption.data,
						[key]: payload,
					},
				},
			}
		case SET_COMPARE_BUSINESS_DISRUPTION_LOADING:
			return {
				...state,
				businessDisruption: {
					...state.businessDisruption,
					isLoading: action.isLoading,
					error: null,
				},
			}
		case SET_COMPARE_BUSINESS_DISRUPTION_ERROR_MESSAGE:
			return {
				...state,
				businessDisruption: {
					...state.businessDisruption,
					isLoading: false,
					error: action.errorMessage,
				},
			}
		case CLOSE_PROJECT_COMPARE:
			return DEFAULT_STATE

		default:
			return state
	}
}
