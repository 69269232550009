import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, isUndefined } from 'lodash'
import Modal from './components/Modal'
import { Header } from './components/Header'
import { LoggedInHeader } from './components/LoggedInHeader'
import { SideBar } from './components/SideBar'
import { getUser } from './redux/selectors/user'
import { autoSignInUser, signOutUser } from './redux/actions/user'
import Routes from './Routes'
import { runAnnouncementsPoll } from './redux/actions/announcements'
import { setModal, closeModal } from 'src/redux/actions/modal'
import ToastContainer from './components/Toast/ToastContainer'
import { MODAL_VIEWS } from 'src/containers/ModalViews'
import { getIsUserAuthenticate, getUserSessionExpireStatus } from './utils'
import { getAppConfig } from './config'
import { HAZARD_NAMES, SCENARIO_TYPES } from 'src/constants'
import { Router } from 'react-router-dom'
import { history } from './redux/store'
import './icons'
import 'bootstrap/dist/css/bootstrap.css'
import './styles.scss'

const appConfig = getAppConfig()

function _App({
	user,
	autoSignInUser,
	runAnnouncementsPoll,
	signOutUser,
	setModal,
	closeModal,
}) {
	const userData = get(user, 'userData')
	const userLoading = get(user, 'loading')
	const isLoggedIn = getIsUserAuthenticate()

	useEffect(() => {
		autoSignInUser()

		// Layers version logic
		const url = `${appConfig.dynamicTilesConfigUrl}?v=` + new Date().getTime()
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				for (const hazard of HAZARD_NAMES) {
					for (const scenario of SCENARIO_TYPES) {
						const actualLayersVersion = data[`${scenario}-${hazard}`]
						const storedLayersVersion = localStorage.getItem(
							`layers_version_${scenario}-${hazard}`
						)

						if (storedLayersVersion !== actualLayersVersion) {
							localStorage.setItem(
								`layers_version_${scenario}-${hazard}`,
								data[`${scenario}-${hazard}`]
							)
						}
					}
				}
			})
			.catch((err) => {
				console.error('Failed to fetch layers version:', err)
			})
	}, [])

	useEffect(() => {
		const status = getUserSessionExpireStatus()
		// we consider initial state here and compare times only when the expire time exist
		const isSessionExpired = status.expireTimeExists && status.isExpired

		if (isLoggedIn && userData && !isSessionExpired) {
			runAnnouncementsPoll()
		}
	}, [userData, isLoggedIn])

	if (userLoading || isUndefined(userLoading)) {
		return null
	}

	const onAutoSignOut = (showSessionExpireModal) => {
		signOutUser()
		if (showSessionExpireModal) {
			setModal({
				viewKey: MODAL_VIEWS.SESSION_EXPIRE_MODAL,
				viewProps: {
					onConfirm: () => {
						closeModal()
					},
				},
			})
		}
	}

	return (
		<Router history={history}>
			<React.Fragment>
				{isLoggedIn ? <LoggedInHeader /> : <Header />}
				<ToastContainer />
				<div className='app'>
					<Routes autoSignOutUser={onAutoSignOut} />
					<Modal />
					{isLoggedIn ? <SideBar /> : null}
				</div>
			</React.Fragment>
		</Router>
	)
}

_App.propTypes = {
	user: PropTypes.object,
	autoSignInUser: PropTypes.func.isRequired,
	runAnnouncementsPoll: PropTypes.func.isRequired,
	signOutUser: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
}

const App = connect(
	(state) => ({
		user: getUser(state),
	}),
	{ autoSignInUser, runAnnouncementsPoll, signOutUser, setModal, closeModal }
)(_App)

export default App
