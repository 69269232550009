import React, { Fragment } from 'react'
import { SCORE_WEIGHTS } from '../../../constants'
import { Score } from '../../../components/Score'
import PropTypes from 'prop-types'
import '../styles.scss'

export const EpcRating = ({ value, label, locatorId }) => {
	return (
		<Fragment>
			<div className='project-summary_content__section-score'>
				{value > 0 ? (
					<Score
						score={value > 6 ? 'G' : SCORE_WEIGHTS[value]}
						locatorId={locatorId}
					/>
				) : (
					<span className='project-summary_content__no-data'>-</span>
				)}
			</div>
			<div className='project-summary_content__section-score-label'>
				{label}
			</div>
		</Fragment>
	)
}

EpcRating.defaultProps = {
	locatorId: 'not-set',
}

EpcRating.propTypes = {
	value: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired,
}
