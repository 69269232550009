import { COUNTRY_CODES_MAPPING } from '../constants'

export const getCountryNamesByCode = (countries) => {
	return countries
		.map((code) =>
			COUNTRY_CODES_MAPPING.hasOwnProperty(code)
				? [...COUNTRY_CODES_MAPPING[code], code]
				: []
		)
		.flat()
}

export const getAllCountryNames = () => {
	return Object.keys(COUNTRY_CODES_MAPPING)
		.map((code) => [...COUNTRY_CODES_MAPPING[code], code])
		.flat()
}
