import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import Login from './pages/Login'
import Settings from './pages/Settings'
import Main from './pages/Main'
import NotFound from './pages/NotFound'
import {
	INDEX_ROUTE,
	HOME_ROUTE,
	LOGIN_ROUTE,
	CONFIRM_USER_ROUTE,
	CONFIRM_PASSWORD_ROUTE,
	RESET_PASSWORD_ROUTE,
	PROJECTS_ROUTE,
	EXPLORER_ROUTE,
	ADAPTATIONS_ROUTE,
	SETTINGS_ROUTE,
	VERIFY_MFA_ROUTE,
	ENABLE_MFA_ROUTE,
	NOT_FOUND_ROUTE,
} from './constants'
import { getIsUserAuthenticate } from './utils'

const protectedRoutes = [
	{
		path: INDEX_ROUTE,
		component: Main,
	},
	{
		path: HOME_ROUTE,
		component: Main,
	},
	{
		path: PROJECTS_ROUTE,
		component: Main,
	},
	{
		path: EXPLORER_ROUTE,
		component: Main,
	},
	{
		path: ADAPTATIONS_ROUTE,
		component: Main,
	},
	{
		path: SETTINGS_ROUTE,
		component: Settings,
	},
]

const publicRoutes = [
	LOGIN_ROUTE,
	CONFIRM_USER_ROUTE,
	RESET_PASSWORD_ROUTE,
	CONFIRM_PASSWORD_ROUTE,
	VERIFY_MFA_ROUTE,
	ENABLE_MFA_ROUTE,
]

function Routes({ autoSignOutUser }) {
	const getCorrectRoute = (props) => {
		const isAuthenticated = getIsUserAuthenticate()

		return isAuthenticated ? <Redirect to={HOME_ROUTE} /> : <Login {...props} />
	}

	return (
		<Switch>
			{/* User signIn/signUp related routes */}
			{publicRoutes.map((route, index) => (
				<Route
					key={index}
					exact
					path={route}
					render={(props) => getCorrectRoute(props)}
				/>
			))}
			{/* Protected routes */}
			{protectedRoutes.map(({ path, component }, index) => (
				<ProtectedRoute
					key={index}
					exact
					path={path}
					component={component}
					autoSignOutUser={autoSignOutUser}
				/>
			))}
			<Route exact path={NOT_FOUND_ROUTE} component={NotFound} />
			<Redirect to={NOT_FOUND_ROUTE} />
		</Switch>
	)
}

export default Routes
