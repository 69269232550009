import React, { useState } from 'react'
import qs from 'qs'
import { NavLink } from 'reactstrap'
import { get } from 'lodash'
import Input from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { validate } from '../../../../utils'

const LoginForm = ({ onSubmit, routerProps, loading }) => {
	const { history } = routerProps

	const queryParams = qs.parse(history.location.search, {
		ignoreQueryPrefix: true,
	})

	const [controls, setControls] = useState({
		email: {
			value: get(queryParams, 'username', ''),
			touched: false,
			validationRules: {
				minLength: 3,
				isEmail: true,
			},
			valid: false,
		},
		password: {
			value: get(queryParams, 'password', ''),
			touched: false,
			validationRules: {
				minLength: 3,
			},
			valid: false,
		},
	})

	const updateState = (field, value) => {
		setControls({
			...controls,
			[field]: {
				...controls[field],
				value,
				touched: true,
				valid: validate(value, controls[field].validationRules),
			},
		})
	}

	const handleFormSubmit = (e) => {
		e.preventDefault()
		if (!controls.email.value || !controls.password.value) {
			setControls({
				...controls,
				email: {
					...controls['email'],
					valid: validate(
						controls.email.value,
						controls['email'].validationRules
					),
				},
				password: {
					...controls['password'],
					valid: validate(
						controls.password.value,
						controls.password.validationRules
					),
				},
			})
		} else {
			onSubmit(controls.email.value, controls.password.value, history)
		}
	}

	return (
		<form className='form-floating' action=''>
			<div className='form-floating mb-3'>
				<Input
					locatorId='input-email'
					type='email'
					label='Email address'
					error={controls.email.touched && !controls.email.valid}
					placeholder='name@example.com'
					autoComplete='username'
					value={controls.email.value}
					onChange={(e) => updateState('email', e.target.value)}
				/>
			</div>
			<div className='form-floating mb-3'>
				<Input
					locatorId='input-password'
					type='password'
					label='Password'
					placeholder='Password'
					autoComplete='current-password'
					error={controls.password.touched && !controls.password.valid}
					value={controls.password.value}
					onChange={(e) => updateState('password', e.target.value)}
				/>
			</div>
			<div className='col-auto col-sm-12'>
				<div className='d-flex align-items-center mt-4 mb-4'>
					<Button
						className='btn btn-primary mt-0'
						onClick={handleFormSubmit}
						disabled={loading}
						locatorId='button-sign-in'
					>
						{loading ? 'Loading ...' : 'Sign in'}
					</Button>
					<NavLink
						onClick={() => history.push('/reset-password')}
						className='form-link'
						data-locator-id='link-forgot-password'
					>
						Forgot Password?
					</NavLink>
				</div>
			</div>
		</form>
	)
}
export default LoginForm
