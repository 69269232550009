import { SET_EXPLAINERS } from '../actions/explainers'

const DEFAULT_STATE = {}

export default function explainers(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SET_EXPLAINERS:
			return action.payload
		default:
			return state
	}
}
