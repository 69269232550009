import React from 'react'
import { useHistory } from 'react-router-dom'
import { PageWrapper } from '../../components/PageWrapper'
import { Button } from '../../components/Button'
import { INDEX_ROUTE } from '../../constants'

const NotFoundContent = () => {
	const history = useHistory()

	return (
		<div className='container'>
			<div className='d-flex justify-content-center'>
				<div className='pt-5'>
					<div className='mt-2'>
						<h1>Oops!</h1>
					</div>
					<div className='mt-2'>
						<h2>Page Not Found</h2>
					</div>
					<div className='mt-5'>
						<Button
							onClick={() => {
								history.replace(INDEX_ROUTE)
							}}
						>
							Return to Homepage
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

const NotFound = () => {
	return (
		<PageWrapper
			bodyContent={NotFoundContent()}
			sidebarContent={null}
			isSidebarHidden={true}
		/>
	)
}

NotFound.propTypes = {}

export default NotFound
