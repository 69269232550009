import {
	DEFAULT_EPC_TARGET_YEAR,
	DEFENDS,
	SCENARIOS,
	EPCS,
	YEARS,
} from 'src/constants'
import { isEpcTargetYearValid } from './validateEpcTargetYear'

export const getDefaultDefend = (defended) => {
	const defaultDefended = DEFENDS.find(({ value }) => value === defended)
	if (defaultDefended) {
		return defaultDefended
	}

	return DEFENDS.find((defend) => defend.isActive)
}

export const getDefaultScenario = (scenario) => {
	const defaultScenario = SCENARIOS.find(({ value }) => value === scenario)
	if (defaultScenario) {
		return defaultScenario
	}
	return SCENARIOS.find((scenario) => scenario.isActive)
}

export const getDefaultYear = (year) => {
	const defaultYear = YEARS.find(({ value }) => value === year)
	if (defaultYear) {
		return defaultYear
	}

	return YEARS.find((year) => year.isActive)
}

export const getDefaultTargetEpc = (targetEpc) => {
	const defaultTargetEpc = EPCS.find(({ value }) => value === targetEpc)
	if (defaultTargetEpc) {
		return defaultTargetEpc
	}

	return EPCS.find((epc) => epc.isActive)
}

export const getDefaultEpcTargetYear = (year) => {
	if (year && isEpcTargetYearValid(Number(year))) {
		return year
	}

	return Math.max(DEFAULT_EPC_TARGET_YEAR, new Date().getFullYear()).toString()
}
