import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'src/components/Select'
import Input from 'src/components/Input'
import { Button } from 'src/components/Button'
import {
	getUnitOfMeasureAreaCopy,
	getUnitOfMeasurePerimeterCopy,
} from 'src/utils/getUnitOfMeasure'
import './styles.scss'

function transformOptions(possibleOptions) {
	if (!possibleOptions || possibleOptions.length === 0) {
		return [{ value: 'None', displayValue: 'None' }]
	}

	return possibleOptions.map((option) => {
		let value = option

		if (typeof option === 'boolean') {
			value = option.toString()
			return {
				value: value,
				displayValue:
					value.charAt(0).toUpperCase() + value.substr(1).toLowerCase(),
			}
		} else if (option === null || option === undefined) {
			value = 'None'
		}

		return {
			value: value,
			displayValue: value,
		}
	})
}
function ExposureSensitivity({ adapt_data, onSelect, onSave, userSettings }) {
	const handleSelect = (key, value, isSelect) => {
		onSelect(key, value, isSelect)
	}

	const submitInput = (key, evt, isSelect) => {
		const val = evt.target.value
		onSelect(key, val, isSelect)
	}
	return (
		<div>
			<div style={{ fontWeight: 'bold', paddingTop: '10px' }}>Exposure</div>
			<div className='row'>
				<div
					className='col-md-6'
					style={{ paddingTop: '10px', paddingLeft: '50px' }}
				>
					<div className='row'>
						<div className='input-label__premise-area'>
							Premise Area
							<span>
								({getUnitOfMeasureAreaCopy(userSettings.preferred_length_unit)})
							</span>
						</div>

						<div className='input'>
							<Input
								locatorId={'adaptations-panel-exposure-premise-area'}
								type='number'
								value={adapt_data.premise_area}
								onChange={(premise_area) =>
									submitInput('premise_area', premise_area, false)
								}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='input-label'>No. Of Floors</div>
						<div className='input'>
							<Input
								locatorId={'adaptations-panel-exposure-floor-count'}
								type='number'
								value={adapt_data.floor_count}
								onChange={(floor_count) =>
									submitInput('floor_count', floor_count, false)
								}
							/>
						</div>
					</div>
				</div>
				<div
					className='col-md-6'
					style={{ paddingTop: '10px', paddingLeft: '50px' }}
				>
					<div className='row'>
						<div className='input-label'>No. Of Occupants</div>
						<div className='input'>
							<Input
								locatorId={'adaptations-panel-exposure-number-of-occupants'}
								type='number'
								value={adapt_data.number_of_occupants}
								onChange={(number_of_occupants) =>
									submitInput('number_of_occupants', number_of_occupants, false)
								}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='input-label'>
							Building Perimeter
							<span>
								(
								{getUnitOfMeasurePerimeterCopy(
									userSettings.preferred_length_unit
								)}
								)
							</span>
						</div>
						<div className='input'>
							<Input
								locatorId={'adaptations-panel-exposure-building_perimeter'}
								type='number'
								value={adapt_data.building_perimeter}
								onChange={(building_perimeter) =>
									submitInput('building_perimeter', building_perimeter, false)
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div style={{ fontWeight: 'bold' }}>Sensitivity</div>
			<div className='row'>
				<div
					className='col-md-6'
					style={{ paddingTop: '10px', paddingLeft: '50px' }}
				>
					<div className='row' style={{ paddingTop: '5px' }}>
						<div className='select-label'>Refurbished Year</div>
						<div className='input'>
							<Input
								locatorId={'adaptations-panel-exposure-refurbished-year'}
								value={adapt_data.refurbished_year}
								onChange={(refurbished_year) =>
									submitInput('refurbished_year', refurbished_year)
								}
							/>
						</div>
					</div>

					<div className='row'>
						<div className='select-label'>Asset Use</div>
						<div>
							<Select
								wrapperId='use'
								options={transformOptions(adapt_data?.use.options)}
								selectedValue={adapt_data?.use.value || 'None'}
								onChange={(use) => handleSelect('use', use, true)}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-exposure-use'}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='select-label'>Roof Type</div>
						<div>
							<Select
								wrapperId='roof_type'
								options={transformOptions(adapt_data?.roof_type.options)}
								selectedValue={adapt_data?.roof_type.value || 'None'}
								onChange={(roof_type) =>
									handleSelect('roof_type', roof_type, true)
								}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-roof-type'}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='select-label'>Roof Shape</div>
						<div>
							<Select
								wrapperId='roof_shape'
								options={transformOptions(adapt_data?.roof_shape.options)}
								selectedValue={adapt_data?.roof_shape.value || 'None'}
								onChange={(roof_shape) =>
									handleSelect('roof_shape', roof_shape, true)
								}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-roof-shape'}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='select-label'>Outdoor Space</div>
						<div>
							<Select
								wrapperId='outdoor_space'
								options={transformOptions(adapt_data?.outdoor_space.options)}
								selectedValue={adapt_data?.outdoor_space.value || 'None'}
								onChange={(outdoor_space) =>
									handleSelect('outdoor_space', outdoor_space, true)
								}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-outdoor-space'}
							/>
						</div>
					</div>
				</div>
				<div
					className='col-md-6'
					style={{ paddingTop: '10px', paddingLeft: '50px' }}
				>
					<div className='row'>
						<div className='select-label'>Age Category</div>
						<div>
							<Select
								wrapperId='age_category'
								options={transformOptions(adapt_data?.age_category.options)}
								selectedValue={adapt_data?.age_category.value || 'None'}
								onChange={(age_category) =>
									handleSelect('age_category', age_category, true)
								}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-age-category'}
							/>
						</div>
					</div>

					<div className='row'>
						<div className='select-label'>Wall Type</div>
						<div>
							<Select
								wrapperId='wall_type'
								options={transformOptions(adapt_data?.wall_type.options)}
								selectedValue={adapt_data?.wall_type.value || 'None'}
								onChange={(wall_type) =>
									handleSelect('wall_type', wall_type, true)
								}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-wall-type'}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='select-label'>Basement Num</div>
						<div>
							<Select
								wrapperId='num_basement_floors'
								options={transformOptions(
									adapt_data?.num_basement_floors.options
								)}
								selectedValue={adapt_data?.num_basement_floors.value || 'None'}
								onChange={(num_basement_floors) =>
									handleSelect('num_basement_floors', num_basement_floors, true)
								}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-num-basement-floors'}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='select-label'>Fortified Foundations</div>
						<div>
							<Select
								wrapperId='fortified_foundations'
								options={transformOptions(
									adapt_data?.fortified_foundations.options
								)}
								selectedValue={adapt_data?.fortified_foundations.value || false}
								onChange={(fortified_foundations) =>
									handleSelect(
										'fortified_foundations',
										fortified_foundations,
										true
									)
								}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-num-fortified-foundations'}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='select-label'>Premise Type</div>
						<div>
							<Select
								wrapperId='premise_type'
								options={transformOptions(adapt_data?.premise_type.options)}
								selectedValue={adapt_data?.premise_type.value || 'None'}
								onChange={(premise_type) =>
									handleSelect('premise_type', premise_type, true)
								}
								className='adaptive-capacity__select'
								locatorId={'adaptations-panel-num-premise-type'}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-md-2'></div>
				<div
					className='col-md-8'
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Button
						onClick={onSave}
						locatorId={'adapt-vulnerabilities-save-exposure'}
					>
						Save Exposure & Sensitivity
					</Button>
				</div>
				<div className='col-md-2'></div>
			</div>
		</div>
	)
}

ExposureSensitivity.propTypes = {
	adapt_data: PropTypes.object,
	onSelect: PropTypes.func,
	onSave: PropTypes.func,
}

export default ExposureSensitivity
