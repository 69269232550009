import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import './styles.scss'

export const MarkdownStrong = ({ children }) => {
	if (isEmpty(children)) {
		return null
	}

	return <strong className='markdown-strong'>{children[0]}</strong>
}

MarkdownStrong.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
