import {
	SET_ASSET_SUMMARY_COMPARE,
	SET_ASSET_SUMMARY_COMPARE_LOADING,
	SET_ASSET_SUMMARY_COMPARE_ERROR_MESSAGE,
} from '../actions/assetSummaryCompare'

const DEFAULT_STATE = {
	data: undefined,
	loss_data: undefined,
	retrofit_data: undefined,
	epc_data: undefined,
	physical_risk: undefined,
	loading: false,
	error: null,
}

export default function assetSummaryCompare(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SET_ASSET_SUMMARY_COMPARE:
			return {
				...state,
				loading: false,
				error: null,
				data: action.summary,
				loss_data: action.loss_data,
				retrofit_data: action.retrofit_data,
				epc_data: action.epc_data,
				physical_risk: action.physical_risk,
			}
		case SET_ASSET_SUMMARY_COMPARE_LOADING:
			return { ...state, loading: action.isLoading, error: null }
		case SET_ASSET_SUMMARY_COMPARE_ERROR_MESSAGE:
			return {
				...state,
				loading: false,
				error: action.errorMessage,
			}
		default:
			return state
	}
}
