import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Transition } from 'react-transition-group'
import { VOLUME_DISTRIBUTION_PALETTE } from 'src/constants'
import { numberWithCommas } from '../../utils'
import './styles.scss'

export const VolumeDistribution = ({
	distributionData,
	activeScoreKey,
	onSelect,
}) => {
	const [startAnimation, setStartAnimation] = useState(false)
	const [distribution, setActiveDistribution] = useState(
		distributionData.map((item) => ({ ...item, labelActive: false }))
	)

	useEffect(() => {
		setActiveDistribution(
			distributionData.map((item) => ({ ...item, labelActive: false }))
		)
		setTimeout(() => {
			setStartAnimation(true)
		})
	}, [distributionData])

	const toggleBarPopup = (item) => {
		setActiveDistribution(
			distribution.map((it) => {
				if (it.key !== item.key) {
					return { ...it, labelActive: false }
				}

				return {
					...it,
					labelActive: !it.labelActive,
				}
			})
		)
	}

	return (
		<div
			className='volume-distribution'
			data-locator-id='element-hazard-volume-distribution'
		>
			{distribution.map((item, index) => (
				<div
					key={item.key}
					className={classnames(
						'volume-distribution__bar-wrapper',
						activeScoreKey &&
							activeScoreKey !== item.score_key &&
							'volume-distribution__bar-wrapper--inactive'
					)}
					onClick={(e) => {
						e.stopPropagation()
						onSelect(item)
					}}
					onMouseEnter={() => toggleBarPopup(item)}
					onMouseLeave={() => toggleBarPopup(item)}
				>
					<Transition key={index} in={startAnimation} timeout={20}>
						{(state) => {
							const defaultStyle = {
								transition: `height 0.2s ease-in-out`,
								transitionDelay: `${100 * (index + 1)}ms`,
								backgroundColor: VOLUME_DISTRIBUTION_PALETTE[item.key],
								height: 0,
							}
							const transitionStyles = {
								entering: { height: `${item.percentage}%` },
								entered: { height: `${item.percentage}%` },
								exiting: { height: 0 },
								exited: { height: 0 },
							}

							return (
								<div
									key={index}
									className='volume-distribution__bar'
									style={{
										...defaultStyle,
										...transitionStyles[state],
									}}
								>
									<span
										className='volume-distribution__bar-label'
										style={{ opacity: item.labelActive ? 1 : 0 }}
									>
										{numberWithCommas(item.amount)}
									</span>
								</div>
							)
						}}
					</Transition>
					<span>{item.label}</span>
				</div>
			))}
		</div>
	)
}

VolumeDistribution.propTypes = {
	distributionData: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			percentage: PropTypes.number.isRequired,
			amount: PropTypes.number.isRequired,
		})
	).isRequired,
	onSelect: PropTypes.func,
}
