export const SET_MODAL = 'setModal'
export function setModal(payload) {
	return { type: SET_MODAL, ...payload }
}

export const SET_MODAL_VIEW_PROPS = 'setModalViewProps'
export function setModalViewProps(payload) {
	return { type: SET_MODAL_VIEW_PROPS, ...payload }
}

export const CLOSE_MODAL = 'closeModal'
export function closeModal() {
	return { type: CLOSE_MODAL }
}
