import React from 'react'
import {
	CircularProgressbarWithChildren,
	buildStyles,
} from 'react-circular-progressbar'
import PropTypes from 'prop-types'
import { pick } from 'lodash'
import qs from 'qs'
import { Button } from 'src/components/Button'
import { connect } from 'react-redux'
import { getBusinessDisruption } from 'src/redux/selectors/businessDisruption'
import { fetchBusinessDisruption } from 'src/redux/actions/businessDisruption'
import { useLocation } from 'react-router-dom'
import { getBusinessDisruptionCacheKey } from 'src/utils/getCacheKey'
import { getDefaultScenario } from 'src/utils'
import { Loading } from './Loading'
import { getUserFeatureFlags } from 'src/redux/selectors/user'
import {
	getCompareBusinessDisruption,
	getCompareFilters,
} from 'src/redux/selectors/compareProject'
import { fetchCompareBusinessDisruption } from 'src/redux/actions/projectCompare'
import './styles.scss'

const BUSINESS_DISRUPTION_LABEL = {
	power_asset_probability: 'Power',
	rail_asset_probability: 'Rail',
	roads_asset_probability: 'Road',
}

const _BusinessDisruption = ({
	businessDisruption,
	compareBusinessDisruption,
	fetchBusinessDisruption,
	fetchCompareBusinessDisruption,
	userFeatureFlags,
	isCompareWindow,
	compareFilters,
}) => {
	const location = useLocation()
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

	const keyParameters = isCompareWindow
		? {
				assetId: queryParams.assetId,
				scenario: getDefaultScenario(compareFilters.scenario).scenario,
				targetYear: compareFilters.year,
				defended: compareFilters.defended === 'defended',
				projectId: queryParams?.projectId,
			}
		: {
				assetId: queryParams.assetId,
				scenario: getDefaultScenario(queryParams.scenario).scenario,
				targetYear: queryParams.year,
				defended: queryParams.defended === 'defended',
				projectId: queryParams?.projectId,
			}

	const key = getBusinessDisruptionCacheKey(keyParameters)

	const activeData = isCompareWindow
		? compareBusinessDisruption
		: businessDisruption

	const enableBusinessDisruption =
		userFeatureFlags['enable-business-disruption'] || false

	const getFontSizeByTextLength = (text) => {
		if (!text || text.length < 4) {
			return '2.4rem'
		}

		if (text.length < 6) {
			return '2.2rem'
		}

		return '2rem'
	}

	if (!enableBusinessDisruption) {
		return null
	}

	const baseYearData = activeData?.data?.[key]?.[0]

	return (
		<div className='row w-100 m-0 business-disruption'>
			<div className='col-12 py-2'>
				<h4 className='pb-2 project-summary__section-title'>
					Business Disruption
				</h4>

				{activeData.isLoading ? <Loading /> : null}
				{activeData?.data?.[key] ? (
					<div data-locator-id='business-disruption-probabilities'>
						{activeData?.data?.[key].map((item) => {
							const strokeWidth = item.year !== baseYearData.year ? 1 : 5
							return (
								<>
									<h3 className='business-disruption__year'>{item.year}</h3>
									<div className='row'>
										{Object.entries(
											pick(item, [
												'roads_asset_probability',
												'rail_asset_probability',
												'power_asset_probability',
											])
										).map(([key, value]) => {
											const activeValue =
												item.year !== baseYearData.year
													? value - baseYearData[key]
													: value
											const activeValueWithPrefix =
												item.year !== baseYearData.year
													? activeValue >= 0
														? `+${activeValue}`
														: `${activeValue}`
													: activeValue

											return (
												<div className='col-4 py-3' key={item.asset_id}>
													<CircularProgressbarWithChildren
														value={value}
														strokeWidth={strokeWidth}
														styles={buildStyles({
															pathColor: '#a7a7a7',
															trailColor: '#a7a7a7',
														})}
													>
														<div className='business-disruption__content'>
															<h4
																style={{
																	fontSize: getFontSizeByTextLength(
																		value.toString()
																	),
																}}
															>
																{activeValueWithPrefix}
															</h4>
															<span className='business-disruption__content-title'>
																{BUSINESS_DISRUPTION_LABEL[key]} <br />{' '}
																Disruption
															</span>
														</div>
													</CircularProgressbarWithChildren>
												</div>
											)
										})}
									</div>
								</>
							)
						})}
					</div>
				) : null}

				{!activeData?.data?.[key] && !activeData.isLoading ? (
					<Button
						locatorId='button-analyse-business-disruption'
						onClick={() =>
							isCompareWindow
								? fetchCompareBusinessDisruption(compareFilters)
								: fetchBusinessDisruption(queryParams)
						}
						className='business-disruption__load-button'
					>
						Analyse Business Disruption
					</Button>
				) : null}
			</div>
		</div>
	)
}

_BusinessDisruption.propTypes = {
	businessDisruption: PropTypes.arrayOf(
		PropTypes.shape({
			asset_id: PropTypes.string,
			power_asset_probability: PropTypes.number,
			rail_asset_probability: PropTypes.number,
			roads_asset_probability: PropTypes.number,
			year: PropTypes.number,
		})
	),
	userFeatureFlags: PropTypes.object,
	compareFilters: PropTypes.object,
	isCompareWindow: PropTypes.bool,
	fetchBusinessDisruption: PropTypes.func,
	fetchCompareBusinessDisruption: PropTypes.func,
}

export const BusinessDisruption = connect(
	(state) => {
		return {
			businessDisruption: getBusinessDisruption(state),
			userFeatureFlags: getUserFeatureFlags(state),
			compareBusinessDisruption: getCompareBusinessDisruption(state),
			compareFilters: getCompareFilters(state),
		}
	},
	{ fetchBusinessDisruption, fetchCompareBusinessDisruption }
)(_BusinessDisruption)
