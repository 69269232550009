import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import './styles.scss'

export const ADAPTATIONS_PAGINATION_ARROWS = {
	RIGHT: 'right',
	LEFT: 'left',
}

function AdaptationsPaginationArrow({
	adaptationsType,
	activeArrow,
	onArrowSelect,
	key,
	label,
	icon,
	dir,
	fa_type,
	display,
	locatorId,
}) {
	return (
		<div
			key={key}
			id={`adaptation-arrow-${dir}`}
			data-locator-id={locatorId}
			className={classnames(
				'adaptation-arrows__arrow',
				activeArrow === key && 'adaptations-arrows__arrow--selected'
			)}
			onClick={() => {
				onArrowSelect(display)
			}}
		>
			<FontAwesomeIcon
				icon={[fa_type, 'fa-chevron-' + dir]}
				className='adaptations-selector-wrapper__adaptations-arrow-icon'
			/>
			{label}
		</div>
	)
}

AdaptationsPaginationArrow.propTypes = {
	adaptationsType: PropTypes.string,
}

export default AdaptationsPaginationArrow
