import React, { useState } from 'react'
import QRCode from 'qrcode.react'
import Input from '../../../../components/Input'
import { validate } from '../../../../utils'
import { Button } from '../../../../components/Button'
import { NavLink } from 'reactstrap'
import './styles.scss'

const EnableMfaForm = ({ onSubmit, loading, user }) => {
	const token = user?.userData?.otpauth_url

	const [controls, setControls] = useState({
		token: {
			value: '',
			touched: false,
			validationRules: {
				isVerificationCode: true,
			},
			valid: false,
		},
	})
	const updateState = (field, value) => {
		setControls({
			...controls,
			[field]: {
				...controls[field],
				value,
				touched: true,
				valid: validate(value, controls[field].validationRules),
			},
		})
	}
	const isFormValid = () => {
		return controls.token.valid
	}

	const onVerifyToken = (e) => {
		e.preventDefault()
		onSubmit(controls.token.value)
	}
	return (
		<div>
			<form className='form-floating'>
				<div className='mfa-form'>
					<div className='form-floating mfa-form__left'>
						<Input
							type='text'
							label='Token'
							error={controls.token.touched && !controls.token.valid}
							placeholder='Token'
							value={controls.token.value}
							onChange={(e) => updateState('token', e.target.value)}
						/>
					</div>
					<div className='mfa-form__right'>
						{token && <QRCode value={token} size={240} />}
					</div>
				</div>
				<div className='col-auto col-sm-12'>
					<div className='d-flex align-items-center mt-4 mb-4'>
						<Button
							className='btn btn-primary mt-0'
							onClick={onVerifyToken}
							disabled={loading || !isFormValid()}
						>
							{loading ? 'Loading ...' : 'Enable'}
						</Button>
						<NavLink
							to='#'
							onClick={(e) => {
								window.location = 'mailto:help@climate-x.com'
								e.preventDefault()
							}}
							className='form-link'
						>
							Having trouble?
						</NavLink>
					</div>
				</div>
			</form>
		</div>
	)
}

export default EnableMfaForm
