import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { fetchProjectCompareSummary } from '../../../redux/actions/projectCompare'
import ProjectSummary from './ProjectSummary'
import { ProjectSummaryFilters } from '../../ProjectSummaryFilters'
import { AssetSummary } from './AssetSummary'
import 'react-circular-progressbar/dist/styles.css'
import { getDefaultTargetEpc } from '../../../utils'

function Summary({
	onSelect,
	defaultDefend,
	defaultScenario,
	defaultYear,
	defaultTargetEpc,
	defaultEpcTargetYear,
	fetchProjectCompareSummary,
	selectedProject,
	assetId,
	assetName,
	userFeatureFlags,
}) {
	const [targetEpc, setTargetEpc] = useState(
		defaultTargetEpc || getDefaultTargetEpc().value
	)
	return (
		<div>
			<div className='row w-100 m-0'>
				<ProjectSummaryFilters
					defaultDefend={defaultDefend}
					defaultScenario={defaultScenario}
					defaultYear={defaultYear}
					setTargetEpc={setTargetEpc}
					defaultTargetEpc={targetEpc}
					defaultEpcTargetYear={defaultEpcTargetYear}
					onSelect={onSelect}
					userFeatureFlags={userFeatureFlags}
				/>
			</div>

			{assetId ? (
				<AssetSummary
					assetId={assetId}
					scenario={defaultScenario}
					year={defaultYear}
					epcTargetYear={defaultEpcTargetYear}
					assetName={assetName}
					defended={defaultDefend}
					targetEpc={targetEpc}
				/>
			) : (
				<ProjectSummary
					projectSummary={get(selectedProject, 'summary')}
					projectSummaryLoading={get(selectedProject, 'loading')}
					projectSummaryErrorMessage={get(selectedProject, 'error')}
					selectedProjectId={get(selectedProject, 'project.id')}
					defaultScenario={defaultScenario}
					defaultYear={defaultYear}
					defaultEpcTargetYear={defaultEpcTargetYear}
					fetchProjectSummary={fetchProjectCompareSummary}
					defended={defaultDefend}
					defaultTargetEpc={targetEpc}
					userFeatureFlags={userFeatureFlags}
				/>
			)}
		</div>
	)
}

Summary.propTypes = {
	fetchProjectSummary: PropTypes.func.isRequired,
	assetId: PropTypes.string,
	assetName: PropTypes.string,
}

export default connect(null, {
	fetchProjectCompareSummary,
})(Summary)
