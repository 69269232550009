import { LOCAL_STORAGE_KEY, USER_ROLE } from '../constants'
import store from 'src/redux/store'

export const getIsUserAuthenticate = () => {
	const user = localStorage.getItem(LOCAL_STORAGE_KEY.USER)
	const userAdditionalData = store.getState()?.user?.userData
	const userJsonData = user && JSON.parse(user)

	if (userJsonData && userAdditionalData) {
		if (userAdditionalData.role === USER_ROLE.STANDARD) {
			if (!userAdditionalData.mfa_enabled) {
				return true
			}
			return !!userAdditionalData.mfa_verified
		} else if (userAdditionalData.role === USER_ROLE.DEMO) {
			return true
		}
	}

	return false
}

export const getIsUserDemo = () => {
	const userAdditionalData = store.getState()?.user?.userData
	if (userAdditionalData && userAdditionalData.role === USER_ROLE.DEMO) {
		return true
	}
	return false
}

export const getUserFromLocalStorage = () => {
	const user = localStorage.getItem(LOCAL_STORAGE_KEY.USER)
	return user && JSON.parse(user)
}

export const removeUserFromLocalStorage = () => {
	localStorage.removeItem(LOCAL_STORAGE_KEY.USER)
	localStorage.removeItem(LOCAL_STORAGE_KEY.IS_LOGGED_IN_ON_HELP_PAGE)
}

export const createHelpPageUrl = (redirectOptions) => {
	return `${redirectOptions.baseUrl}?redirect_url=${redirectOptions.redirectUrl}&jwt=${redirectOptions.jwt}`
}
