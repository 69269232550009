import React from 'react'
import {
	signInUser,
	confirmUser,
	resetPassword,
	confirmPassword,
	verifySoftwareToken,
	validateMfa,
	signOut,
} from '../../redux/actions/user'
import { connect } from 'react-redux'
import { formsObj } from './formsObject'
import FormMessages from 'src/containers/Auth/FormMessages/container'
import AuthLayout from '../../containers/Auth'
import { Timer } from './Timer'
import { COGNITO_SESSION_TIMEOUT } from '../../constants'

const Login = ({
	signInUser,
	confirmUser,
	resetPassword,
	confirmPassword,
	loading,
	user,
	verifySoftwareToken,
	validateMfa,
	signOut,
	...routerProps
}) => {
	const functions = {
		signInUser,
		confirmUser,
		resetPassword,
		confirmPassword,
		verifySoftwareToken,
		validateMfa,
	}
	const formObject = formsObj[routerProps.location.pathname]
	const FormComponent = formObject?.component

	return (
		<AuthLayout>
			{FormComponent && (
				<>
					<FormMessages formObject={formObject} />
					<FormComponent
						onSubmit={functions[formObject.function]}
						onSignOut={signOut}
						routerProps={routerProps}
						loading={loading}
						user={user}
					/>
					{formObject.withTimer && (
						<Timer onFinish={signOut} maxSeconds={COGNITO_SESSION_TIMEOUT} />
					)}
				</>
			)}
		</AuthLayout>
	)
}

const mapStateToProps = ({ user, ui }) => {
	return {
		user: user,
		loading: ui.loading,
	}
}

export default connect(mapStateToProps, {
	signInUser,
	confirmUser,
	confirmPassword,
	resetPassword,
	verifySoftwareToken,
	validateMfa,
	signOut,
})(Login)
