import { numberWithCommas } from './numberWithCommas'

const MILLION = Math.pow(10, 6)
const BILLION = Math.pow(10, 9)

export const numberWithSuffix = (number) => {
	if (!number && number !== 0) {
		return ''
	}

	if (number < MILLION) {
		return numberWithCommas(number)
	}

	if (number >= MILLION && number < BILLION) {
		let suffix = 'm'
		let mValue = Math.round((number * 10) / MILLION) / 10
		if (mValue >= 1000) {
			mValue /= 1000
			suffix = 'bn'
		}
		return `${mValue}${suffix}`
	}

	if (number >= BILLION) {
		return `${Math.round((number * 10) / BILLION) / 10}bn`
	}

	return number
}
