import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

function AdaptationsRollUpDisplayBoxSortedRiskScores({
	boxLabel,
	data,
	color,
	getRatingAndColor,
	locatorId,
}) {
	const stripUnderScores = (str) => {
		return str.replaceAll('_', ' ')
	}
	if (data.length > 0) {
		return (
			<div className={`boxWrapper--${color}`} data-locator-id={locatorId}>
				<div className='boxLabel'>{boxLabel}</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[0].score)[1]}` }}
					>
						{getRatingAndColor(data[0].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[0].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[1].score)[1]}` }}
					>
						{getRatingAndColor(data[1].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[1].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[2].score)[1]}` }}
					>
						{getRatingAndColor(data[2].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[2].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[3].score)[1]}` }}
					>
						{getRatingAndColor(data[3].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[3].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[4].score)[1]}` }}
					>
						{getRatingAndColor(data[4].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[4].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[5].score)[1]}` }}
					>
						{getRatingAndColor(data[5].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[5].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[6].score)[1]}` }}
					>
						{getRatingAndColor(data[6].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[6].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[7].score)[1]}` }}
					>
						{getRatingAndColor(data[7].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[7].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[8].score)[1]}` }}
					>
						{getRatingAndColor(data[8].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[8].hazard)}</div>
				</div>
				<div className='row'>
					<div
						className='score'
						style={{ color: `${getRatingAndColor(data[9].score)[1]}` }}
					>
						{getRatingAndColor(data[9].score)[0]}
					</div>
					<div className='hazard'>{stripUnderScores(data[9].hazard)}</div>
				</div>
			</div>
		)
	} else {
		return (
			<div className={`boxWrapper--${color}`}>
				<div className='boxLabel'>{boxLabel}</div>
			</div>
		)
	}
}

AdaptationsRollUpDisplayBoxSortedRiskScores.defaultProps = {
	locatorId: 'not-set',
}

AdaptationsRollUpDisplayBoxSortedRiskScores.propTypes = {
	displayValue: PropTypes.string,
}

export default AdaptationsRollUpDisplayBoxSortedRiskScores
