import api from '../../service/api'
import { isEmpty } from 'lodash'
import { addToast } from './toasts'
import { getErrorMessage } from '../../utils'

let announcementsPollInterval = undefined
let initialAnnouncementsFetch = true

export const SET_ANNOUNCEMENTS_LOADING = `setAnnouncementsLoading`
export function setAnnouncementsLoading(isLoading) {
	return { type: SET_ANNOUNCEMENTS_LOADING, isLoading }
}

export const SET_ANNOUNCEMENTS_ERROR_MESSAGE = 'setAnnouncementsErrorMessage'
export function setAnnouncementsErrorMessage(message) {
	return {
		type: SET_ANNOUNCEMENTS_ERROR_MESSAGE,
		message,
	}
}

export const SET_ANNOUNCEMENT_AS_READ = 'setAnnouncementAsRead'
export function setAnnouncementAsRead(announcementId) {
	return async (dispatch) => {
		dispatch(setAnnouncementsLoading(true))

		try {
			await api.put(`/announcements/${announcementId}`)

			dispatch({
				type: SET_ANNOUNCEMENT_AS_READ,
				announcementId,
			})
		} catch (error) {
			dispatch(
				setAnnouncementsErrorMessage(
					getErrorMessage(error, 'Error: Failed to mark announcement as read')
				)
			)
		}
	}
}

export const SET_ANNOUNCEMENTS = 'setAnnouncements'
export function runAnnouncementsPoll() {
	return async (dispatch, getState) => {
		try {
			dispatch(stopAnnouncementsPoll())
			dispatch(setAnnouncementsLoading(true))

			const isAnnouncementsPolling =
				getState().announcements.isAnnouncementsPolling

			const fetchAnnouncements = async () => {
				try {
					const existingAnnouncements = getState().announcements.data

					const result = await api.get('/announcements')

					const announcements = result || []
					const isAnnouncementsUnread = announcements.find(
						(announcement) => !announcement.read
					)

					if (
						(isEmpty(existingAnnouncements) && !isEmpty(announcements)) ||
						!!isAnnouncementsUnread
					) {
						dispatch({
							type: SET_ANNOUNCEMENTS,
							announcements: announcements,
							isUnreadAnnouncementExists: !!isAnnouncementsUnread,
						})
					}
				} catch (error) {
					dispatch(
						addToast({
							body: getErrorMessage(
								error,
								'Error: Announcements could not be fetched'
							),
							type: 'error',
						})
					)
				}
			}

			if (initialAnnouncementsFetch) {
				initialAnnouncementsFetch = false
				fetchAnnouncements()
			}

			if (!isAnnouncementsPolling) {
				dispatch(setAnnouncementsPollingState(true))

				announcementsPollInterval = setInterval(async () => {
					fetchAnnouncements()
				}, 60000) // 1min poll
			}
		} catch (error) {
			dispatch(setAnnouncementsErrorMessage('Error: Announcements'))
		}
	}
}

export const STOP_ANNOUNCEMENTS_POLL = 'stopAnnouncementsPoll'
export function stopAnnouncementsPoll() {
	clearInterval(announcementsPollInterval)
	initialAnnouncementsFetch = true
	return { type: STOP_ANNOUNCEMENTS_POLL }
}

export const SET_ANNOUNCEMENTS_POLLING_STATE = 'setAnnouncementsPollingState'
export function setAnnouncementsPollingState(isRunnning) {
	return {
		type: SET_ANNOUNCEMENTS_POLLING_STATE,
		isAnnouncementsPolling: isRunnning,
	}
}
