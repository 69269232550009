import { getActiveBaseUrl } from '../service/api'
import { getIsUserAuthenticate, getUserSessionExpireStatus } from '../utils'
import {
	CLIMATE_X_USER_EXPIRE_TIME,
	MAX_TIME_TO_SHOW_SESSION_EXPIRE_MODAL,
} from '../constants'
import { debounce } from 'lodash'

export class IdleTimer {
	constructor({ timeout, onTimeout, onExpired }) {
		let self = this
		this.timeout = timeout
		this.onTimeout = onTimeout
		this.interval = undefined

		this.isAuthenticated = getIsUserAuthenticate()

		const status = getUserSessionExpireStatus()
		// we consider initial state here and compare times only when the expire time exist
		if (status.expireTimeExists && status.isExpired) {
			onExpired(status.timeDiff < MAX_TIME_TO_SHOW_SESSION_EXPIRE_MODAL)
			return
		}

		localStorage.setItem(
			CLIMATE_X_USER_EXPIRE_TIME,
			Date.now() + this.timeout * 1000
		)

		this.eventHandler = this.updateExpiredTime.bind(this)
		this.tracker()
		this.startInterval()
		// Overwrite default fetch functionality
		window.fetch = new Proxy(window.fetch, {
			apply(fetch, that, args) {
				const result = fetch.apply(that, args)

				// Update climateXExpireTime when request URL is not /announcements
				if (
					self.isAuthenticated &&
					localStorage.getItem(CLIMATE_X_USER_EXPIRE_TIME)
				) {
					if (args[0] !== `${getActiveBaseUrl()}/announcements`) {
						self.eventHandler()
					}
				}

				return result
			},
		})
	}

	updateExpiredTime = debounce(() => {
		if (localStorage.getItem(CLIMATE_X_USER_EXPIRE_TIME)) {
			localStorage.setItem(
				CLIMATE_X_USER_EXPIRE_TIME,
				Date.now() + this.timeout * 1000
			)
		}
	}, 300)

	startInterval() {
		this.updateExpiredTime()

		if (this.interval) {
			clearInterval(this.interval)
		}

		this.interval = setInterval(() => {
			const status = getUserSessionExpireStatus()
			if (status.isExpired && this.onTimeout) {
				this.cleanUp()
				this.onTimeout(status.timeDiff < MAX_TIME_TO_SHOW_SESSION_EXPIRE_MODAL)
			}
		}, 1000)
	}

	tracker() {
		window.addEventListener('mousemove', this.eventHandler)
		window.addEventListener('scroll', this.eventHandler)
		window.addEventListener('keydown', this.eventHandler)
	}

	cleanUp() {
		localStorage.removeItem(CLIMATE_X_USER_EXPIRE_TIME)

		if (this.interval) {
			clearInterval(this.interval)
		}

		window.removeEventListener('mousemove', this.eventHandler)
		window.removeEventListener('scroll', this.eventHandler)
		window.removeEventListener('keydown', this.eventHandler)
	}
}
