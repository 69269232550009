export const prodEnvConfig = {
	environment: 'production',
	authApiUrl: 'https://apis.climate-x.com/auth',
	mainApiUrl: 'https://apis.climate-x.com/main',
	layersApiUrl: 'https://d23mydvp1zfoe3.cloudfront.net/tile-server/tile',
	dynamicTilesConfigUrl:
		'https://prod-climatex-heatmap-store.s3.eu-west-2.amazonaws.com/hazard_layers_version_config.json',
	layerProperties: {
		coastal_flood: {
			hasDefend: true,
		},
		river_flood: {
			hasDefend: true,
			hasExtent: true,
		},
		surface_flood: {
			hasExtent: true,
		},
		storm_surge: {
			hasDefend: true,
			hasExtent: true,
		},
	},
	customHeaders: {
		'x-tenant-type': 'STANDARD',
	},
	isReduxLogEnabled: false, // should be always false for production env
	featureFlags: {
		isDefendsButtonEnabled: true,
	},
}
