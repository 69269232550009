import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOnClickOutside } from '../../../customHooks'
import { createHomeLink, createProjectsLink } from 'src/utils'
import { PROJECT_NAME_MAX_LENGTH } from '../../../constants'
import './styles.scss'

function ProjectsSelector({
	projects,
	queryParams,
	selectedProject,
	onProjectSelect,
	onProjectNameEdit,
}) {
	const [activeProject, setActiveProject] = useState(selectedProject || {})
	const [isProjectsOpen, setIsProjectsOpen] = useState(false)
	const projectsRef = useRef(null)

	useOnClickOutside(projectsRef, () => setIsProjectsOpen(false))

	const handleProjectNameChange = (name) => {
		if (name?.trim().length <= PROJECT_NAME_MAX_LENGTH) {
			setActiveProject({ ...activeProject, name: name })
			onProjectNameEdit(name)
		}
	}

	const handleProjectSelect = (project) => {
		setActiveProject(project)

		setIsProjectsOpen(false)
		onProjectSelect({ projectId: project.id })
	}

	useEffect(() => {
		if (
			activeProject &&
			selectedProject &&
			selectedProject.id !== activeProject.id
		) {
			setActiveProject(selectedProject)
		}
	}, [selectedProject, activeProject])

	return (
		<div
			className='projects-selector-wrapper'
			ref={projectsRef}
			onClick={(e) => e.stopPropagation()}
		>
			<div className='projects-selector-wrapper__selected-project'>
				<input
					type='text'
					value={activeProject.name}
					onChange={(e) => handleProjectNameChange(e.target.value)}
					className='projects-selector-wrapper__selected-project-input'
				/>
				<FontAwesomeIcon
					icon={['far', 'chevron-down']}
					className={classnames(
						'projects-selector-wrapper__dropdown-icon',
						isProjectsOpen && 'projects-selector-wrapper__dropdown-icon--active'
					)}
					onClick={() => setIsProjectsOpen(!isProjectsOpen)}
				/>
			</div>
			{isProjectsOpen && (
				<div className='projects-selector-wrapper__popular-projects'>
					<div className='projects-selector-wrapper__popular-project-item'>
						<NavLink
							to={createHomeLink(queryParams)}
							activeClassName='active-link'
						>
							<FontAwesomeIcon
								icon={['far', 'plus']}
								className='projects-selector-wrapper__popular-project-icon'
							/>
							Add new project
						</NavLink>
					</div>
					{projects.map((project) => {
						if (project.id === selectedProject.id) {
							return null
						}

						return (
							<div
								key={project.id}
								className='projects-selector-wrapper__popular-project-item projects-selector-wrapper__popular-project-item--top-devider'
								onClick={() => handleProjectSelect(project)}
							>
								<div>
									<FontAwesomeIcon
										icon={['far', 'city']}
										className='projects-selector-wrapper__popular-project-icon'
									/>
									{project.name}
								</div>
								<FontAwesomeIcon
									icon={['fa-solid', 'chevron-right']}
									className='projects-selector-wrapper__popular-project-arrow-icon'
								/>
							</div>
						)
					})}

					<div className='projects-selector-wrapper__popular-project-item projects-selector-wrapper__popular-project-item--top-devider'>
						<NavLink
							to={createProjectsLink(queryParams)}
							activeClassName='active-link'
						>
							<FontAwesomeIcon
								icon={['far', 'city']}
								className='projects-selector-wrapper__popular-project-icon'
							/>
							View all projects
						</NavLink>
						<FontAwesomeIcon
							icon={['fa-solid', 'chevron-right']}
							className='projects-selector-wrapper__popular-project-arrow-icon'
						/>
					</div>
				</div>
			)}
		</div>
	)
}

ProjectsSelector.propTypes = {
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	).isRequired,
	queryParams: PropTypes.object,
	selectedProject: PropTypes.object,
	onProjectSelect: PropTypes.func.isRequired,
	onProjectNameEdit: PropTypes.func.isRequired,
}

export default ProjectsSelector
