import _ from 'lodash'
import 'whatwg-fetch'
import fetchDefaults from 'fetch-defaults'
import fetchJsonify from 'fetch-jsonify'
import fetchThrow from 'fetch-throw'
import FetchError from 'fetch-error'
import { LOCAL_STORAGE_KEY } from '../constants'
import store from 'src/redux/store'
import { signOutUser } from '../redux/actions/user'
import { getAppConfig } from '../config'

const appConfig = getAppConfig()

export const getActiveBaseUrl = (isAuthUrl) => {
	return isAuthUrl ? appConfig.authApiUrl : appConfig.mainApiUrl
}

const getAuthHeader = (accessToken) => {
	const currentUser = localStorage.getItem(LOCAL_STORAGE_KEY.USER)

	const currentAuthToken = currentUser
		? JSON.parse(currentUser)
		: { access_token: accessToken }

	return {
		Accept: 'application/json',
		...appConfig.customHeaders,
		authorization: currentAuthToken
			? `Bearer ${currentAuthToken.access_token}`
			: null,
	}
}

const _fetch = (authHeader) => {
	return fetchDefaults(fetch, {
		headers: authHeader,
	})
}

export default async function api(url, _opts = {}, isAuthUrl) {
	const { delay, headerOptions, ...opts } = _opts
	const authHeader = getAuthHeader(headerOptions?.access_token)

	const activeBaseUrl = getActiveBaseUrl(isAuthUrl)

	return _.flow([fetchJsonify, fetchThrow])(_fetch(authHeader))(
		`${activeBaseUrl}${url}`,
		opts
	)
		.then((res) => {
			if (isJsonContent(res)) {
				return res.json()
			}

			return undefined
		})
		.catch((err) => {
			if (
				err instanceof FetchError &&
				err.response &&
				isJsonContent(err.response)
			) {
				// Dispatch logout action if cognito authorizer returns access denied
				if (err.code === 403) {
					store.dispatch(signOutUser())
					return
				}
				return err.response.json().then((res) => {
					// eslint-disable-next-line no-throw-literal
					throw { code: err.code, body: res }
				})
			} else {
				throw err
			}
		})
}

api.get = (url, json) => api(url, { json, method: 'GET' })
api.post = (url, json) => api(url, { json, method: 'POST' })
api.put = (url, json) => api(url, { json, method: 'PUT' })
api.patch = (url, json) => api(url, { json, method: 'PATCH' })
api.head = (url) => api(url, { method: 'HEAD' })
api.delete = (url, json) => api(url, { json, method: 'DELETE' })

function isJsonContent(res) {
	const contentType = res.headers.get('content-type')
	return contentType && contentType.indexOf('application/json') !== -1
}
