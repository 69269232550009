import React, { useState } from 'react'
import Input from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { validate } from '../../../../utils'
import qs from 'qs'

const PasswordConfirmForm = ({ onSubmit, routerProps, loading }) => {
	const { history } = routerProps
	const queryParams = qs.parse(history.location.search, {
		ignoreQueryPrefix: true,
	})

	const [controls, setControls] = useState({
		email: {
			value: queryParams.username || '',
			touched: Boolean(queryParams.username) || false,
			validationRules: {
				minLength: 3,
				isEmail: true,
			},
			valid:
				(queryParams.username &&
					validate(queryParams.username, {
						minLength: 3,
						isEmail: true,
					})) ||
				false,
		},
		password: {
			value: '',
			touched: false,
			validationRules: {
				minLength: 3,
				isCognitoPassword: true,
			},
			valid: false,
		},
		passwordRepeat: {
			value: '',
			touched: false,
			validationRules: {
				minLength: 3,
				isCognitoPassword: true,
			},
			valid: false,
		},
	})

	const updateState = (field, value) => {
		setControls((prevState) => {
			const newState = {
				...prevState,
				[field]: {
					...prevState[field],
					value,
					touched: true,
					valid: validate(value, prevState[field].validationRules),
				},
			}

			newState.passwordRepeat.valid =
				newState.passwordRepeat.value === newState.password.value

			return newState
		})
	}

	const onResetPassword = (e) => {
		e.preventDefault()
		isFormValid() &&
			onSubmit(queryParams?.code, controls.password.value, history)
	}

	const isFormValid = () => {
		return controls.password.valid && controls.passwordRepeat.valid
	}

	return (
		<form className='form-floating' action=''>
			<div className='form-floating mb-3' hidden>
				<Input
					type='email'
					label='Email address'
					error={controls.email.touched && !controls.email.valid}
					placeholder='name@example.com'
					autoComplete='new-password'
					value={controls.email.value}
					onChange={(e) => updateState('email', e.target.value)}
				/>
			</div>
			<div className='form-floating mb-3'>
				<Input
					type='password'
					label='New Password'
					placeholder='New Password'
					autoComplete='new-password'
					error={controls.password.touched && !controls.password.valid}
					value={controls.password.value}
					onChange={(e) => updateState('password', e.target.value)}
				/>
			</div>
			<div className='form-floating mb-3'>
				<Input
					type='password'
					label='Confirm Password'
					placeholder='Confirm Password'
					autoComplete='new-password'
					error={
						controls.passwordRepeat.touched && !controls.passwordRepeat.valid
					}
					value={controls.passwordRepeat.value}
					onChange={(e) => updateState('passwordRepeat', e.target.value)}
				/>
			</div>
			<div className='col-auto col-sm-12'>
				<div className='d-flex align-items-center mt-4 mb-4'>
					<Button
						className='btn btn-primary mt-0'
						onClick={onResetPassword}
						disabled={loading || !isFormValid()}
					>
						{loading ? 'Loading ...' : 'Confirm Password'}
					</Button>
				</div>
			</div>
		</form>
	)
}
export default PasswordConfirmForm
