import {
	ADD_PROJECT_SUMMARY,
	SET_PROJECT_SUMMARY_LOADING,
	SET_PROJECT_SUMMARY_ERROR_MESSAGE,
} from '../actions/projectsSummaries'

const DEFAULT_STATE = {
	data: undefined,
	loading: undefined,
	error: null,
}

export default function projectsSummaries(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case ADD_PROJECT_SUMMARY:
			return {
				...state,
				loading: false,
				error: null,
				data: action.summary,
			}
		case SET_PROJECT_SUMMARY_LOADING:
			return {
				...state,
				loading: action.isLoading,
				error: null,
			}
		case SET_PROJECT_SUMMARY_ERROR_MESSAGE:
			return {
				...state,
				data: {},
				loading: false,
				error: action.errorMessage,
			}
		default:
			return state
	}
}
