import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { PageTitle } from 'src/components/PageTitle'
import InputWithInsideLabel from '../../components/Input/InputWithInsideLabel'
import { Select } from '../../components/Select'
import { getCurrencies } from '../../redux/selectors/currencies'
import { fetchCurrencies } from '../../redux/actions/currencies'
import { updateCurrency, updateLengthUnit } from '../../redux/actions/user'
import { getUserSettings } from '../../redux/selectors/user'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import ChangePasswordForm from './ChangePasswordForm'
import { LENGTH_UNITS } from 'src/constants/units'
import './styles.scss'

const Settings = ({
	userSettings,
	currencies,
	fetchCurrencies,
	updateCurrency,
	updateLengthUnit,
}) => {
	const [currencyOptions, setCurrencyOptions] = useState([])
	const [selectedCurrency, setSelectedCurrency] = useState(
		userSettings?.preferred_currency_id
	)
	const [selectedLengthUnit, setSelectedLengthUnit] = useState(
		userSettings?.preferred_length_unit
	)

	useEffect(() => {
		if (isEmpty(currencies)) {
			fetchCurrencies()
		}
	}, [])

	useEffect(() => {
		setSelectedCurrency(userSettings?.preferred_currency_id)
		setSelectedLengthUnit(userSettings?.preferred_length_unit)
	}, [userSettings])

	useEffect(() => {
		setCurrencyOptions(
			currencies.map((currency) => {
				return {
					displayValue: `${currency.code} ${currency.symbol}`,
					value: currency.id,
				}
			})
		)
	}, [currencies])

	const handleCurrencyChange = (currency) => {
		setSelectedCurrency(currency)
		updateCurrency(parseInt(currency))
	}

	const handleLengthChange = (lengthUnit) => {
		setSelectedLengthUnit(lengthUnit)
		updateLengthUnit(lengthUnit)
	}

	return (
		<div className='page-top-padding settings-page'>
			<PageTitle title='Your Account' />

			<div className='container-fluid px-4 pb-4'>
				<div className='row'>
					<div className='col-sm-12 col-md-9 col-lg-5'>
						<div className='settings-page__heading'>Change Password</div>
						<div>Password must contain at least 8 characters, including:</div>
						<ul>
							<li>At least one lowercase letter</li>
							<li>At least one capital letter</li>
						</ul>
						<ChangePasswordForm />
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						<div className='settings-page__heading'>System Settings</div>
						<div className='settings-page__text'>
							These settings are used to personalise your experience.
						</div>
					</div>
				</div>
				<div className='row mb-5'>
					<div className='col-sm-12 col-md-9 col-lg-5'>
						<InputWithInsideLabel
							label='Language'
							disabled={true}
							value='English'
							icon='asterisk'
						/>
						<div className='form-group'>
							<InputWithInsideLabel
								label='Date Format'
								value='United Kingdom'
								icon='asterisk'
								hint='Format: 11 May, 2021 11/05/2021, and 1,234.56'
								disabled={true}
							/>
						</div>
						<div className='form-group'>
							<InputWithInsideLabel
								label='2FA'
								value='Enabled'
								disabled={true}
								success={true}
							/>
						</div>
						<div className='settings-page__units'>
							<div className='settings-page__select-wrapper'>
								<div
									className='settings-page__select-label-currency'
									data-locator-id={'div-currency-user-settings'}
								>
									Currency:
								</div>
								<Select
									locatorId={'select-currency-user-settings'}
									options={currencyOptions}
									selectedValue={selectedCurrency}
									onChange={handleCurrencyChange}
									className='settings-page__select-currency'
								/>
							</div>

							<div className='settings-page__select-wrapper'>
								<div
									className='settings-page__select-label-length'
									data-locator-id={'div-length-user-settings'}
								>
									Length Unit:
								</div>
								<Select
									locatorId={'select-length-user-settings'}
									options={LENGTH_UNITS}
									selectedValue={selectedLengthUnit}
									onChange={handleLengthChange}
									className='settings-page__select-length'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

Settings.propTypes = {
	userSettings: PropTypes.shape({
		preferred_currency_id: PropTypes.string,
		preferred_length_unit: PropTypes.string,
	}),
	currencies: PropTypes.shape({
		id: PropTypes.string,
		code: PropTypes.string,
		symbol: PropTypes.string,
	}),
	updateCurrency: PropTypes.func.isRequired,
	fetchCurrencies: PropTypes.func.isRequired,
	updateLengthUnit: PropTypes.func.isRequired,
}

export default connect(
	(state) => ({
		userSettings: getUserSettings(state),
		currencies: getCurrencies(state),
	}),
	{
		updateCurrency,
		fetchCurrencies,
		updateLengthUnit,
	}
)(Settings)
