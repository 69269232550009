import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'src/components/Button'
import './styles.scss'

export const SessionExpireModalContent = ({ onConfirm }) => {
	return (
		<div className='session-expire-modal-content'>
			<h2 className='session-expire-modal-content__question'>
				Your session has expired
			</h2>
			<div className='session-expire-modal-content__actions'>
				<Button
					className='session-expire-modal-content__confirm'
					onClick={onConfirm}
				>
					Log in
				</Button>
			</div>
		</div>
	)
}

SessionExpireModalContent.propTypes = {
	onConfirm: PropTypes.func.isRequired,
}
