import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import {
	HELP_PAGE_LOGIN_URL,
	HELP_PAGE_URL,
	LOCAL_STORAGE_KEY,
} from '../../../constants'
import { createHelpPageUrl } from '../../../utils'
import { addToast } from '../../../redux/actions/toasts'

export const MarkdownLink = ({ key, href, children }) => {
	let target = href?.includes(`${window.location.origin}`) ? '_self' : '_blank'
	const isHelpPageLink = href?.startsWith(HELP_PAGE_URL)
	const originalHref = href

	if (isHelpPageLink) {
		href = '#'
		target = '_self'
	}

	const onClick = (e) => {
		if (isHelpPageLink) {
			e.preventDefault()

			let url
			if (!localStorage.getItem(LOCAL_STORAGE_KEY.IS_LOGGED_IN_ON_HELP_PAGE)) {
				try {
					const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER))
					url = createHelpPageUrl({
						baseUrl: HELP_PAGE_LOGIN_URL,
						redirectUrl: originalHref,
						jwt: user.access_token,
					})
					localStorage.setItem(
						LOCAL_STORAGE_KEY.IS_LOGGED_IN_ON_HELP_PAGE,
						Boolean(true).toString()
					)
				} catch (error) {
					addToast({
						body: 'Error: URL could not be opened',
						type: 'error',
					})
				}
			} else {
				url = originalHref
			}
			window.location.replace(url)
		}
	}

	return (
		<a
			className='markdown-link'
			href={href}
			key={key}
			target={target}
			rel='noreferrer'
			onClick={onClick}
		>
			{children}
		</a>
	)
}

MarkdownLink.propTypes = {
	children: PropTypes.array.isRequired,
	href: PropTypes.string.isRequired,
	key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
