import React, { useState } from 'react'
import { Button } from '../../../components/Button'
import Input from '../../../components/Input'
import { validate } from '../../../utils'
import { changeUserPassword } from '../../../redux/actions/user'
import { connect } from 'react-redux'

const _ChangePasswordForm = ({ changeUserPassword }) => {
	const [loading, setLoading] = useState(false)
	const [controls, setControls] = useState({
		oldPassword: {
			value: '',
			touched: false,
			validationRules: {
				minLength: 3,
			},
			valid: false,
		},
		newPassword: {
			value: '',
			touched: false,
			validationRules: {
				isCognitoPassword: true,
			},
			valid: false,
		},
		passwordRepeat: {
			value: '',
			touched: false,
			validationRules: {
				isCognitoPassword: true,
			},
			valid: false,
		},
	})

	const updateState = (field, value) => {
		if (!loading) {
			setControls((prevState) => {
				const newState = {
					...prevState,
					[field]: {
						...prevState[field],
						value,
						touched: true,
						valid: validate(value, prevState[field].validationRules),
					},
				}

				newState.passwordRepeat.valid =
					newState.passwordRepeat.value === newState.newPassword.value

				return newState
			})
		}
	}

	const onPasswordChange = async (e) => {
		e.preventDefault()
		if (isFormValid()) {
			setLoading(true)
			await changeUserPassword(
				controls.oldPassword.value,
				controls.newPassword.value
			)
			setLoading(false)

			setControls((prevState) => ({
				...prevState,
				oldPassword: {
					...prevState.oldPassword,
					value: '',
				},
				newPassword: {
					...prevState.newPassword,
					value: '',
				},
				passwordRepeat: {
					...prevState.passwordRepeat,
					value: '',
				},
			}))
		}
	}

	const isFormValid = () => {
		return (
			controls.oldPassword.valid &&
			controls.newPassword.valid &&
			controls.passwordRepeat.valid
		)
	}

	return (
		<form className='form-floating'>
			<div className='form-floating mb-3'>
				<Input
					type='password'
					label='Current Password'
					placeholder='Current Password'
					autoComplete='current-password'
					error={controls.oldPassword.touched && !controls.oldPassword.valid}
					value={controls.oldPassword.value}
					onChange={(e) => updateState('oldPassword', e.target.value)}
				/>
			</div>
			<div className='form-floating mb-3'>
				<Input
					type='password'
					label='New Password'
					placeholder='New Password'
					autoComplete='off'
					error={controls.newPassword.touched && !controls.newPassword.valid}
					value={controls.newPassword.value}
					onChange={(e) => updateState('newPassword', e.target.value)}
				/>
			</div>
			<div className='form-floating mb-3'>
				<Input
					type='password'
					label='Confirm Password'
					placeholder='Confirm Password'
					autoComplete='off'
					error={
						controls.passwordRepeat.touched && !controls.passwordRepeat.valid
					}
					value={controls.passwordRepeat.value}
					onChange={(e) => updateState('passwordRepeat', e.target.value)}
				/>
			</div>
			<div className='col-auto col-sm-12'>
				<div className='d-flex align-items-center mt-4 mb-4'>
					<Button
						className='btn btn-secondary mt-0'
						onClick={onPasswordChange}
						disabled={loading || !isFormValid()}
					>
						Change Password
					</Button>
				</div>
			</div>
		</form>
	)
}

export default connect(null, { changeUserPassword })(_ChangePasswordForm)
