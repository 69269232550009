import React from 'react'
import { isNil, round, get } from 'lodash'
import { Score } from '../components/Score'
import {
	GREAT_BRITAIN_COUNTRY_CODE,
	HAZARD_TYPES,
	RISK_DASHBOARD_COLUMNS,
	SCORE_WEIGHTS,
} from '../constants'

const getPercentage = (likelihood) => {
	if (isNil(likelihood)) {
		return null
	}

	const percentage = round(likelihood * 100)

	if (percentage === 0) {
		return '0'
	}

	return percentage
}

const getLandslideSeverityLabel = (severityValue) => {
	switch (severityValue) {
		case 0:
			return 'Ground displacement'
		case 1:
			return 'Shallow'
		case 2:
			return 'Slides'
		case 3:
			return 'Compound'
		case 4:
			return 'Ground displacement'
		default:
			return ''
	}
}

const getSeverityLabel = (key, severityValue, text, roundNumber = 2) => {
	if (isNil(severityValue)) {
		return null
	}

	const floodTypes = [
		HAZARD_TYPES.river_flood.value,
		HAZARD_TYPES.surface_flood.value,
		HAZARD_TYPES.coastal_flood.value,
		HAZARD_TYPES.storm_surge.value,
	]

	if (floodTypes.includes(key)) {
		if (severityValue > 6.0) {
			return '+6 ' + text
		} else {
			return `${round(severityValue, roundNumber).toFixed(roundNumber)} ${text}`
		}
	}

	if (key === HAZARD_TYPES.landslide.value) {
		return getLandslideSeverityLabel(severityValue)
	}

	if (key === HAZARD_TYPES.landslide.value) {
		return getLandslideSeverityLabel(severityValue)
	}

	return `${round(severityValue, roundNumber).toFixed(roundNumber)} ${text}`
}

const getRatingScore = (value) => {
	if (SCORE_WEIGHTS.hasOwnProperty(value)) {
		return SCORE_WEIGHTS[value]
	}
	return null
}

const getScore = (summary, key) => {
	const scoreKey = `${key}.score`

	return (
		<Score
			score={getRatingScore(get(summary, scoreKey)) || 'A'}
			wrapperClassName='asset-risk-dashboard__score'
		/>
	)
}

const getSeverity = (summary, key, defaultLabel, roundNumber) => {
	const severityKey = `${key}.severity_value`
	const severityValue = get(summary, severityKey)

	return !isNil(severityValue)
		? getSeverityLabel(key, severityValue, defaultLabel, roundNumber)
		: 'n/a'
}

const getProbability = (summary, key, defaultValue = 'n/a') => {
	const probability = getPercentage(get(summary[key], 'likelihood'))
	return probability ? `${probability}%` : defaultValue
}

const getAccuracy = (summary, key) => {
	const accuracyKey = `${key}.climate_reliability`

	return !isNil(get(summary, accuracyKey))
		? `${round(get(summary, accuracyKey) * 100)}%`
		: 'n/a'
}

export const getRiskDashboardTableData = (summary) => {
	const rows = [
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Acute',
				hasInPlaceExplainer: true,
				explainerKey: 'acute',
				className: 'pb-2 asset-risk-dashboard__col-title',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: '',
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'River flooding',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.river_flood.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(
					summary,
					HAZARD_TYPES.river_flood.value,
					'm Depth'
				),
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: getProbability(summary, HAZARD_TYPES.river_flood.value),
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.river_flood.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Surface flooding',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.surface_flood.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(
					summary,
					HAZARD_TYPES.surface_flood.value,
					'm Depth'
				),
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: getProbability(summary, HAZARD_TYPES.surface_flood.value),
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.surface_flood.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Landslide',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.landslide.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(summary, HAZARD_TYPES.landslide.value, ''),
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: getProbability(summary, HAZARD_TYPES.landslide.value),
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.landslide.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Wildfire',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.wildfire.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(
					summary,
					HAZARD_TYPES.wildfire.value,
					'No of fire weather days',
					0
				),
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: getProbability(summary, HAZARD_TYPES.wildfire.value),
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.wildfire.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Storm',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.storm.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: !isNil(
					get(summary, `${HAZARD_TYPES.storm.value}.severity_value`)
				)
					? getSeverity(summary, HAZARD_TYPES.storm.value, 'km/h gust speed')
					: '0 km/h gust speed',
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: getProbability(summary, HAZARD_TYPES.storm.value, '0%'),
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.storm.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Tropical Cyclones',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.tropical_cyclones.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: !isNil(
					get(summary, `${HAZARD_TYPES.tropical_cyclones.value}.severity_value`)
				)
					? getSeverity(
							summary,
							HAZARD_TYPES.tropical_cyclones.value,
							'km/h gust speed'
						)
					: '0 km/h gust speed',
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: getProbability(
					summary,
					HAZARD_TYPES.tropical_cyclones.value,
					'0%'
				),
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.tropical_cyclones.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Drought',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.droughts.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(summary, HAZARD_TYPES.droughts.value, 'TDM'),
				hasInPlaceExplainer: true,
				explainerKey: 'droughts_severity_metric',
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: getProbability(summary, HAZARD_TYPES.droughts.value),
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.droughts.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Storm Surge',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.storm_surge.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(
					summary,
					HAZARD_TYPES.storm_surge.value,
					'm Depth'
				),
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: getProbability(summary, HAZARD_TYPES.storm_surge.value),
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.storm_surge.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Chronic',
				hasInPlaceExplainer: true,
				explainerKey: 'chronic',
				className: 'pb-2 asset-risk-dashboard__col-title',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: '',
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Subsidence',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.subsidence.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(
					summary,
					HAZARD_TYPES.subsidence.value,
					get(summary, 'country_id') === GREAT_BRITAIN_COUNTRY_CODE &&
						get(summary, 'landslide.scenario').toLowerCase().includes('rcp')
						? 'cm/year'
						: 'indicator [0,2]'
				),
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.subsidence.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Coastal flooding',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.coastal_flood.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(
					summary,
					HAZARD_TYPES.coastal_flood.value,
					'm Depth'
				),
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.coastal_flood.value),
			},
		},
		{
			[RISK_DASHBOARD_COLUMNS.HAZARD]: {
				content: 'Extreme heat',
			},
			[RISK_DASHBOARD_COLUMNS.RATING]: {
				content: getScore(summary, HAZARD_TYPES.extreme_heat.value),
			},
			[RISK_DASHBOARD_COLUMNS.SEVERITY]: {
				content: getSeverity(
					summary,
					HAZARD_TYPES.extreme_heat.value,
					'No of heatwave days',
					0
				),
			},
			[RISK_DASHBOARD_COLUMNS.PROBABILITY]: {
				content: '',
			},
			[RISK_DASHBOARD_COLUMNS.ACCURACY]: {
				content: getAccuracy(summary, HAZARD_TYPES.extreme_heat.value),
			},
		},
	]

	return {
		columns: [
			{
				id: RISK_DASHBOARD_COLUMNS.HAZARD,
				title: 'Hazard',
			},
			{
				id: RISK_DASHBOARD_COLUMNS.RATING,
				title: 'Rating',
				hasColumnExplainer: true,
				explainerKey: 'rating',
			},
			{
				id: RISK_DASHBOARD_COLUMNS.SEVERITY,
				title: 'Severity',
				hasColumnExplainer: true,
				explainerKey: 'severity',
			},
			{
				id: RISK_DASHBOARD_COLUMNS.PROBABILITY,
				title: 'Probability',
				hasColumnExplainer: true,
				explainerKey: 'probability',
			},
			{
				id: RISK_DASHBOARD_COLUMNS.ACCURACY,
				title: 'Accuracy',
				hasColumnExplainer: true,
				explainerKey: 'accuracy',
			},
		],
		rows: rows.filter((row) => row !== null),
	}
}
