import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import './styles.scss'

const InputWithInsideLabel = ({
	id = `${new Date().getTime().toString()}__id`,
	error = '',
	icon = '',
	label,
	success,
	hint,
	...rest
}) => {
	return (
		<div>
			<div
				className={classNames('labeled-control', {
					'error bg-opacity-10 bg-danger': error,
					'success bg-opacity-10 bg-success': success,
				})}
			>
				<div className='d-flex w-100'>
					{label && <label htmlFor={id}>{label}</label>}
					<input
						id={id}
						aria-describedby='hint-text'
						aria-label='Demo'
						{...rest}
					/>
				</div>
				{icon && <FontAwesomeIcon icon={icon} />}
			</div>
			{error && <div className='text-danger my-1 me-1 ml-185'>{error}</div>}
			{hint && <div className='hint my-1 me-1 ml-185'>{hint}</div>}
		</div>
	)
}

export default InputWithInsideLabel
