import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export const Timer = ({ maxSeconds, onFinish }) => {
	const [seconds, setSeconds] = useState(maxSeconds)
	useEffect(() => {
		if (seconds === 0) {
			onFinish && onFinish()
		}
		const timer =
			seconds > 0 &&
			setInterval(() => {
				const newSeconds = seconds - 1
				setSeconds(newSeconds)
			}, 1000)
		return () => clearInterval(timer)
	}, [seconds, onFinish])
	return (
		<div className='my-2'>
			Your session will expire in - <strong>{seconds} seconds</strong>
		</div>
	)
}

Timer.propTypes = {
	maxSeconds: PropTypes.number.isRequired,
	onFinish: PropTypes.func,
}
