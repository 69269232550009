import { isEmpty } from 'lodash'
import qs from 'qs'
import { getSelectedProject, getProjectSummary } from '../selectors/projects'
import api from '../../service/api'
import { getDefaultScenario, getErrorMessage } from '../../utils'
import { DEFAULT_EXTENT_VALUE } from '../../constants'
import { getBusinessDisruption } from '../selectors/businessDisruption'
import { BASE_YEAR } from 'src/constants/baseParameters'

export const SET_PROJECT_COMPARE_SUMMARY_ERROR_MESSAGE =
	'setProjectCompareSummaryErrorMessage'
export function setProjectCompareSummaryErrorMessage(errorMessage) {
	return { type: SET_PROJECT_COMPARE_SUMMARY_ERROR_MESSAGE, errorMessage }
}

export function fetchProjectCompareSummary(
	scenario,
	year,
	epcTargetYear,
	defended,
	project_id
) {
	return async (dispatch) => {
		dispatch(setCompareProjectLoading(true))
		try {
			const query_id = qs.stringify({
				project_id: project_id.toString(),
				scenario: getDefaultScenario(scenario).scenario,
				action: getDefaultScenario(scenario).action,
				year: parseInt(year),
				target_year: parseInt(epcTargetYear),
				defended: defended === 'defended',
				one_in_x: DEFAULT_EXTENT_VALUE,
			})

			const summary = await api.get(`/project-summary?${query_id}`)

			dispatch(
				replaceCompareProjectSummary({
					summary: summary,
				})
			)
		} catch (error) {
			dispatch(
				setProjectCompareSummaryErrorMessage(
					getErrorMessage(error, 'Could not load project summary')
				)
			)
		}
	}
}

export const CREATE_DUPLICATE_PROJECT = `createDuplicateProject`
export function createDuplicateProject({
	projectId,
	selectedYear,
	selectedEpcTargetYear,
	selectedScenario,
	defended,
}) {
	return (dispatch, getState) => {
		const existingCompareProjectSummary = getState().projectCompare.summary
		if (isEmpty(existingCompareProjectSummary)) {
			const selectedProject = getSelectedProject(getState(), projectId)

			const selectedProjectSummary = getProjectSummary(getState())

			const businessDisruption = getBusinessDisruption(getState())

			dispatch({
				type: CREATE_DUPLICATE_PROJECT,
				selectedProject,
				selectedProjectSummary,
				businessDisruption,
				selectedYear,
				selectedEpcTargetYear,
				selectedScenario,
				defended: defended,
			})
		}
	}
}

export const REPLACE_COMPARE_PROJECT_SUMMARY = 'replaceCompareProjectSummary'
export function replaceCompareProjectSummary({ summary }) {
	return {
		type: REPLACE_COMPARE_PROJECT_SUMMARY,
		summary,
	}
}

export const CLOSE_PROJECT_COMPARE = 'closeProjectCompare'
export function closeProjectCompare() {
	return {
		type: CLOSE_PROJECT_COMPARE,
	}
}

export const SET_COMPARE_PROJECT_LOADING = 'setCompareProjectLoading'
export function setCompareProjectLoading(isLoading) {
	return {
		type: SET_COMPARE_PROJECT_LOADING,
		isLoading,
	}
}

export const SET_COMPARE_PROJECT_FILTERS = 'setCompareProjectFilters'
export function setCompareProjectFilters(filters) {
	return {
		type: SET_COMPARE_PROJECT_FILTERS,
		filters,
	}
}

export function fetchCompareBusinessDisruption(queryParams) {
	return async (dispatch, getState) => {
		dispatch(setCompareBusinessDisruptionLoading(true))
		const activeAsset = getState().adaptations.current_asset
		const projectId = getState().projectCompare.project.id

		const requestData = {
			asset_id: activeAsset,
			options: {
				scenario: getDefaultScenario(queryParams.scenario).scenario,
				base_year: BASE_YEAR,
				target_year: parseInt(queryParams.year),
				defended: queryParams.defended === 'defended',
			},
		}

		const makeRequest = async (retry = false) => {
			try {
				const data = await api.post(
					`/business-disruption/${activeAsset}`,
					requestData
				)

				if (data === null && !retry) {
					// TODO: fix properly in BE next sprint but for now retrying the request once if data is null
					// which happens first time calling BD on new asset
					await makeRequest(true)
					return
				}

				setTimeout(() => {
					dispatch(
						setCompareBusinessDisruption({
							projectId: projectId,
							assetId: activeAsset,
							scenario: getDefaultScenario(queryParams.scenario).scenario,
							targetYear: parseInt(queryParams.year),
							defended: queryParams.defended === 'defended',
							payload: data?.business_disruption_results,
						})
					)
					dispatch(setCompareBusinessDisruptionLoading(false))
				}, 5000)
			} catch (error) {
				dispatch(
					setCompareBusinessDisruptionErrorMessage(
						getErrorMessage(error, 'Error: Could not load business disruption')
					)
				)
				dispatch(setCompareBusinessDisruptionLoading(false))
			}
		}

		await makeRequest()
	}
}

export const SET_COMPARE_BUSINESS_DISRUPTION_LOADING = `setCompareBusinessDisruptionLoading`
export function setCompareBusinessDisruptionLoading(isLoading) {
	return { type: SET_COMPARE_BUSINESS_DISRUPTION_LOADING, isLoading }
}

export const SET_COMPARE_BUSINESS_DISRUPTION = `setCompareBusinessDisruption`
export function setCompareBusinessDisruption(payload) {
	return { type: SET_COMPARE_BUSINESS_DISRUPTION, payload }
}

export const SET_COMPARE_BUSINESS_DISRUPTION_ERROR_MESSAGE = `setCompareBusinessDisruptionErrorMessage`
export function setCompareBusinessDisruptionErrorMessage(errorMessage) {
	return { type: SET_COMPARE_BUSINESS_DISRUPTION_ERROR_MESSAGE, errorMessage }
}
