export const validate = (val, rules) => {
	let isValid = true
	for (let rule in rules) {
		switch (rule) {
			case 'isEmail':
				isValid = isValid && emailValidator(val)
				break
			case 'isCognitoPassword':
				isValid = isValid && cognitoPasswordValidator(val)
				break
			case 'isVerificationCode':
				isValid = isValid && verificationCodeValidator(val)
				break
			case 'minLength':
				isValid = isValid && minLengthValidator(val, rules[rule])
				break
			case 'equalTo':
				isValid = isValid && equalToValidator(val, rules[rule])
				break
			default:
				isValid = true
		}
	}
	return isValid
}

const emailValidator = (val) => {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		val
	)
}

const cognitoPasswordValidator = (val) => {
	// at least one lowercase and uppercase letters, can contain numbers and special characters !@%#*$&
	return /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#\$%\^\&*\)\(+=._-]{8,}$/.test(val)
}

const verificationCodeValidator = (val) => {
	return /^[0-9]{6}$/.test(val)
}

const equalToValidator = (val, matchedVal) => {
	return val === matchedVal
}

const minLengthValidator = (val, minLength) => {
	return val.length >= minLength
}
