export const GREAT_BRITAIN_COUNTRY_CODE = 'GBR'

export const COUNTRY_CODES_MAPPING = {
	AFG: ['Afghanistan', 'AF', 'AFG'],
	ALA: ['Aland Islands', 'AX', 'ALA'],
	ALB: ['Albania', 'AL', 'ALB'],
	DZA: ['Algeria', 'DZ', 'DZA'],
	ASM: ['American Samoa', 'AS', 'ASM'],
	AND: ['Andorra', 'AD', 'AND'],
	AGO: ['Angola', 'AO', 'AGO'],
	AIA: ['Anguilla', 'AI', 'AIA'],
	ATA: ['Antarctica', 'AQ', 'ATA'],
	ATG: ['Antigua and Barbuda', 'AG', 'ATG'],
	ARG: ['Argentina', 'AR', 'ARG'],
	ARM: ['Armenia', 'AM', 'ARM'],
	ABW: ['Aruba', 'AW', 'ABW'],
	AUS: ['Australia', 'AU', 'AUS'],
	AUT: ['Austria', 'AT', 'AUT'],
	AZE: ['Azerbaijan', 'AZ', 'AZE'],
	BHS: ['Bahamas', 'BS', 'BHS'],
	BHR: ['Bahrain', 'BH', 'BHR'],
	BGD: ['Bangladesh', 'BD', 'BGD'],
	BRB: ['Barbados', 'BB', 'BRB'],
	BLR: ['Belarus', 'BY', 'BLR'],
	BEL: ['Belgium', 'BE', 'BEL'],
	BLZ: ['Belize', 'BZ', 'BLZ'],
	BEN: ['Benin', 'BJ', 'BEN'],
	BMU: ['Bermuda', 'BM', 'BMU'],
	BTN: ['Bhutan', 'BT', 'BTN'],
	BOL: ['Bolivia', 'BO', 'BOL'],
	BIH: ['Bosnia and Herzegovina', 'BA', 'BIH'],
	BWA: ['Botswana', 'BW', 'BWA'],
	BVT: ['Bouvet Island', 'BV', 'BVT'],
	BRA: ['Brazil', 'BR', 'BRA'],
	VGB: ['British Virgin Islands', 'VG', 'VGB'],
	IOT: ['British Indian Ocean Territory', 'IO', 'IOT'],
	BRN: ['Brunei Darussalam', 'Brunei', 'BN', 'BRN'],
	BGR: ['Bulgaria', 'BG', 'BGR'],
	BFA: ['Burkina Faso', 'BF', 'BFA'],
	BDI: ['Burundi', 'BI', 'BDI'],
	KHM: ['Cambodia', 'KH', 'KHM'],
	CMR: ['Cameroon', 'CM', 'CMR'],
	CAN: ['Canada', 'CA', 'CAN'],
	CPV: ['Cape Verde', 'CV', 'CPV'],
	CYM: ['Cayman Islands', 'KY', 'CYM'],
	CAF: ['Central African Republic', 'CF', 'CAF'],
	TCD: ['Chad', 'TD', 'TCD'],
	CHL: ['Chile', 'CL', 'CHL'],
	CHN: ['China', 'CN', 'CHN'],
	HKG: ['Hong Kong', 'China - Hong Kong', 'HK', 'HKG'],
	MAC: ['Macao', 'MO', 'MAC'],
	CXR: ['Christmas Island', 'CX', 'CXR'],
	CCK: ['Cocos Islands', 'CC', 'CCK'],
	COL: ['Colombia', 'CO', 'COL'],
	COM: ['Comoros', 'KM', 'COM'],
	COG: ['Congo', 'CG', 'COG'],
	COD: ['Congo', 'CD', 'COD'],
	COK: ['Cook Islands', 'CK', 'COK'],
	CRI: ['Costa Rica', 'CR', 'CRI'],
	CIV: ["Côte d'Ivoire", 'CI', 'CIV'],
	HRV: ['Croatia', 'HR', 'HRV'],
	CUB: ['Cuba', 'CU', 'CUB'],
	CYP: ['Cyprus', 'CY', 'CYP'],
	CZE: ['Czech Republic', 'CZ', 'Czechia', 'CZE'],
	DNK: ['Denmark', 'DK', 'DNK'],
	DJI: ['Djibouti', 'DJ', 'DJI'],
	DMA: ['Dominica', 'DM', 'DMA'],
	DOM: ['Dominican Republic', 'DO', 'DOM'],
	ECU: ['Ecuador', 'EC', 'ECU'],
	EGY: ['Egypt', 'EG', 'EGY'],
	SLV: ['El Salvador', 'SV', 'SLV'],
	GNQ: ['Equatorial Guinea', 'GQ', 'GNQ'],
	ERI: ['Eritrea', 'ER', 'ERI'],
	EST: ['Estonia', 'EE', 'EST'],
	ETH: ['Ethiopia', 'ET', 'ETH'],
	FLK: ['Falkland Islands', 'FK', 'FLK'],
	FRO: ['Faroe Islands', 'FO', 'FRO'],
	FJI: ['Fiji', 'FJ', 'FJI'],
	FIN: ['Finland', 'FI', 'FIN'],
	FRA: ['France', 'FR', 'FRA'],
	GUF: ['French Guiana', 'GF', 'GUF'],
	PYF: ['French Polynesia', 'PF', 'PYF'],
	ATF: ['French Southern Territories', 'TF', 'ATF'],
	GAB: ['Gabon', 'GA', 'GAB'],
	GMB: ['Gambia', 'GM', 'GMB'],
	GEO: ['Georgia', 'GE', 'GEO'],
	DEU: ['Germany', 'DE', 'DEU'],
	GHA: ['Ghana', 'GH', 'GHA'],
	GIB: ['Gibraltar', 'GI', 'GIB'],
	GRC: ['Greece', 'GR', 'GRC'],
	GRL: ['Greenland', 'GL', 'GRL'],
	GRD: ['Grenada', 'GD', 'GRD'],
	GLP: ['Guadeloupe', 'GP', 'GLP'],
	GUM: ['Guam', 'GU', 'GUM'],
	GTM: ['Guatemala', 'GT', 'GTM'],
	GGY: ['Guernsey', 'GG', 'GGY'],
	GIN: ['Guinea', 'GN', 'GIN'],
	GNB: ['Guinea-Bissau', 'GW', 'GNB'],
	GUY: ['Guyana', 'GY', 'GUY'],
	HTI: ['Haiti', 'HT', 'HTI'],
	HMD: ['Heard and Mcdonald Islands', 'HM', 'HMD'],
	VAT: ['Vatican City', 'Vatican', 'VA', 'VAT'],
	HND: ['Honduras', 'HN', 'HND'],
	HUN: ['Hungary', 'HU', 'HUN'],
	ISL: ['Iceland', 'IS', 'ISL'],
	IND: ['India', 'IN', 'IND'],
	IDN: ['Indonesia', 'ID', 'IDN'],
	IRN: ['Iran', 'IR', 'IRN'],
	IRQ: ['Iraq', 'IQ', 'IRQ'],
	IRL: ['Ireland', 'IE', 'IRL'],
	IMN: ['Isle of Man', 'IM', 'IMN'],
	ISR: ['Israel', 'IL', 'ISR'],
	ITA: ['Italy', 'IT', 'ITA'],
	JAM: ['Jamaica', 'JM', 'JAM'],
	JPN: ['Japan', 'JP', 'JPN'],
	JEY: ['Jersey', 'JE', 'JEY'],
	JOR: ['Jordan', 'JO', 'JOR'],
	KAZ: ['Kazakhstan', 'KZ', 'KAZ'],
	KEN: ['Kenya', 'KE', 'KEN'],
	KIR: ['Kiribati', 'KI', 'KIR'],
	PRK: ['North Korea', 'N Korea', 'KP', 'PRK'],
	KOR: ['South Korea', 'S Korea', 'Korea', 'KR', 'KOR'],
	XXK: ['Kosovo', 'XK', 'XXK'],
	KWT: ['Kuwait', 'KW', 'KWT'],
	KGZ: ['Kyrgyzstan', 'KG', 'KGZ'],
	LAO: ['Lao PDR', 'Laos', 'Lao', 'LA', 'LAO'],
	LVA: ['Latvia', 'LV', 'LVA'],
	LBN: ['Lebanon', 'LB', 'LBN'],
	LSO: ['Lesotho', 'LS', 'LSO'],
	LBR: ['Liberia', 'LR', 'LBR'],
	LBY: ['Libya', 'LY', 'LBY'],
	LIE: ['Liechtenstein', 'LI', 'LIE'],
	LTU: ['Lithuania', 'LT', 'LTU'],
	LUX: ['Luxembourg', 'LU', 'LUX'],
	MKD: ['Macedonia', 'North Macedonia', 'North_Macedonia', 'MK', 'MKD'],
	MDG: ['Madagascar', 'MG', 'MDG'],
	MWI: ['Malawi', 'MW', 'MWI'],
	MYS: ['Malaysia', 'MY', 'MYS'],
	MDV: ['Maldives', 'MV', 'MDV'],
	MLI: ['Mali', 'ML', 'MLI'],
	MLT: ['Malta', 'MT', 'MLT'],
	MHL: ['Marshall Islands', 'MH', 'MHL'],
	MTQ: ['Martinique', 'MQ', 'MTQ'],
	MRT: ['Mauritania', 'MR', 'MRT'],
	MUS: ['Mauritius', 'MU', 'MUS'],
	MYT: ['Mayotte', 'YT', 'MYT'],
	MEX: ['Mexico', 'MX', 'MEX'],
	FSM: ['Micronesia', 'FM', 'FSM'],
	MDA: ['Moldova', 'MD', 'MDA'],
	MCO: ['Monaco', 'MC', 'MCO'],
	MNG: ['Mongolia', 'MN', 'MNG'],
	MNE: ['Montenegro', 'ME', 'MNE'],
	MSR: ['Montserrat', 'MS', 'MSR'],
	MAR: ['Morocco', 'MA', 'MAR'],
	MOZ: ['Mozambique', 'MZ', 'MOZ'],
	MMR: ['Myanmar', 'Myanmar/Burma', 'Burma/Myanmar', 'Burma', 'MM', 'MMR'],
	NAM: ['Namibia', 'NA', 'NAM'],
	NRU: ['Nauru', 'NR', 'NRU'],
	NPL: ['Nepal', 'NP', 'NPL'],
	NLD: ['Netherlands', 'NL', 'NLD'],
	ANT: ['Netherlands Antilles', 'AN', 'ANT'],
	NCL: ['New Caledonia', 'NC', 'NCL'],
	NZL: ['New Zealand', 'NewZealand', 'NZ', 'NZL'],
	NIC: ['Nicaragua', 'NI', 'NIC'],
	NER: ['Niger', 'NE', 'NER'],
	NGA: ['Nigeria', 'NG', 'NGA'],
	NIU: ['Niue', 'NU', 'NIU'],
	NFK: ['Norfolk Island', 'NF', 'NFK'],
	MNP: ['Northern Mariana Islands', 'MP', 'MNP'],
	NOR: ['Norway', 'NO', 'NOR'],
	OMN: ['Oman', 'OM', 'OMN'],
	PAK: ['Pakistan', 'PK', 'PAK'],
	PLW: ['Palau', 'PW', 'PLW'],
	PSE: ['Palestinian Territory', 'PS', 'PSE'],
	PAN: ['Panama', 'PA', 'PAN'],
	PNG: ['Papua New Guinea', 'PG', 'PNG'],
	PRY: ['Paraguay', 'PY', 'PRY'],
	PER: ['Peru', 'PE', 'PER'],
	PHL: ['Philippines', 'PH', 'PHL'],
	PCN: ['Pitcairn', 'PN', 'PCN'],
	POL: ['Poland', 'PL', 'POL'],
	PRT: ['Portugal', 'PT', 'PRT'],
	PRI: ['Puerto Rico', 'PR', 'PRI'],
	QAT: ['Qatar', 'QA', 'QAT'],
	REU: ['Réunion', 'RE', 'REU'],
	ROU: ['Romania', 'RO', 'ROU'],
	RUS: ['Russian Federation', 'Russia', 'RU', 'RUS'],
	RWA: ['Rwanda', 'RW', 'RWA'],
	BLM: ['Saint Barthélemy', 'BL', 'BLM'],
	SHN: ['Saint Helena', 'SH', 'SHN'],
	KNA: ['Saint Kitts and Nevis', 'KN', 'KNA'],
	LCA: ['Saint Lucia', 'LC', 'LCA'],
	MAF: ['Saint-Martin', 'MF', 'MAF'],
	SPM: ['Saint Pierre and Miquelon', 'PM', 'SPM'],
	VCT: ['Saint Vincent and Grenadines', 'VC', 'VCT'],
	WSM: ['Samoa', 'WS', 'WSM'],
	SMR: ['San Marino', 'SM', 'SMR'],
	STP: ['Sao Tome and Principe', 'ST', 'STP'],
	SAU: ['Saudi Arabia', 'SA', 'SAU'],
	SEN: ['Senegal', 'SN', 'SEN'],
	SRB: ['Serbia', 'RS', 'SRB'],
	SYC: ['Seychelles', 'SC', 'SYC'],
	SLE: ['Sierra Leone', 'SL', 'SLE'],
	SGP: ['Singapore', 'SG', 'SGP'],
	SVK: ['Slovakia', 'SK', 'SVK'],
	SVN: ['Slovenia', 'SI', 'SVN'],
	SLB: ['Solomon Islands', 'SB', 'SLB'],
	SOM: ['Somalia', 'SO', 'SOM'],
	ZAF: ['South Africa', 'ZA', 'ZAF'],
	SGS: ['South Georgia and the South Sandwich Islands', 'GS', 'SGS'],
	SSD: ['South Sudan', 'SS', 'SSD'],
	ESP: ['Spain', 'ES', 'ESP'],
	LKA: ['Sri Lanka', 'LK', 'LKA'],
	SDN: ['Sudan', 'SD', 'SDN'],
	SUR: ['Suriname', 'SR', 'SUR'],
	SJM: ['Svalbard and Jan Mayen Islands', 'SJ', 'SJM'],
	SWZ: ['Swaziland', 'SZ', 'SWZ'],
	SWE: ['Sweden', 'SE', 'SWE'],
	CHE: ['Switzerland', 'CH', 'CHE'],
	SYR: ['Syrian Arab Republic', 'SY', 'SYR'],
	TWN: ['Taiwan', 'TW', 'TWN'],
	TJK: ['Tajikistan', 'TJ', 'TJK'],
	TZA: ['Tanzania', 'TZ', 'TZA'],
	THA: ['Thailand', 'TH', 'THA'],
	TLS: ['Timor-Leste', 'TL', 'TLS'],
	TGO: ['Togo', 'TG', 'TGO'],
	TKL: ['Tokelau', 'TK', 'TKL'],
	TON: ['Tonga', 'TO', 'TON'],
	TTO: ['Trinidad and Tobago', 'TT', 'TTO'],
	TUN: ['Tunisia', 'TN', 'TUN'],
	TUR: ['Turkey', 'TR', 'TUR'],
	TKM: ['Turkmenistan', 'TM', 'TKM'],
	TCA: ['Turks and Caicos Islands', 'TC', 'TCA'],
	TUV: ['Tuvalu', 'TV', 'TUV'],
	UGA: ['Uganda', 'UG', 'UGA'],
	UKR: ['Ukraine', 'UA', 'UKR'],
	ARE: ['United Arab Emirates', 'AE', 'ARE'],
	GBR: [
		'Great Britain',
		'GB',
		'United Kingdom',
		'UK',
		'England',
		'Scotland',
		'Wales',
		'Northern Ireland',
		'NI',
		'GBR',
	],
	USA: ['United States of America', 'United States', 'US', 'USA'],
	UMI: ['US Minor Outlying Islands', 'UM', 'UMI'],
	URY: ['Uruguay', 'UY', 'URY'],
	UZB: ['Uzbekistan', 'UZ', 'UZB'],
	VUT: ['Vanuatu', 'VU', 'VUT'],
	VEN: ['Venezuela', 'VE'],
	VNM: ['Viet Nam', 'VietNam', 'Vietnam', 'VN', 'VNM'],
	VIR: ['Virgin Islands', 'VI', 'VIR'],
	WLF: ['Wallis and Futuna Islands', 'WF', 'WLF'],
	ESH: ['Western Sahara', 'EH', 'ESH'],
	YEM: ['Yemen', 'YE', 'YEM'],
	ZMB: ['Zambia', 'ZM', 'ZMB'],
	ZWE: ['Zimbabwe', 'ZW', 'ZWE'],
}
