import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import AdaptationsPaginationArrow from './AdaptationsPaginationArrow'

function ProjectAdaptationsPagination({
	onSelect,
	adaptations,
	adaptationConfiguration,
}) {
	const buildDisplayText = (text) => {
		return text === 'exposure_sensitivity'
			? 'Asset Exposure & Sensitivity'
			: text === 'indirect_measures'
				? 'Regional Adaptations'
				: 'Direct Asset Adaptations'
	}
	const displayText = buildDisplayText(adaptations.display)
	return (
		<div className='adaptations_pagination_wrapper'>
			<div className='adaptation-pagination'>
				<div className='adaptation-pagination-left'>
					<AdaptationsPaginationArrow
						locatorId={'adaptation-paginate-left'}
						onArrowSelect={(isActive) => onSelect(isActive)}
						dir={'left'}
						fa_type={'solid'}
						display={adaptationConfiguration.displayPrevious}
					/>
				</div>
				<div className='adaptation-pagination-title'>{displayText}</div>
				<div className='adaptation-pagination-right'>
					<AdaptationsPaginationArrow
						locatorId={'adaptation-paginate-right'}
						onArrowSelect={(isActive) => onSelect(isActive)}
						dir={'right'}
						fa_type={'regular'}
						display={adaptationConfiguration.displayNext}
					/>
				</div>
			</div>
		</div>
	)
}

ProjectAdaptationsPagination.propTypes = {
	adaptationsType: PropTypes.string,
}

export default ProjectAdaptationsPagination
