import {
	ADD_ASSETS,
	CLEAR_ASSETS_SEARCH,
	CLEAR_PDF_EXPORT_DATA,
	ON_ASSET_DELETE,
	SELECT_ALL_ASSETS,
	SELECT_ASSET,
	SET_ASSETS_LOADING,
	SET_CSV_EXPORT_PROGRESS,
	SET_CSV_EXPORT_URL,
	SET_PDF_EXPORT_DATA,
	SET_PDF_EXPORT_LOADING_DATA,
	STOP_CSV_EXPORT_POLL,
	RESET_ASSETS,
} from '../actions/assets'

export const INITIAL_ASSETS_PAGINATION = {
	offset: 0,
	limit: 20,
}

const DEFAULT_STATE = {
	data: undefined,
	pagination: INITIAL_ASSETS_PAGINATION,
	searchValue: undefined,
	loading: false,
	pdfExportData: {
		loading: false,
		percent: 0,
		url: undefined,
		loadingAdapt: false,
		percentAdapt: 0,
		adaptUrl: undefined,
	},
	paginationLoading: false,
	error: undefined,
	csvExportData: {
		loading: false,
		progressInPercentage: 0,
		url: undefined,
	},
}

export default function assets(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SET_ASSETS_LOADING:
			return {
				...state,
				loading: action.loading,
			}
		case ADD_ASSETS:
			return {
				...state,
				data: action.assets,
				pagination: { ...state.pagination, ...action.pagination },
				loading: false,
				paginationLoading: false,
				searchValue: action.searchValue,
			}
		case CLEAR_ASSETS_SEARCH:
			return { ...state, searchValue: undefined }
		case ON_ASSET_DELETE:
			return {
				...state,
				data: state.data.filter(
					(asset) => !action.assetsIds.includes(asset.id)
				),
			}
		case SELECT_ASSET:
			return {
				...state,
				data: state.data.map((asset) => {
					if (asset.id === action.assetId) {
						return { ...asset, isSelected: action.isSelected }
					}

					return asset
				}),
			}
		case SELECT_ALL_ASSETS:
			return {
				...state,
				data: state.data.map((asset) => {
					return { ...asset, isSelected: action.isSelected }
				}),
			}
		// CSV export actions.
		case SET_CSV_EXPORT_PROGRESS:
			return {
				...state,
				csvExportData: {
					...state.csvExportData,
					loading: action.loading,
					progressInPercentage: Math.max(
						state.csvExportData?.progressInPercentage || 0,
						action.csvExportProgress
					),
				},
			}
		case SET_CSV_EXPORT_URL:
			return {
				...state,
				csvExportData: {
					...state.csvExportData,
					url: action.csvExportUrl,
					loading: false,
					progressInPercentage: 0,
				},
			}
		case STOP_CSV_EXPORT_POLL:
			return {
				...state,
				csvExportData: {
					...state.csvExportData,
					url: undefined,
					loading: false,
					progressInPercentage: 0,
				},
			}
		// PDF export actions.
		case SET_PDF_EXPORT_LOADING_DATA:
			return {
				...state,
				pdfExportData: {
					...state.pdfExportData,
					...action.pdfExportLoadingData,
				},
			}
		case SET_PDF_EXPORT_DATA:
			return {
				...state,
				pdfExportData: {
					...state.pdfExportData,
					...(action.pdfExportData.url !== undefined && {
						url: action.pdfExportData.url,
					}),
					...(action.pdfExportData.adaptUrl !== undefined && {
						adaptUrl: action.pdfExportData.adaptUrl,
					}),
				},
			}
		case CLEAR_PDF_EXPORT_DATA:
			return {
				...state,
				pdfExportData: {
					loading: false,
					loadingAdapt: false,
					percent: 0,
					percentAdapt: 0,
					url: undefined,
					adaptUrl: undefined,
				},
			}
		case RESET_ASSETS:
			return DEFAULT_STATE
		default:
			return state
	}
}
