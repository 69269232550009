import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { get, isUndefined } from 'lodash'
import classnames from 'classnames'
import { getVolumePercentage, numberWithCommas } from 'src/utils'
import { usePrevious } from 'src/customHooks'
import {
	buildStyles,
	CircularProgressbarWithChildren,
} from 'react-circular-progressbar'

export const Volumes = ({
	projectSummary,
	isMainSideBar,
	isCompareEnabled,
	onAssetsScoreSelect,
}) => {
	const location = useLocation()
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
	const [activeCircle, setActiveCircle] = useState(queryParams.assets_score_key)
	const prevActiveCircle = usePrevious(activeCircle)

	useEffect(() => {
		if (prevActiveCircle && !queryParams.assets_score_key) {
			setActiveCircle(undefined)
		}
	}, [prevActiveCircle, queryParams])

	const getDataByRiskCategory = (riskCategory) => {
		return get(projectSummary, 'risk_category_distribution', []).find(
			(it) => it.risk_category === riskCategory
		)
	}

	const circularProgressBars = [
		{
			value: getVolumePercentage(
				get(projectSummary, 'num_assets_analyzed', 0),
				getDataByRiskCategory('LOW')?.num_assets || 0
			),
			label: 'Low risk',
			riskType: 'low',
			title: getDataByRiskCategory('LOW')?.num_assets || 0,
			isActive:
				activeCircle === 'low' || queryParams.assets_score_key === 'low',
		},
		{
			value: getVolumePercentage(
				get(projectSummary, 'num_assets_analyzed', 0),
				getDataByRiskCategory('MEDIUM')?.num_assets || 0
			),
			label: 'Medium risk',
			riskType: 'medium',
			title: getDataByRiskCategory('MEDIUM')?.num_assets || 0,
			isActive:
				activeCircle === 'medium' || queryParams.assets_score_key === 'medium',
		},
		{
			value: getVolumePercentage(
				get(projectSummary, 'num_assets_analyzed', 0),
				getDataByRiskCategory('HIGH')?.num_assets || 0
			),
			label: 'High risk',
			riskType: 'high',
			title: getDataByRiskCategory('HIGH')?.num_assets || 0,
			isActive:
				activeCircle === 'high' || queryParams.assets_score_key === 'high',
		},
	]

	const getProgressBarLineColor = (riskType) => {
		if (riskType === 'low') {
			return '#a7a7a7'
		}
		if (riskType === 'medium') {
			return '#ef8082'
		}
		return '#c30000'
	}

	const getFontSizeByTextLength = (text) => {
		if (!text || text.length < 4) {
			return '2.8rem'
		}

		if (text.length < 6) {
			return '2.4rem'
		}

		return '2rem'
	}

	const buildProgressBarStyles = (riskType) => {
		return buildStyles({
			pathColor: getProgressBarLineColor(riskType),
			trailColor: '#a7a7a7',
		})
	}
	const risk_circles_locators = [
		'element-low-risk-circle',
		'element-med-risk-circle',
		'element-high-risk-circle',
	]
	return (
		<>
			{circularProgressBars.map((circularProgressBar, index) => {
				const title =
					typeof circularProgressBar.title === 'number'
						? numberWithCommas(circularProgressBar.title)
						: circularProgressBar.title

				const isInactive =
					!circularProgressBar.isActive &&
					(queryParams.assets_score_key || activeCircle)

				return (
					<div
						data-locator-id={risk_circles_locators[index]}
						className={classnames(
							'col-4 py-3 project-summary_content__circular-progressbar-wr',
							isInactive &&
								'project-summary_content__circular-progressbar-wr--inactive'
						)}
						key={index}
					>
						<CircularProgressbarWithChildren
							value={
								isUndefined(circularProgressBar.value) ||
								isNaN(circularProgressBar.value)
									? 0
									: circularProgressBar.value
							}
							strokeWidth={5}
							styles={buildProgressBarStyles(circularProgressBar.riskType)}
						>
							<div
								className='project-summary_content__circular-progressbar-content'
								onClick={(e) => {
									e.stopPropagation()
									isMainSideBar &&
										!isCompareEnabled &&
										onAssetsScoreSelect(circularProgressBar)
								}}
								onMouseEnter={() =>
									isMainSideBar &&
									!isCompareEnabled &&
									setActiveCircle(circularProgressBar.riskType)
								}
								onMouseLeave={() =>
									isMainSideBar &&
									!isCompareEnabled &&
									setActiveCircle(undefined)
								}
							>
								<h4
									style={{
										color: getProgressBarLineColor(
											circularProgressBar.riskType
										),
										fontSize: getFontSizeByTextLength(title),
									}}
								>
									{title}
								</h4>
								<span>{circularProgressBar.label}</span>
							</div>
						</CircularProgressbarWithChildren>
					</div>
				)
			})}
		</>
	)
}

Volumes.propTypes = {
	projectSummary: PropTypes.object,
	isMainSideBar: PropTypes.bool,
	isCompareEnabled: PropTypes.bool,
	onAssetsScoreSelect: PropTypes.func,
}
