export const AEROWAY_LINE = 'aeroway-line'

// Assets
export const ASSET_MARKERS_SOURCE_ID = 'asset-markers-source'
export const ASSET_MARKERS_CLUSTERS_ID = 'asset-markers-clusters-id'
export const ASSET_MARKERS_CLUSTER_COUNT_ID = 'cluster-count'
export const ASSET_MARKERS_SINGLE_ASSET_ID = 'single-asset-marker'

export const ASSET_MARKERS_FILL_COLOR = '#f7cd41'

// Buildings
export const BUILDINGS_SOURCE_ID = '3d-building-source'

// Water areas layer
export const WATER_LAYER_ID = 'water-areas-fill'
export const WATER_LAYER_SOURCE_ID = 'water'

export const MAP_STYLE = 'mapbox://styles/mapbox/light-v10'

export const THROTTLE_TIME = 500
export const DEBOUNCE_TIME = 300

export const ASSET_MARKER_ZOOM_BREAKPOINT = 14

export const MAP_LAYER_ID_SEPARATOR = '-'
export const MAP_LAYER_ID_NOT_AVAILABLE = 'NA'

export const LAYER_MAX_BOUNDS = {
	// [HAZARD_TYPES.subsidence.value]: [
	// 	[-180, -90],
	// 	[32, 90],
	// ],
	// [HAZARD_TYPES.wildfire.value]: [
	// 	[-180, -90],
	// 	[35.5963676568693722, 90],
	// ],
}

export const BUFFERING_LAYER_DEFAULT_COLOR = '#F2F2F0'

export const COUNTRY_CODE_MAP_MASKS = [
	{ country_code: 'AFG', mask_country: 1 },
	{ country_code: 'DZA', mask_country: 1 },
	{ country_code: 'ASM', mask_country: 1 },
	{ country_code: 'AGO', mask_country: 1 },
	{ country_code: 'AIA', mask_country: 1 },
	{ country_code: 'ATA', mask_country: 1 },
	{ country_code: 'ATG', mask_country: 1 },
	{ country_code: 'ARG', mask_country: 1 },
	{ country_code: 'ARM', mask_country: 1 },
	{ country_code: 'ABW', mask_country: 1 },
	{ country_code: 'AZE', mask_country: 1 },
	{ country_code: 'BHS', mask_country: 1 },
	{ country_code: 'BHR', mask_country: 1 },
	{ country_code: 'BRB', mask_country: 1 },
	{ country_code: 'BLZ', mask_country: 1 },
	{ country_code: 'BEN', mask_country: 1 },
	{ country_code: 'BMU', mask_country: 1 },
	{ country_code: 'BOL', mask_country: 1 },
	{ country_code: 'BES', mask_country: 1 },
	{ country_code: 'BWA', mask_country: 1 },
	{ country_code: 'BVT', mask_country: 1 },
	{ country_code: 'BRA', mask_country: 1 },
	{ country_code: 'IOT', mask_country: 1 },
	{ country_code: 'BFA', mask_country: 1 },
	{ country_code: 'BDI', mask_country: 1 },
	{ country_code: 'CPV', mask_country: 1 },
	{ country_code: 'CMR', mask_country: 1 },
	{ country_code: 'CYM', mask_country: 1 },
	{ country_code: 'CAF', mask_country: 1 },
	{ country_code: 'TCD', mask_country: 1 },
	{ country_code: 'CHL', mask_country: 1 },
	{ country_code: 'CXR', mask_country: 1 },
	{ country_code: 'CCK', mask_country: 1 },
	{ country_code: 'COL', mask_country: 1 },
	{ country_code: 'COM', mask_country: 1 },
	{ country_code: 'COD', mask_country: 1 },
	{ country_code: 'COG', mask_country: 1 },
	{ country_code: 'COK', mask_country: 1 },
	{ country_code: 'CRI', mask_country: 1 },
	{ country_code: 'CUB', mask_country: 1 },
	{ country_code: 'CUW', mask_country: 1 },
	{ country_code: 'CYP', mask_country: 1 },
	{ country_code: 'CIV', mask_country: 1 },
	{ country_code: 'DJI', mask_country: 1 },
	{ country_code: 'DMA', mask_country: 1 },
	{ country_code: 'DOM', mask_country: 1 },
	{ country_code: 'ECU', mask_country: 1 },
	{ country_code: 'EGY', mask_country: 1 },
	{ country_code: 'SLV', mask_country: 1 },
	{ country_code: 'GNQ', mask_country: 1 },
	{ country_code: 'ERI', mask_country: 1 },
	{ country_code: 'SWZ', mask_country: 1 },
	{ country_code: 'ETH', mask_country: 1 },
	{ country_code: 'FLK', mask_country: 1 },
	{ country_code: 'FJI', mask_country: 1 },
	{ country_code: 'GUF', mask_country: 1 },
	{ country_code: 'PYF', mask_country: 1 },
	{ country_code: 'ATF', mask_country: 1 },
	{ country_code: 'GAB', mask_country: 1 },
	{ country_code: 'GMB', mask_country: 1 },
	{ country_code: 'GEO', mask_country: 1 },
	{ country_code: 'GHA', mask_country: 1 },
	{ country_code: 'GIB', mask_country: 1 },
	{ country_code: 'GRL', mask_country: 1 },
	{ country_code: 'GRD', mask_country: 1 },
	{ country_code: 'GLP', mask_country: 1 },
	{ country_code: 'GUM', mask_country: 1 },
	{ country_code: 'GTM', mask_country: 1 },
	{ country_code: 'GIN', mask_country: 1 },
	{ country_code: 'GNB', mask_country: 1 },
	{ country_code: 'GUY', mask_country: 1 },
	{ country_code: 'HTI', mask_country: 1 },
	{ country_code: 'HMD', mask_country: 1 },
	{ country_code: 'HND', mask_country: 1 },
	{ country_code: 'IRN', mask_country: 1 },
	{ country_code: 'IRQ', mask_country: 1 },
	{ country_code: 'ISR', mask_country: 1 },
	{ country_code: 'JAM', mask_country: 1 },
	{ country_code: 'JOR', mask_country: 1 },
	{ country_code: 'KAZ', mask_country: 1 },
	{ country_code: 'KEN', mask_country: 1 },
	{ country_code: 'KIR', mask_country: 1 },
	{ country_code: 'PRK', mask_country: 1 },
	{ country_code: 'KWT', mask_country: 1 },
	{ country_code: 'KGZ', mask_country: 1 },
	{ country_code: 'LBN', mask_country: 1 },
	{ country_code: 'LSO', mask_country: 1 },
	{ country_code: 'LBR', mask_country: 1 },
	{ country_code: 'LBY', mask_country: 1 },
	{ country_code: 'MAC', mask_country: 1 },
	{ country_code: 'MDG', mask_country: 1 },
	{ country_code: 'MWI', mask_country: 1 },
	{ country_code: 'MDV', mask_country: 1 },
	{ country_code: 'MLI', mask_country: 1 },
	{ country_code: 'MHL', mask_country: 1 },
	{ country_code: 'MTQ', mask_country: 1 },
	{ country_code: 'MRT', mask_country: 1 },
	{ country_code: 'MUS', mask_country: 1 },
	{ country_code: 'MYT', mask_country: 1 },
	{ country_code: 'MEX', mask_country: 1 },
	{ country_code: 'FSM', mask_country: 1 },
	{ country_code: 'MSR', mask_country: 1 },
	{ country_code: 'MAR', mask_country: 1 },
	{ country_code: 'MOZ', mask_country: 1 },
	{ country_code: 'NAM', mask_country: 1 },
	{ country_code: 'NRU', mask_country: 1 },
	{ country_code: 'NCL', mask_country: 1 },
	{ country_code: 'NIC', mask_country: 1 },
	{ country_code: 'NER', mask_country: 1 },
	{ country_code: 'NGA', mask_country: 1 },
	{ country_code: 'NIU', mask_country: 1 },
	{ country_code: 'NFK', mask_country: 1 },
	{ country_code: 'MNP', mask_country: 1 },
	{ country_code: 'OMN', mask_country: 1 },
	{ country_code: 'PLW', mask_country: 1 },
	{ country_code: 'PSE', mask_country: 1 },
	{ country_code: 'PAN', mask_country: 1 },
	{ country_code: 'PRY', mask_country: 1 },
	{ country_code: 'PER', mask_country: 1 },
	{ country_code: 'PCN', mask_country: 1 },
	{ country_code: 'QAT', mask_country: 1 },
	{ country_code: 'RUS', mask_country: 1 },
	{ country_code: 'RWA', mask_country: 1 },
	{ country_code: 'REU', mask_country: 1 },
	{ country_code: 'BLM', mask_country: 1 },
	{ country_code: 'SHN', mask_country: 1 },
	{ country_code: 'KNA', mask_country: 1 },
	{ country_code: 'LCA', mask_country: 1 },
	{ country_code: 'MAF', mask_country: 1 },
	{ country_code: 'SPM', mask_country: 1 },
	{ country_code: 'VCT', mask_country: 1 },
	{ country_code: 'WSM', mask_country: 1 },
	{ country_code: 'STP', mask_country: 1 },
	{ country_code: 'SAU', mask_country: 1 },
	{ country_code: 'SEN', mask_country: 1 },
	{ country_code: 'SYC', mask_country: 1 },
	{ country_code: 'SLE', mask_country: 1 },
	{ country_code: 'SXM', mask_country: 1 },
	{ country_code: 'SLB', mask_country: 1 },
	{ country_code: 'SOM', mask_country: 1 },
	{ country_code: 'ZAF', mask_country: 1 },
	{ country_code: 'SGS', mask_country: 1 },
	{ country_code: 'SSD', mask_country: 1 },
	{ country_code: 'SDN', mask_country: 1 },
	{ country_code: 'SUR', mask_country: 1 },
	{ country_code: 'SJM', mask_country: 1 },
	{ country_code: 'SYR', mask_country: 1 },
	{ country_code: 'TJK', mask_country: 1 },
	{ country_code: 'TZA', mask_country: 1 },
	{ country_code: 'TLS', mask_country: 1 },
	{ country_code: 'TGO', mask_country: 1 },
	{ country_code: 'TKL', mask_country: 1 },
	{ country_code: 'TON', mask_country: 1 },
	{ country_code: 'TTO', mask_country: 1 },
	{ country_code: 'TUN', mask_country: 1 },
	{ country_code: 'TUR', mask_country: 1 },
	{ country_code: 'TKM', mask_country: 1 },
	{ country_code: 'TCA', mask_country: 1 },
	{ country_code: 'TUV', mask_country: 1 },
	{ country_code: 'UGA', mask_country: 1 },
	{ country_code: 'UKR', mask_country: 1 },
	{ country_code: 'ARE', mask_country: 1 },
	{ country_code: 'UMI', mask_country: 1 },
	{ country_code: 'URY', mask_country: 1 },
	{ country_code: 'UZB', mask_country: 1 },
	{ country_code: 'VUT', mask_country: 1 },
	{ country_code: 'VEN', mask_country: 1 },
	{ country_code: 'VGB', mask_country: 1 },
	{ country_code: 'VIR', mask_country: 1 },
	{ country_code: 'WLF', mask_country: 1 },
	{ country_code: 'ESH', mask_country: 1 },
	{ country_code: 'YEM', mask_country: 1 },
	{ country_code: 'ZMB', mask_country: 1 },
	{ country_code: 'ZWE', mask_country: 1 },
	{ country_code: 'ALA', mask_country: 1 },
]
