import { LENGTH_UNIT } from '../constants/units'

export const getUnitOfMeasureAreaCopy = (unit) => {
	if (unit === LENGTH_UNIT.meter) {
		return (
			<>
				m<sup>2</sup>
			</>
		)
	} else if (unit === LENGTH_UNIT.feet) {
		return 'sqft'
	}

	return 'sqft'
}

export const getUnitOfMeasurePerimeterCopy = (unit) => {
	if (unit === LENGTH_UNIT.meter) {
		return 'm'
	} else if (unit === LENGTH_UNIT.feet) {
		return 'ft'
	}

	return 'ft'
}
