import { get, isEmpty, map, trim } from 'lodash'
import qs from 'qs'
import api from '../../service/api'
import { INITIAL_ASSETS_PAGINATION } from '../reducers/assets'
import {
	getDefaultEpcTargetYear,
	getDefaultScenario,
	getErrorMessage,
	getInitialRandomProgress,
	Poll,
} from 'src/utils'
import { getProjects } from 'src/redux/selectors/projects'
import { updateProjectItem } from './projects'
import { getAssets } from '../selectors/assets'
import { addToast } from './toasts'
import {
	CSV_EXPORT_EMAIL_NOTIFICATION_TEXT,
	DEFAULT_EXTENT_VALUE,
	PDF_EXPORT_EMAIL_NOTIFICATION_TEXT,
	POLL_STOPPED,
	PROGRESS_STATUS,
} from '../../constants'
import { setProgress } from './progressBars'
import { setCurrentAsset } from './adaptations'
import { getCurrentAsset } from '../selectors/adaptations'
import { history } from '../store'

const CSV_EXPORT_POLL_INTERVAL = 2000
const CSV_EXPORT_POLL_MAX_ATTEMPTS = 500
const csvExportPoll = new Poll(
	CSV_EXPORT_POLL_INTERVAL,
	CSV_EXPORT_POLL_MAX_ATTEMPTS
)

const PDF_EXPORT_POLL_INTERVAL = 5000
const PDF_EXPORT_POLL_MAX_ATTEMPTS = 100
const pdfExportPoll = new Poll(
	PDF_EXPORT_POLL_INTERVAL,
	PDF_EXPORT_POLL_MAX_ATTEMPTS
)

export const SET_ASSETS_LOADING = 'setAssetsLoading'
export function setAssetsLoading(action) {
	return { type: SET_ASSETS_LOADING, loading: action }
}

export const ADD_ASSETS = 'addAssets'
export const addAssets = (assets) => {
	return { type: ADD_ASSETS, ...assets }
}

export const fetchAssetsByProjectId = (
	projectId,
	pagination = {},
	isNewProject
) => {
	const searchValue = get(pagination, 'search_value')

	return async (dispatch, getState) => {
		dispatch(setAssetsLoading(true))

		const activeSearchValue = searchValue || getState().assets.searchValue
		const activeAssets = getState().assets.data

		try {
			const paginationOptions = isEmpty(pagination)
				? qs.stringify(INITIAL_ASSETS_PAGINATION)
				: qs.stringify({ ...pagination, search_value: activeSearchValue })

			const locationSearch = history.location.search
			const queryParams = qs.parse(locationSearch, {
				ignoreQueryPrefix: true,
			})

			const filterOptionsQs = qs.stringify({
				scenario: getDefaultScenario(queryParams.scenario).scenario,
				year: queryParams.year,
				defended: queryParams.defended === 'defended',
				one_in_x: DEFAULT_EXTENT_VALUE,
				action: getDefaultScenario(queryParams.scenario).action,
			})

			const activeLink = `/assets?project_id=${projectId}&${paginationOptions}&${filterOptionsQs}`

			const data = await api.get(activeLink)

			dispatch(
				addAssets({
					assets: data.result,
					pagination: data.pagination,
					searchValue: activeSearchValue,
				})
			)

			const currentAssetData = getCurrentAsset(getState())

			dispatch(
				setCurrentAsset({
					asset:
						isNewProject || !activeAssets ? data.result[0] : currentAssetData,
				})
			)
			dispatch(setAssetsLoading(false))
		} catch (error) {
			dispatch(setAssetsLoading(false))
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Assets could not be loaded'),
					type: 'error',
				})
			)
		}
	}
}

export const CLEAR_ASSETS_SEARCH = 'clearAssetsSearch'
export function clearAssetsSearch() {
	return (dispatch) => {
		dispatch({ type: CLEAR_ASSETS_SEARCH })
	}
}

export const UPDATE_ASSET_ITEM = 'updateAssetItem'
export function updateAssetItem(asset) {
	return { type: UPDATE_ASSET_ITEM, asset }
}

export const ON_ASSET_DELETE = 'onAssetDelete'
export function onAssetDelete(assetsIds) {
	return { type: ON_ASSET_DELETE, assetsIds }
}

export const ADD_ASSETS_TO_PROJECT = `addAssetsToProject`
export function addAssetsToProject(
	assets,
	source,
	batchId,
	allDataLength,
	activeProjectId
) {
	if (batchId) {
		return async (dispatch, getState) => {
			dispatch(
				addAssetsThroughFlatFile(batchId, allDataLength, activeProjectId)
			)
		}
	} else {
		return async (dispatch, getState) => {
			try {
				const projects = getProjects(getState())

				const project = projects.find(
					(project) => project.id === activeProjectId
				)

				dispatch(
					updateProjectItem({
						...project,
						assets: project.num_assets + 1,
					})
				)

				await api.post('/assets', {
					project_id: activeProjectId,
					data: assets,
					source,
				})
				dispatch(
					fetchAssetsByProjectId(activeProjectId, INITIAL_ASSETS_PAGINATION)
				)
			} catch (error) {
				dispatch(
					addToast({
						body: getErrorMessage(error, 'Error: Could not add assets'),
						type: 'error',
					})
				)
			}
		}
	}
}

export const addAssetsThroughFlatFile = (
	batchId,
	allDataLength,
	activeProjectId
) => {
	return async (dispatch, getState) => {
		try {
			const projects = getProjects(getState())

			const project = projects.find((project) => project.id === activeProjectId)

			let progress = undefined
			dispatch(
				setProgress({ progress: 1, status: PROGRESS_STATUS.IN_PROGRESS })
			)

			const { id: jobId } = await api.post('/import-assets', {
				project_id: activeProjectId,
				batch_id: batchId,
			})

			progress = await api.get(`/import-assets/${jobId}`)

			dispatch(setProgress(progress))

			const fixAssetsInterval = setInterval(async () => {
				progress = await api.get(`/import-assets/${jobId}`)
				dispatch(setProgress(progress))
				if (progress && progress.status === PROGRESS_STATUS.COMPLETED) {
					dispatch(
						setProgress({ progress: 100, status: PROGRESS_STATUS.IN_PROGRESS })
					)
					clearInterval(fixAssetsInterval)

					setTimeout(() => {
						// Update assets number inside projects list.
						dispatch(
							updateProjectItem({
								...project,
								num_assets: project?.num_assets + allDataLength,
							})
						)
						dispatch(
							fetchAssetsByProjectId(activeProjectId, INITIAL_ASSETS_PAGINATION)
						)
						dispatch(setProgress(null))
					}, 1000)
				}
			}, 2000)
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not add assets'),
					type: 'error',
				})
			)
		}
	}
}

export function deleteAsset(assetsIds = []) {
	return async (dispatch, getState) => {
		try {
			dispatch(setAssetsLoading(true))
			const pairs = assetsIds.map(function (value) {
				return 'asset_id=' + encodeURIComponent(value)
			})
			const query_string = pairs.join('&')

			const locationSearch = history.location.search
			const queryParams = qs.parse(locationSearch, { ignoreQueryPrefix: true })

			const activeProjectId = queryParams.projectId

			const { pagination } = getAssets(getState())

			const projects = getProjects(getState())
			const project = projects.find((project) => project.id === activeProjectId)
			dispatch(onAssetDelete(assetsIds))

			if (project) {
				dispatch(
					updateProjectItem({
						...project,
						assets: project.num_assets - assetsIds.length,
					})
				)
			}

			const fetchPreviousPage = pagination.count === assetsIds.length
			if (fetchPreviousPage) {
				dispatch(setAssetsLoading(true))
			}

			await api.delete(`/assets?${query_string}`)

			const newPagination = {
				...pagination,
				offset: fetchPreviousPage
					? Math.max(0, pagination.offset - pagination.limit)
					: pagination.offset,
			}
			dispatch(fetchAssetsByProjectId(activeProjectId, newPagination))
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not delete asset(s)'),
					type: 'error',
				})
			)
		}
	}
}

export const SELECT_ASSET = 'selectAsset'
export function selectAsset(isSelected, assetId) {
	return { type: SELECT_ASSET, isSelected, assetId }
}

export const SELECT_ALL_ASSETS = 'selectAllAssets'
export function selectAllAssets(isSelected) {
	return { type: SELECT_ALL_ASSETS, isSelected }
}

export const SET_CSV_EXPORT_PROGRESS = 'setCsvExportLoading'
export function setCsvExportProgress(csvExportProgress) {
	return { type: SET_CSV_EXPORT_PROGRESS, ...csvExportProgress }
}

export const SET_CSV_EXPORT_URL = 'setCsvExportUrl'
export function setCsvExportUrl(csvExportUrl) {
	return { type: SET_CSV_EXPORT_URL, csvExportUrl }
}

export function exportAssets(projectId, shouldUserReceiveEmail, location) {
	return async (dispatch, getState) => {
		const locationSearch = location.search
		const queryParams = qs.parse(locationSearch, { ignoreQueryPrefix: true })

		// Set initial loading state.
		dispatch(
			setCsvExportProgress({
				loading: true,
				csvExportProgress: getInitialRandomProgress(),
			})
		)

		const { data } = getState().assets
		const searchValue = getState().assets.searchValue

		const selectedAssets = data.filter((asset) => asset.isSelected)
		const selectedAssetsIds = map(selectedAssets, 'id')
		const selectedScenario = getDefaultScenario(get(queryParams, 'scenario'))

		const params = {
			project_id: projectId.toString(),
			asset_ids: selectedAssetsIds,
			defended: get(queryParams, 'defended') === 'defended',
			extent: DEFAULT_EXTENT_VALUE,
			target_year: parseInt(
				getDefaultEpcTargetYear(get(queryParams, 'epcTargetYear'))
			),
			scenario: selectedScenario.scenario,
			action: selectedScenario.action,
		}

		// Passs search value params to api if exists.
		if (searchValue && trim(searchValue).length > 2) {
			params['search_value'] = searchValue
		}

		try {
			const { export_job_id: jobId } = await api.post('/export-csv', params)

			if (shouldUserReceiveEmail) {
				dispatch(
					addToast({
						body: CSV_EXPORT_EMAIL_NOTIFICATION_TEXT,
						type: 'info',
					})
				)
			}

			csvExportPoll.resetToInitial()

			csvExportPoll
				.initPoll({
					fn: async () => await api.get(`/export-csv/${jobId}`),
					validate: (summary) => {
						// Increase progress on every request call.
						dispatch(
							setCsvExportProgress({
								loading: true,
								csvExportProgress: Math.floor(summary.progress),
							})
						)

						return (
							(summary.status === PROGRESS_STATUS.COMPLETED &&
								get(summary, 'url')) ||
							summary.status === PROGRESS_STATUS.FAILED
						)
					},
				})
				.then((result) => {
					if (result.status === PROGRESS_STATUS.FAILED) {
						dispatch(
							setCsvExportProgress({
								loading: false,
								csvExportProgress: 0,
							})
						)
						dispatch(
							addToast({
								body: 'Error: CSV export failed',
								type: 'error',
							})
						)
						return
					}

					if (result.url) {
						dispatch(
							setCsvExportProgress({
								loading: true,
								csvExportProgress: 100,
							})
						)
						setTimeout(() => {
							dispatch(setCsvExportUrl(result.url))
						}, 100)
					}
				})
				.catch((err) => {
					dispatch(
						setCsvExportProgress({
							loading: false,
							csvExportProgress: 0,
						})
					)
					if (err !== POLL_STOPPED) {
						dispatch(
							addToast({
								body: getErrorMessage(err, 'Error: CSV export failed'),
								type: 'error',
							})
						)
					}
				})
		} catch (error) {
			dispatch(
				setCsvExportProgress({
					loading: false,
					csvExportProgress: 0,
				})
			)
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not export CSV'),
					type: 'error',
				})
			)
		}
	}
}

export const SET_PDF_EXPORT_LOADING_DATA = 'setPdfExportLoadingData'
export function setPdfExportLoadingData(pdfExportLoadingData) {
	return {
		type: SET_PDF_EXPORT_LOADING_DATA,
		pdfExportLoadingData: pdfExportLoadingData,
	}
}

export const SET_PDF_EXPORT_DATA = 'setPdfExportData'
export function setPdfExportData(pdfExportData) {
	return {
		type: SET_PDF_EXPORT_DATA,
		pdfExportData: {
			...pdfExportData,
		},
	}
}

export const CLEAR_PDF_EXPORT_DATA = 'clearPdfExportData'
export function clearPdfExportData() {
	pdfExportPoll.resetToInitial()
	return { type: CLEAR_PDF_EXPORT_DATA }
}

const openGeneratedPdf = (url) => {
	window.open(url, '_blank')
}

export function downloadDataDictionary() {
	return async (dispatch) => {
		try {
			const data = await api.get(`/export-csv/data-dictionary`)
			const url = get(data, 'dictionary_url')
			window.open(url, '_self')
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(
						error,
						'Error: Could not download data dictionary'
					),
					type: 'error',
				})
			)
		}
	}
}
export function exportPdf({
	selectedProjectId: projectId,
	defaultScenario: emission,
	defaultYear: year,
	defaultEpcTargetYear: epcTargetYear,
	defaultTargetEpc: targetEpc,
	defended,
	shouldUserReceiveEmail,
	isAdapt = false,
	selectedAssetId = 0,
}) {
	return async (dispatch) => {
		const params = {
			project_id: encodeURIComponent(projectId),
			scenario: getDefaultScenario(encodeURIComponent(emission)).scenario,
			action: getDefaultScenario(encodeURIComponent(emission)).action,
			year: encodeURIComponent(year),
			target_year: encodeURIComponent(epcTargetYear),
			defended: defended === 'defended',
			one_in_x: DEFAULT_EXTENT_VALUE,
			target_epc: encodeURIComponent(targetEpc),
			is_adapt: isAdapt,
			asset_id: encodeURIComponent(selectedAssetId),
		}

		let percentKey = 'percent'
		let loadingKey = 'loading'
		let urlKey = 'url'

		if (isAdapt) {
			percentKey = 'percentAdapt'
			loadingKey = 'loadingAdapt'
			urlKey = 'adaptUrl'
		}

		dispatch(setPdfExportLoadingData({ [loadingKey]: true, [percentKey]: 0 }))

		try {
			const pdfData = await api.post(`/export-pdf?${qs.stringify(params)}`)

			if (pdfData.status === PROGRESS_STATUS.COMPLETED && pdfData.url) {
				dispatch(setPdfExportData({ [urlKey]: pdfData.url }))

				dispatch(
					setPdfExportLoadingData({ [loadingKey]: false, [percentKey]: 0 })
				)
				openGeneratedPdf(pdfData.url)
				return
			}

			if (shouldUserReceiveEmail) {
				dispatch(
					addToast({
						body: PDF_EXPORT_EMAIL_NOTIFICATION_TEXT,
						type: 'info',
					})
				)
			}

			pdfExportPoll.resetToInitial()
			pdfExportPoll
				.initPoll({
					fn: async () => await api.get(`/export-pdf/${pdfData.id}`),
					validate: (jobData) => {
						// Increase progress on every request call.
						dispatch(
							setPdfExportLoadingData({
								[loadingKey]: true,
								[percentKey]: jobData.progress,
							})
						)

						return (
							(jobData.status === PROGRESS_STATUS.COMPLETED &&
								get(jobData, 'url')) ||
							jobData.status === PROGRESS_STATUS.FAILED
						)
					},
				})
				.then((result) => {
					if (result.status === PROGRESS_STATUS.FAILED) {
						dispatch(
							setPdfExportLoadingData({
								[loadingKey]: false,
								[percentKey]: 0,
							})
						)
						dispatch(
							addToast({
								body: 'Error: PDF export failed',
								type: 'error',
							})
						)
						return
					}

					if (result.url) {
						dispatch(
							setPdfExportLoadingData({
								[loadingKey]: true,
								[percentKey]: 100,
							})
						)
						setTimeout(() => {
							dispatch(setPdfExportData({ [urlKey]: result.url }))

							dispatch(
								setPdfExportLoadingData({
									[loadingKey]: false,
									[percentKey]: 0,
								})
							)
							openGeneratedPdf(result.url)
						}, 100)
					}
				})
				.catch((err) => {
					dispatch(
						setPdfExportLoadingData({
							[loadingKey]: false,
							percent: 0,
						})
					)
					if (err !== POLL_STOPPED) {
						dispatch(
							addToast({
								body: getErrorMessage(err, 'Error: PDF export failed'),
								type: 'error',
							})
						)
					}
				})
		} catch (error) {
			dispatch(
				setPdfExportLoadingData({
					[loadingKey]: false,
					[percentKey]: 0,
				})
			)
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not export PDF'),
					type: 'error',
				})
			)
		}
	}
}

export const STOP_CSV_EXPORT_POLL = 'stopCsvExportPoll'
export function stopCsvExportPoll() {
	csvExportPoll.resetToInitial()
	return { type: STOP_CSV_EXPORT_POLL }
}

export const RESET_ASSETS = 'resetAssets'
export const resetAssets = () => {
	return { type: RESET_ASSETS }
}
