import qs from 'qs'
import { isString, isObject, reduce, get, omit } from 'lodash'
import {
	EXPLORER_ROUTE,
	HAZARD_TYPES,
	HOME_ROUTE,
	PROJECTS_ROUTE,
	ADAPTATIONS_ROUTE,
} from 'src/constants'
import {
	getDefaultScenario,
	getDefaultYear,
	getDefaultTargetEpc,
	getDefaultDefend,
	getDefaultEpcTargetYear,
} from 'src/utils'
import store from 'src/redux/store'
import { getSelectedProject } from 'src/redux/selectors/projects'

const OPTIONAL_PARAM_TEST = new RegExp(':.+[?]')

export const createLink = (linkParam, { params, queryParams } = {}) => {
	if (!linkParam) {
		throw new Error('Please provide link')
	}

	const link = isString(linkParam.link) ? linkParam.link : linkParam

	if (isObject(linkParam)) {
		params = linkParam.params
		queryParams = linkParam.queryParams
	}

	if (!link) {
		throw new Error('Please provide link')
	}

	const qp = qs.stringify(queryParams, { addQueryPrefix: true })

	let constructedLink = reduce(
		params,
		(result, value, key) => {
			return result.replace(new RegExp(`:${key}[?]?`), value || '')
		},
		link
	)

	constructedLink = constructedLink.replace(OPTIONAL_PARAM_TEST, '')
	constructedLink = constructedLink.replace(/\/$/, '')
	constructedLink = constructedLink + qp

	if (constructedLink.indexOf(':') !== -1) {
		throw new Error('Constructed link is missing params')
	}

	return constructedLink
}

export const createSidebarQueryParams = (providedQueryParams = {}) => {
	const defaultProjectId = get(getSelectedProject(store.getState()), 'id')

	// Required
	const hazardType =
		providedQueryParams.hazardType || HAZARD_TYPES.river_flood.value
	const scenario = getDefaultScenario(providedQueryParams.scenario).value
	const year = getDefaultYear(providedQueryParams.year).value
	const targetEpc = getDefaultTargetEpc(providedQueryParams.targetEpc).value
	const epcTargetYear = getDefaultEpcTargetYear(
		providedQueryParams.epcTargetYear
	)
	const defended = getDefaultDefend(providedQueryParams.defended).value
	const projectId = providedQueryParams.projectId || defaultProjectId
	const assets_score_key = providedQueryParams.assets_score_key
	const assets_volume_key = providedQueryParams.assets_volume_key
	const activeTab = providedQueryParams.activeTab

	return {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		hazardType,
		projectId,
		assets_score_key,
		assets_volume_key,
		defended,
		activeTab,
	}
}

export const createExplorerLink = (
	providedQueryParams = {},
	excludeQueryParams = []
) => {
	// Required
	const {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		hazardType,
		projectId,
		assets_score_key,
		assets_volume_key,
		defended,
	} = createSidebarQueryParams(providedQueryParams)

	// Optional
	const assetId = providedQueryParams.assetId
	const assetName = providedQueryParams.assetName
	const goTo = providedQueryParams.goTo

	const queryParamsObject = {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		hazardType,
		projectId,
		assetId,
		assetName,
		goTo,
		assets_score_key,
		assets_volume_key,
		defended,
	}

	const resultQueryParams = omit(queryParamsObject, excludeQueryParams)

	return createLink(EXPLORER_ROUTE, {
		queryParams: resultQueryParams,
	})
}

export const createHomeLink = (providedQueryParams = {}) => {
	const {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		defended,
		hazardType,
		projectId,
	} = createSidebarQueryParams(providedQueryParams)

	const queryParamsObject = {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		hazardType,
		projectId,
		defended,
	}

	return createLink(HOME_ROUTE, {
		queryParams: queryParamsObject,
	})
}

export const createProjectsLink = (providedQueryParams = {}) => {
	const {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		defended,
		hazardType,
		projectId,
	} = createSidebarQueryParams(providedQueryParams)

	const queryParamsObject = {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		hazardType,
		projectId,
		defended,
	}

	return createLink(PROJECTS_ROUTE, {
		queryParams: queryParamsObject,
	})
}

export const createAdaptationsLink = (providedQueryParams = {}) => {
	const {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		defended,
		hazardType,
		projectId,
		activeTab,
	} = createSidebarQueryParams(providedQueryParams)

	const queryParamsObject = {
		scenario,
		year,
		targetEpc,
		epcTargetYear,
		hazardType,
		projectId,
		defended,
		activeTab,
	}

	return createLink(ADAPTATIONS_ROUTE, {
		queryParams: queryParamsObject,
	})
}
