import { START_UI_LOADING, STOP_UI_LOADING } from '../actions/ui'

const DEFAULT_STATE = {
	loading: false,
}

export default function ui(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case START_UI_LOADING:
			return {
				...state,
				loading: true,
			}
		case STOP_UI_LOADING:
			return {
				...state,
				loading: false,
			}
		default:
			return state
	}
}
