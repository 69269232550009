import React from 'react'
import PropTypes from 'prop-types'
import { isPlainObject } from 'lodash'
import { MarkdownHeadline } from '../Headline'
import './styles.scss'

export const MarkdownParagraph = ({ key, children }) => {
	const filteredChildren = children.filter((item) => {
		return typeof item !== 'string' || item.trim().length > 0
	})

	if (filteredChildren.length === 0) {
		return null
	}

	// In case the paragraph contains a single `strong` element
	// we render it as a headline instead of a paragraph. The
	// reason for this is some external openings use strong
	// instead of headline elements for headlines.
	if (
		filteredChildren.length === 1 &&
		isPlainObject(filteredChildren[0]) &&
		filteredChildren[0].type === 'strong'
	) {
		return (
			<MarkdownHeadline key={key}>
				{filteredChildren[0].props.children}
			</MarkdownHeadline>
		)
	}

	// In case we receive a nested paragraph we flatten the
	// hierarchy to avoid descendant errors from React.
	if (
		filteredChildren.length === 1 &&
		isPlainObject(filteredChildren[0]) &&
		typeof filteredChildren[0].type !== 'string' &&
		filteredChildren[0].type.name === 'MarkdownParagraph'
	) {
		return (
			<MarkdownParagraph key={key}>
				{filteredChildren[0].props.children}
			</MarkdownParagraph>
		)
	}

	return (
		<p className='markdown-paragraph' key={key}>
			{filteredChildren}
		</p>
	)
}

MarkdownParagraph.propTypes = {
	children: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
					.isRequired,
				props: PropTypes.shape({
					children: PropTypes.array.isRequired,
				}).isRequired,
				type: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.shape({
						name: PropTypes.string.isRequired,
					}),
				]).isRequired,
			}),
		])
	).isRequired,
	key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
