import { SET_CSV_UPLOAD_PROGRESS } from '../actions/progressBars'

const DEFAULT_STATE = {
	csvUploadIndicator: null,
	// csvUploadIndicator example object:
	// {
	// 	progress: 0, percentage
	// 	step: 'Climate scenarios', - message step
	// },
}

export default function progressBars(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SET_CSV_UPLOAD_PROGRESS:
			return {
				...state,
				csvUploadIndicator: action.csvUploadIndicator
					? {
							...action.csvUploadIndicator,
							progress: Math.max(
								state.csvUploadIndicator?.progress || 0,
								action.csvUploadIndicator.progress
							),
						}
					: null,
			}
		default:
			return state
	}
}
