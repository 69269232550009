import api from '../../service/api'
import qs from 'qs'
import {
	getDefaultScenario,
	getErrorMessage,
	isEpcTargetYearValid,
} from 'src/utils'
import { DEFAULT_EXTENT_VALUE } from '../../constants'

export const SET_PROJECT_SUMMARY_LOADING = `setProjectSummaryLoading`
export function setProjectsSummariesLoading(isLoading) {
	return { type: SET_PROJECT_SUMMARY_LOADING, isLoading }
}

export const SET_PROJECT_SUMMARY_ERROR_MESSAGE = 'setProjectSummaryErrorMessage'
export function setProjectSummaryErrorMessage(errorMessage) {
	return { type: SET_PROJECT_SUMMARY_ERROR_MESSAGE, errorMessage }
}

export const ADD_PROJECT_SUMMARY = 'addProjectSummary'
export function addProjectSummary(data) {
	return { type: ADD_PROJECT_SUMMARY, ...data }
}

export function fetchProjectSummary(
	scenario,
	year,
	epcTargetYear,
	defended,
	project_id
) {
	return async (dispatch) => {
		if (!isEpcTargetYearValid(epcTargetYear)) {
			dispatch(setProjectsSummariesLoading(false))
			return
		}

		dispatch(setProjectsSummariesLoading(true))

		const query_id = qs.stringify({
			project_id: project_id.toString(),
			scenario: getDefaultScenario(scenario).scenario,
			action: getDefaultScenario(scenario).action,
			year: parseInt(year),
			target_year: parseInt(epcTargetYear),
			defended: defended === 'defended',
			one_in_x: DEFAULT_EXTENT_VALUE,
		})

		try {
			const summary = await api.get(`/project-summary?${query_id}`)

			dispatch(
				addProjectSummary({
					summary: summary,
				})
			)
		} catch (error) {
			dispatch(
				setProjectSummaryErrorMessage(
					getErrorMessage(error, 'Error: Could not load project summary')
				)
			)
		}
	}
}
