import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { IdleTimer } from 'src/utils'

const UserTimerContextProvider = ({ onTimerFinish, children }) => {
	const timerRef = useRef(null)
	useEffect(() => {
		timerRef.current = new IdleTimer({
			timeout: 1800, // expire in 30 minutes
			onTimeout: (showSessionExpireModal) => {
				onTimerFinish(showSessionExpireModal)
			},
			onExpired: (showSessionExpireModal) => {
				onTimerFinish(showSessionExpireModal)
			},
		})
		return () => {
			timerRef.current.cleanUp()
		}
	}, [])
	return children
}
UserTimerContextProvider.propTypes = {
	onTimerFinish: PropTypes.func.isRequired,
}
export default UserTimerContextProvider
