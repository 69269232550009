import {
	isProductionEnv,
	isStagingEnv,
	isLocalEnv,
	isScratchEnv,
} from '../utils'
export const DEFENDS = [
	{ value: 'undefended', displayValue: 'Undefended', isActive: true },
	{ value: 'defended', displayValue: 'Defended' },
]

export const SCENARIOS = [
	{
		value: 'early_action_rcp26',
		action: 'early-action',
		scenario: 'rcp26',
		displayValue: 'RCP2.6',
	},
]

// TODO: this is a temporary option remove when have mid rcps in stage and prod
if (isProductionEnv()) {
	SCENARIOS.push(
		{
			value: 'late_action_rcp45',
			action: 'late-action',
			scenario: 'rcp45',
			displayValue: 'RCP4.5',
			disabled: true,
		},
		{
			value: 'late_action_rcp60',
			action: 'late-action',
			scenario: 'rcp60',
			displayValue: 'RCP6.0',
			disabled: true,
		}
	)
}

if (isLocalEnv() || isScratchEnv() || isStagingEnv()) {
	SCENARIOS.push(
		{
			value: 'late_action_rcp45',
			action: 'late-action',
			scenario: 'rcp45',
			displayValue: 'RCP4.5',
		},
		{
			value: 'late_action_rcp60',
			action: 'late-action',
			scenario: 'rcp60',
			displayValue: 'RCP6.0',
		}
	)
}

SCENARIOS.push({
	value: 'no_action_rcp85',
	action: 'no-action',
	scenario: 'rcp85',
	displayValue: 'RCP8.5',
	isActive: true,
})

if (isProductionEnv() || isStagingEnv()) {
	SCENARIOS.push(
		{
			value: 'early_action_ssp126',
			action: 'early-action',
			scenario: 'ssp126',
			displayValue: 'SSP1-2.6',
			disabled: true,
		},
		{
			value: 'late_action_ssp245',
			action: 'late-action',
			scenario: 'ssp245',
			displayValue: 'SSP2-4.5',
			disabled: true,
		},
		{
			value: 'late_action_ssp370',
			action: 'late-action',
			scenario: 'ssp370',
			displayValue: 'SSP3-7.0',
			disabled: true,
		},
		{
			value: 'no_action_ssp585',
			action: 'no-action',
			scenario: 'ssp585',
			displayValue: 'SSP5-8.5',
			disabled: true,
		}
	)
}

if (isLocalEnv() || isScratchEnv()) {
	SCENARIOS.push(
		{
			value: 'early_action_ssp126',
			action: 'early-action',
			scenario: 'ssp126',
			displayValue: 'SSP1-2.6',
		},
		{
			value: 'late_action_ssp245',
			action: 'late-action',
			scenario: 'ssp245',
			displayValue: 'SSP2-4.5',
		},
		{
			value: 'late_action_ssp370',
			action: 'late-action',
			scenario: 'ssp370',
			displayValue: 'SSP3-7.0',
		},
		{
			value: 'no_action_ssp585',
			action: 'no-action',
			scenario: 'ssp585',
			displayValue: 'SSP5-8.5',
		}
	)
}

export const YEARS = [
	{ value: '2020', displayValue: '2020' },
	{ value: '2025', displayValue: '2025' },
	{ value: '2030', displayValue: '2030' },
	{ value: '2035', displayValue: '2035' },
	{ value: '2040', displayValue: '2040' },
	{ value: '2045', displayValue: '2045' },
	{ value: '2050', displayValue: '2050', isActive: true },
	{ value: '2060', displayValue: '2060' },
	{ value: '2070', displayValue: '2070' },
	{ value: '2080', displayValue: '2080' },
	{ value: '2090', displayValue: '2090' },
	{ value: '2100', displayValue: '2100' },
]
export const EPCS = [
	{ value: 'A', displayValue: 'A' },
	{ value: 'B', displayValue: 'B' },
	{ value: 'C', displayValue: 'C', isActive: true },
]

export const DEFAULT_EPC_TARGET_YEAR = 2050
export const MAX_EPC_TARGET_YEAR = 2100

export const SCORE_WEIGHTS = {
	1: 'A',
	2: 'B',
	3: 'C',
	4: 'D',
	5: 'E',
	6: 'F',
}

export const DEFAULT_EXTENT_VALUE = 100
export const DEMO_USER_MAX_PROJECTS_DEFAULT = 3
export const DEMO_USER_MAX_ASSETS_PER_PROJECT_DEFAULT = 5
