import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'src/components/Button'
import api from 'src/service/api'
import { getErrorMessage } from '../../../../utils'

export function DownloadProjects({ openConfirmationModal, addToast }) {
	const [loading, setLoading] = useState(false)
	const handleProjectsDownload = async () => {
		setLoading(true)
		try {
			const projectsCsv = await api.get(`/projects?csv=true`)
			const url = get(projectsCsv, 'result.url')
			openConfirmationModal(url, () => {}, 'Your data is ready')
			setLoading(false)
		} catch (error) {
			setLoading(false)
			addToast({
				body: getErrorMessage(error, 'Error: Could not download projects'),
				type: 'error',
			})
		}
	}
	return (
		<Button
			className='projections-body__download'
			onClick={handleProjectsDownload}
			disabled={loading}
		>
			<FontAwesomeIcon icon={['far', 'download']} />
			{loading ? 'Loading...' : 'Download'}
		</Button>
	)
}

DownloadProjects.propTypes = {
	openConfirmationModal: PropTypes.func.isRequired,
	addToast: PropTypes.func.isRequired,
}
