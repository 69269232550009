import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss'

export const Skeleton = ({ type, width, height, measureUnit, className }) => {
	return (
		<div
			className={classnames(
				'skeleton pulse-fade',
				`skeleton--${type}`,
				className
			)}
			style={{
				width: `${width}${measureUnit}`,
				height: `${height}${measureUnit}`,
				borderRadius: type === 'circle' ? Math.round(width / 2) : '8px',
			}}
		/>
	)
}

Skeleton.defaultProps = {
	type: 'square',
	width: '100%',
	height: '100%',
	measureUnit: 'px',
}

Skeleton.propTypes = {
	// If "circle" type is selected, 'width' and 'height' should be same size.
	type: PropTypes.oneOf(['circle', 'square']).isRequired,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	measureUnit: PropTypes.oneOf(['px', '%', 'rem', 'em']).isRequired,
}
