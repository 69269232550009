import React, { useState } from 'react'
import Input from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { validate } from '../../../../utils'
import { NavLink } from 'reactstrap'
import StatusMessage from '../../../../components/StatusMessage'

const PasswordResetForm = ({ onSubmit, loading, routerProps, formMessage }) => {
	const { history } = routerProps
	const [controls, setControls] = useState({
		email: {
			value: '',
			touched: false,
			validationRules: {
				minLength: 3,
				isEmail: true,
			},
			valid: false,
		},
	})

	const updateState = (field, value) => {
		setControls({
			...controls,
			[field]: {
				...controls[field],
				value,
				touched: true,
				valid: validate(value, controls[field].validationRules),
			},
		})
	}

	const onResetPassword = (e) => {
		e.preventDefault()
		controls.email.valid && onSubmit(controls.email.value)
	}

	const isFormValid = () => {
		return controls.email.valid
	}

	return (
		<form className='form-floating' action=''>
			{formMessage && (
				<StatusMessage type={formMessage.type}>
					{formMessage.message}
				</StatusMessage>
			)}
			<div className='form-floating mb-3'>
				<Input
					type='email'
					label='Email address'
					id='floatingemail'
					error={controls.email.touched && !controls.email.valid}
					placeholder='name@example.com'
					value={controls.email.value}
					onChange={(e) => updateState('email', e.target.value)}
				/>
			</div>
			<div className='col-auto col-sm-12'>
				<div className='d-flex align-items-center mt-4 mb-4'>
					<Button
						className='btn btn-primary mt-0'
						onClick={onResetPassword}
						disabled={loading || !isFormValid()}
					>
						{loading ? 'Loading ...' : 'Reset Password'}
					</Button>
					<NavLink onClick={() => history.push('/login')} className='form-link'>
						Back to login
					</NavLink>
				</div>
			</div>
		</form>
	)
}
export default PasswordResetForm
