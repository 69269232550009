export const theme = {
	global: {
		backgroundColor: '#f8f8fb',
		fontFamily: 'Graphik, Arial, sans-serif',
		textColor: '#545454',
		primaryTextColor: '#545454',
		secondaryTextColor: '#545454',
		successColor: '#2F9B00',
		warningColor: '#ffad29',
	},
	iterator: {
		root: {
			backgroundColor: '#ff0000',
			color: '#c2c3c3',
		},
		overlayColor: 'rgba(0,0,0,0.5)',
	},
	buttons: {
		primary: {
			backgroundColor: '#ff0000',
			color: '#ffffff',
			border: '1px solid #ff0000',
			borderRadius: '2.5em',
			boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
			padding: '1em 1.75em',
			':hover': {
				backgroundColor: '#118CB0',
				color: '#02394b',
			},
		},
		secondary: {
			backgroundColor: '#f8f8fb',
			color: '#545454',
			border: '1px solid #545454',
			borderRadius: '2.5em',
			boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
			padding: '1em 1.75em',
			':hover': {
				backgroundColor: '#118CB0',
				color: '#02394b',
			},
		},
		columnMatchConfirm: {
			backgroundColor: '#2F9B00',
			color: '#ffffff',
			border: 'none',
			borderRadius: '0px',
			boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
			':hover': {
				backgroundColor: '#118CB0',
				color: '#02394b',
			},
		},
	},
}
