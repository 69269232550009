import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

export const SelectLayer = ({
	activeOption,
	options,
	onOptionClick,
	clearFilters,
}) => {
	const arrowIcon = (
		<FontAwesomeIcon
			icon={['far', 'arrow-right']}
			className='map-select-layer__icon'
		/>
	)

	return (
		<div className='map-select-layer'>
			<div
				className={classnames(
					'map-select-layer__option',
					!activeOption && 'map-select-layer__option--active'
				)}
				onClick={() => clearFilters()}
			>
				{arrowIcon}
				{!!activeOption ? 'Clear Risk filter' : 'Select Risk filter'}
			</div>
			<div className='map-select-layer__divider'></div>

			{options.map(({ name, value }) => (
				<div
					className={classnames(
						'map-select-layer__option',
						activeOption === value && 'map-select-layer__option--active'
					)}
					onClick={() => onOptionClick(value)}
					key={value}
					data-locator-id='tab-map-select-layer-option'
				>
					{arrowIcon}
					{name}
				</div>
			))}
		</div>
	)
}

SelectLayer.propTypes = {
	activeOption: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	onOptionClick: PropTypes.func.isRequired,
	clearFilters: PropTypes.func.isRequired,
}
