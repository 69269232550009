import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { removeToast } from '../../redux/actions/toasts'
import { getToasts } from '../../redux/selectors/toasts'
import { Toast } from './index'
import './styles.scss'

const DEFAULT_TIMEOUT = 5000

const _ToastContainer = ({ toasts, removeToast }) => {
	const [isHover, setIsHover] = useState(false)
	const timers = useRef({})

	const onMouseEnter = () => {
		setIsHover(true)
	}

	const onClose = (toast) => () => {
		removeToast(toast)
	}

	const onMouseLeave = () => {
		setIsHover(false)
		toasts.forEach((toast, index) => {
			timers.current[toast.id] = {}
			timers.current[toast.id].timeout = DEFAULT_TIMEOUT + index * 1000
			timers.current[toast.id].timeoutId = setTimeout(() => {
				clearTimeout(timers.current[toast.id].timeoutId)
				delete timers.current[toast.id]
				removeToast(toast)
			}, timers.current[toast.id].timeout)
		})
	}

	useEffect(() => {
		toasts.forEach((toast, index) => {
			if (!timers.current.hasOwnProperty(toast.id) && !isHover) {
				const prevIndex = index - 1
				timers.current[toast.id] = {}

				timers.current[toast.id].timeout =
					toasts[prevIndex] &&
					timers.current[toasts[prevIndex].id] &&
					timers.current[toasts[prevIndex].id].timeout > DEFAULT_TIMEOUT
						? timers.current[toasts[prevIndex].id].timeout + 1000
						: DEFAULT_TIMEOUT

				timers.current[toast.id].timeoutId = setTimeout(() => {
					clearTimeout(timers.current[toast.id].timeoutId)
					delete timers.current[toast.id]
					removeToast(toast)
				}, timers.current[toast.id].timeout)
			} else if (timers.current.hasOwnProperty(toast.id) && isHover) {
				clearTimeout(timers.current[toast.id].timeoutId)
				delete timers.current[toast.id]
			}
		})
	}, [isHover, removeToast, timers, toasts])

	return (
		<div className='toast-container toast-wrapper'>
			{toasts.map((toast) => {
				return (
					<Toast
						key={toast.id}
						body={toast.body}
						type={toast.type}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						onClose={onClose(toast)}
					/>
				)
			})}
		</div>
	)
}

const ToastContainer = connect(
	(state) => ({
		toasts: getToasts(state),
	}),
	{
		removeToast,
	}
)(_ToastContainer)

export default ToastContainer
