import React, { forwardRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Input = forwardRef(
	(
		{
			type,
			id,
			placeholder,
			value,
			required,
			label,
			className,
			labelClassName,
			error,
			onChange,
			autoFocus,
			autoComplete,
			locatorId,
		},
		ref
	) => {
		return (
			<Fragment>
				<input
					type={type}
					className={classNames(className, { error: error })}
					id={id}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					required={required}
					ref={ref}
					autoFocus={autoFocus}
					autoComplete={autoComplete}
					data-locator-id={locatorId}
				/>
				<label
					htmlFor={id}
					className={classNames(labelClassName, { error: error })}
				>
					{label}
				</label>
			</Fragment>
		)
	}
)

Input.defaultProps = {
	required: false,
	className: 'form-control',
	locatorId: 'not-set',
}

Input.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
	className: PropTypes.string,
	labelClassName: PropTypes.string,
	error: PropTypes.bool,
	autoFocus: PropTypes.bool,
	onChange: PropTypes.func,
	autoComplete: PropTypes.string,
	locatorId: PropTypes.string,
}

export default Input
