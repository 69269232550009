import { createFlatFileFieldsConfig } from './fields'
import { theme } from './theme'
import { getAllCountryNames, getCountryNamesByCode } from '../../utils'

export const generateConfig = (
	title,
	maxRecords,
	allowedCountries,
	enableBigBuildings
) => ({
	type: 'Addresses',
	title,
	fields: createFlatFileFieldsConfig(
		getCountryNamesByCode(allowedCountries),
		enableBigBuildings
	),
	theme,
	devMode: false,
	autoDetectHeaders: true,
	allowInvalidSubmit: false,
	disableManualInput: true,
	maxRecords,
	managed: true,
})

const ALL_COUNTRY_NAMES = getAllCountryNames()

export const createFlatfileAnyCountryRegex = () => {
	return `^(${ALL_COUNTRY_NAMES.join('|')})$`
}

export const createFlatfileAllowedCountriesRegex = (countryNames) => {
	return `^(${countryNames.join('|')})$`
}
