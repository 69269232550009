import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUserFeatureFlags } from '../../redux/selectors/user'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

export const PROJECT_SIDEBAR_TABS = {
	ASSETS: 'assets',
	ADAPTATIONS: 'adaptations',
	SUMMARY: 'summary',
}

function _ProjectSidebarTabs({
	activeTab,
	onTabSelect,
	hideTabs,
	userFeatureFlags,
}) {
	const enableAdapt = userFeatureFlags['enable-adapt'] || false

	const TABS = [
		{
			key: PROJECT_SIDEBAR_TABS.SUMMARY,
			label: 'Summary',
			icon: ['far', 'globe'],
			isEnabled: true,
			locatorId: 'tab-summary',
		},
		{
			key: PROJECT_SIDEBAR_TABS.ASSETS,
			label: 'Data',
			icon: ['far', 'list'],
			isEnabled: true,
			locatorId: 'tab-data',
		},
		{
			key: PROJECT_SIDEBAR_TABS.ADAPTATIONS,
			label: 'Adapt',
			icon: ['far', enableAdapt ? 'list' : 'lock'],
			isEnabled: enableAdapt ? true : false,
			locatorId: 'tab-adapt',
		},
	]
	return (
		<div className='project-tabs'>
			{TABS.map(({ key, label, icon, isEnabled, locatorId }) => {
				if (hideTabs && hideTabs.includes(key)) {
					return null
				}

				return (
					<div
						data-locator-id={locatorId}
						key={key}
						id={`sidebar-tab-${key}`}
						className={classnames(
							'project-tabs__tab',
							activeTab === key && 'project-tabs__tab--selected',
							!isEnabled && 'project-tabs__tab--disabled'
						)}
						onClick={(e) => {
							onTabSelect(key, e)
						}}
					>
						<FontAwesomeIcon icon={icon} className='project-tabs__tab-icon' />
						{label}
					</div>
				)
			})}
		</div>
	)
}

_ProjectSidebarTabs.propTypes = {
	onTabSelect: PropTypes.func.isRequired,
	activeTab: PropTypes.oneOf(Object.values(PROJECT_SIDEBAR_TABS)).isRequired,
	hideTabs: PropTypes.arrayOf(Object.values(PROJECT_SIDEBAR_TABS)),
	userFeatureFlags: PropTypes.object.isRequired,
}

export const ProjectSidebarTabs = connect(
	(state) => ({
		userFeatureFlags: getUserFeatureFlags(state),
	}),
	{}
)(_ProjectSidebarTabs)

export default ProjectSidebarTabs
