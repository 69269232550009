import React from 'react'
import Switch from '@mui/joy/Switch'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.scss'

export const CustomSwitch = ({
	className,
	id,
	locatorId,
	color,
	size,
	onChange,
	checked,
}) => {
	return (
		<div id={id} className={classnames('switch', className)}>
			<Switch
				size={size}
				variant='solid'
				data-locator-id={locatorId}
				color={color}
				onChange={onChange}
				checked={checked}
			/>
		</div>
	)
}

CustomSwitch.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	locatorId: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.string,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
}
