import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { PageWrapper } from '../../components/PageWrapper'
import { ProjectSideBar } from '../../containers/ProjectSideBar'
import Projects from '../Projects'
import Adaptation from '../Adaptation'
import Explorer from '../Explorer'
import Home from '../Home'
import { fetchProjects } from '../../redux/actions/projects'
import {
	getProjectsLoading,
	getProjects,
	getProjectsPaginationOptions,
	getProjectsSearchValue,
} from '../../redux/selectors/projects'
import {
	INDEX_ROUTE,
	HOME_ROUTE,
	PROJECTS_ROUTE,
	EXPLORER_ROUTE,
	ADAPTATIONS_ROUTE,
} from '../../constants'
import { fetchExplainers } from '../../redux/actions/explainers'
import { getCurrencies } from '../../redux/selectors/currencies'

function Main({
	pagination,
	projects,
	searchValue,
	projectsLoading,
	fetchProjects,
	fetchExplainers,
}) {
	const isInitialProjectsFetched = useRef(false)
	const isFirstPage = pagination.offset === 0

	useEffect(() => {
		fetchExplainers()
	}, [])

	useEffect(() => {
		if (!projects.length && !isInitialProjectsFetched.current) {
			isInitialProjectsFetched.current = true
			fetchProjects()
		}
	}, [projects, fetchProjects])

	return (
		<PageWrapper
			bodyContent={
				<Switch>
					<Route exact path={INDEX_ROUTE} component={Home} />
					<Route exact path={HOME_ROUTE} component={Home} />
					<Route exact path={PROJECTS_ROUTE} component={Projects} />
					<Route exact path={EXPLORER_ROUTE} component={Explorer} />
					<Route exact path={ADAPTATIONS_ROUTE} component={Adaptation} />
				</Switch>
			}
			sidebarContent={<ProjectSideBar />}
			isSidebarHidden={isFirstPage && projects.length === 0 && !searchValue}
			isContentDisabled={projectsLoading}
		/>
	)
}

Main.propTypes = {
	projectsLoading: PropTypes.bool,
	projects: PropTypes.array,
	pagination: PropTypes.object,
	fetchProjects: PropTypes.func.isRequired,
}

export default connect(
	(state) => ({
		projectsLoading: getProjectsLoading(state),
		projects: getProjects(state),
		pagination: getProjectsPaginationOptions(state),
		searchValue: getProjectsSearchValue(state),
		currencies: getCurrencies(state),
	}),
	{ fetchProjects, fetchExplainers }
)(Main)
