import { get } from 'lodash'

export const getAssets = (state) => state.assets
export const getAssetsListLoading = (state) => state.assets.loading
export const getAssetsSearchValue = (state) => get(state, 'assets.searchValue')
export const getAssetsTotalCount = (state) =>
	get(state, 'assets.pagination.total')
export const getAssetsPdfLoading = (state, isAdapt = false) =>
	get(state, `assets.pdfExportData.${isAdapt ? 'loadingAdapt' : 'loading'}`)
export const getAssetsPdfExportData = (state) => state.assets.pdfExportData
export const getAssetsCsvExportData = (state) => state.assets.csvExportData
