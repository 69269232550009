import { getDefaultScenario, getErrorMessage } from '../../utils'
import api from 'src/service/api'
import { BASE_YEAR } from 'src/constants/baseParameters'

export const SET_BUSINESS_DISRUPTION_LOADING = `setBusinessDisruptionLoading`
export function setBusinessDisruptionLoading(isLoading) {
	return { type: SET_BUSINESS_DISRUPTION_LOADING, isLoading }
}

export const SET_BUSINESS_DISRUPTION = `setBusinessDisruption`
export function setBusinessDisruption(payload) {
	return { type: SET_BUSINESS_DISRUPTION, payload }
}

export const SET_BUSINESS_DISRUPTION_ERROR_MESSAGE = `setBusinessDisruptionErrorMessage`
export function setBusinessDisruptionErrorMessage(errorMessage) {
	return { type: SET_BUSINESS_DISRUPTION_LOADING, errorMessage }
}

export function fetchBusinessDisruption(queryParams) {
	return async (dispatch, getState) => {
		dispatch(setBusinessDisruptionLoading(true))
		const activeAsset = getState().adaptations.current_asset.id
		const requestData = {
			asset_id: activeAsset,
			options: {
				scenario: getDefaultScenario(queryParams.scenario).scenario,
				base_year: BASE_YEAR,
				target_year: parseInt(queryParams.year),
				defended: queryParams.defended === 'defended',
			},
		}

		const makeRequest = async (retry = false) => {
			try {
				const data = await api.post(
					`/business-disruption/${activeAsset}`,
					requestData
				)

				if (data === null && !retry) {
					// TODO: fix properly in BE next sprint but for now retrying the request once if data is null
					// which happens first time calling BD on new asset
					await makeRequest(true)
					return
				}

				dispatch(
					setBusinessDisruption({
						projectId: queryParams.projectId,
						assetId: activeAsset,
						scenario: getDefaultScenario(queryParams.scenario).scenario,
						targetYear: parseInt(queryParams.year),
						defended: queryParams.defended === 'defended',
						payload: data?.business_disruption_results,
					})
				)
				dispatch(setBusinessDisruptionLoading(false))
			} catch (error) {
				dispatch(
					setBusinessDisruptionErrorMessage(
						getErrorMessage(error, 'Error: Could not load business disruption')
					)
				)
			}
		}

		await makeRequest()
	}
}
