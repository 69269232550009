import React, { Fragment, useEffect, useState } from 'react'
import { Score } from 'src/components/Score'
import { get, isEmpty, round } from 'lodash'
import { RiskDashboard } from '../ProjectSideBar/RiskDashboard'
import { LoadingState } from './LoadingState'
import { EpcRating } from '../ProjectSummaryContent/EpcRating'
import { Losses } from '../../components/Losses'
import { SCORE_WEIGHTS } from 'src/constants'
import Explainer from '../../components/Explainer'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { BusinessDisruption } from './BusinessDisruption'

export const AssetSummaryContent = ({
	assetName,
	fetchAssetSummary,
	scenario,
	year,
	targetEpc,
	epcTargetYear,
	assetId,
	assetSummary,
	defended,
	userSettings,
	userFeatureFlags,
	isCompareWindow,
}) => {
	const [lossesDisplayFormat, setLossesDisplayFormat] = useState('cost')
	const enableEpcs = userFeatureFlags['enable-epcs'] || false

	useEffect(() => {
		fetchAssetSummary(scenario, year, epcTargetYear, defended, assetId)
	}, [
		scenario,
		year,
		epcTargetYear,
		assetId,
		fetchAssetSummary,
		defended,
		targetEpc,
	])

	if (assetSummary.loading) {
		return <LoadingState />
	}

	if (isEmpty(assetSummary.data) || assetSummary.error) {
		return (
			<div className='project-summary__empty'>
				{assetSummary.error ||
					'No summary data to show, please try selecting another asset.'}
			</div>
		)
	}
	const summary = get(assetSummary, 'data')

	return (
		<Fragment>
			<div className='row w-100 m-0 project-summary__main-title-row'>
				<div className='col-12 py-2'>
					<h3 className='project-summary__main-title'>{assetName}</h3>
				</div>
			</div>

			<div className='row w-100 m-0 project-summary__risc-score'>
				<div
					className={`col-12 col-md-${
						enableEpcs ? '6' : '12'
					} py-2 project-summary__physical-score`}
				>
					<h4 className='project-summary__section-title'>
						Physical risk score
					</h4>
					<div
						id='explainer-asset_summary_physical_risk_score'
						className='project-summary__section-score'
					>
						<Score score={SCORE_WEIGHTS[get(assetSummary, 'physical_risk')]} />
					</div>
					<Explainer explainerKey='asset_summary_physical_risk_score' />
				</div>
				{enableEpcs ? (
					<div className='col-12 col-md-6 py-2'>
						<h4 className='project-summary_content__section-title'>
							EPC rating
						</h4>
						<div className='row w-100 m-0'>
							<div
								id='explainer-asset_summary_epc_rating_actual'
								className='col-6'
							>
								<EpcRating
									label='Actual'
									value={
										get(assetSummary, 'epc_data.epc_rating') > 0
											? round(get(assetSummary, 'epc_data.epc_rating'))
											: null
									}
								/>
							</div>
							<Explainer explainerKey='asset_summary_epc_rating_actual' />

							<div
								id='explainer-asset_summary_epc_rating_potential'
								className='col-6'
							>
								<EpcRating
									label='Potential'
									value={
										get(assetSummary, 'epc_data.epc_potential_rating') > 0
											? round(
													get(assetSummary, 'epc_data.epc_potential_rating')
												)
											: null
									}
								/>
							</div>
							<Explainer explainerKey='asset_summary_epc_rating_potential' />
						</div>
					</div>
				) : null}
			</div>

			<div className='row w-100 m-0 project-summary__risc-score'>
				<div className='col-12 py-2'>
					<h4 className='pb-2 project-summary__section-title'>
						Risk dashboard
					</h4>
					<RiskDashboard summary={summary} />
				</div>
			</div>

			<div className='row w-100 m-0'>
				<div
					id={`explainer-${enableEpcs ? 'losses' : 'losses_no_epc'}`}
					className='col-12 py-2'
				>
					<div className='project-summary_content__section-title-wrapper'>
						<h4 className='project-summary_content__section-title'>
							Estimated Losses p.a
						</h4>
						<div
							className={classnames(
								'project-summary_content__section-title-icon',
								lossesDisplayFormat !== 'cost' &&
									'project-summary_content__section-title-icon--inactive'
							)}
							onClick={() => setLossesDisplayFormat('cost')}
						>
							{userSettings?.preferred_currency_symbol || ''}
						</div>
						<div
							className={classnames(
								'project-summary_content__section-title-icon',
								lossesDisplayFormat !== 'percentage' &&
									'project-summary_content__section-title-icon--inactive'
							)}
							onClick={() => setLossesDisplayFormat('percentage')}
						>
							%
						</div>
					</div>
				</div>
				<Explainer explainerKey={enableEpcs ? 'losses' : 'losses_no_epc'} />
			</div>
			<div className='row w-100 m-0 pb-3 justify-content-center'>
				<div className='col-6'>
					<Losses
						displayFormat={lossesDisplayFormat}
						riskCost={get(assetSummary, 'loss_data.loss')}
						riskPercentage={
							get(assetSummary, 'loss_data.loss_percentage') * 100
						}
						riskLabel='physical risk'
					/>
				</div>
				{enableEpcs ? (
					<div className='col-6'>
						<Losses
							displayFormat={lossesDisplayFormat}
							riskCost={get(
								assetSummary,
								`retrofit_data.retrofit_cost_to_${targetEpc.toLowerCase()}`
							)}
							riskPercentage={
								get(
									assetSummary,
									`retrofit_data.retrofit_cost_to_${targetEpc.toLowerCase()}_percentage`
								) * 100
							}
							riskLabel={
								<span>
									transition risk<sup>beta</sup>
								</span>
							}
						/>
					</div>
				) : null}
			</div>

			<BusinessDisruption isCompareWindow={isCompareWindow} />
		</Fragment>
	)
}

AssetSummaryContent.propTypes = {
	assetId: PropTypes.string.isRequired,
	scenario: PropTypes.string,
	year: PropTypes.string,
	targetEpc: PropTypes.string,
	defended: PropTypes.string,
	assetSummary: PropTypes.object,
	assetName: PropTypes.string, /// not required as sometimes missing
	fetchAssetSummary: PropTypes.func.isRequired,
	isCompareWindow: PropTypes.bool,
}
