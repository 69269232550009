import api from '../../service/api'
import { addToast } from './toasts'
import { getErrorMessage } from '../../utils'

export const SET_CURRENCIES = 'setCurrencies'
export const setCurrencies = (payload) => {
	return {
		type: SET_CURRENCIES,
		payload,
	}
}

export const fetchCurrencies = () => async (dispatch) => {
	try {
		const { currencies } = await api.get('/ref/currencies')
		dispatch(setCurrencies(currencies))
	} catch (error) {
		dispatch(
			addToast({
				body: getErrorMessage(error, 'Error: Could not load currencies'),
				type: 'error',
			})
		)
	}
}
