import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import classnames from 'classnames'
import { getFirstModal } from 'src/redux/selectors/modal'
import { closeModal } from 'src/redux/actions/modal'
import { AddAssets } from 'src/containers/ModalViews'
import { ConfirmationModalContent } from 'src/components/ConfirmationModalContent'
import { InformationModalContent } from '../InformationModalContent'
import { SessionExpireModalContent } from 'src/components/SessionExpireModalContent'
import { MODAL_VIEWS } from 'src/containers/ModalViews'
import './styles.scss'

function Modal({ closeModal, modal }) {
	const { viewKey, viewProps, config, className } = modal

	const handleModalClose = useCallback(() => {
		closeModal && closeModal()
	}, [closeModal])

	const renderModalView = useCallback(() => {
		switch (viewKey) {
			case MODAL_VIEWS.ADD_ASSETS:
				return <AddAssets {...viewProps} />
			case MODAL_VIEWS.CONFIRMATION_MODAL:
				return <ConfirmationModalContent {...viewProps} />
			case MODAL_VIEWS.INFORMATION_MODAL:
				return <InformationModalContent {...viewProps} />
			case MODAL_VIEWS.SESSION_EXPIRE_MODAL:
				return <SessionExpireModalContent {...viewProps} />
			default:
				return null
		}
	}, [viewKey, viewProps])

	return (
		<ReactModal
			className={classnames('react-modal', className)}
			isOpen={!!modal.viewKey}
			appElement={document.body}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			onRequestClose={handleModalClose}
			{...config}
		>
			{renderModalView()}
		</ReactModal>
	)
}

Modal.propTypes = {
	closeModal: PropTypes.func.isRequired,
}

export default connect(
	(state) => ({
		modal: getFirstModal(state),
	}),
	{ closeModal }
)(Modal)
