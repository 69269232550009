import React, { useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

export const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	return (
		<>
			<nav className='navbar fixed-top navbar-expand-lg navbar-light login-header'>
				<div className='container'>
					<a
						className='navbar-brand'
						href='https://www.climate-x.com'
						title='App Home'
						rel='noopener noreferrer'
					>
						<img
							src='img/Transparent_Grey_X_Logo.png'
							width='152px'
							alt='Climate X | App Home'
						/>
					</a>

					<div className='collapse navbar-collapse login-header__header-nav'>
						<ul className='navbar-nav mx-auto'>
							<li className='nav-item'>
								<a
									className='nav-link login-header__link'
									href='https://www.climate-x.com/product'
									title='Discover how Climate X can deliver the climate risk data you need'
									rel='noopener noreferrer'
								>
									Product
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link login-header__link'
									href='https://www.climate-x.com/industries'
									title='Industries covered by Climate X'
									rel='noopener noreferrer'
								>
									Industries
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link login-header__link'
									href='https://www.climate-x.com/people'
									title='Learn about the people behind Climate X'
									rel='noopener noreferrer'
								>
									People
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link login-header__link'
									href='https://www.climate-x.com/values'
									title='Learn about the values that drive Climate X'
									rel='noopener noreferrer'
								>
									Values
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link login-header__link'
									href='https://www.climate-x.com/careers'
									title='Career opportunities at Climate-X'
									rel='noopener noreferrer'
								>
									Careers
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link login-header__link'
									href='https://www.climate-x.com/press'
									title='Visit our Press Area for useful media downloads, videos and PDFs'
									rel='noopener noreferrer'
								>
									Press
								</a>
							</li>
							<li className='nav-item'>
								<a
									className='nav-link login-header__link'
									href='https://www.climate-x.com/lets-talk'
									title='Connect with the team at Climate X'
									rel='noopener noreferrer'
								>
									Let's Talk
								</a>
							</li>
						</ul>
					</div>
				</div>
				<FontAwesomeIcon
					icon={['fas', 'ellipsis']}
					className='d-block d-lg-none login-header__menu-icon'
					onClick={() => setIsMenuOpen(true)}
				/>
			</nav>
			<div
				className={classNames(
					'd-block d-lg-none login-header-mobile',
					isMenuOpen && 'login-header-mobile--active'
				)}
			>
				<FontAwesomeIcon
					icon={['fas', 'times']}
					className='login-header-mobile__close-menu-icon'
					onClick={() => setIsMenuOpen(false)}
				/>
				<div className='d-lg-none d-xl-none login-header-mobile__menu'>
					<a
						className='login-header-mobile__logo'
						href='https://www.climate-x.com'
						title='App Home'
						rel='noopener noreferrer'
					>
						<img
							src='img/Transparent_Grey_X_Logo.png'
							width='152px'
							alt='Climate X | App Home'
						/>
					</a>

					<ul>
						<li>
							<a
								href='https://www.climate-x.com/product'
								title='Discover how Climate X can deliver the climate risk data you need'
								rel='noopener noreferrer'
							>
								Product
							</a>
						</li>
						<li>
							<a
								href='https://www.climate-x.com/industries'
								title='Industries covered by Climate X'
								rel='noopener noreferrer'
							>
								Industries
							</a>
						</li>
						<li>
							<a
								href='https://www.climate-x.com/people'
								title='Learn about the people behind Climate X'
								rel='noopener noreferrer'
							>
								People
							</a>
						</li>
						<li>
							<a
								href='https://www.climate-x.com/values'
								title='Learn about the values that drive Climate X'
								rel='noopener noreferrer'
							>
								Values
							</a>
						</li>
						<li>
							<a
								href='https://www.climate-x.com/careers'
								title='Career opportunities at Climate-X'
								rel='noopener noreferrer'
							>
								Careers
							</a>
						</li>
						<li>
							<a
								href='https://www.climate-x.com/press'
								title='Visit our Press Area for useful media downloads, videos and PDFs'
								rel='noopener noreferrer'
							>
								Press
							</a>
						</li>
						<li>
							<a
								href='https://www.climate-x.com/lets-talk'
								title='Connect with the team at Climate X'
								rel='noopener noreferrer'
							>
								Let's Talk
							</a>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}
