import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import './styles.scss'

const transformDataToRows = (data, adapt_direct_measure_results) => {
	let rows = []
	let idCounter = 1

	const processMeasures = (measures) => {
		Object.entries(measures).forEach(([key, value]) => {
			const measureResult = adapt_direct_measure_results?.[key]
			const adaptCost = measureResult ? measureResult.adapt_cost : 0
			const oneYearROI = measureResult ? measureResult.one_year_ROI : 0.0
			const tenYrROI = measureResult ? measureResult.ten_yr_ROI : 0.0
			const oneYrLossSavings = measureResult
				? measureResult.one_yr_loss_savings
				: 0
			const tenYrLossSavings = measureResult
				? measureResult.ten_yr_loss_savings
				: 0

			rows.push({
				id: idCounter++,
				ref_adaptation_id: key,
				label: key
					.toLowerCase()
					.replaceAll('_', ' ')
					.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
				active: value.default,
				adaptCost: adaptCost,
				oneYearROI: oneYearROI,
				tenYearROI: tenYrROI,
				oneYrLossSavings: oneYrLossSavings,
				tenYrLossSavings: tenYrLossSavings,
				hazards: value.hazards,
				relevant: oneYrLossSavings + tenYrLossSavings > 0 ? 'true' : 'false',
			})
		})
	}

	if (data?.direct_measures) {
		processMeasures(data.direct_measures)
	}
	return rows
}

function DirectAdaptationsBuildingsTable({
	userSettings,
	adapt_data,
	adapt_direct_measure_results,
	setChecked,
	locatorId,
}) {
	const [rows, setRows] = useState([])
	const [sortModel, setSortModel] = useState([])

	useEffect(() => {
		const newRows = transformDataToRows(
			adapt_data,
			adapt_direct_measure_results
		)
		setRows(newRows)
	}, [adapt_data, adapt_direct_measure_results])

	const handleSortModelChange = (model) => {
		setSortModel(model)
	}
	const columns = [
		{
			field: 'label',
			headerName: 'Measure',
			width: 150,
			renderCell: (params) => (
				<span
					style={{
						fontWeight: 'bold',
						wordWrap: 'break-word',
						whiteSpace: 'normal',
						fontSize: '12px',
					}}
				>
					{params.row.label}
				</span>
			),
		},

		{
			field: 'active',
			headerName: 'Active',
			width: 10,
			renderCell: (params) => (
				<Checkbox
					checked={params.row.active}
					size='small'
					onChange={(event) => {
						setChecked(params.row.ref_adaptation_id, event.target.checked)
					}}
					inputProps={{ 'aria-labelledby': `checkbox-${params.row.id}` }}
				/>
			),
		},
		{
			field: 'adaptCost',
			headerName: 'Cost',
			type: 'number',
			width: 100,
			valueGetter: (value) => {
				return value.row.adaptCost
			},
			valueFormatter: (value) => {
				return `${userSettings.preferred_currency_symbol}${parseFloat(
					value.value
				).toFixed(0)}`
			},
		},
		{
			field: 'oneYearROI',
			headerName: '1 YR ROI',
			type: 'number',
			width: 100,
			valueGetter: (value) => {
				return value.row.oneYearROI
			},
			valueFormatter: (value) => {
				return `${parseFloat(value.value * 100).toFixed(2)}%`
			},
		},
		{
			field: 'tenYearROI',
			headerName: '10 YR ROI',
			type: 'number',
			width: 100,
			valueGetter: (value) => {
				return value.row.tenYearROI
			},
			valueFormatter: (value) => {
				return `${parseFloat(value.value * 100).toFixed(2)}%`
			},
		},
		{
			field: 'oneYrLossSavings',
			headerName: '1 YR loss savings',
			type: 'number',
			width: 130,
			valueGetter: (value) => {
				return value.row.oneYrLossSavings
			},
			valueFormatter: (value) => {
				return `${userSettings.preferred_currency_symbol}${parseFloat(
					value.value
				).toFixed(0)}`
			},
		},
		{
			field: 'tenYrLossSavings',
			headerName: '10 YR loss savings',
			type: 'number',
			width: 140,
			valueGetter: (value) => {
				return value.row.tenYrLossSavings
			},
			valueFormatter: (value) => {
				return `${userSettings.preferred_currency_symbol}${parseFloat(
					value.value
				).toFixed(0)}`
			},
		},
		{ field: 'hazards', headerName: 'Hazards', width: 290 },
	]
	const StyledDataGrid = styled(DataGrid)(({ theme }) => ({}))

	return (
		<div
			style={{ height: 550, width: 'auto' }}
			data-locator-id={locatorId}
			className='direct-adapt-buildings-table'
		>
			<StyledDataGrid
				rows={rows}
				columns={columns}
				rowHeight={40}
				hideFooterPagination={true}
				sortModel={sortModel}
				onSortModelChange={handleSortModelChange}
				sx={{
					'& .MuiDataGrid-cell': {
						fontSize: '10px',
						color: '#5e5e5e',
						fontFamily:
							'"Graphik Web", "Segoe UI", "Helvetica Neue", Helvetica, Arial, "sans-serif"',
					},
					'& .MuiDataGrid-columnHeaders': {
						fontSize: '12px',
						color: '#5e5e5e',
						fontFamily:
							'"Graphik Web", "Segoe UI", "Helvetica Neue", Helvetica, Arial, "sans-serif"',
					},
					// TODO" Add styling to fix word wrap
				}}
				getRowClassName={(rows) => `relevant--${rows.row.relevant}`}
			/>
		</div>
	)
}

DirectAdaptationsBuildingsTable.defaultProps = {
	locatorId: 'not-set',
}
DirectAdaptationsBuildingsTable.propTypes = {}

export default DirectAdaptationsBuildingsTable
