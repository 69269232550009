import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './style.scss'

const StatusMessage = ({ type, children }) => {
	return (
		<div
			className={`form__${classNames(
				{ error: type === 'error' },
				{ success: type === 'success' }
			)}`}
		>
			{children}
		</div>
	)
}

StatusMessage.propTypes = {
	type: PropTypes.oneOf(['error', 'success']),
	children: PropTypes.node,
}

export default StatusMessage
