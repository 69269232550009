import { MAX_ATTEMPTS_EXCEEDED, POLL_STOPPED } from 'src/constants'

export class Poll {
	constructor(interval, maxAttempts) {
		this.attempts = 0
		this.interval = interval
		this.maxAttempts = maxAttempts
		this.timeout = null
		this.executionId = 0
	}

	resetToInitial() {
		clearTimeout(this.timeout)
		this.attempts = 0
		this.timeout = null
		this.executionId++
	}

	async initPoll({ fn, validate }) {
		this.executionId++
		const executePoll = async (resolve, reject) => {
			try {
				const executionId = this.executionId
				const result = await fn()

				if (this.executionId !== executionId) {
					return reject(POLL_STOPPED)
				}

				this.attempts++

				if (validate(result)) {
					this.resetToInitial()
					return resolve(result)
				} else if (this.attempts >= this.maxAttempts) {
					this.resetToInitial()
					return reject(MAX_ATTEMPTS_EXCEEDED)
				} else {
					this.timeout = setTimeout(executePoll, this.interval, resolve, reject)
				}
			} catch (error) {
				reject(error)
			}
		}

		return new Promise(executePoll)
	}
}
