import React from 'react'
import { Skeleton } from '../../../../components/Skeleton'

export const Loading = () => {
	return (
		<div className='row w-100 m-0 project-summary_content__risc-score'>
			<div className='col-4 py-3 d-flex justify-content-center'>
				<Skeleton
					width={144}
					height={144}
					type='circle'
					className='mb-2 flex-shrink-none'
				/>
			</div>
			<div className='col-4 py-3 d-flex justify-content-center'>
				<Skeleton
					width={144}
					height={144}
					type='circle'
					className='mb-2 flex-shrink-none'
				/>
			</div>
			<div className='col-4 py-3 d-flex justify-content-center'>
				<Skeleton
					width={144}
					height={144}
					type='circle'
					className='mb-2 flex-shrink-none'
				/>
			</div>
		</div>
	)
}
