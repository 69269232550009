import React, { useState, useEffect } from 'react'
import { Button } from '../../components/Button'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.scss'
import { getIsUserDemo } from '../../utils'

const ButtonGroup = ({
	wrapperId,
	options,
	defaultValue,
	activeClassName,
	onChange,
	locatorId,
	customClassName,
}) => {
	const [selected, setSelected] = useState(defaultValue)

	useEffect(() => {
		if (selected !== defaultValue) {
			setSelected(defaultValue)
		}
	}, [defaultValue])

	const handleOnClick = (e, newValue) => {
		if (newValue.value !== selected.value) {
			e.stopPropagation()
			setSelected(newValue)
			onChange(newValue)
		}
	}

	const baseButtonClass = customClassName
		? `${customClassName}__button`
		: 'button-group__button'
	const activeButtonClass = customClassName
		? `${customClassName}__button--${activeClassName}-active`
		: `button-group__button--${activeClassName}-active`

	return (
		<div
			id={wrapperId}
			className={classnames('button-group', customClassName)}
			data-locator-id={locatorId}
		>
			{options.map((option) => {
				const isSelected = option.value === selected.value
				return (
					<Button
						key={option.value}
						{...option}
						disabled={
							getIsUserDemo() && option.value === 'defended'
								? true
								: option.isDisabled
						}
						className={classnames(
							baseButtonClass,
							isSelected && activeButtonClass
						)}
						onClick={(e) => handleOnClick(e, option)}
					>
						{option.displayValue}
					</Button>
				)
			})}
		</div>
	)
}

const optionType = PropTypes.shape({
	type: PropTypes.oneOf(['primary', 'secondary']),
	displayValue: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isDisabled: PropTypes.bool,
})

ButtonGroup.defaultProps = {
	defaultValue: {},
	isDisabled: false,
	activeClassName: 'red',
	locatorId: 'not-set',
}

ButtonGroup.propTypes = {
	wrapperId: PropTypes.string,
	defaultValue: optionType,
	activeClassName: PropTypes.oneOf(['red']),
	isDisabled: PropTypes.bool,
	options: PropTypes.arrayOf(optionType).isRequired,
	onChange: PropTypes.func.isRequired,
	customClassName: PropTypes.string,
}

export default ButtonGroup
