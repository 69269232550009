import {
	SET_USER_DATA_LOADING,
	SIGN_OUT,
	SET_USER_DATA,
	SET_COGNITO_SESSION,
	SET_USER_SETTINGS,
	UPDATE_USER_CURRENCY,
	UPDATE_USER_LENGTH_UNIT,
} from '../constants'
import { SET_FORM_MESSAGES } from '../actions/user'
const DEFAULT_STATE = {
	userName: null,
	userData: null,
	loading: undefined,
	cognitoSession: null,
	userSettings: null,
	formMessage: {
		login: null,
		confirmUser: null,
		resetPassword: null,
		confirmPassword: null,
	},
}

export default function user(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case SET_USER_DATA_LOADING:
			return {
				...state,
				loading: action.isLoading,
			}
		case SET_FORM_MESSAGES:
			return {
				...state,
				formMessage: {
					...state.formMessage,
					...action.payload,
				},
			}
		case SET_USER_DATA:
			return {
				...state,
				userData: {
					...state.userData,
					...action.userData,
				},
				loading: false,
			}
		case SET_USER_SETTINGS:
			return {
				...state,
				userSettings: action.payload,
			}
		case UPDATE_USER_CURRENCY:
			return {
				...state,
				userSettings: {
					...state.userSettings,
					preferred_currency_id: action.payload.id,
					preferred_currency_code: action.payload.code,
					preferred_currency_symbol: action.payload.symbol,
				},
			}

		case UPDATE_USER_LENGTH_UNIT:
			return {
				...state,
				userSettings: {
					...state.userSettings,
					preferred_length_unit: action.payload,
				},
			}

		case SET_COGNITO_SESSION:
			return {
				...state,
				cognitoSession: action.token,
			}
		case SIGN_OUT:
			return {
				...state,
				userData: null,
				loading: false,
			}
		default:
			return state
	}
}
