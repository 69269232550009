import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Tooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CheckBox } from '../../CheckBox'
import { getUpdatedAtFormat, numberWithCommas } from 'src/utils'
import { PROJECT_NAME_MAX_LENGTH } from '../../../constants'

export const ProjectListItem = ({
	activeProjectId,
	project,
	onDelete,
	onEdit,
	onReview,
	onSelect,
	onDoubleClick,
}) => {
	const inputRef = useRef(null)

	const { id: projectId, updated_at } = project

	const [projectName, setProjectName] = useState(project.name)

	const [activeTooltip, setActiveTooltip] = useState({
		[`project-edit-${projectId}`]: false,
		[`project-delete-${projectId}`]: false,
		[`project-explore-${projectId}`]: false,
	})

	const [isEditing, setIsEditing] = useState(false)

	const handleTooltip = (key) => {
		setActiveTooltip({ ...activeTooltip, [key]: !activeTooltip[key] })
	}

	const onNameChange = (name) => {
		if (name?.trim().length <= PROJECT_NAME_MAX_LENGTH) {
			setProjectName(name)
			onEdit(name)
		}
	}

	const startEdit = () => {
		setIsEditing(true)
	}

	useEffect(() => {
		if (isEditing) {
			inputRef.current.focus()
		} else {
			inputRef.current.blur()
		}
	}, [isEditing])

	const isActive = projectId === activeProjectId

	return (
		<div
			className={classnames(
				'projections-list__row',
				isActive && 'projections-list__row--active'
			)}
			onClick={() => {
				onSelect(projectId)
			}}
			onDoubleClick={() => {
				onDoubleClick(projectId)
			}}
		>
			<div className='projections-list__body-cell'>
				<CheckBox
					appearance='circle'
					defaultValue={isActive}
					applyChangeFromParent
					onChange={() => {}}
				/>
			</div>
			<input
				className={classnames(
					'projections-list__body-cell',
					'projections-list__body-cell--name',
					isEditing && 'projections-list__body-cell--editing'
				)}
				ref={inputRef}
				value={projectName}
				onChange={(e) => onNameChange(e.target.value)}
				disabled={!isEditing}
				onBlur={() => {
					setIsEditing(false)
				}}
				onKeyDown={(e) => {
					if (e.keyCode === 13) {
						setIsEditing(false)
					}
				}}
			/>
			<div className='projections-list__body-cell'>
				{numberWithCommas(project.num_assets)}
			</div>
			<div className='projections-list__body-cell'>
				{getUpdatedAtFormat(updated_at)}
			</div>
			<div className='projections-list__body-cell'>
				<FontAwesomeIcon
					icon={['far', 'pen-circle']}
					id={`project-edit-${projectId}`}
					className='projections-list__edit'
					onClick={(e) => {
						e.stopPropagation()
						startEdit()
					}}
				/>
				<FontAwesomeIcon
					icon={['far', 'circle-trash']}
					id={`project-delete-${projectId}`}
					className='projections-list__remove'
					onClick={(e) => {
						e.stopPropagation()
						onDelete()
					}}
				/>
				<FontAwesomeIcon
					icon={['far', 'arrow-right']}
					id={`project-explore-${projectId}`}
					className='projections-list__review'
					onClick={(e) => {
						e.stopPropagation()
						onReview(projectId)
					}}
				/>
				<Tooltip
					placement='top'
					isOpen={activeTooltip[`project-edit-${projectId}`]}
					target={`project-edit-${projectId}`}
					toggle={() => handleTooltip(`project-edit-${projectId}`)}
					hideArrow={false}
				>
					Edit this project
				</Tooltip>
				<Tooltip
					placement='top'
					isOpen={activeTooltip[`project-delete-${projectId}`]}
					target={`project-delete-${projectId}`}
					toggle={() => handleTooltip(`project-delete-${projectId}`)}
					hideArrow={false}
				>
					Delete this project
				</Tooltip>
				<Tooltip
					placement='top'
					isOpen={activeTooltip[`project-explore-${projectId}`]}
					target={`project-explore-${projectId}`}
					toggle={() => handleTooltip(`project-explore-${projectId}`)}
					hideArrow={false}
				>
					Show on the Heatmap
				</Tooltip>
			</div>
		</div>
	)
}

ProjectListItem.propTypes = {
	isActive: PropTypes.bool,
	onDelete: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onReview: PropTypes.func.isRequired,
	onDoubleClick: PropTypes.func.isRequired,
}
