import React, { memo, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useElementResize } from 'src/customHooks'
import { useHistory, useLocation } from 'react-router-dom'
import {
	addWaterLayer,
	addBufferingFixLayer,
	useAssets,
	useBuildings,
	useLayers,
	useMapCenter,
	usePopup,
	useQueryParams,
} from './hooks'
import { debounce, get, isEmpty } from 'lodash'
import { SelectLayer } from './SelectLayer'
import {
	getAssetsSearchValue,
	getAssetsTotalCount,
} from '../../redux/selectors/assets'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
	easeToTarget,
	findFeatureById,
	isAssetMarker,
	isProductionEnv,
} from 'src/utils'
import {
	ASSET_MARKERS_SINGLE_ASSET_ID,
	BUILDINGS_SOURCE_ID,
	DEBOUNCE_TIME,
	HAZARD_TYPES,
	MAP_STYLE,
	THROTTLE_TIME,
} from 'src/constants'
import { getProjectSummaryLoading } from '../../redux/selectors/projects'
import { setCurrentAsset } from 'src/redux/actions/adaptations'
import './styles.scss'

mapboxgl.accessToken =
	'pk.eyJ1Ijoia2FtaWxrbHV6YSIsImEiOiJja3Jsc2I3bnAwcDMyMnBsN3M4MDQ4ZGtxIn0.lLukhHS1-VWsuoMBeIS2pg'

const isProductionMode = isProductionEnv()

const _Map = memo(
	({
		assetsSearchValue,
		assetsTotalCount,
		projectSummaryLoading,
		setCurrentAsset,
	}) => {
		const history = useHistory()
		const location = useLocation()
		const mapWrapper = useRef(null)
		const mapContainer = useRef(null)
		const [isMapLoaded, setIsMapLoaded] = useState(false)
		const map = useRef(null)

		const [{ aimCoordinates, isAiming }, setIsAimActive] = useState({
			aimCoordinates: null,
			isAiming: false,
		})

		useElementResize(mapWrapper, {
			onResize: () => {
				if (map.current) {
					map.current.resize()
				}
			},
		})

		const {
			scenario,
			hazardType,
			year,
			defended,
			lng,
			lat,
			zoom,
			assetName,
			assetId,
			projectId,
			goTo,
			prevQueryParams,
			assets_score_key,
			assets_volume_key,
			setQueryParams,
			deleteQueryParams,
		} = useQueryParams({
			locationPathname: location.pathname,
			locationSearch: location.search,
			historyReplace: history.replace,
		})

		const { removePopup, setPopup } = usePopup({
			map: map.current,
			assetName: assetName,
		})

		const { layerId, hideLayerTiles, toggleOrCreateLayerTiles } = useLayers({
			activeScenario: scenario,
			hazardType,
			selectedYear: year,
			selectedDefend: defended,
		})

		const {
			generateBuildingLayer,
			highlightBuilding,
			removeHighlightBuilding,
		} = useBuildings({
			map: map.current,
		})

		const { setMapCenter } = useMapCenter({ projectId })

		const { updateOrCreateAssetClusters, createMarkerClickHandler } = useAssets(
			{
				projectId,
				onSelectAsset: ({ activeAsset, coordinates, assetName }) => {
					setCurrentAsset({
						asset: activeAsset,
					})
					setQueryParams({
						assetId: activeAsset.id,
						goTo: coordinates,
						assetName,
					})
				},
				assets_score_key,
				assets_volume_key,
				hazardType,
				year,
				scenario,
				defended,
				assetsSearchValue,
			}
		)

		const removeSelectedAsset = (e) => {
			const features = map.current.queryRenderedFeatures(e.point)
			const isMarker = features.some((feature) =>
				isAssetMarker(feature.layer.id)
			)
			const keysToDelete = []

			if (assetId) {
				keysToDelete.push('assetId')
				keysToDelete.push('assetName')
			}
			if (!isMarker && !isEmpty(keysToDelete)) {
				deleteQueryParams(keysToDelete)
			}
		}

		useEffect(() => {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: MAP_STYLE,
				center: [lng, lat],
				zoom: zoom,
				/**
				 * antialias is disabled because in some cases
				 * it causes a lot of lag when panning, zooming, etc.
				 * Needs more investigation.
				 *
				 * antialias improves the quality of building outlines
				 */
				// antialias: true,
			})

			map.current.on('load', () => {
				if (!goTo) {
					setMapCenter({ map: map.current })
				}
				addWaterLayer({ map: map.current })

				toggleOrCreateLayerTiles({ map: map.current })

				generateBuildingLayer({ map: map.current })

				updateOrCreateAssetClusters({
					map: map.current,
				})

				createMarkerClickHandler({ map: map.current })

				addBufferingFixLayer({ map: map.current })

				// Disable map rotation using right click + drag
				map.current.dragRotate.disable()

				// Disable map rotation using touch rotation gesture
				map.current.touchZoomRotate.disableRotation()

				setIsMapLoaded(true)

				map.current.on('click', (e) => {
					removeSelectedAsset(e)
				})
			})
		}, [])

		useEffect(() => {
			if (!layerId && isMapLoaded) {
				hideLayerTiles(map.current)
			}
			if (layerId && isMapLoaded) {
				toggleOrCreateLayerTiles({ map: map.current })
			}
		}, [layerId, isMapLoaded, hideLayerTiles, toggleOrCreateLayerTiles])

		useEffect(() => {
			if (projectId && map.current && isMapLoaded && !projectSummaryLoading) {
				updateOrCreateAssetClusters({ map: map.current })
			}
		}, [
			projectId,
			assets_score_key,
			assets_volume_key,
			hazardType,
			isMapLoaded,
			assetsSearchValue,
			assetsTotalCount,
			projectSummaryLoading,
			updateOrCreateAssetClusters,
		])

		useEffect(() => {
			if (
				!goTo &&
				projectId &&
				map.current &&
				isMapLoaded &&
				!projectSummaryLoading
			) {
				setMapCenter({ map: map.current })
			}
		}, [projectId, isMapLoaded])

		useEffect(() => {
			if (!assetId && map.current && isMapLoaded) {
				removePopup()

				removeHighlightBuilding()

				map.current.easeTo({ pitch: 0 })
			}
		}, [assetId, isMapLoaded])

		// back to helicopter view on zoom out
		useEffect(() => {
			if (!map.current || !isMapLoaded) {
				return
			}
			const pitch = map.current.getPitch()

			const prevZoom = prevQueryParams.zoom && parseFloat(prevQueryParams.zoom)

			if (pitch !== 0 && prevZoom && prevZoom > zoom) {
				map.current.easeTo({ pitch: 0 })
			}
		}, [zoom, isMapLoaded])

		useEffect(() => {
			if (!map.current || !isMapLoaded) return
			const onPositionsUpdateDebounce = debounce(() => {
				const lng = map.current.getCenter().lng.toFixed(4)
				const lat = map.current.getCenter().lat.toFixed(4)
				const zoom = map.current.getZoom().toFixed(2)

				setQueryParams({ lng, lat, zoom })
			}, DEBOUNCE_TIME)

			const handlePositionsUpdate = () => {
				if (aimCoordinates && !isAiming) {
					setIsAimActive({ aimCoordinates: null, isAiming: false })
				}

				/**
				 * Debouncing is used for non-interval type actions,
				 * like updating the URL after the user has stopped moving the map.
				 */
				onPositionsUpdateDebounce()
			}

			map.current.on('move', handlePositionsUpdate)
			return () => {
				map.current.off('move', handlePositionsUpdate)
				onPositionsUpdateDebounce.cancel()
			}
		}, [
			lng,
			lat,
			zoom,
			projectId,
			aimCoordinates,
			isAiming,
			isMapLoaded,
			setQueryParams,
		])

		// go to targeted asset
		useEffect(() => {
			if (!map.current || !goTo || !isMapLoaded) {
				return
			}

			removeHighlightBuilding()

			const coordinates = [parseFloat(goTo[0]), parseFloat(goTo[1])]

			easeToTarget({
				map: map.current,
				coordinates,
				onEaseEnd: () => {
					const pixelCoordinates = map.current.project(coordinates)
					const features = map.current.queryRenderedFeatures(pixelCoordinates)
					const buildingFeature = findFeatureById(features, BUILDINGS_SOURCE_ID)
					if (buildingFeature) {
						highlightBuilding({ buildingFeature })
					}

					const assetFeature = findFeatureById(
						features,
						ASSET_MARKERS_SINGLE_ASSET_ID
					)
					if (assetFeature) {
						setPopup({ assetFeature })

						setIsAimActive({ aimCoordinates: pixelCoordinates, isAiming: true })

						setTimeout(() => {
							setIsAimActive({
								aimCoordinates: pixelCoordinates,
								isAiming: false,
							})
						}, THROTTLE_TIME)
					}
				},
			})

			return () => {
				if (goTo) {
					deleteQueryParams(['goTo'])
				}
			}
		}, [
			deleteQueryParams,
			goTo,
			highlightBuilding,
			isMapLoaded,
			removeHighlightBuilding,
			setPopup,
		])

		return (
			<div className='map-wrapper' ref={mapWrapper}>
				<div className='map-wrapper__sidebar'>
					<SelectLayer
						options={Object.values(HAZARD_TYPES).map((item) => ({
							value: item.value,
							name: item.mapDisplayValue,
						}))}
						activeOption={hazardType}
						onOptionClick={(hazardType) => setQueryParams({ hazardType })}
						clearFilters={() =>
							deleteQueryParams([
								'hazardType',
								'assets_score_key',
								'assets_volume_key',
							])
						}
					/>
				</div>

				<div
					ref={mapContainer}
					className='map-wrapper__map'
					id='walkthrough-map'
					data-locator-id='heatmap-canvas'
				/>

				<div
					className={classnames(
						'map-wrapper__aim-wrapper',
						aimCoordinates && 'map-wrapper__aim-wrapper--active'
					)}
				>
					<div
						className='map-wrapper__aim-x'
						style={{
							left: `${get(aimCoordinates, 'x', 0)}px`,
						}}
					/>
					<div
						className='map-wrapper__aim-y'
						style={{
							top: `${get(aimCoordinates, 'y', 0)}px`,
						}}
					/>
					<div
						className='map-wrapper__aim-circle'
						style={{
							left: `${get(aimCoordinates, 'x', 0)}px`,
							top: `${get(aimCoordinates, 'y', 0)}px`,
						}}
					/>
				</div>
			</div>
		)
	}
)

_Map.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	setCurrentAsset: PropTypes.func,
}

export const Map = connect(
	(state) => ({
		assetsSearchValue: getAssetsSearchValue(state),
		assetsTotalCount: getAssetsTotalCount(state),
		projectSummaryLoading: getProjectSummaryLoading(state),
	}),
	{ setCurrentAsset }
)(_Map)
