import React, { useEffect, useCallback, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, trim, debounce, isUndefined } from 'lodash'
import { connect } from 'react-redux'
import qs from 'qs'
import AssetsList from '../../../components/AssetsList'
import { SearchInput } from '../../../components/SearchInput'
import { Button } from '../../../components/Button'
import { PaginationAndBulkActions } from '../PaginationAndBulkActions'
import { fetchAssetsByProjectId } from 'src/redux/actions/assets'
import { getAssets } from 'src/redux/selectors/assets'
import {
	deleteAsset,
	selectAsset,
	selectAllAssets,
	clearAssetsSearch,
} from 'src/redux/actions/assets'
import { addToast } from '../../../redux/actions/toasts'
import { INITIAL_ASSETS_PAGINATION } from '../../../redux/reducers/assets'
import { closeModal, setModal } from 'src/redux/actions/modal'
import { EXPLORER_ROUTE } from 'src/constants'
import { MODAL_VIEWS } from 'src/containers/ModalViews'
import { Skeleton } from '../../../components/Skeleton'
import {
	createAssetFullName,
	createExplorerLink,
	numberWithCommas,
} from 'src/utils'
import { Tooltip } from 'reactstrap'
import {
	getIsUserDemo,
	getUserFeatureFlags,
} from '../../../redux/selectors/user'
import { FLAT_FILE_MAX_RECORDS } from '../../../components/FlatFile/constants'

import {
	DEMO_USER_MAX_PROJECTS_DEFAULT,
	DEMO_USER_MAX_ASSETS_PER_PROJECT_DEFAULT,
} from '../../../constants'
import classNames from 'classnames'
import { setCurrentAsset } from 'src/redux/actions/adaptations'
import './styles.scss'

function Assets({
	assets,
	projectId,
	defaultScenario,
	defaultDefend,
	defaultYear,
	defaultTargetEpc,
	defaultEpcTargetYear,
	shouldFetchAssets,
	onAssetsFetch,
	onAssetReview,
	fetchAssetsByProjectId,
	deleteAsset,
	selectAsset,
	selectAllAssets,
	location,
	history,
	clearAssetsSearch,
	setModal,
	closeModal,
	isUserDemo,
	userFeatureFlags,
	addToast,
	setCurrentAsset,
}) {
	const demoUserLimits = userFeatureFlags['increase_demo_limit_projects'] || {
		MAX_RECORDS_FOR_DEMO_USER: DEMO_USER_MAX_ASSETS_PER_PROJECT_DEFAULT,
		MAX_PROJECTS_FOR_DEMO_USER: DEMO_USER_MAX_PROJECTS_DEFAULT,
	}
	const MAX_RECORDS_FOR_DEMO_USER = demoUserLimits.MAX_RECORDS_FOR_DEMO_USER

	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
	const selectedAssets = get(assets, 'data', []).filter(
		(asset) => asset.isSelected
	)

	const [activeTooltip, setActiveTooltip] = useState({
		'add-asset': false,
	})

	const handleTooltip = (key) => {
		setActiveTooltip({ ...activeTooltip, [key]: !activeTooltip[key] })
	}

	useEffect(() => {
		if ((shouldFetchAssets || !assets?.data) && !assets.loading) {
			fetchAssetsByProjectId(projectId, null, false)
			onAssetsFetch()
		}
	}, [
		projectId,
		shouldFetchAssets,
		assets,
		fetchAssetsByProjectId,
		onAssetsFetch,
	])

	const handleAssetsSearch = useCallback(
		debounce((searchInput) => {
			if (trim(searchInput).length > 2) {
				fetchAssetsByProjectId(projectId, {
					...INITIAL_ASSETS_PAGINATION,
					search_value: searchInput,
				})
			} else if (!trim(searchInput).length) {
				clearAssetsSearch()
				fetchAssetsByProjectId(projectId, INITIAL_ASSETS_PAGINATION)
			}
		}, 200),
		[projectId]
	)

	const maxRecords = isUserDemo
		? MAX_RECORDS_FOR_DEMO_USER
		: FLAT_FILE_MAX_RECORDS
	const isAddAssetDisabled = assets?.data?.length >= maxRecords
	const isLoading = assets.loading || isUndefined(assets.data)

	const handleAssetReview = (asset) => {
		const assetName = createAssetFullName(asset)
		// FIXME: Need to fix asset id and parameters duplication in whole app
		setCurrentAsset({
			asset: asset,
		})

		if (location.pathname.includes(EXPLORER_ROUTE)) {
			history.replace({
				pathname: location.pathname,
				search: qs.stringify(
					{
						...queryParams,
						assetId: asset.id,
						assetName,
						goTo: [asset.longitude, asset.latitude],
					},
					{ addQueryPrefix: true }
				),
			})

			onAssetReview(asset)
		} else {
			history.push(
				createExplorerLink({
					...queryParams,
					assetId: asset.id,
					assetName,
					goTo: [asset.longitude, asset.latitude],
				})
			)
		}
	}

	const handleAddAssets = async () => {
		setModal({
			viewKey: MODAL_VIEWS.ADD_ASSETS,
			viewProps: {
				maximumAssetsToAdd: Math.max(maxRecords - assets.data.length, 0),
				activeProjectId: parseInt(projectId),
			},
		})
	}

	const handleSelectedAssetsDelete = async () => {
		const selectedAssetIds = selectedAssets.map(({ id }) => id)
		deleteAsset(selectedAssetIds)
	}

	return (
		<div className='sidebar-assets-wrapper'>
			<div
				className={classNames(
					'sidebar-assets-wrapper__header',
					isLoading && 'sidebar-assets-wrapper__header--disable'
				)}
			>
				<h3 className='sidebar-assets-wrapper__title'>Assets listed</h3>
				<div className='sidebar-assets-wrapper__assets-amount'>
					{isLoading ? (
						<Skeleton
							height={35}
							width={80}
							className='sidebar-assets-wrapper__assets-amount-skeleton'
						/>
					) : (
						numberWithCommas(assets.pagination.total)
					)}
				</div>
				<div id='add-asset' className='sidebar-assets-wrapper__actions'>
					<Button
						className='sidebar-assets-wrapper__add-asset'
						onClick={handleAddAssets}
						disabled={isAddAssetDisabled}
					>
						+ Add Asset
					</Button>
					{isAddAssetDisabled && (
						<Tooltip
							placement='top'
							isOpen={activeTooltip['add-asset']}
							target='add-asset'
							toggle={() => handleTooltip('add-asset')}
						>
							{`Maximum ${maxRecords} assets can be added for demo user.`}
						</Tooltip>
					)}
					<SearchInput
						placeholder='Search Asset List'
						wrapperClassName='sidebar-assets-wrapper__search-assets'
						inputClassName='sidebar-assets-wrapper__search-input'
						onChange={(value) => handleAssetsSearch(value)}
					/>
				</div>
			</div>

			{!isLoading && isEmpty(assets.data) ? (
				<div className='sidebar-assets-wrapper__empty'>
					No assets data to show
				</div>
			) : (
				<Fragment>
					<AssetsList
						assets={assets.data}
						projectId={projectId}
						defaultScenario={defaultScenario}
						defaultDefend={defaultDefend}
						defaultYear={defaultYear}
						defaultTargetEpc={defaultTargetEpc}
						defaultEpcTargetYear={defaultEpcTargetYear}
						onDelete={(assetId) => {
							deleteAsset([assetId])
						}}
						selectAsset={(isActive, assetId) => selectAsset(isActive, assetId)}
						selectAllAssets={(isSelected) => selectAllAssets(isSelected)}
						onAssetReview={(asset) => handleAssetReview(asset)}
						addToast={addToast}
						isLoading={isLoading}
						setModal={setModal}
						closeModal={closeModal}
					/>

					{!isEmpty(assets.data) ? (
						<PaginationAndBulkActions
							pagination={assets.pagination}
							fetchAssets={(updatedPaginationOptions) =>
								fetchAssetsByProjectId(projectId, updatedPaginationOptions)
							}
							deleteSelectedAssets={handleSelectedAssetsDelete}
							isAssetsSelected={!isEmpty(selectedAssets)}
						/>
					) : null}
				</Fragment>
			)}
		</div>
	)
}

Assets.propTypes = {
	projectId: PropTypes.string.isRequired,
	fetchAssetsByProjectId: PropTypes.func.isRequired,
	deleteAsset: PropTypes.func.isRequired,
	onAssetReview: PropTypes.func.isRequired,
	clearAssetsSearch: PropTypes.func.isRequired,
	location: PropTypes.object,
	history: PropTypes.func.isRequired,
	setCurrentAsset: PropTypes.func.isRequired,
	defaultScenario: PropTypes.string.isRequired,
	defaultTargetEpc: PropTypes.string.isRequired,
	defaultDefend: PropTypes.string.isRequired,
	defaultYear: PropTypes.string.isRequired,
	defaultEpcTargetYear: PropTypes.string.isRequired,
	shouldFetchAssets: PropTypes.bool.isRequired,
}

export default connect(
	(state) => ({
		assets: getAssets(state),
		isUserDemo: getIsUserDemo(state),
		userFeatureFlags: getUserFeatureFlags(state),
	}),
	{
		fetchAssetsByProjectId,
		deleteAsset,
		selectAsset,
		selectAllAssets,
		clearAssetsSearch,
		setModal,
		closeModal,
		addToast,
		setCurrentAsset,
	}
)(Assets)
