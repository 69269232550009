import { ADD_PROJECT_FILTERS } from '../actions/projectFilters'

const DEFAULT_STATE = {
	data: { scenario: 'no_action_rcp85', defended: false },
}

export default function projectFilters(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case ADD_PROJECT_FILTERS:
			return {
				...state,
				data: action.filters,
			}
		default:
			return state
	}
}
