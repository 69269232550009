import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get, omit, range } from 'lodash'
import { Tooltip } from 'reactstrap'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { ProjectListItem } from './ProjectListItem'
import qs from 'qs'
import { getProjects, getProjectsLoading } from 'src/redux/selectors/projects'
import { deleteProjects, editProject } from 'src/redux/actions/projects'
import { createExplorerLink } from 'src/utils'
import { Skeleton } from '../Skeleton'
import { setModal, closeModal } from '../../redux/actions/modal'
import { MODAL_VIEWS } from '../../containers/ModalViews'
import { fetchAssetsByProjectId } from 'src/redux/actions/assets'
import './styles.scss'

const _ProjectList = ({
	projects,
	deleteProjects,
	editProject,
	isLoading,
	setModal,
	closeModal,
	fetchAssetsByProjectId,
}) => {
	const location = useLocation()
	const history = useHistory()
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
	const [activeTooltip, setActiveTooltip] = useState({
		'post-code': false,
		'asset-country': false,
		'asset-options': false,
	})

	const handleTooltip = (key) => {
		setActiveTooltip({ ...activeTooltip, [key]: !activeTooltip[key] })
	}

	const handleProjectSelect = (projectId) => {
		fetchAssetsByProjectId(projectId, undefined, true)
		history.replace({
			pathname: location.pathname,
			search: qs.stringify(
				{ ...omit(queryParams, 'assetId'), projectId },
				{ addQueryPrefix: true }
			),
		})
	}

	const handleProjectExplore = (projectId) => {
		fetchAssetsByProjectId(projectId, undefined, true)
		history.push(createExplorerLink({ ...queryParams, projectId }))
	}

	const handleProjectDelete = (item) => {
		setModal({
			viewKey: MODAL_VIEWS.CONFIRMATION_MODAL,
			viewProps: {
				question: `Are you sure you want to delete ${item.name}?`,
				onCancel: () => {
					closeModal()
				},
				onConfirm: () => {
					deleteProjects([item.id])
				},
			},
		})
	}

	return (
		<Fragment>
			<div className='projections-list'>
				<div className='projections-list__table'>
					<div className='projections-list__header-group'>
						<div className='projections-list__header-cell'></div>
						<div className='projections-list__header-cell'>Name</div>
						<div
							style={{ width: '7em' }}
							className='projections-list__header-cell'
							id='project-header-asset'
						>
							Asset #
						</div>
						<div
							style={{ width: '9em' }}
							className='projections-list__header-cell'
							id='project-header-date'
						>
							Date
						</div>
						<div
							style={{ width: '6em' }}
							className='projections-list__header-cell'
							id='project-header-oprions'
						>
							Options
						</div>
					</div>
					<div
						className={classnames(
							'projections-list__body-group',
							isLoading && 'projections-list__body-group--small-gap'
						)}
					>
						{!!projects.length &&
							!isLoading &&
							projects.map((item) => (
								<ProjectListItem
									key={item.id}
									project={item}
									activeProjectId={parseInt(get(queryParams, 'projectId'))}
									onSelect={handleProjectSelect}
									onDelete={() => {
										handleProjectDelete(item)
									}}
									onEdit={(projectName) =>
										editProject(item.id, { name: projectName })
									}
									onReview={handleProjectExplore}
									onDoubleClick={handleProjectExplore}
								/>
							))}
					</div>
				</div>
				{isLoading
					? range(20).map((it) => (
							<Skeleton
								key={it}
								height={24}
								className='projections-list__row-skeleton'
							/>
						))
					: null}
				{!isLoading && !projects.length && <h4>Project not found</h4>}
			</div>
			<Tooltip
				placement='top'
				isOpen={activeTooltip['project-header-asset']}
				target='project-header-asset'
				toggle={() => handleTooltip('project-header-asset')}
				hideArrow={false}
			>
				Total number of Assets in the Project
			</Tooltip>
			<Tooltip
				placement='top'
				isOpen={activeTooltip['project-header-date']}
				target='project-header-date'
				toggle={() => handleTooltip('project-header-date')}
				hideArrow={false}
			>
				Date Project was last saved
			</Tooltip>
			<Tooltip
				placement='top'
				isOpen={activeTooltip['project-header-oprions']}
				target='project-header-oprions'
				toggle={() => handleTooltip('project-header-oprions')}
				hideArrow={false}
			>
				Operational features, edit, delete, etc.
			</Tooltip>
		</Fragment>
	)
}

_ProjectList.propTypes = {
	projects: PropTypes.array.isRequired,
	deleteProjects: PropTypes.func.isRequired,
	fetchAssetsByProjectId: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
}

export const ProjectList = connect(
	(state) => ({
		projects: getProjects(state),
		isLoading: getProjectsLoading(state),
	}),
	{
		deleteProjects,
		editProject,
		setModal,
		closeModal,
		fetchAssetsByProjectId,
	}
)(_ProjectList)
