import { ADD_TOAST, REMOVE_TOAST } from '../actions/toasts'
import { isEqual } from 'lodash'

const DEFAULT_STATE = {
	items: [],
}

export default function toasts(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case ADD_TOAST: {
			return {
				...state,
				items: state.items.concat({
					...action.payload,
					id: Math.random(),
				}),
			}
		}
		case REMOVE_TOAST: {
			return {
				...state,
				items: state.items.filter((item) => !isEqual(item, action.payload)),
			}
		}
		default:
			return state
	}
}
