import { slice } from 'lodash'
import {
	ADD_POPULAR_PROJECT,
	ADD_POPULAR_PROJECTS,
	DELETE_POPULAR_PROJECT,
	EDIT_POPULAR_PROJECT,
} from '../actions/popularProjects'

const DEFAULT_STATE = {
	data: [],
}

export default function popularProjects(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case ADD_POPULAR_PROJECTS:
			return {
				...state,
				data: action.projects,
			}
		case ADD_POPULAR_PROJECT:
			return {
				...state,
				data: slice([action.project, ...state.data], 0, 5),
			}
		case DELETE_POPULAR_PROJECT:
			return {
				...state,
				data: state.data.filter(
					(project) => !action.projectIds.includes(project.id)
				),
			}
		case EDIT_POPULAR_PROJECT:
			return {
				...state,
				data: state.data.map((project) => {
					if (project.id !== action.projectId) {
						return project
					}

					return {
						...project,
						...action.payload,
					}
				}),
			}
		default:
			return state
	}
}
