import { debounce, first, get, isEmpty, omit, slice } from 'lodash'
import qs from 'qs'
import api from '../../service/api'
import { history } from 'src/redux/store'
import {
	addPopularProject,
	addPopularProjects,
	deletePopularProject,
	editPopularProject,
} from './popularProjects'
import {
	createExplorerLink,
	getDefaultDefend,
	getDefaultEpcTargetYear,
	getDefaultScenario,
	getDefaultYear,
	getDefaultTargetEpc,
	getErrorMessage,
	getInitialRandomProgress,
	getRandomProgress,
	getProgressStep,
} from 'src/utils'
import { PROJECTS_INIT_PAGINATION } from '../reducers/projects'
import { fetchAssetsByProjectId, resetAssets } from '../actions/assets'
import { setProgress } from './progressBars'
import {
	setModal,
	setModalViewProps,
	closeModal,
} from 'src/redux/actions/modal'
import { MODAL_VIEWS } from 'src/containers/ModalViews'
import {
	getProjects,
	getProjectsPaginationOptions,
} from 'src/redux/selectors/projects'
import { getCsvUploadProgress } from '../selectors/progressBars'
import { stopCsvExportPoll } from './assets'
import { addToast } from './toasts'
import { PROGRESS_STATUS } from '../../constants'
import { setProjectsSummariesLoading } from './projectsSummaries'

export const SET_PROJECT_LOADING = `setProjectLoading`
export function setProjectLoading(isLoading) {
	return { type: SET_PROJECT_LOADING, isLoading }
}

const SMALL_PROJECT_SIZE = 1000

export function createProject(assets, source, batchId, allDataLength) {
	return async (dispatch, getState) => {
		const locationSearch = history.location.search
		const existingProjects = getState().projects.data

		let jobId = undefined
		const queryParams = qs.parse(locationSearch, { ignoreQueryPrefix: true })

		if (!isEmpty(assets)) {
			try {
				dispatch(setProjectsSummariesLoading(true))
				const newProject = await api.post('/projects')

				if (batchId) {
					const result = await api.post('/import-assets', {
						project_id: newProject.id,
						batch_id: batchId,
					})
					jobId = result.id
				}

				dispatch(
					addProject({
						...newProject,
						assets: batchId ? allDataLength : 1,
					})
				)

				dispatch(resetAssets())

				// If batchId exist it means we are uploading csv file.
				if (!batchId) {
					await api.post('/assets', {
						project_id: newProject.id,
						data: assets,
						source,
					})

					// Reset Csv Export poll after create new project with single asset.
					dispatch(stopCsvExportPoll())
					dispatch(addPopularProject(newProject.id))

					history.push(
						createExplorerLink({
							...queryParams,
							projectId: newProject.id,
							scenario: getDefaultScenario(),
							year: getDefaultYear(),
							targetEpc: getDefaultTargetEpc(),
							epcTargetYear: getDefaultEpcTargetYear(),
							defended: getDefaultDefend(),
						})
					)

					dispatch(fetchProjects())
				} else {
					let importAssets = await api.get(`/import-assets/${jobId}`)

					if (importAssets.progress === 0) {
						importAssets.progress = getInitialRandomProgress()
					}

					importAssets.step = getProgressStep(importAssets.progress)
					dispatch(setProgress(importAssets))

					const fixAssetsInterval = setInterval(async () => {
						if (
							importAssets &&
							importAssets.status === PROGRESS_STATUS.FAILED
						) {
							clearInterval(fixAssetsInterval)
							dispatch(setProgress(null))
							dispatch(setProjectsSummariesLoading(false))
							dispatch(
								addToast({
									body: 'Error: could not import assets',
									type: 'error',
								})
							)
							return
						}

						if (
							importAssets &&
							importAssets.status !== PROGRESS_STATUS.COMPLETED
						) {
							importAssets = await api.get(`/import-assets/${jobId}`)

							// increase progress manually for small csv files
							if (
								allDataLength <= SMALL_PROJECT_SIZE &&
								importAssets?.status === PROGRESS_STATUS.IN_PROGRESS
							) {
								const currentProgress = getCsvUploadProgress(getState()) || 0
								importAssets.progress = Math.min(
									99,
									currentProgress + getRandomProgress()
								)
							}

							importAssets.step = getProgressStep(importAssets.progress)
							dispatch(setProgress(importAssets))
						} else if (
							importAssets &&
							importAssets.status === PROGRESS_STATUS.COMPLETED
						) {
							clearInterval(fixAssetsInterval)
							setTimeout(() => {
								dispatch(setProgress(null))
								// Reset projects list and pagination
								dispatch(fetchProjects())

								// Don't show confirmation modal when creating first project
								if (existingProjects.length) {
									dispatch(
										setModal({
											viewKey: MODAL_VIEWS.CONFIRMATION_MODAL,
											config: {
												shouldCloseOnEsc: false,
												shouldCloseOnOverlayClick: false,
											},
											viewProps: {
												question: 'Take me to the results',
												onCancel: () => {
													dispatch(closeModal())
													dispatch(setProjectsSummariesLoading(false))
													dispatch(addPopularProject(newProject.id))
												},
												onConfirm: () => {
													dispatch(closeModal())
													dispatch(addPopularProject(newProject.id))

													history.push(
														createExplorerLink({
															...queryParams,
															projectId: newProject.id,
															scenario: getDefaultScenario(),
															year: getDefaultYear(),
															targetEpc: getDefaultTargetEpc(),
															epcTargetYear: getDefaultEpcTargetYear(),
															defended: getDefaultDefend(),
														})
													)
												},
											},
										})
									)
								} else {
									dispatch(addPopularProject(newProject.id))

									history.push(
										createExplorerLink({
											...queryParams,
											projectId: newProject.id,
											scenario: getDefaultScenario(),
											year: getDefaultYear(),
											targetEpc: getDefaultTargetEpc(),
											epcTargetYear: getDefaultEpcTargetYear(),
											defended: getDefaultDefend(),
										})
									)
								}
							}, 1000)
						}
					}, 2000)
				}
			} catch (error) {
				dispatch(setProjectsSummariesLoading(false))
				dispatch(
					addToast({
						body: getErrorMessage(error),
						type: 'error',
					})
				)
			}
		}
	}
}

export function fetchProjects(pagination = {}, onCompleted) {
	const isInitialFetch = isEmpty(pagination)
	const searchValue = get(pagination, 'search_value')

	return async (dispatch, getState) => {
		dispatch(setProjectLoading(true))

		const aciveSearchValue = searchValue || getState().projects.searchValue

		try {
			const paginationOptions = isEmpty(pagination)
				? qs.stringify(PROJECTS_INIT_PAGINATION)
				: qs.stringify({ ...pagination, search_value: aciveSearchValue })

			const activeLink = `/projects?${paginationOptions}`

			const data = await api.get(activeLink)
			const projects = get(data, 'result') || []

			if (isInitialFetch && !searchValue) {
				const firstFiveProject = slice(projects, 0, 5)
				dispatch(addPopularProjects(firstFiveProject))
			}

			dispatch(
				addProjectsToBottom({
					projects,
					pagination: data.pagination,
					searchValue: aciveSearchValue,
				})
			)

			if (onCompleted) {
				onCompleted()
			}
		} catch (error) {
			dispatch(setProjectLoading(false))
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not load projects'),
					type: 'error',
				})
			)
		}
	}
}

export const CLEAR_PROJECTS_SEARCH_AND_REFETCH = 'clearProjectsSearchAndRefetch'
export function clearProjectsSearchAndRefetch() {
	return (dispatch) => {
		dispatch({ type: CLEAR_PROJECTS_SEARCH_AND_REFETCH })
		dispatch(fetchProjects(PROJECTS_INIT_PAGINATION))
	}
}

export const ADD_PROJECT = `addProject`
export function addProject(project) {
	return { type: ADD_PROJECT, project }
}

export const ADD_PROJECTS_TO_BOTTOM = 'addProjectsToBottom'
export function addProjectsToBottom(projects) {
	return { type: ADD_PROJECTS_TO_BOTTOM, ...projects }
}

export const ON_PROJECT_DELETE = 'onProjectDelete'
export function onProjectDelete(projectIds) {
	return { type: ON_PROJECT_DELETE, projectIds }
}

const setActiveProject = (projectId) => {
	return async (dispatch) => {
		const location = history.location

		const queryParams = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		})

		dispatch(fetchAssetsByProjectId(projectId))
		history.replace({
			pathname: location.pathname,
			search: qs.stringify(
				{ ...omit(queryParams, 'assetId'), projectId },
				{ addQueryPrefix: true }
			),
		})
	}
}

export function deleteProjects(projectIds) {
	return async (dispatch, getState) => {
		try {
			dispatch(
				setModalViewProps({
					viewKey: MODAL_VIEWS.CONFIRMATION_MODAL,
					viewProps: {
						isLoading: true,
					},
				})
			)
			dispatch(setProjectLoading(true))
			dispatch(onProjectDelete(projectIds))

			const location = history.location
			const queryParams = qs.parse(location.search, {
				ignoreQueryPrefix: true,
			})
			const activeProjectId = parseInt(get(queryParams, 'projectId'))
			const isSelectedProjectDeleted = projectIds.includes(activeProjectId)

			if (isSelectedProjectDeleted) {
				dispatch(stopCsvExportPoll())
			}

			await api.delete(
				`/projects?project_id=${projectIds.join('&project_id=')}`
			)

			dispatch(closeModal())

			const pagination = getProjectsPaginationOptions(getState())

			const newPagination = {
				...pagination,
				offset:
					pagination.count === projectIds.length
						? Math.max(0, pagination.offset - pagination.limit)
						: pagination.offset,
			}

			// loading state will be handled by fetchProjects
			dispatch(
				fetchProjects(newPagination, () => {
					const restOfProjects = getProjects(getState())
					const nextProject = first(restOfProjects)

					if (nextProject && isSelectedProjectDeleted) {
						dispatch(setActiveProject(nextProject.id))
					}
				})
			)

			dispatch(deletePopularProject(projectIds))
		} catch (error) {
			dispatch(
				setModalViewProps({
					viewKey: MODAL_VIEWS.CONFIRMATION_MODAL,
					viewProps: {
						isLoading: false,
					},
				})
			)
			dispatch(setProjectLoading(false))
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not delete project(s)'),
					type: 'error',
				})
			)
		}
	}
}

export const ON_PROJECT_EDIT = 'onProjectEdit'
export function onProjectEdit(projectId, payload) {
	return { type: ON_PROJECT_EDIT, projectId, payload }
}

const sendProjectEditRequest = debounce(
	async (projectId, payload, dispatch) => {
		try {
			const params = qs.stringify({
				project_id: projectId,
				...payload,
			})

			await api.put(`/projects?${params}`)
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(error, 'Error: Could not edit project'),
					type: 'error',
				})
			)
		}
	},
	350
)

export function editProject(projectId, payload) {
	return (dispatch) => {
		dispatch(onProjectEdit(projectId, payload))
		dispatch(editPopularProject(projectId, payload))

		if (payload.name.trim().length > 2) {
			sendProjectEditRequest(projectId, payload, dispatch)
		}
	}
}

export const UPDATE_PROJECT_ITEM = 'updateProjectItem'
export function updateProjectItem(project) {
	return { type: UPDATE_PROJECT_ITEM, project }
}
