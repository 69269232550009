import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { find, get, isEmpty, omit } from 'lodash'
import { connect } from 'react-redux'
import qs from 'qs'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { usePrevious } from '../../customHooks'
import ProjectsSelector from './ProjectsSelector'
import Assets from './Assets'
import Summary from './Summary'
import Adaptations from './Adaptations'
import { ActionButtons } from './ActionButtons'
import { PROJECT_SIDEBAR_TABS } from '../ProjectSidebarTabs'
import {
	createExplorerLink,
	createLink,
	createAssetFullName,
	createAdaptationsLink,
	createSidebarQueryParams,
	isEpcTargetYearValid,
} from 'src/utils'
import {
	getProjectSummaryLoading,
	getSelectedProject,
} from '../../redux/selectors/projects'
import { getPopularProjects } from '../../redux/selectors/popularProjects'
import { getAdaptations } from '../../redux/selectors/adaptations'
import { fetchAssetsByProjectId } from '../../redux/actions/assets'
import {
	CSV_EXPORT_EMAIL_NOTIFICATION_MIN_ASSETS_COUNT,
	EXPLORER_ROUTE,
	PDF_EXPORT_EMAIL_NOTIFICATION_MIN_ASSETS_COUNT,
	ADAPTATIONS_ROUTE,
} from '../../constants'
import { ProjectCompare } from '../ProjectCompare'
import { editProject } from 'src/redux/actions/projects'
import { createDuplicateProject } from 'src/redux/actions/projectCompare'
import {
	clearAssetsSearch,
	clearPdfExportData,
	exportAssets,
	exportPdf,
	setCsvExportUrl,
	stopCsvExportPoll,
	downloadDataDictionary,
} from 'src/redux/actions/assets'
import ProjectSidebarTabs from '../ProjectSidebarTabs'
import { getCompareProject } from '../../redux/selectors/compareProject'
import {
	getAssetsCsvExportData,
	getAssetsListLoading,
	getAssetsPdfExportData,
	getAssetsPdfLoading,
	getAssetsTotalCount,
} from 'src/redux/selectors/assets'
import { getIsAssetSummaryLoading } from '../../redux/selectors/assetSummary'
import { closeModal, setModal } from 'src/redux/actions/modal'
import { MODAL_VIEWS } from 'src/containers/ModalViews'
import { history } from 'src/redux/store'
import './styles.scss'

const _ProjectSideBar = ({
	assetsTotalCount,
	isAssetsListLoading,
	projectsData,
	adaptationsData,
	editProject,
	exportAssets,
	downloadDataDictionary,
	exportPdf,
	createDuplicateProject,
	compareProject,
	isAssetsPdfLoading,
	isAssetSummaryLoading,
	assetsPdfExportData,
	setCsvExportUrl,
	stopCsvExportPoll,
	setModal,
	closeModal,
	assetsCsvExportdata,
	clearPdfExportData,
	projectSummaryLoading,
	clearAssetsSearch,
	fetchAssetsByProjectId,
	selectedProject,
}) => {
	const [activeTab, setActiveTab] = useState(PROJECT_SIDEBAR_TABS.SUMMARY)

	const history = useHistory()
	const location = useLocation()

	const projects = get(projectsData, 'data')
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
	const defaultScenario = get(queryParams, 'scenario')
	const defaultYear = get(queryParams, 'year')
	const defaultTargetEpc = get(queryParams, 'targetEpc')
	const defaultEpcTargetYear = get(queryParams, 'epcTargetYear')
	const selectedProjectId = get(queryParams, 'projectId')
	const riskType = get(queryParams, 'hazardType')
	const defended = get(queryParams, 'defended')
	let assetId = get(queryParams, 'assetId')
	const assetName = get(queryParams, 'assetName')

	const selectedTab = get(queryParams, 'activeTab', activeTab)

	const isExploreRoute = find([EXPLORER_ROUTE], (route) =>
		matchPath(location.pathname, { path: route })
	)

	const isAdaptationsRoute = find([ADAPTATIONS_ROUTE], (route) =>
		matchPath(location.pathname, { path: route })
	)

	const [shouldFetchAssets, setShouldFetchAssets] = useState(false)
	const [shouldFetchAdaptations, setShouldFetchAdaptations] = useState(true)
	const prevProjectId = usePrevious(selectedProjectId)
	const prevAssetId = usePrevious(assetId)

	useEffect(() => {
		if (
			defaultScenario &&
			defaultYear &&
			defaultTargetEpc &&
			selectedProjectId &&
			isEpcTargetYearValid(defaultEpcTargetYear)
		) {
			clearPdfExportData()
		}
	}, [
		defended,
		defaultYear,
		defaultEpcTargetYear,
		defaultScenario,
		defaultTargetEpc,
		selectedProjectId,
		assetsTotalCount,
	])

	useEffect(() => {
		if (!isEmpty(projects)) {
			const isRequireParamsMissing =
				!defaultScenario ||
				!defaultYear ||
				!defaultEpcTargetYear ||
				!defaultTargetEpc ||
				!selectedProjectId ||
				!defended
			if (isRequireParamsMissing) {
				// Set default query params to preselect project, scenario and year
				history.replace(
					createLink(location.pathname, {
						queryParams: createSidebarQueryParams({
							scenario: defaultScenario,
							year: defaultYear,
							targetEpc: defaultTargetEpc,
							epcTargetYear: defaultEpcTargetYear,
							projectId: selectedProjectId,
							defended: defended,
						}),
					})
				)
			}
		}
	}, [projects])

	const openConfirmationModal = (url, callback, question) => {
		setModal({
			viewKey: MODAL_VIEWS.CONFIRMATION_MODAL,
			viewProps: {
				question: question,
				text: 'Please click the button below to download it.',
				confirmLabel: 'Download',
				cancelLabel: 'Cancel',
				onCancel: () => {
					closeModal()
					callback(null)
				},
				onConfirm: () => {
					window.open(url, '_self')
					closeModal()
					callback(null)
				},
			},
		})
	}

	const handleAssetsReview = (asset) => {
		const assetName = createAssetFullName(asset)

		if (location.pathname.includes(EXPLORER_ROUTE)) {
			history.replace({
				pathname: location.pathname,
				search: qs.stringify(
					{
						...queryParams,
						assetId: asset.id,
						assetName,
						goTo: [asset.longitude, asset.latitude],
						activeTab: 'summary',
					},
					{ addQueryPrefix: true }
				),
			})
		} else {
			history.push(
				createExplorerLink({
					...queryParams,
					assetId: asset.id,
					assetName,
					goTo: [asset.longitude, asset.latitude],
				})
			)
		}
		setActiveTab(PROJECT_SIDEBAR_TABS.SUMMARY)
	}

	useEffect(() => {
		if (activeTab !== PROJECT_SIDEBAR_TABS.SUMMARY) {
			clearVolumeAndScoreFilters()
		}
		if (activeTab !== PROJECT_SIDEBAR_TABS.ASSETS) {
			clearAssetsSearch()
		}
		if (activeTab !== PROJECT_SIDEBAR_TABS.ADAPTATIONS) {
			/// do something here too
		}
	}, [activeTab])

	useEffect(() => {
		if (assetId) {
			setActiveTab(PROJECT_SIDEBAR_TABS.SUMMARY)
		}
	}, [assetId])

	useEffect(() => {
		if (!assetsCsvExportdata.loading && assetsCsvExportdata.url) {
			openConfirmationModal(
				assetsCsvExportdata.url,
				setCsvExportUrl,
				'Your data extract is ready'
			)
		}
	}, [assetsCsvExportdata])

	useEffect(() => {
		setShouldFetchAdaptations(prevAssetId !== assetId)
		return () => {
			stopCsvExportPoll()
			clearPdfExportData()
		}
	}, [assetId])

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const activeTabFromUrl = searchParams.get('activeTab')
		if (
			activeTabFromUrl &&
			Object.values(PROJECT_SIDEBAR_TABS).includes(activeTabFromUrl)
		) {
			setActiveTab(activeTabFromUrl)
		}
	}, [location])

	useEffect(() => {
		if (!shouldFetchAssets) {
			setShouldFetchAssets(prevProjectId !== selectedProjectId)
		}

		return () => {
			stopCsvExportPoll()
			clearPdfExportData()
		}
	}, [selectedProjectId, prevProjectId, stopCsvExportPoll, clearPdfExportData])

	const projectSelect = (updatedParams) => {
		handleQueryParamsChange('project', updatedParams)

		fetchAssetsByProjectId(updatedParams.projectId, undefined, true)
	}

	const fetchAssets = () => {
		fetchAssetsByProjectId(selectedProjectId, undefined, false)
	}

	const handleQueryParamsChange = (key, updatedParams) => {
		let newParams = { ...queryParams, ...updatedParams }
		if (key === 'project' && get(newParams, 'assetId')) {
			newParams = omit(newParams, ['assetId'])
		}

		history.replace({
			pathname: location.pathname,
			search: qs.stringify({ ...newParams }),
		})
	}

	const handleHeatMapClick = (e) => {
		e.stopPropagation()
		history.push(
			createExplorerLink({
				...queryParams,
				projectId: selectedProjectId,
			})
		)
	}

	const handleProjectSummaryClick = (e) => {
		e.stopPropagation()

		if (isExploreRoute && assetId) {
			setActiveTab(PROJECT_SIDEBAR_TABS.SUMMARY)
			history.replace(
				createExplorerLink({
					...omit(queryParams, ['assetId', 'assetName', 'goTo']),
				})
			)
		}
	}

	const clearVolumeAndScoreFilters = () => {
		history.replace({
			pathname: location.pathname,
			search: qs.stringify(
				omit(queryParams, ['assets_volume_key', 'assets_score_key']),
				{
					addQueryPrefix: true,
				}
			),
		})
	}

	const handleDownloadDataDict = () => {
		downloadDataDictionary()
	}

	const handleProjectCompare = () => {
		clearVolumeAndScoreFilters()
		createDuplicateProject({
			projectId: selectedProjectId,
			selectedYear: defaultYear,
			selectedEpcTargetYear: defaultEpcTargetYear,
			selectedScenario: defaultScenario,
			selectedTargetEpc: defaultTargetEpc,
			defended: defended,
		})
	}

	const handleTabSelect = (tab, e) => {
		e.stopPropagation()

		if (tab === PROJECT_SIDEBAR_TABS.ADAPTATIONS) {
			history.push(
				createAdaptationsLink({
					...queryParams,
					projectId: selectedProjectId,
					activeTab: tab,
				})
			)
		} else {
			history.push({
				pathname: location.pathname,
				search: qs.stringify(omit({ ...queryParams, activeTab: tab }), {
					addQueryPrefix: true,
				}),
			})
		}
	}

	const handleCsvExport = () => {
		const assetsCount = get(selectedProject, 'num_assets', 0)
		const shouldUserReceiveEmail =
			assetsCount > CSV_EXPORT_EMAIL_NOTIFICATION_MIN_ASSETS_COUNT

		exportAssets(selectedProjectId, shouldUserReceiveEmail, location)
	}

	const handlePdfExport = () => {
		const assetsCount = get(selectedProject, 'num_assets', 0)
		const shouldUserReceiveEmail =
			assetsCount > PDF_EXPORT_EMAIL_NOTIFICATION_MIN_ASSETS_COUNT

		if (assetsPdfExportData?.url) {
			window.open(assetsPdfExportData.url, '_blank')
		} else {
			exportPdf({
				selectedProjectId: selectedProjectId,
				shouldUserReceiveEmail: shouldUserReceiveEmail,
				defaultScenario: defaultScenario,
				defaultYear: defaultYear,
				defaultTargetEpc: defaultTargetEpc,
				defaultEpcTargetYear: defaultEpcTargetYear,
				defended: defended,
				selectedAssetId: assetId || 0, // when in project summary, assetID is not defined, make it default as 0
			})
		}
	}

	const handleAssetsScoreSelect = (assetsScoreData) => {
		if (isExploreRoute) {
			if (
				assetsScoreData &&
				queryParams.assets_score_key !== assetsScoreData.riskType
			) {
				history.replace(
					createLink(location.pathname, {
						queryParams: {
							...queryParams,
							assets_score_key: assetsScoreData.riskType,
						},
					})
				)
			} else {
				history.replace(
					createLink(location.pathname, {
						queryParams: {
							...omit(queryParams, ['assets_score_key']),
						},
					})
				)
			}
		} else {
			history.push(
				createExplorerLink({
					...queryParams,
					assets_score_key: assetsScoreData.riskType,
				})
			)
		}
	}

	const handleVolumeDistributionSelect = (paramKey) => {
		if (isExploreRoute) {
			if (paramKey && queryParams.assets_volume_key !== paramKey) {
				history.replace(
					createExplorerLink({
						...queryParams,
						assets_volume_key: paramKey,
					})
				)
			} else {
				history.replace(
					createExplorerLink({
						...omit(queryParams, ['assets_volume_key']),
					})
				)
			}
		} else {
			history.push(
				createExplorerLink({
					...queryParams,
					assets_volume_key: paramKey,
				})
			)
		}
	}

	if (isEmpty(projects) && !projectSummaryLoading) {
		return null
	}

	return (
		<>
			{!isEmpty(compareProject.project) && (
				<ProjectCompare assetId={assetId} assetName={assetName} />
			)}
			<div className='project-sidebar' onClick={clearVolumeAndScoreFilters}>
				<ProjectsSelector
					projects={projects}
					queryParams={queryParams}
					selectedProject={selectedProject}
					onProjectSelect={(updatedParams) => projectSelect(updatedParams)}
					onProjectNameEdit={(projectName) =>
						editProject(Number(selectedProjectId), { name: projectName })
					}
				/>

				<ProjectSidebarTabs
					onTabSelect={handleTabSelect}
					activeTab={activeTab}
				/>
				<div className='project-sidebar__body'>
					{selectedTab === PROJECT_SIDEBAR_TABS.ASSETS && (
						<Assets
							projectId={selectedProjectId}
							defaultScenario={defaultScenario}
							defaultDefend={defended}
							defaultYear={defaultYear}
							defaultTargetEpc={defaultTargetEpc}
							defaultEpcTargetYear={defaultEpcTargetYear}
							prevProjectId={prevProjectId}
							onAssetsFetch={() => setShouldFetchAssets(false)}
							shouldFetchAssets={shouldFetchAssets}
							onAssetReview={handleAssetsReview}
							location={location}
							history={history}
						/>
					)}
					{selectedTab === PROJECT_SIDEBAR_TABS.SUMMARY && (
						<Summary
							onSelect={(updatedParams) =>
								handleQueryParamsChange('summary', updatedParams)
							}
							defaultDefend={defended}
							defaultScenario={defaultScenario}
							defaultYear={defaultYear}
							defaultEpcTargetYear={defaultEpcTargetYear}
							selectedProjectId={selectedProjectId}
							prevProjectId={prevProjectId}
							assetId={assetId}
							assetName={assetName}
							history={history}
							defaultRiskType={riskType}
							isCompareEnabled={!isEmpty(compareProject.project)}
							onRiskTypeSet={(riskType) =>
								handleQueryParamsChange('hazardType', { hazardType: riskType })
							}
							onAssetsScoreSelect={handleAssetsScoreSelect}
							onVolumeDistributionSelect={handleVolumeDistributionSelect}
							location={location}
							projectSummaryLoading={projectSummaryLoading}
						/>
					)}
					{selectedTab === PROJECT_SIDEBAR_TABS.ADAPTATIONS && (
						<Adaptations
							currentAsset={adaptationsData.current_asset}
							selectedProjectId={selectedProjectId}
							onAdaptationsFetch={() => setShouldFetchAdaptations(false)}
							fetchAssets={fetchAssets}
						/>
					)}
				</div>

				<div className='project-sidebar__action-buttons'>
					<ActionButtons
						activeTab={activeTab}
						onHeatMapClick={handleHeatMapClick}
						onProjectSummaryClick={handleProjectSummaryClick}
						onCompareClick={handleProjectCompare}
						onDownloadDataDictClick={handleDownloadDataDict}
						isExploreRoute={!!isExploreRoute}
						isAdaptationsRoute={isAdaptationsRoute}
						assetId={assetId}
						isAssetSummaryLoading={isAssetSummaryLoading}
						isAssetsListLoading={isAssetsListLoading}
						isAssetsPdfLoading={isAssetsPdfLoading}
						assetsPdfExportData={assetsPdfExportData}
						assetsCsvExportdata={assetsCsvExportdata}
						onExport={() =>
							activeTab === PROJECT_SIDEBAR_TABS.SUMMARY
								? handlePdfExport()
								: handleCsvExport()
						}
					/>
				</div>
			</div>
		</>
	)
}

_ProjectSideBar.propTypes = {
	isAssetsPdfLoading: PropTypes.bool.isRequired,
	isAssetSummaryLoading: PropTypes.bool.isRequired,
	projectsData: PropTypes.object.isRequired,
	adaptationsData: PropTypes.object,
	selectedProject: PropTypes.object,
	exportAssets: PropTypes.func.isRequired,
	downloadDataDictionary: PropTypes.func.isRequired,
	createDuplicateProject: PropTypes.func.isRequired,
	setCsvExportUrl: PropTypes.func.isRequired,
	stopCsvExportPoll: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
}

export const ProjectSideBar = connect(
	(state) => {
		const queryParams = qs.parse(history.location.search, {
			ignoreQueryPrefix: true,
		})

		return {
			isAssetsListLoading: getAssetsListLoading(state),
			projectsData: getPopularProjects(state),
			isAssetsPdfLoading: getAssetsPdfLoading(state),
			isAssetSummaryLoading: getIsAssetSummaryLoading(state),
			compareProject: getCompareProject(state),
			assetsPdfExportData: getAssetsPdfExportData(state),
			assetsTotalCount: getAssetsTotalCount(state),
			assetsCsvExportdata: getAssetsCsvExportData(state),
			projectSummaryLoading: getProjectSummaryLoading(state),
			adaptationsData: getAdaptations(state),
			selectedProject: getSelectedProject(state, queryParams?.projectId),
		}
	},
	{
		editProject,
		exportAssets,
		downloadDataDictionary,
		createDuplicateProject,
		setCsvExportUrl,
		stopCsvExportPoll,
		setModal,
		closeModal,
		exportPdf,
		clearPdfExportData,
		clearAssetsSearch,
		fetchAssetsByProjectId,
	}
)(_ProjectSideBar)
