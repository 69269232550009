import React from 'react'
import { Progress } from 'reactstrap'
import PropTypes from 'prop-types'
import './styles.scss'
const ProgressIndicator = ({ progress }) => {
	const { progress: progressInPercentage, step } = progress
	return (
		<Progress multi>
			<Progress bar value={progressInPercentage} color='danger' />
			{!isNaN(progressInPercentage) && (
				<span className='progress-indicator'>
					{step} ... Loading - {progressInPercentage}%
				</span>
			)}
		</Progress>
	)
}

ProgressIndicator.propTypes = {
	progress: PropTypes.shape({
		val: PropTypes.number,
		text: PropTypes.string,
	}),
}

export default ProgressIndicator
