import React from 'react'
import PropTypes from 'prop-types'
import HomeMain from '../main'

export const HomeBody = ({ createProject, fetchUserSettings, isLoading }) => {
	return (
		<div className='app__product'>
			<div className='container-fluid px-0'>
				<div className='row'>
					<div className='col-lg-1'></div>
					<HomeMain
						createProject={createProject}
						fetchUserSettings={fetchUserSettings}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	)
}

HomeBody.propTypes = {
	createProject: PropTypes.func.isRequired,
	fetchUserSettings: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
}
