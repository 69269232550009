import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'
import { CheckBox } from '../CheckBox'
import { AssetListItem } from './AssetListItem'
import { Skeleton } from '../../components/Skeleton'
import { MODAL_VIEWS } from '../../containers/ModalViews'
import './styles.scss'

function AssetsList({
	assets,
	onDelete,
	selectAsset,
	selectAllAssets,
	onAssetReview,
	isLoading,
	setModal,
	closeModal,
}) {
	const handleDeleteAsset = async (item) => {
		setModal({
			viewKey: MODAL_VIEWS.CONFIRMATION_MODAL,
			viewProps: {
				question: item.street_name
					? `Are you sure you want to delete ${item.street_name}?`
					: `Are you sure you want to delete asset: ${item.asset_id}?`,
				onCancel: () => {
					closeModal()
				},
				onConfirm: () => {
					onDelete(item.id)
					closeModal()
				},
			},
		})
	}

	return (
		<Fragment>
			<div className='assets-list'>
				<div className='assets-list__table'>
					<div className='assets-list__header-group'>
						<div className='assets-list__header-cell'>
							<CheckBox
								appearance='circle'
								onChange={(value) => selectAllAssets(value)}
							/>
						</div>
						<div className='assets-list__header-cell'>ID</div>
						<div className='assets-list__header-cell'>Address</div>
						<div
							style={{ width: '7em' }}
							className='assets-list__header-cell'
							data-bs-original-title='Total number of Assets in the Project'
						>
							Post code
						</div>
						<div
							style={{ width: '9em' }}
							className='assets-list__header-cell'
							data-bs-original-title='Date Project was last saved'
						>
							Country
						</div>
						<div
							style={{ width: '6em' }}
							className='assets-list__header-cell'
							data-bs-original-title='Operational features, edit, delete, etc.'
						>
							Options
						</div>
					</div>
					<div className='assets-list__body-group'>
						{!isLoading &&
							assets.map((item) => (
								<AssetListItem
									key={item.id}
									asset={item}
									isActive={item.isSelected}
									onDelete={() => handleDeleteAsset(item)}
									onSelect={(isActive) => selectAsset(isActive, item.id)}
									onReview={() => onAssetReview(item)}
								/>
							))}
					</div>
				</div>
				{isLoading &&
					range(20).map((it) => (
						<Skeleton
							key={it}
							height={24}
							className='projections-list__row-skeleton'
						/>
					))}
			</div>
		</Fragment>
	)
}

AssetsList.propTypes = {
	assets: PropTypes.arrayOf(PropTypes.object).isRequired,
	projectId: PropTypes.number.isRequired,
	defaultScenario: PropTypes.string.isRequired,
	defaultDefend: PropTypes.string.isRequired,
	defaultYear: PropTypes.string.isRequired,
	defaultEpcTargetYear: PropTypes.string.isRequired,
	defaultTargetEpc: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
	selectAsset: PropTypes.func.isRequired,
	selectAllAssets: PropTypes.func.isRequired,
	onAssetReview: PropTypes.func.isRequired,
	addToast: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
}

export default AssetsList
