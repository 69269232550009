import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import qs from 'qs'
import { useHistory, NavLink, useLocation } from 'react-router-dom'
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap'
import { connect } from 'react-redux'
import { openSideBar } from '../../redux/actions/sidebar'
import { signOutUser } from '../../redux/actions/user'
import { ReactComponent as Logo } from '../../assets/icons/header-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	HELP_PAGE_LOGIN_URL,
	HELP_PAGE_URL,
	LEFT_COLUMN_CLASSES,
	LOCAL_STORAGE_KEY,
	RIGHT_COLUMN_CLASSES,
	WALKTHROUGH,
	SETTINGS_ROUTE,
} from 'src/constants'
import {
	createHelpPageUrl,
	createHomeLink,
	createProjectsLink,
} from 'src/utils'
import './styles.scss'

const _LoggedInHeader = ({
	openSideBar,
	signOutUser,
	userData,
	isUnreadAnnouncementExists,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [isHelpDropdownOpen, setIsHelpDropdownOpen] = useState(false)
	const location = useLocation()
	const history = useHistory()

	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

	const logOutUser = (e) => {
		e.preventDefault()
		signOutUser()
	}

	const onHelpCentreClick = () => {
		let url = HELP_PAGE_URL
		if (!localStorage.getItem(LOCAL_STORAGE_KEY.IS_LOGGED_IN_ON_HELP_PAGE)) {
			url = createHelpPageUrl({
				baseUrl: HELP_PAGE_LOGIN_URL,
				redirectUrl: HELP_PAGE_URL,
				jwt: userData.accessRights?.access_token,
			})
			localStorage.setItem(
				LOCAL_STORAGE_KEY.IS_LOGGED_IN_ON_HELP_PAGE,
				Boolean(true).toString()
			)
		}
		// window.location.replace(url)
		window.open(url)
	}

	const onGuideClick = () => {
		localStorage.removeItem(WALKTHROUGH)
		history.replace(createHomeLink(queryParams))
	}

	return (
		<header className='logged-in-header'>
			<div className='container-fluid'>
				<div className='row'>
					{/* <!-- Header NAV Left Side --> */}
					<div
						className={`${LEFT_COLUMN_CLASSES} col-sm-12 col-xs-12 order-xs-2 logged-in-header__left`}
					>
						<NavLink
							to={createHomeLink(queryParams)}
							className='logged-in-header__logo-left'
						>
							<img
								src='img/Logo_Spectra_Greytransparent.png'
								alt='ClimateX Logo'
							/>
						</NavLink>
					</div>
					{/* <!-- // Header NAV Left Side --> */}

					{/* <!-- Header NAV Right Side --> */}
					<div
						className={`${RIGHT_COLUMN_CLASSES} col-sm-12 col-xs-12 order-xs-1 logged-in-header__right`}
					>
						<div className='d-flex align-items-center justify-content-between logged-in-header__right-section-wrapper'>
							{/* <!-- Data Manager --> */}
							<NavLink
								id='nav-link-projects'
								to={createProjectsLink(queryParams)}
								className='nav-link text-center'
								activeClassName='active-link'
							>
								<FontAwesomeIcon
									icon={['far', 'table-layout']}
									className='logged-in-header__link-icon'
								/>
								<span className='logged-in-header__link-label'>Projects</span>
							</NavLink>
							{/* <!-- // Data Manager --> */}

							{/* <!-- Icons --> */}
							<div className='text-center logged-in-header__right-icons'>
								<span
									id='nav-link-announcements'
									className={classnames(
										'nav-link logged-in-header__notifications-icon',
										isUnreadAnnouncementExists &&
											'logged-in-header__notifications-icon--active'
									)}
									data-bs-toggle='offcanvas'
									role='button'
									aria-controls='climatexcomannouncements'
									onClick={() => openSideBar({ view: 'notifications' })}
								>
									<FontAwesomeIcon icon={['far', 'bullhorn']} />
								</span>

								<ButtonDropdown
									id='nav-link-helps'
									className='logged-in-header__dropdown logged-in-header__dropdown--help'
									isOpen={isHelpDropdownOpen}
									toggle={() => setIsHelpDropdownOpen(!isHelpDropdownOpen)}
								>
									<DropdownToggle
										caret
										className='logged-in-header__dropdown-toggle logged-in-header__dropdown-toggle--help'
									>
										<span role='button'>
											<FontAwesomeIcon icon={['far', 'circle-question']} />
										</span>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem>
											<span onClick={onHelpCentreClick}>Help Centre</span>
										</DropdownItem>
										<DropdownItem>
											<span onClick={onGuideClick}>Guide</span>
										</DropdownItem>
									</DropdownMenu>
								</ButtonDropdown>
							</div>
							{/* <!-- // Icons --> */}

							{/* <!-- User Options --> */}
							<ButtonDropdown
								id='logged-in-header__user-dropdown'
								className='logged-in-header__dropdown'
								isOpen={isDropdownOpen}
								toggle={() => setIsDropdownOpen(!isDropdownOpen)}
								data-locator-id='user-dropdown'
							>
								<DropdownToggle
									caret
									className='logged-in-header__user-fullname'
								>
									<FontAwesomeIcon
										icon={['far', 'circle-user']}
										className='d-block logged-in-header__user-icon'
									/>
									<span className='d-none d-sm-block'>
										{(userData.name &&
											userData.name + ' ' + userData.family_name) ||
											userData.email}
									</span>
									<FontAwesomeIcon icon={['far', 'chevron-down']} />
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem>
										<div data-locator-id='user-dropdown-settings'>
											<NavLink to={SETTINGS_ROUTE}>Settings</NavLink>
										</div>
									</DropdownItem>
									<DropdownItem>
										<a href='#' onClick={logOutUser}>
											Log out
										</a>
									</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
							{/* <!-- // User Options --> */}

							{/* <!-- CLX Logo --> */}
							<div className='logged-in-header__logo-right'>
								<span onClick={() => openSideBar({ view: 'menu' })}>
									<Logo height='36px' />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

_LoggedInHeader.propTypes = {
	openSideBar: PropTypes.func.isRequired,
	isUnreadAnnouncementExists: PropTypes.bool.isRequired,
}
const mapStateToProps = (state) => ({
	userData: state.user.userData,
	isUnreadAnnouncementExists: state.announcements.isUnreadAnnouncementExists,
})
export const LoggedInHeader = connect(mapStateToProps, {
	openSideBar,
	signOutUser,
})(_LoggedInHeader)
