import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ProjectSummaryContent from '../../../ProjectSummaryContent'
import 'react-circular-progressbar/dist/styles.css'

function ProjectSummary({
	projectSummary,
	projectSummaryLoading,
	projectSummaryErrorMessage,
	selectedProjectId,
	defaultScenario,
	defaultYear,
	defaultEpcTargetYear,
	fetchProjectSummary,
	defaultRiskType,
	onRiskTypeSet,
	onAssetsScoreSelect,
	onVolumeDistributionSelect,
	isCompareEnabled,
	defended,
	userSettings,
	userFeatureFlags,
	defaultTargetEpc,
}) {
	useEffect(() => {
		if (
			selectedProjectId &&
			defaultScenario &&
			defaultYear &&
			defaultEpcTargetYear
		) {
			fetchProjectSummary(
				defaultScenario,
				defaultYear,
				defaultEpcTargetYear,
				defended,
				selectedProjectId
			)
		}
	}, [
		selectedProjectId,
		defaultScenario,
		defaultYear,
		defaultEpcTargetYear,
		defended,
		defaultTargetEpc,
	])

	return (
		<ProjectSummaryContent
			projectSummary={projectSummary}
			projectSummaryLoading={projectSummaryLoading}
			projectSummaryErrorMessage={projectSummaryErrorMessage}
			defaultRiskType={defaultRiskType}
			onRiskTypeSet={onRiskTypeSet}
			isMainSideBar
			isCompareEnabled={isCompareEnabled}
			onAssetsScoreSelect={onAssetsScoreSelect}
			shouldUpdateDefaultRiskType
			onVolumeDistributionSelect={onVolumeDistributionSelect}
			userSettings={userSettings}
			userFeatureFlags={userFeatureFlags}
			targetEpc={defaultTargetEpc}
		/>
	)
}

ProjectSummary.propTypes = {
	projectSummary: PropTypes.object,
	onAssetsScoreSelect: PropTypes.func,
	projectSummaryLoading: PropTypes.bool,
	isCompareEnabled: PropTypes.bool.isRequired,
	projectSummaryErrorMessage: PropTypes.string,
	selectedProjectId: PropTypes.string,
	defaultScenario: PropTypes.string,
	defaultYear: PropTypes.string,
	defaultEpcTargetYear: PropTypes.string,
	fetchProjectSummary: PropTypes.func.isRequired,
	defaultRiskType: PropTypes.string,
	onRiskTypeSet: PropTypes.func,
	defended: PropTypes.string,
	userSettings: PropTypes.object,
	userFeatureFlags: PropTypes.object,
	defaultTargetEpc: PropTypes.string,
}

export default ProjectSummary
