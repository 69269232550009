export const SCORE_PALETTE = {
	A: 'rgb(167, 167, 166)',
	B: 'rgb(246, 180, 185)',
	C: 'rgb(239, 120, 128)',
	D: 'rgb(227, 6, 22)',
	E: 'rgb(184, 24, 26)',
	F: 'rgb(105, 20, 16)',
	G: 'rgb(97, 0, 0)',
}

export const VOLUME_DISTRIBUTION_PALETTE = {
	improbable: SCORE_PALETTE.A,
	unlikely: SCORE_PALETTE.B,
	likely: SCORE_PALETTE.C,
	high: SCORE_PALETTE.D,
	very_high: SCORE_PALETTE.E,
	extreme: SCORE_PALETTE.F,
}
