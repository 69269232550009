import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from '../actions/sidebar'

const DEFAULT_STATE = {
	//Keys: 'notifications' || 'menu'
	view: null,
}

export default function sidebar(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case OPEN_SIDEBAR:
			return {
				view: action.view,
			}
		case CLOSE_SIDEBAR:
			return {
				view: null,
			}
		default:
			return state
	}
}
