import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isUndefined } from 'lodash'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { createProject } from '../../redux/actions/projects'
import { fetchUserSettings } from '../../redux/actions/user'
import { getProjectsLoading, getProjects } from '../../redux/selectors/projects'
import { HomeBody } from './HomeBody'
import { getProjectSummaryLoading } from '../../redux/selectors/projects'
import {
	WALKTHROUGH_STEPS_HOME_PAGE,
	WALKTHROUGH,
	WALKTHROUGH_SPOTLIGHT_STYLES,
} from '../../constants'
import { createExplorerLink } from 'src/utils'
import './styles.scss'

function Home({
	createProject,
	fetchUserSettings,
	projectsLoading,
	projectSummaryLoading,
	projects,
	location,
}) {
	const history = useHistory()
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
	const walkthroughLocalStorage = localStorage.getItem(WALKTHROUGH)
	const isHomePageWalkThroughDone =
		walkthroughLocalStorage &&
		JSON.parse(walkthroughLocalStorage)?.isHomePageWalkThroughDone === 'true'

	const [walkthrough, setWalkthrough] = useState({
		run: false,
		stepIndex: 0,
		tourActive: true,
	})

	useEffect(() => {
		if (
			!projectsLoading &&
			!isUndefined(projectsLoading) &&
			!projectSummaryLoading &&
			!isHomePageWalkThroughDone
		) {
			setWalkthrough({
				...walkthrough,
				run: !isHomePageWalkThroughDone,
			})
		}
	}, [projectsLoading, projectSummaryLoading, location])

	const handleCallback = (data) => {
		const { action, index, type, status } = data

		if (action === ACTIONS.CLOSE) {
			localStorage.setItem(
				WALKTHROUGH,
				JSON.stringify({
					isHomePageWalkThroughDone: 'true',
					isExplorePageWalkthroughDone: 'true',
				})
			)
			setWalkthrough({ ...walkthrough, run: false, stepIndex: 0 })
			return
		}

		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			setWalkthrough({ ...walkthrough, run: false, stepIndex: 0 })

			localStorage.setItem(
				WALKTHROUGH,
				JSON.stringify({ isHomePageWalkThroughDone: 'true' })
			)

			if (projects && projects.length) {
				history.push(createExplorerLink(queryParams))
			}
		} else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)

			setWalkthrough({
				...walkthrough,
				stepIndex: nextStepIndex,
			})
		}
	}

	return (
		<>
			<Joyride
				callback={handleCallback}
				continuous
				run={walkthrough.run}
				stepIndex={walkthrough.stepIndex}
				steps={WALKTHROUGH_STEPS_HOME_PAGE}
				disableOverlayClose
				disableScrolling
				floaterProps={{ disableAnimation: true }}
				locale={{ last: 'Next' }}
				styles={{
					spotlight: WALKTHROUGH_SPOTLIGHT_STYLES,
				}}
			/>
			<HomeBody
				createProject={createProject}
				fetchUserSettings={fetchUserSettings}
				isLoading={projectSummaryLoading}
			/>
		</>
	)
}

Home.propTypes = {
	createProject: PropTypes.func.isRequired,
	fetchUserSettings: PropTypes.func.isRequired,
	projectsLoading: PropTypes.bool,
}

export default connect(
	(state) => ({
		projectsLoading: getProjectsLoading(state),
		projects: getProjects(state),
		projectSummaryLoading: getProjectSummaryLoading(state),
	}),
	{ createProject, fetchUserSettings }
)(Home)
