import qs from 'qs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { get, omit } from 'lodash'
import api from 'src/service/api'
import {
	AEROWAY_LINE,
	ASSET_MARKERS_CLUSTER_COUNT_ID,
	ASSET_MARKERS_CLUSTERS_ID,
	ASSET_MARKERS_SINGLE_ASSET_ID,
	ASSET_MARKERS_SOURCE_ID,
	ASSET_MARKERS_FILL_COLOR,
	BUILDINGS_SOURCE_ID,
	MAP_LAYER_ID_NOT_AVAILABLE,
	MAP_LAYER_ID_SEPARATOR,
	WATER_LAYER_ID,
	WATER_LAYER_SOURCE_ID,
	DEFAULT_EXTENT_VALUE,
	LAYER_MAX_BOUNDS,
	COUNTRY_CODE_MAP_MASKS,
	BUFFERING_LAYER_DEFAULT_COLOR,
} from 'src/constants'
import {
	createFullAddress,
	generateGeoJson,
	getDefaultScenario,
	isDefendValid,
	isHazardTypeValid,
	isScenarioValid,
	isYearValid,
} from 'src/utils'
import { usePrevious } from 'src/customHooks'
import { getAppConfig } from '../../config'

const getAssetById = async (assetId) => {
	const asset = await api(`/assets/${assetId}`)

	return asset
}

export const useQueryParams = ({
	locationPathname,
	locationSearch,
	historyReplace,
}) => {
	const [paramsToSet, setParamsToSet] = useState(null)
	const [paramsToDelete, setParamsToDelete] = useState(null)

	const queryParamsObj = qs.parse(locationSearch, { ignoreQueryPrefix: true })

	const prevQueryParams = useRef(queryParamsObj)

	const hazardType = useMemo(
		() => queryParamsObj.hazardType,
		[queryParamsObj.hazardType]
	)
	const projectId = useMemo(
		() => queryParamsObj.projectId,
		[queryParamsObj.projectId]
	)
	const assets_score_key = useMemo(
		() => queryParamsObj.assets_score_key,
		[queryParamsObj.assets_score_key]
	)
	const assets_volume_key = useMemo(
		() => queryParamsObj.assets_volume_key,
		[queryParamsObj.assets_volume_key]
	)
	const goTo = useMemo(() => queryParamsObj.goTo, [queryParamsObj.goTo])
	const assetId = useMemo(
		() => queryParamsObj.assetId,
		[queryParamsObj.assetId]
	)

	const { defended, scenario, year, lng, lat, zoom, assetName } = queryParamsObj

	/**
	 * Because some of the actions are created during the initialization of the map,
	 * they are setting initial query params instead of latest ones.
	 * To avoid this, we use state as a bridge to use new query params and spread them with the latest ones.
	 */
	useEffect(() => {
		const setQueryParams = (paramsToSet) => {
			const queryParams = qs.stringify(
				{ ...queryParamsObj, ...paramsToSet },
				{ addQueryPrefix: true }
			)
			historyReplace(`${locationPathname}${queryParams}`)
		}

		if (paramsToSet !== null) {
			setQueryParams(paramsToSet)
			setParamsToSet(null)
		}
	}, [paramsToSet])

	useEffect(() => {
		const deleteQueryParams = (paramsToDelete) => {
			const queryParams = { ...queryParamsObj }

			if (queryParams && paramsToDelete) {
				const newParams = omit(queryParams, paramsToDelete)

				const stringifiedQueryParams = qs.stringify(newParams, {
					addQueryPrefix: true,
				})

				historyReplace(`${locationPathname}${stringifiedQueryParams}`)
			}
		}

		if (paramsToDelete !== null) {
			deleteQueryParams(paramsToDelete)
			setParamsToDelete(null)
		}
	}, [paramsToDelete])

	useEffect(() => {
		prevQueryParams.current = queryParamsObj
	}, [queryParamsObj, prevQueryParams])

	return {
		scenario,
		hazardType,
		year,
		defended,
		lng: lng ? parseFloat(lng) : get(goTo, '0') || -3.3215, // default to UK
		lat: lat ? parseFloat(lat) : get(goTo, '1') || 54.2813,
		zoom: zoom ? parseFloat(zoom) : 3.6,
		assetId,
		projectId,
		assets_score_key,
		assets_volume_key,
		assetName,
		goTo,
		prevQueryParams: prevQueryParams.current,
		setQueryParams: (queryParams) => setParamsToSet(queryParams),
		deleteQueryParams: (queryParam) => setParamsToDelete(queryParam),
	}
}

export const useMapCenter = ({ projectId }) => {
	const prevAbortController = useRef(undefined)

	const fetchMapCenter = async (projectId) => {
		if (prevAbortController.current) {
			prevAbortController.current.abort()
		}

		prevAbortController.current = new AbortController()

		const signal =
			prevAbortController.current && prevAbortController.current.signal

		return await api(`/projects/${projectId}/center`, {
			signal,
		})
	}

	const setMapCenter = async ({ map }) => {
		if (!projectId || !map) return

		try {
			const center = await fetchMapCenter(projectId)

			if (center?.lon && center?.lat) {
				map.setCenter([center.lon, center.lat])
			}
		} catch (error) {
			return
		}
	}

	return { setMapCenter }
}

export const useAssets = ({
	projectId,
	onSelectAsset,
	assets_score_key,
	assets_volume_key,
	hazardType,
	year,
	scenario,
	defended,
	assetsSearchValue,
}) => {
	const prevAbortController = useRef(undefined)

	const fetchAssets = async ({
		projectId,
		assets_score_key,
		assets_volume_key,
		hazardType,
		year,
		scenario,
		defended,
		assetsSearchValue,
	}) => {
		const paramsObject = {
			project_id: projectId,
			risk_category: assets_score_key,
			year: parseInt(year),
			scenario: getDefaultScenario(scenario).scenario,
			action: getDefaultScenario(scenario).action,
			defended: defended === 'defended',
			one_in_x: DEFAULT_EXTENT_VALUE,
			search_value: assetsSearchValue,
			fetch_all: true,
		}

		if (assets_volume_key && hazardType) {
			paramsObject['hazard_type'] = hazardType
			paramsObject['score'] = assets_volume_key
		}

		const params = qs.stringify(paramsObject, { addQueryPrefix: true })

		if (prevAbortController.current) {
			prevAbortController.current.abort()
		}

		prevAbortController.current = new AbortController()

		const signal =
			prevAbortController.current && prevAbortController.current.signal

		return await api(`/assets${params}`, {
			signal,
		})
	}

	const fadeMarkers = (map, opacity) => {
		if (map.getLayer(ASSET_MARKERS_CLUSTERS_ID)) {
			map.setPaintProperty(ASSET_MARKERS_CLUSTERS_ID, 'circle-opacity', opacity)
			map.setPaintProperty(
				ASSET_MARKERS_CLUSTERS_ID,
				'circle-stroke-opacity',
				opacity
			)
		}
		if (map.getLayer(ASSET_MARKERS_SINGLE_ASSET_ID)) {
			map.setPaintProperty(
				ASSET_MARKERS_SINGLE_ASSET_ID,
				'circle-opacity',
				opacity
			)
			map.setPaintProperty(
				ASSET_MARKERS_SINGLE_ASSET_ID,
				'circle-stroke-opacity',
				opacity
			)
		}
	}

	const zoomToCluster = (map, e, layerId) => {
		const feature = map.queryRenderedFeatures(e.point, {
			layers: [layerId],
		})[0]

		if (!feature) {
			return
		}

		let zoomIncrease = 2
		let zoom = map.getZoom()

		if (zoom <= 3) {
			zoomIncrease = 4
		} else if (zoom > 3 && zoom <= 11) {
			zoomIncrease = 2
		} else {
			zoomIncrease = 1
		}

		map.easeTo({
			center: feature.geometry.coordinates,
			zoom: zoom + zoomIncrease,
		})
	}

	const updateOrCreateAssetClusters = async ({ map }) => {
		if (!projectId || !map) return

		fadeMarkers(map, 0.5)

		let responseData
		try {
			responseData = await fetchAssets({
				projectId,
				assets_score_key: assets_score_key,
				assets_volume_key: assets_volume_key,
				hazardType,
				year,
				scenario,
				defended,
				assetsSearchValue,
			})
		} catch (error) {
			return
		}

		const assetsData = generateGeoJson(responseData.result)

		fadeMarkers(map, 1)

		if (map.getSource(ASSET_MARKERS_SOURCE_ID)) {
			map.getSource(ASSET_MARKERS_SOURCE_ID).setData(assetsData)
			map.setPaintProperty(
				ASSET_MARKERS_CLUSTERS_ID,
				'circle-color',
				hazardType ? ASSET_MARKERS_FILL_COLOR : 'transparent'
			)
			map.setPaintProperty(
				ASSET_MARKERS_SINGLE_ASSET_ID,
				'circle-color',
				hazardType ? ASSET_MARKERS_FILL_COLOR : '#6b6b6b'
			)
			return
		}

		map.addSource(ASSET_MARKERS_SOURCE_ID, {
			type: 'geojson',
			data: assetsData,
			cluster: true,
			clusterMaxZoom: 15,
			clusterRadius: 75,
		})

		map.addLayer({
			id: ASSET_MARKERS_CLUSTERS_ID,
			type: 'circle',
			source: ASSET_MARKERS_SOURCE_ID,
			filter: ['has', 'point_count'],
			paint: {
				'circle-color': hazardType ? ASSET_MARKERS_FILL_COLOR : 'transparent',
				'circle-stroke-width': 3,
				'circle-stroke-color': '#6b6b6b',
				// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
				// with three steps to implement three types of circles:
				//   * 15px circles when point count is less than 50
				//   * 20px circles when point count is between 50 and 150
				//   * 30px circles when point count is greater than or equal to 150
				'circle-radius': ['step', ['get', 'point_count'], 15, 50, 20, 150, 30],
			},
		})

		map.addLayer({
			id: ASSET_MARKERS_CLUSTER_COUNT_ID,
			type: 'symbol',
			source: ASSET_MARKERS_SOURCE_ID,
			filter: ['has', 'point_count'],
			layout: {
				'text-field': '{point_count_abbreviated}',
				'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
				'text-size': 16,
			},
			paint: {
				'text-color': '#6b6b6b',
			},
		})

		map.addLayer({
			id: ASSET_MARKERS_SINGLE_ASSET_ID,
			type: 'circle',
			source: ASSET_MARKERS_SOURCE_ID,
			filter: ['!', ['has', 'point_count']],
			paint: {
				'circle-color': hazardType ? ASSET_MARKERS_FILL_COLOR : '#6b6b6b',
				'circle-radius': 8,
				'circle-stroke-width': 2,
				'circle-stroke-color': hazardType ? '#6b6b6b' : '#fff',
			},
		})

		// inspect a cluster on click
		map.on('click', ASSET_MARKERS_CLUSTERS_ID, (e) => {
			zoomToCluster(map, e, ASSET_MARKERS_CLUSTERS_ID)
		})

		map.on('zoomstart', () =>
			map.setLayoutProperty(
				ASSET_MARKERS_CLUSTER_COUNT_ID,
				'visibility',
				'none'
			)
		)

		map.on('zoomend', () =>
			map.setLayoutProperty(
				ASSET_MARKERS_CLUSTER_COUNT_ID,
				'visibility',
				'visible'
			)
		)

		map.on('mouseenter', ASSET_MARKERS_CLUSTERS_ID, () => {
			map.getCanvas().style.cursor = 'pointer'
		})
		map.on('mouseleave', ASSET_MARKERS_CLUSTERS_ID, () => {
			map.getCanvas().style.cursor = ''
		})
		map.on('mouseenter', ASSET_MARKERS_SINGLE_ASSET_ID, () => {
			map.getCanvas().style.cursor = 'pointer'
		})
		map.on('mouseleave', ASSET_MARKERS_SINGLE_ASSET_ID, () => {
			map.getCanvas().style.cursor = ''
		})
	}

	const createMarkerClickHandler = ({ map }) => {
		const onMarkerClick = async (e) => {
			const { id, latitude, longitude } = e.features[0].properties || {}

			const coordinates = [longitude, latitude]

			const asset = await getAssetById(id)
			const addressParts = []
			const addressFields = [
				'city',
				'postcode',
				'street_name',
				'building_number',
			]
			addressFields.forEach((field) => addressParts.push(asset[field]))

			const assetName = createFullAddress(addressParts)

			onSelectAsset({
				activeAsset: asset,
				coordinates,
				assetName,
			})
		}

		map.on('click', ASSET_MARKERS_SINGLE_ASSET_ID, onMarkerClick)
	}

	return {
		updateOrCreateAssetClusters,
		createMarkerClickHandler,
	}
}

export const addWaterLayer = ({ map }) => {
	if (map.getLayer(WATER_LAYER_ID)) {
		return
	}

	map.addLayer(
		{
			id: WATER_LAYER_ID,
			type: 'fill',
			source: 'composite',
			'source-layer': WATER_LAYER_SOURCE_ID,
			layout: {},
			paint: {
				'fill-color': '#cad2d3',
			},
		},
		AEROWAY_LINE // puts below of the roads/cities
	)
}

const generateMatchExpression = (countryCodeMasks, defaultColor) => {
	const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']]
	for (const { country_code } of countryCodeMasks) {
		matchExpression.push(country_code, defaultColor)
	}
	matchExpression.push('rgba(0, 0, 0, 0)')
	return matchExpression
}

export const addBufferingFixLayer = ({ map }) => {
	const matchExpression = generateMatchExpression(
		COUNTRY_CODE_MAP_MASKS,
		BUFFERING_LAYER_DEFAULT_COLOR
	)
	const WORLDVIEW = 'US'
	const worldview_filter = [
		'all',
		['==', ['get', 'disputed'], 'false'],
		[
			'any',
			['==', 'all', ['get', 'worldview']],
			['in', WORLDVIEW, ['get', 'worldview']],
		],
	]

	map.addSource('countries', {
		type: 'vector',
		url: 'mapbox://mapbox.country-boundaries-v1',
	})

	map.addLayer(
		{
			id: 'countries-join',
			type: 'fill',
			source: 'countries',
			'source-layer': 'country_boundaries',
			paint: {
				'fill-color': matchExpression,
			},
			filter: worldview_filter,
		},
		'country-label'
	)
}

export const useLayers = ({
	activeScenario,
	hazardType,
	selectedYear,
	selectedDefend,
}) => {
	const appConfig = getAppConfig()

	const layerId = useMemo(() => {
		if (
			!isHazardTypeValid(hazardType) ||
			!isYearValid(selectedYear) ||
			!isScenarioValid(activeScenario)
		) {
			return
		}

		if (
			appConfig.layerProperties[hazardType]?.hasDefend &&
			!isDefendValid(selectedDefend)
		) {
			return
		}

		const ids = [
			getDefaultScenario(activeScenario).scenario,
			selectedYear,
			hazardType,
		]

		ids.push(
			appConfig.layerProperties[hazardType]?.hasExtent
				? DEFAULT_EXTENT_VALUE
				: MAP_LAYER_ID_NOT_AVAILABLE
		)

		ids.push(
			appConfig.layerProperties[hazardType]?.hasDefend
				? selectedDefend === 'defended'
				: MAP_LAYER_ID_NOT_AVAILABLE
		)

		const layerId = ids.join(MAP_LAYER_ID_SEPARATOR)

		// TODO remove if statement when have storm surge defended cogs
		if (layerId.includes('storm_surge')) {
			const newLayerId = layerId.replace('true', 'false')
			return newLayerId
		} else {
			return layerId
		}
	}, [hazardType, selectedYear, activeScenario, selectedDefend])

	const prevLayerId = usePrevious(layerId)

	const hideLayerTiles = (map) => {
		if (map.getLayer(prevLayerId)) {
			map.setLayoutProperty(prevLayerId, 'visibility', 'none')
		}
	}

	const extractInfo = (layerId) => {
		// Check if layerId is undefined and handle accordingly
		if (typeof layerId === 'undefined') {
			// Return default values or handle the undefined case
			return { rcpValue: 'rcp26', hazardName: 'extreme_heat_days' }
		}

		// Regular expression to match the RCP value and hazard name
		const rcpRegex = /rcp\d{2}/
		const hazardRegex = /-(\d{4})-(.*?)-/

		const rcpMatch = layerId.match(rcpRegex)
		const rcpValue = rcpMatch ? rcpMatch[0] : 'rcp26'

		const hazardMatch = layerId.match(hazardRegex)
		const hazardName = hazardMatch ? hazardMatch[2] : 'extreme_heat_days'

		return { rcpValue, hazardName }
	}

	const hazardScenario = extractInfo(layerId)
	const storedLayersVersion = localStorage.getItem(
		`layers_version_${hazardScenario.rcpValue}-${hazardScenario.hazardName}`
	)
	const getTile = (layerId) => {
		return `${appConfig.layersApiUrl}/version_${storedLayersVersion}/environment_${appConfig.environment}/${layerId}/{z}/{x}/{y}.png`
	}

	const updateMaxBounds = (map, hazardType) => {
		if (LAYER_MAX_BOUNDS.hasOwnProperty(hazardType)) {
			map.setMaxBounds(LAYER_MAX_BOUNDS[hazardType])
		} else {
			map.setMaxBounds(null)
		}
	}

	const toggleOrCreateLayerTiles = ({ map }) => {
		if (!layerId) {
			return
		}

		hideLayerTiles(map)

		if (map.getLayer(layerId)) {
			map.setLayoutProperty(layerId, 'visibility', 'visible')
			// TODO: remove when layers will be updated/fixed
			updateMaxBounds(map, hazardType)
			return
		}

		map.addLayer(
			{
				id: layerId,
				source: {
					type: 'raster',
					tiles: [getTile(layerId)],
					tileSize: 512,
					minzoom: 3,
					maxzoom: 11,
				},
				layout: { visibility: 'visible' },
				type: 'raster',
				minzoom: 3,
				maxzoom: 17,
				paint: {
					'raster-resampling': 'nearest',
				},
			},
			WATER_LAYER_ID // puts below of the water layer
		)

		// TODO: remove when layers will be updated/fixed
		updateMaxBounds(map, hazardType)
	}

	return {
		layerId,
		hideLayerTiles,
		toggleOrCreateLayerTiles,
	}
}

export const useBuildings = ({ map }) => {
	const savedBuilding = useRef(null)

	const generateBuildingLayer = ({ map }) => {
		if (map.getLayer(BUILDINGS_SOURCE_ID)) {
			return
		}

		// Insert the layer beneath any symbol layer.
		const layers = map.getStyle().layers
		const labelLayerId = layers.find(
			(layer) => layer.type === 'symbol' && layer.layout['text-field']
		).id

		// The 'building' layer in the Mapbox Streets
		// vector tile set contains building height data
		// from OpenStreetMap.
		map.addLayer(
			{
				id: BUILDINGS_SOURCE_ID,
				source: 'composite',
				'source-layer': 'building',
				filter: ['==', 'extrude', 'true'],
				type: 'fill-extrusion',
				minzoom: 15,
				paint: {
					'fill-extrusion-color': [
						'case',
						['boolean', ['feature-state', 'selected'], false],
						'red',
						'#aaa',
					],

					// Use an 'interpolate' expression to
					// add a smooth transition effect to
					// the buildings as the user zooms in.
					'fill-extrusion-height': [
						'interpolate',
						['linear'],
						['zoom'],
						15,
						0,
						15.05,
						['get', 'height'],
					],
					'fill-extrusion-base': [
						'interpolate',
						['linear'],
						['zoom'],
						15,
						0,
						15.05,
						['get', 'min_height'],
					],
					'fill-extrusion-opacity': 0.6,
				},
			},
			labelLayerId
		)
	}

	const highlightBuilding = ({ buildingFeature }) => {
		if (!buildingFeature || !map) return
		savedBuilding.current = buildingFeature

		map.setFeatureState(
			{
				source: savedBuilding.current.source,
				sourceLayer: savedBuilding.current.sourceLayer,
				id: savedBuilding.current.id,
			},
			{
				selected: true,
			}
		)
	}

	const removeHighlightBuilding = () => {
		if (savedBuilding.current) {
			map.setFeatureState(
				{
					source: savedBuilding.current.source,
					sourceLayer: savedBuilding.current.sourceLayer,
					id: savedBuilding.current.id,
				},
				{
					selected: false,
				}
			)

			savedBuilding.current = null
		}
	}

	return { generateBuildingLayer, highlightBuilding, removeHighlightBuilding }
}

export const usePopup = ({ map, assetName }) => {
	const mapPopup = useRef(null)

	const removePopup = () => {
		if (mapPopup.current) {
			mapPopup.current.remove()
			mapPopup.current = null
		}
	}

	const setPopup = useCallback(
		async ({ assetFeature }) => {
			if (!map || !assetFeature) return

			removePopup()

			const { longitude, latitude } = assetFeature.properties || {}

			mapPopup.current = new mapboxgl.Popup({
				offset: 10,
				closeButton: false,
			})
				.setLngLat([longitude, latitude])
				.setHTML(assetName)
				.addTo(map)
		},
		[map, assetName]
	)

	return { removePopup, setPopup }
}
