import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { connect } from 'react-redux'
import { debounce, get, isEmpty, map, trim } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { ProjectList } from '../../../components/ProjectList'
import { SearchInput } from '../../../components/SearchInput'
import { Button } from '../../../components/Button'
import { PaginationNavigation } from '../../../components/PaginationNavigation'
import {
	getProjects,
	getProjectsLoading,
	getProjectsPaginationOptions,
	getProjectsSearchValue,
} from 'src/redux/selectors/projects'
import {
	fetchProjects,
	clearProjectsSearchAndRefetch,
} from 'src/redux/actions/projects'
import { MODAL_VIEWS } from 'src/containers/ModalViews'
import { addPopularProject } from 'src/redux/actions/popularProjects'
import { deleteProjects } from 'src/redux/actions/projects'
import { setModal, closeModal } from 'src/redux/actions/modal'
import { DEFAULT_PROJECTS_PAGINATION_STATE } from 'src/redux/reducers/projects'
import { createHomeLink } from '../../../utils'
import { DownloadProjects } from './DownloadProjects'
import { addToast } from '../../../redux/actions/toasts'
import { Skeleton } from '../../../components/Skeleton'
import './styles.scss'

export const _ProjectsBody = ({
	addPopularProject,
	clearProjectsSearchAndRefetch,
	location,
	pagination,
	fetchProjects,
	projects,
	deleteProjects,
	setModal,
	closeModal,
	addToast,
	projectsLoading,
	searchValue,
}) => {
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

	useEffect(() => {
		if (get(queryParams, 'projectId')) {
			addPopularProject(queryParams.projectId)
		}
	}, [queryParams, addPopularProject])

	const handleProjectSearch = useCallback(
		debounce((searchInput) => {
			if (trim(searchInput).length > 2) {
				fetchProjects({
					...DEFAULT_PROJECTS_PAGINATION_STATE,
					search_value: searchInput,
				})
			} else if (!trim(searchInput).length) {
				clearProjectsSearchAndRefetch()
			}
		}, 200),
		[]
	)

	const onDeleteAll = () => {
		setModal({
			viewKey: MODAL_VIEWS.CONFIRMATION_MODAL,
			viewProps: {
				question: `Are you sure you want to delete all projects from this page?`,
				onCancel: closeModal,
				onConfirm: () => {
					const projectIds = map(projects, 'id')
					deleteProjects(projectIds)
					closeModal()
				},
			},
		})
	}

	const openConfirmationModal = (url, callback, question) => {
		setModal({
			viewKey: MODAL_VIEWS.CONFIRMATION_MODAL,
			viewProps: {
				question: question,
				text: 'Please click the button below to download it.',
				confirmLabel: 'Download',
				cancelLabel: 'Cancel',
				onCancel: () => {
					closeModal()
					callback(null)
				},
				onConfirm: () => {
					window.open(url, '_blank')
					closeModal()
					callback(null)
				},
			},
		})
	}

	if (projects.length === 0 && !searchValue && !projectsLoading) {
		return (
			<div className='d-flex flex-column align-items-center justify-content-center h-100'>
				<img
					src='/img/empty_project_list.svg'
					className='w-25'
					alt='No input data'
				/>
				<h3 className='my-4'>No input data</h3>
				<Link to={createHomeLink()}>
					<Button type='secondary' onClick={() => {}}>
						<FontAwesomeIcon
							icon={['far', 'arrow-right']}
							className='me-3'
							size='lg'
						/>
						Get Started
					</Button>
				</Link>
			</div>
		)
	}

	return (
		<div className='container-fluid projections-body'>
			<div className='row mb-5'>
				<div className='col-8'>
					<h1 className='projections-body__header-title'>
						Projects List{' '}
						{projectsLoading ? (
							<Skeleton width={60} height={16} className='d-inline-block' />
						) : (
							<small>
								{pagination.offset + 1}-{pagination.offset + pagination.count}{' '}
								of {pagination.total}
							</small>
						)}
					</h1>
				</div>
				<div className='col-4'>
					<SearchInput
						placeholder='Search project list'
						onChange={(value) => handleProjectSearch(value)}
					/>
				</div>
			</div>
			<div className='row mb-4'>
				<div className='col-12'>
					<ProjectList />
				</div>
			</div>
			{projects.length ? (
				<div className='d-flex justify-content-between'>
					<div>
						<PaginationNavigation
							activeOffset={pagination.offset}
							total={pagination.total}
							limit={pagination.limit}
							onPageChange={(activePage) =>
								fetchProjects({ ...pagination, offset: activePage.offset })
							}
						/>
					</div>

					<div className='d-none d-md-block'>
						<div className='projections-body__actions'>
							{!isEmpty(projects) && (
								<Button
									type='secondary'
									className='projections-body__remove'
									onClick={onDeleteAll}
								>
									<FontAwesomeIcon icon={['far', 'trash-can']} />
									Delete all projects
								</Button>
							)}

							<DownloadProjects
								openConfirmationModal={openConfirmationModal}
								addToast={addToast}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	)
}

_ProjectsBody.propTypes = {
	fetchProjects: PropTypes.func.isRequired,
	projects: PropTypes.array.isRequired,
	pagination: PropTypes.object.isRequired,
	deleteProjects: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	projectsLoading: PropTypes.bool,
	clearProjectsSearchAndRefetch: PropTypes.func.isRequired,
	searchValue: PropTypes.string,
}

export const ProjectsBody = connect(
	(state) => ({
		pagination: getProjectsPaginationOptions(state),
		projects: getProjects(state),
		searchValue: getProjectsSearchValue(state),
		projectsLoading: getProjectsLoading(state),
	}),
	{
		fetchProjects,
		addPopularProject,
		deleteProjects,
		setModal,
		clearProjectsSearchAndRefetch,
		closeModal,
		addToast,
	}
)(_ProjectsBody)
