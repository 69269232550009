import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './styles.scss'

export const PageTitle = ({ title, className }) => {
	return (
		<div className={classNames('px-4', 'page-title', className)}>
			<div className='row'>
				<div className='col'>{title}</div>
			</div>
		</div>
	)
}

PageTitle.propTypes = {
	className: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}
