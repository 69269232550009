import React from 'react'
import { Skeleton } from '../../../components/Skeleton'

export const LoadingState = () => {
	return (
		<div className='project-summary_content'>
			<div className='row w-100 m-0 project-summary_content__main-title-row'>
				<div className='col-12 py-2'>
					<Skeleton width={120} height={16} className='my-1' />
				</div>
			</div>
			<div className='row w-100 m-0 project-summary_content__portfolio-analised'>
				<div className='col-12 col-md-4 py-2 project-summary_content__portfolio'>
					<Skeleton width={90} height={16} className='mb-3' />
					<div className='project-summary_content__section-score'>
						<Skeleton width={42} height={42} type='circle' className='mb-2' />
					</div>
				</div>
				<div className='col-12 col-md-4 py-2 project-summary_content__portfolio'>
					<Skeleton width={90} height={16} className='mb-3' />
					<div className='row justify-content-center'>
						<div className='col-6 d-flex project-summary_content__section-score'>
							<Skeleton width={42} height={42} type='circle' className='mb-2' />
						</div>
						<div className='col-6 d-flex project-summary_content__section-score'>
							<Skeleton width={42} height={42} type='circle' className='mb-2' />
						</div>
					</div>
				</div>
				<div className='col-12 col-md-4 py-2'>
					<Skeleton width={90} height={16} className='mb-3' />
					<div className='project-summary_content__section-score'>
						<Skeleton width={42} height={42} type='circle' className='mb-2' />
					</div>
				</div>
			</div>
			<div className='row w-100 m-0 project-summary_content__risc-score'>
				<div className='col-12 py-2'>
					<Skeleton width={90} height={16} className='mb-2' />
				</div>
				<div className='col-4 py-3 d-flex justify-content-center'>
					<Skeleton
						width={150}
						height={150}
						type='circle'
						className='mb-2 flex-shrink-none'
					/>
				</div>
				<div className='col-4 py-3 d-flex justify-content-center'>
					<Skeleton
						width={150}
						height={150}
						type='circle'
						className='mb-2 flex-shrink-none'
					/>
				</div>
				<div className='col-4 py-3 d-flex justify-content-center'>
					<Skeleton
						width={150}
						height={150}
						type='circle'
						className='mb-2 flex-shrink-none'
					/>
				</div>
			</div>
			<div className='row w-100 m-0'>
				<div className='col-12 py-2'>
					<Skeleton width={110} height={16} className='mb-2' />

					<div className='project-summary_content__section-score'>
						<Skeleton width={42} height={42} type='circle' className='mb-2' />
					</div>
				</div>
			</div>
			<div className='row w-100 mx-0 project-summary_content__volume-distribution-row'>
				<div className='col-12 py-2'>
					<Skeleton width={110} height={16} className='mb-2' />
					<div className='d-flex align-items-end' style={{ height: '100px' }}>
						<Skeleton width={16.6} height={25} measureUnit='%' />
						<Skeleton width={16.6} height={40} measureUnit='%' />
						<Skeleton width={16.6} height={30} measureUnit='%' />
						<Skeleton width={16.6} height={70} measureUnit='%' />
						<Skeleton width={16.6} height={50} measureUnit='%' />
						<Skeleton width={16.6} height={65} measureUnit='%' />
					</div>
				</div>
			</div>
			<div className='row w-100 m-0'>
				<div className='col-12 py-2'>
					<Skeleton width={110} height={16} className='mb-4' />

					<div className='row d-flex'>
						<div className='col-6 d-flex project-summary_content__loss justify-content-center'>
							<Skeleton width={80} height={16} className='mb-2' />
						</div>
						<div className='col-6 d-flex project-summary_content__loss justify-content-center'>
							<Skeleton width={80} height={16} className='mb-2' />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
