import { SET_MODAL, SET_MODAL_VIEW_PROPS, CLOSE_MODAL } from '../actions/modal'

/**
 * required keys for modal:
 * @key viewKey: string,
 * @key viewProps: object,
 * @key config: object,
 */

const INITIAL_STATE = []

export default function modal(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_MODAL:
			return [
				...state,
				{
					viewKey: action.viewKey,
					viewProps: action.viewProps,
					config: action.config,
				},
			]
		case SET_MODAL_VIEW_PROPS:
			return state.map((modal) => {
				if (modal.viewKey === action.viewKey) {
					return {
						...modal,
						viewProps: {
							...modal.viewProps,
							...action.viewProps,
						},
					}
				}
				return modal
			})
		case CLOSE_MODAL:
			return INITIAL_STATE
		default:
			return state
	}
}
