import React, { useCallback } from 'react'
import CountUp from 'react-countup'
import { numberWithSuffix } from '../../utils'
import { getUserSettings } from '../../redux/selectors/user'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './styles.scss'

const _Losses = ({
	displayFormat,
	riskCost,
	riskPercentage,
	riskLabel,
	userSettings,
	locatorId,
}) => {
	const costFormattingFn = useCallback(
		(value) => {
			return `${
				userSettings?.preferred_currency_symbol || ''
			}${numberWithSuffix(value)}`
		},
		[userSettings]
	)

	if (!['cost', 'percentage'].includes(displayFormat)) {
		return null
	}

	return (
		<div className='loss'>
			<div className='loss__amount' data-locator-id={locatorId}>
				{displayFormat === 'cost' &&
					(riskCost >= 0 ? (
						<CountUp
							start={0}
							end={riskCost}
							duration={1}
							delay={0}
							formattingFn={costFormattingFn}
						/>
					) : (
						<span className='loss__no-data'>-</span>
					))}
				{displayFormat === 'percentage' &&
					(riskPercentage >= 0 ? (
						<CountUp
							decimals={1}
							suffix='%'
							start={0}
							end={riskPercentage}
							duration={1}
							delay={0}
						/>
					) : (
						<span className='loss__no-data '>-</span>
					))}
			</div>
			<div className='loss__label'>{riskLabel}</div>
		</div>
	)
}

_Losses.defaultProps = {
	locatorId: 'not-set',
}

_Losses.propTypes = {
	displayFormat: PropTypes.oneOf(['cost', 'percentage']).isRequired,
	riskCost: PropTypes.number.isRequired,
	riskPercentage: PropTypes.number.isRequired,
	riskLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	userSettings: PropTypes.object,
}

export const Losses = connect((state) => ({
	userSettings: getUserSettings(state),
}))(_Losses)
